import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import { CanvasType, IAspectRatioProps, ContextType } from '../MapCanvas.interface'
import WarehouseStore from '@/store/Warehouse/Warehouse'
import WhZoneStore from '@/store/Warehouse/WhZone'
import RackStore from '@/store/Warehouse/Racks'

configure({
	enforceActions: 'never'
})

class CanvasStore {
	canvas: CanvasType | null = null
	context: ContextType | null = null
	// Соотношение сторон
	aspectRatio = 0
	maxCanvasHeight = 500
	maxCanvasWidth = 500

	// Взята ли зона
	startZonePicking: boolean = false
	// Взята ли начальная точка
	startDotPicking: boolean = false
	// Нажато ли перемещение
	pressed: boolean = false

	production = false

	fetchData = async ({ zoneId, canvas }: { zoneId: number; canvas: CanvasType }) => {
		try {
			// Получаем все складские зоны
			await WarehouseStore.fetchWarehouseView()
			// Выбираем текущуюю
			await WarehouseStore.filterWarehouseByZone(zoneId)
			// Получить складской зоны
			await WhZoneStore.fetchWHZone(zoneId)
			// Установка фона карты
			if (WhZoneStore.WHZone && canvas.current) {
				canvas.current.style.backgroundImage = `url("data:image/png;base64, ${WhZoneStore.WHZone?.background}")`
			}
			//Получаем все стеллажи
			await RackStore.fetchRacks(zoneId)
			await RackStore.filterRacksByZone(zoneId)
			// Получать модели стеллажей
			await RackStore.fetchRackModels()
			// Первая отрисовка
			this.render()
		} catch (e) {
			console.log(e)
		}
	}

	// высчитывание коэффициента соотношения сторон
	aspestCalc = ({ maxCanvasWidth, maxCanvasHeight, canvas }: IAspectRatioProps) => {
		if (!canvas || !canvas.current || !WarehouseStore.warehouse) return
		const zoneHeight = Number(WarehouseStore.warehouse.length)
		const zoneWidth = Number(WarehouseStore.warehouse.width)

		// Если высота больше чем ширина
		if (zoneHeight > zoneWidth) {
			/*
				пусть height = 43500,
				пусть width = 1500
				1500/43500 = 0.034482758620689655
			*/
			const aspect = zoneWidth / zoneHeight
			// height = 500
			canvas.current.height = maxCanvasHeight
			// width = 500 * 0.034482758620689655
			canvas.current.width = maxCanvasHeight * aspect
			// Сохранение коэффициента
			this.aspectRatio = aspect
		}
		// Если ширина больше чем ширина
		else {
			/*
				пусть height = 1500,
				пусть width = 43500
				1500/43500 = 0.034482758620689655
			*/
			const aspect = zoneHeight / zoneWidth
			// width = 500
			canvas.current.width = maxCanvasWidth
			// height = 500 * 0.034482758620689655
			canvas.current.height = maxCanvasWidth * aspect
			// Сохранение коэффициента
			this.aspectRatio = aspect
		}
	}

	render() {
		if (!this.canvas?.current || !this.context) return
		// Очистка холста
		this.context.clearRect(0, 0, this.canvas.current.clientWidth, this.canvas.current.clientHeight)
		RackStore.canvasAdaptation()
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new CanvasStore()
