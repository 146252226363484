import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef, useState } from 'react'
import st from './AddProductsPage.module.scss'
import searchImg from '@/assets/search-bolder.svg'
import quitIcon from '@/assets/quit.svg'
import createIcon from '@/assets/create.svg'
import { NavLink } from 'react-router-dom'
import AddProductPage from '@/store/AddProductPage'
import { pushProducts } from '@/API/AddProductPageAPI'
import Product from '@/components/PushProduct/Product/Product'
import AddProductsPopup from './AddProductsPopup'
import AddProductsModal from './AddProductsModal'
import { findProduct } from '@/API/PushProducts'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { ToastContainer } from 'react-toastify'
import AddСategory from '@/store/AddСategoryPage'
import { getCategories } from '@/API/ChangeCategoryPageAPI'
import { notification } from '@/utils/notification'
import { toJS } from 'mobx'
import { useIsInViewport } from '@/hooks/useIsInViewport'
import { Language } from '@/utils/langSwitch'
import { dict } from './TranslationList'
import Logotype from '@/components/Logotype'
import { IProduct } from '@/types/createorder'

const t = Language.translateWithDictionary(dict)
const AddProductsPage: FC<any> = ({ main }) => {
	const ref1 = useRef(null)
	const isInViewport1 = useIsInViewport(ref1)
	useEffect(() => {
		if (AddСategory.categories.length === 0) {
			getCategories()
		}
		if (typeof window !== 'undefined') {
			injectStyle()
		}
	}, [])

	useEffect(() => {
		if (!isInViewport1) return
		const nextPage = async () => {
			if (AddProductPage.category === 0) {
				await pushProducts(AddProductPage.PagesStructure.page)
			} else {
				await pushProducts(AddProductPage.PagesStructure.page, AddProductPage.category)
			}
		}
		nextPage()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInViewport1])

	const openModal = (e: IProduct) => {
		console.log(toJS(AddProductPage.modal))
		document.body.style.overflow = 'hidden'
		AddProductPage.modal = e
	}

	const openPopup = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
		AddProductPage.popup = !AddProductPage.popup
	}

	// =========================QRCode Handler===========================
	const [value, setValue] = useState<string>('')
	useEffect(() => {
		console.log(value)
		if (value === '') return
		const finded = async () => {
			const e = await findProduct(value)
			if (e) {
				openModal(e)
				notification({ text: t('Товар найден в базе') })
				notification({ text: value })
				setValue('')
			} else {
				notification({ text: t('Товар не найден в базе'), type: 'error' })
				notification({ text: value, type: 'error' })
				setValue('')
			}
		}
		finded()
	}, [value])
	// ===================================================================

	return (
		<>
			<div className={st.header}>
				<div className={st.container}>
					<Logotype />
					<div className={st.headicons}>
						<select
							onChange={e => {
								AddProductPage.PagesStructure.page = 1
								AddProductPage.PagesStructure.totalPages = 0
								AddProductPage.PagesStructure.total = 0
								if (AddProductPage.products) {
									AddProductPage.products.products = []
								}
								pushProducts(AddProductPage.PagesStructure.page, Number(e.target.value))
								AddProductPage.category = Number(e.target.value)
							}}>
							<option defaultChecked value='0'>
								Всё
							</option>
							{AddСategory.categories.map(category => (
								<option value={category.id}>{category.categoryname}</option>
							))}
						</select>
						<img className={st.searchIcon} src={createIcon} alt='' onClick={e => openPopup(e)} />

						<img className={st.searchIcon} src={searchImg} alt='' />
						<NavLink to={'/'}>
							<img className={st.searchIcon} src={quitIcon} alt='' />
						</NavLink>
					</div>
				</div>
			</div>

			<div className={`${st.productlist} ${st.container} relative`}>
				{AddProductPage.products &&
					AddProductPage.getFiltred().map(product => (
						<Product product={product} key={product.id} />
					))}
			</div>
			<div style={{ height: '30px' }} ref={ref1}></div>
			{/* <BtnHandler setValue={setValue} /> */}
			{AddProductPage.popup && <AddProductsPopup />}
			{AddProductPage.modal && <AddProductsModal />}
			<ToastContainer />
		</>
	)
}

export default observer(AddProductsPage)
