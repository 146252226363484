import { $authHost, errorHandler } from ".";

interface IPost1Cproducts {
  category_id: number;
  products: any;
}

export const post1Cproducts = async ({
  category_id,
  products,
}: IPost1Cproducts) => {
  try {
    const formData = new FormData();
    formData.append("category_id", `${category_id}`);
    formData.append("products", `${products}`);
    const { data } = await $authHost.post(`/api/import1cproducts`, formData);
    return data;
  } catch (e) {
    errorHandler(e, () => post1Cproducts({ category_id, products }));
  }
};

export const post1CStore = async (products: any) => {
  try {
    const formData = new FormData();
    formData.append("products", `${products}`);
    const { data } = await $authHost.post(`/api/import1cstore`, formData);
    return data;
  } catch (e) {
    errorHandler(e, () => post1Cproducts(products));
  }
};


export const post1COrders = (orders: any) => {

}