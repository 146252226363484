import { FC } from 'react'
import { IBreadcrumbsProps } from '../Breadcrumbs.interface'
import { observer } from 'mobx-react-lite'
import BreadCrumbItem from './BreadCrumbItem'

const LinksList: FC<{ links: IBreadcrumbsProps[] }> = ({ links }) => {
	return (
		<>
			{links.map((link, idx) => {
				if (idx === 0) return <></>
				if (idx === links.length - 1) return <></>
				return <BreadCrumbItem link={link} key={`${idx}`} />
			})}
		</>
	)
}

export default observer(LinksList)
