import { deleteOrderAPI, updateOrderAPI } from './../API/fastomatWorkerAPI'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import { IFastomatAPI } from '../types/fastomatWorker'

configure({
	enforceActions: 'never'
})

class FastomatWorker {
	orders: IFastomatAPI[] = []
	RdyOrder: IFastomatAPI | null = null
	filted: boolean = true
	addModal: boolean = false
	delay: boolean = false
	request: number = 6
	PickWin: boolean = false
	EditQr: IFastomatAPI | null = null
	isQrEdit: boolean = false
	DeleteOrder = async (order: IFastomatAPI) => {
		if (order.orderstate !== 10) {
			await updateOrderAPI({
				...order,
				orderstate: 10
			})
			return
		}
		await deleteOrderAPI(order.id)
	}
	constructor() {
		makeAutoObservable(this)
	}
}

export default new FastomatWorker()
