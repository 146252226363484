export const stages = [
  {
    name: "Создание/Выбор инструкции",
  },
  {
    name: "Изменение инструкции",
  },
  {
    name: "Изменение стадии",
  },
  {
    name: "Изменения списка компанентов",
  },
  {
    name: "Сохранение результатов",
  },
];
