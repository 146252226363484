export const cutMaxTextLength = (
	text?: string | null,
	len: number = 50
): string => {
	if (!text) return ''
	if (text.length > len) {
		return text.substr(0, len) + '...'
	}
	return text.substr(0, len)
}
