import st from '../../Fastomat.module.scss'
import { FC } from 'react'
import { stateHandler } from '@/utils/statehandler'
import Selector from './Selector'
import { observer } from 'mobx-react-lite'
import { IFastomatAPI } from '@/types/fastomatWorker'

interface Props {
	edited: boolean
	state: number
	ChangeState(value: number): void
	order: IFastomatAPI
}
interface StateStableProps {
	order: IFastomatAPI
}
interface StateNowChangesProps {
	state: number
	ChangeState(value: number): void
}
const StateChanger: FC<Props> = observer(
	({ edited, state, ChangeState, order }) => {
		if (edited)
			return <StateNowChanges state={state} ChangeState={ChangeState} />
		else return <StateStable order={order} />
	}
)

const StateNowChanges: FC<StateNowChangesProps> = observer(
	({ ChangeState, state }) => {
		return <Selector state={state} ChangeState={ChangeState} />
	}
)

const StateStable: FC<StateStableProps> = observer(({ order }) => {
	return <p className={st.state}>{stateHandler(order.orderstate)}</p>
})

export default StateChanger
