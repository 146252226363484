import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import st from './Product.module.scss'
import arrowAnimation from '../../../assets/next.png'
import ProductName from '../../ProductName'
import { NavLink } from 'react-router-dom'
import { IProduct } from '@/types/createorder'
import ProductImg from '@/components/ProductImg/ProductImg'



const Product: FC<{product: IProduct}> = ({ product }) => {
	return (
		<NavLink to={`push/${product.id}`}>
			<div className={st.product} >
				<div className={st.productphoto}>
						<ProductImg product={product} />
				</div>
				<div className={st.productname}>
					<ProductName
						ProductName={product.productname}
						ProductNameEng={product.ProductNameEng}
					/>
				</div>
				<div className={st.productselect}>
					<img src={arrowAnimation} alt='arrow' />
				</div>
			</div>
		</NavLink>
	)
}
export default observer(Product)
