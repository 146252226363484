import { observer } from 'mobx-react-lite'
import { Language } from '../../utils/langSwitch'
import { cutMaxTextLength } from '../../utils/maxText'
import { NavLink } from 'react-router-dom'
import { FC } from 'react'
import { PRODUCT_ROUTE } from '../../utils/consts'

interface IProps {
	id: number
	productname: string | null
	ProductNameEng: string | null
}

const ProductName: FC<IProps> = ({ id, productname, ProductNameEng }) => {
	const text =
		Language.current === 'ru' ? productname || '' : ProductNameEng || ''
	return (
		<NavLink to={`${PRODUCT_ROUTE}/${id}`}>
			<h5 className='text-sm md:text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>
				{cutMaxTextLength(text, 19)}
			</h5>
		</NavLink>
	)
}

export default observer(ProductName)
