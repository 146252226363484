import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import { IProduct, IResponceProductList } from '../types/createorder'
import { FetchStages } from '../types'
import { $authHost } from '@/API'

configure({ enforceActions: 'never' })

class Products {
	// стадия запроса
	isFetching: string = FetchStages.waiting
	// все продукты
	Products: IProduct[] = []
	// отфильтрованные продукты
	FiltredProducts: IProduct[] = []
	// id категории фильтрации
	Filtred: number = 0
	// корзина
	basket: IProduct[] = []
	PagesStructure = {
		page: 1,
		total: 0,
		totalPages: 0
	}

	getProduct = async (product_id: number) => {
		const currentProduct = this.Products.find(
			product => product.id === product_id
		)
		if (currentProduct) {
			return currentProduct
		} else {
			const { data } = await $authHost.get<IResponceProductList>(
				`api/products/?id=${product_id}`
			)
			if (!data.products || data.products.length === 0) return null
			return data.products[0]
		}
	}

	addToBasket(id: number): void {
		const obj = this.Products.find(elem => elem.id === id)
		if (!obj) return
		if (obj.basketcount) {
			obj.basketcount = obj.basketcount + 1
		} else {
			obj.basketcount = 1
			this.basket.push(obj)
		}
	}

	getFiltredByCategory() {
		if (this.Filtred === 0) {
			this.FiltredProducts = this.Products
			return
		}
		this.FiltredProducts = this.Products.filter(
			e => e.category_id === this.Filtred
		)
	}

	removeFromBasket(id: number): void {
		const obj = this.Products.find(elem => elem.id === id)
		if (!obj) return
		if (obj.basketcount && obj.basketcount > 1) {
			obj.basketcount = obj.basketcount - 1
		} else {
			this.basket = this.basket.filter(elem => elem.id !== id)
			delete obj.basketcount
		}
	}

	// выбрать все товары в корзине
	checkAllBasket(): void {
		if (this.basket.every(elem => elem?.checked)) {
			this.basket.forEach(elem => (elem.checked = false))
		} else {
			this.basket.forEach(elem => (elem.checked = true))
		}
	}

	// выбрать товар в корзине
	checkProduct(product: IProduct): void {
		if (product.checked) {
			product.checked = false
		} else {
			product.checked = true
		}
	}

	// сумма продуктов в корзине
	getSummary(): string {
		return `${this.basket
			.filter(e => e.checked)
			.reduce((acc, val) => {
				return (val.basketcount || 0) * Number(val.CurrentPrice) + acc
			}, 0)} ₽`
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new Products()
