import {
	ChangePayload,
	Client,
	IWSConnectProps,
	WSMessage
} from '@/types/WebSocket'
import { notification } from '../../utils/notification'
import TYPicking from '../TYPicking'
import RackGrid from '../RackGrid'
import { IProducts } from '@/types/TYPicking'
import { sleep } from '@/utils/sleep'
import { configure, makeAutoObservable } from 'mobx'

configure({ enforceActions: 'never' })

class WebSocketClient {
	// вебсокет
	websocket: null | WebSocket = null
	// состояние подключения
	connectionState: boolean = false
	products: IProducts[] = []
	id: null | number = 0
	method: null | number = 0
	orderid: number | null = null
	// id монитора стеллажа
	collector_id: number | null | string = null
	// Экран стеллажа подключен
	hasCollectorDisplay = false
	// Подключение к WS севреру
	connect = ({ DeviceTypeId, DeviceModelId }: IWSConnectProps) => {
		const url = process.env.REACT_APP_WEBSOCKET_URL || ''
		this.websocket = new WebSocket(url)
		this.websocket.onopen = () => 
		this.onOpen({ DeviceTypeId, DeviceModelId })
		this.websocket.onmessage = event => 
		this.onMessage(event)
		this.websocket.onerror = () => 
		this.onError({ DeviceTypeId, DeviceModelId })
		this.websocket.onclose = () => 
		this.onClose({ DeviceTypeId, DeviceModelId })
	}
	// WebSocket Events
	// Открытие WS соединения
	onOpen = ({
		DeviceId = '1',
		DeviceTypeId,
		DeviceModelId
	}: IWSConnectProps) => {
		if (!this.websocket) return
		notification({ text: 'WS соединение открыто' })
		this.connectionState = true
		this.send({
			action: 'connect',
			payload: {
				device_id: DeviceId,
				device_type: DeviceTypeId,
				device_model: DeviceModelId
			}
		})
		this.send({
			action: 'getclients',
			payload: {}
		})
	}
	// Закрытие WS соединения
	onClose = async (props: IWSConnectProps) => {
		this.connectionState = false
		notification({ text: 'Ошибка в WS соединении', type: 'error' })
		console.log('Socket произошла ошибка')
		// Переподключение через 10 секунды
		await sleep(10000)
		this.connect(props)
	}
	// Ошибка при WS соединении
	onError = async (props: IWSConnectProps) => {
		this.connectionState = false
		notification({ text: 'Ошибка в WS соединении', type: 'error' })
		console.log('Socket произошла ошибка')
		// Переподключение через 10 секунды
		await sleep(10000)
		this.connect(props)
	}
	// Сообщение из WS соединении
	onMessage = (event: MessageEvent<any>) => {
		const message = JSON.parse(event.data)
		const { action, payload }: WSMessage = message
		switch (action) {
			case 'collected':
				this.collected(payload.device_id)
				break
			case 'getclients':
				this.getClients(payload.clients)
				break
			case 'collect_sync':
				this.collectSync(payload.products)
				break
			case 'change':
				this.change(payload)
		}
	}
	// WebSocket Functions
	send = (data: any) => {
		if (!this.websocket || !this.connectionState) return
		this.websocket.send(JSON.stringify(data))
	}

	getClients = async (clients: Client[]) => {
		// смотрим изменения клиентов
		let collector_founded = false
		let collector_id: number | null | string = null
		clients.forEach(client => {
			if (client.device_type === 2 && client.device_model === 5) {
				// device_type = 2 это сборщик, если сборщик найден =>
				// указываем это
				collector_founded = true
				collector_id = client.device_id
			}
		})
		console.log(
			`%cсборщик ${collector_founded ? 'подключен' : 'не найден'}`,
			`color: ${collector_founded ? 'green' : 'red'}`
			
		)
		this.hasCollectorDisplay = collector_founded
		this.collector_id = collector_id
	}

	collectSync(payload: IProducts[]) {
		const rack = payload[0].positions[0].rack
		this.products = this.products.map(e => {
			const obj = payload.find(product => product.id === e.id)
			if (obj) {
				return obj
			} else {
				return e
			}
		})
		RackGrid.drawRacks(rack, this.orderid)
	}

	collected = async (device_id: string | number) => {
		if (this.hasCollectorDisplay) {
			// игнорировать
			console.log('Сборщик подключен')
		} else {
			const collectedProducts = await TYPicking.collectAllProducts(device_id)
			this.send({
				action: 'collect_sync',
				payload: {
					device_id: '1',
					products: collectedProducts
				}
			})
		}
	}
	change = async (payload: ChangePayload) => {
		if (this.orderid !== payload.orderid) {
			this.products = []
			RackGrid.isRacksGetted = []
			await RackGrid.drawRacks(payload.rack, payload.orderid)
			this.orderid = payload.orderid
			RackGrid.firstReSend = false
		}
		RackGrid.flash(payload.col, payload.row)
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new WebSocketClient()
