import { configure, makeAutoObservable } from 'mobx'
import { MouseEvent } from 'react'
import Canvas from './Canvas'
import WhZone from '@/store/Warehouse/WhZone'
import RacksStore from '@/store/Warehouse/Racks'

configure({
	enforceActions: 'never'
})

export class Controls {
	down = false

	coffX = 1
	coffY = 1

	/* Взял объект */
	handleMouseDown = (event: MouseEvent<HTMLCanvasElement>) => {
		if (Canvas.production) return
		this.down = true
		const zoneX = Number(WhZone.WHZone?.whzone_x)
		const zoneY = Number(WhZone.WHZone?.whzone_y)
		const mouseX =
			Math.floor((event.pageX - event.currentTarget.offsetLeft) / this.coffX) +
			zoneX
		const mouseY =
			Math.floor((event.pageY - event.currentTarget.offsetTop) / this.coffY) +
			zoneY

		RacksStore.racks.forEach(rack => {
			const rackModel = RacksStore.rackModels.find(
				model => model.id === rack.Model_id
			)
			if (!rack.xcoord || !rack.ycoord || !rackModel) return

			// Ширина стеллажа = кол-во ячеек * ширину 1-ой ячейки
			const rackWidth = rackModel.colnumber * rackModel.cellwidth
			const rackHeight = rackModel.cellheight

			// Входит в область определения Х
			const inXCoord =
				rack.xcoord - rackWidth / 2 < mouseX &&
				mouseX < rack.xcoord + rackWidth / 2
			// Входит в область определения Y
			const inYCoord =
				rack.ycoord - rackHeight / 2 < mouseY &&
				mouseY < rack.ycoord + rackHeight / 2

			if (inXCoord && inYCoord) {
				RacksStore.racks.forEach(e => (e.active = false))
				RacksStore.selectedRack = rack
				console.log(rack)
				rack.active = true
			}
		})
	}

	/* Двигаешь объект */
	handleMouseMove = (event: MouseEvent<HTMLCanvasElement>) => {
		if (Canvas.production) return
		if (!this.down) return
		this.coffRecalculate()
		// поиск модели перетаскаемого стеллажа
		const rackModel = RacksStore.rackModels.find(model => {
			if (!RacksStore.selectedRack) return false
			return model.id === RacksStore.selectedRack.Model_id
		})
		// Дополнительные пол стеллажа высоты для синхр. с мышкой
		const addX = rackModel ? rackModel.celldepth : 0
		const zoneX = Number(WhZone.WHZone?.whzone_x)
		const zoneY = Number(WhZone.WHZone?.whzone_y)
		const mouseX =
			Math.floor((event.pageX - event.currentTarget.offsetLeft) / this.coffX) +
			zoneX
		const mouseY =
			Math.floor((event.pageY - event.currentTarget.offsetTop) / this.coffY) +
			addX +
			zoneY
		if (RacksStore.selectedRack) {
			RacksStore.selectedRack.xcoord = mouseX
			RacksStore.selectedRack.ycoord = mouseY
		}
	}

	/* Отпустил объект */
	handleMouseUp = (event: MouseEvent<HTMLCanvasElement>) => {
		if (Canvas.production) return
		this.down = false
	}

	// Пересчёт коэффициента
	coffRecalculate() {
		if (Canvas.canvas && Canvas.canvas.current && WhZone.WHZone) {
			if (Canvas.canvas.current.clientWidth < WhZone.WHZone.width) {
				this.coffX = Canvas.canvas.current.clientWidth / WhZone.WHZone.width
			}
			if (Canvas.canvas.current.clientHeight < WhZone.WHZone.length) {
				this.coffY = Canvas.canvas.current.clientHeight / WhZone.WHZone.length
			}
		}
	}

	constructor() {
		this.coffRecalculate()
		makeAutoObservable(this)
	}
}

export default new Controls()
