import st from '../../Fastomat.module.scss'
import { FC } from 'react'
import { Language } from '../../../../utils/langSwitch'
import { dict } from './TranslationList'

const t = Language.translateWithDictionary(dict)
interface MainProps {
	edited: boolean
	ChangeBtn(): void
}

interface SaveButtonProps {
	ChangeBtn(): void
}
interface EditButtonProps {
	ChangeBtn(): void
}

const EditBtn: FC<MainProps> = ({ edited, ChangeBtn }) => {
	if (!edited) return <EditButton ChangeBtn={ChangeBtn} />
	else return <SaveButton ChangeBtn={ChangeBtn} />
}

export default EditBtn

const SaveButton: FC<SaveButtonProps> = ({ ChangeBtn }) => {
	return (
		<div className={st.addBtnAddictional} onClick={() => ChangeBtn()}>
			<p>{t('Сохранить')}</p>
		</div>
	)
}

const EditButton: FC<EditButtonProps> = ({ ChangeBtn }) => {
	return (
		<div className={st.addBtnAddictional} onClick={() => ChangeBtn()}>
			<p>{t('Изменить')}</p>
		</div>
	)
}
