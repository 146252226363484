import st from '../Fastomat.module.scss'
import { FC } from 'react'
import Archive from './PostFooter/Archive'
import { observer } from 'mobx-react-lite'
import FastomatWorker from '../../../store/FastomatWorker'
import { Language } from '../../../utils/langSwitch'
import { dict } from './TranslationList'

// Переводчик
const t = Language.translateWithDictionary(dict)

const MainButtons: FC = () => {
  const openModal = () => {
    FastomatWorker.addModal = true
  }
	return (
		<div
			className={`mt-4 flex justify-between 
        container items-center max-w-[980px] 
        mx-auto`}>
			<h3 className='text-4xl'>{t('Мир БЕЗ ОЧЕРЕДЕЙ')}</h3>
			{/* 
        <p>Проект Возьми Свое - технологии 
        бесконтактной выдачи заказов</p> 
      */}
			<Archive />
			<div
				className={st.addBtn}
				onClick={openModal}>
				<p>{t('Добавить новый заказ')}</p>
			</div>
		</div>
	)
}

export default observer(MainButtons)
