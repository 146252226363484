import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { postWarehouseEmpl } from "../../../API/TYPickingAPI";
import { ITreeWarehouse } from "../../../types/Warehouse";
import st from "./WareHouseItem.module.scss";
import { dict } from "../TranslationList";
import { Language } from "../../../utils/langSwitch";

const t = Language.translateWithDictionary(dict);
interface IProps {
  e: ITreeWarehouse;
}

const WareHouseItem: FC<IProps> = ({ e }) => {
  const [visible, setVisible] = useState<boolean>(false);
  let navigate = useNavigate();
  const selecyWarehouse = async (whzone: number) => {
    await postWarehouseEmpl(whzone);
    navigate('/orders')
  };

  let { zones } = e;
  return (
    <>
      <div onClick={() => setVisible((prev) => !prev)}>
        <div className={st.item} style={{ textAlign: "center" }}>
          <div className={st.name}>
            <p>{zones[0].whname}</p>
          </div>
          <div>
            <p>{zones[0].adress}</p>
            <p>{zones[0].phone}</p>
          </div>
          <div className={st.name}>
            <p>{zones[0].emp_name}</p>
          </div>
        </div>

        <div className={st.zones}>
          {zones.map((zone) => {
            return (
              <NavLink
                to={`/orders/`}
                key={zone.ZoneName}
                onClick={() => selecyWarehouse(zone.WH_id)}
                style={visible ? { display: "flex" } : { display: "none" }}
              >
                <div className={st.zone}>
                  <p>{t("Зона")}:</p>
                  <div>{zone.ZoneName}</div>
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default observer(WareHouseItem);
