import GantSettings from '@/store/Settings/Gant/Gant'
import DragAndDrop from '@/store/GantProduction/DragAndDrop'
import GantTasks from '@/store/GantProduction/GantTasks'
import { cutMaxTextLength } from '@/utils/maxText'
import { observer } from 'mobx-react-lite'

const GantTasksList = () => {
	return (
		<>
			{GantTasks.filteredTasks.map(task => (
				<div className='flex flex-col'>
					<div
						draggable={DragAndDrop.draggable}
						onDragStart={event => DragAndDrop.dragStart({ event, task })}
						onDragLeave={event => DragAndDrop.dragLeave({ event, task })}
						onDragEnd={event => DragAndDrop.dragEnd({ event, task })}
						onDragOver={event => DragAndDrop.dragOver({ event, task })}
						onDrop={event => {
							DragAndDrop.dragDrop({ event, task })
						}}
						style={{
							// background: `${task.color || ''}`,
							// color: `${task.color ? 'white' : 'black'}`,
							opacity: `${task.color ? '1' : ''}`,
							height: `${
								GantSettings.dayHeight > GantSettings.minimalDayHeight
									? GantSettings.dayHeight
									: GantSettings.minimalDayHeight
							}px`
						}}
						className={`w-full flex text-center
            items-center pl-4 py-[23px] 
          hover:bg-gray-100 cursor-pointer`}>
						<p className='w-1/12'>{task.id}</p>
						<p className='w-2/12'>{cutMaxTextLength(task.client, 10)}</p>
						<p className='w-3/12'>{cutMaxTextLength(task.product, 13)}</p>
						<p className='w-2/12'>{task.technology}</p>
						<p className='w-2/12'>{task.created}</p>
						<p className='w-2/12'>{task.ready}</p>
						<p className='w-2/12'>{task.shipment}</p>
						<p className='w-1/12'>{task.sortorder}</p>
					</div>
				</div>
			))}
		</>
	)
}

export default observer(GantTasksList)
