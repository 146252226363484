import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import st from "./AddProductsPage.module.scss";
import logoImg from "../../assets/logo.png";
import AddProductPage from "../../store/AddProductPage";
import { postProduct } from "../../API/AddProductPageAPI";
import { getCategories } from "../../API/ChangeCategoryPageAPI";
import AddСategory from "../../store/AddСategoryPage";
import { Language } from "../../utils/langSwitch";
import { dict } from "./TranslationList";

const t = Language.translateWithDictionary(dict);
const AddProductsPopup: FC = () => {
  const closeButton = useRef(null);
  const closeButton2 = useRef(null);
  const closeButton3 = useRef(null);

  const gotoWebsite = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Вы уверены, что хотите перейти на сайт TakeYours?")) {
      window.open("https://www.take-yours.online/");
    }
  };

  const [ProductName, setProductName] = useState("");
  const [ProductDescr, setProductDescr] = useState("");
  const [SizeWidth, setSizeWidth] = useState("");
  const [SizeHeight, setSizeHeight] = useState("");
  const [SizeDepth, setSizeDepth] = useState("");
  const [Weight, setWeight] = useState("");
  const [OnlyTop, setOnlyTop] = useState<boolean>(false);
  const [BarCode, setBarCode] = useState("");
  const [CurrentPrice, setCurrentPrice] = useState("");
  const [Category_id, setCategory_id] = useState("");
  const [photo, setPhoto] = useState<string>("");
  const [currentFileList, setCurrentFileList] = useState<FileList | null>(null);

  const close = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (
      e.target === closeButton.current ||
      e.target === closeButton2.current ||
      e.target === closeButton3.current
    ) {
      AddProductPage.popup = !AddProductPage.popup;
    }
  };

  const sendData = () => {
    (async () => {
      let data = await postProduct({
        ProductName,
        ProductDescr,
        SizeWidth: Number(SizeWidth),
        SizeHeight: Number(SizeHeight),
        SizeDepth: Number(SizeDepth),
        OnlyTop,
        BarCode,
        CurrentPrice,
        Weight: Number(Weight),
        productphoto: currentFileList,
        Category_id: Number(Category_id),
      });
      AddProductPage.popup = !AddProductPage.popup;
    })();
  };

  return (
    <div ref={closeButton} className={`${st.popup} h-screen`} onClick={(e) => close(e)}>
      <div className={st.popupContent}>
        <div className={st.popupHeader}>
          <img src={logoImg} onClick={() => gotoWebsite()} alt="logotype" />
          <div className={st.content}>{t("Добавить продукт")}</div>
          <div className={st.close} ref={closeButton2}>
            <p ref={closeButton3}>X</p>
          </div>
        </div>

        <div className="w-full px-4">
          <div className="flex w-full mt-6">
            <p className="w-32">{t("Имя")}</p>
            <input
              type="text"
              className="w-full px-2 text-sm border-b-2 border-gray-400 outline-none opacity-80 focus:border-blue-400"
              value={ProductName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>

          <div className="flex w-full mt-6 gap-4">
            <p>{t("Описание")}</p>
            <input
              type="text"
              className="w-full px-2 text-sm border-b-2 border-gray-400 outline-none opacity-80 focus:border-blue-400"
              value={ProductDescr}
              onChange={(e) => setProductDescr(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-3 mt-6">
            <div className="flex gap-5">
              <p>{t("Ширина")}</p>
              <input
                type="text"
                value={SizeWidth}
                className="w-full px-2 text-sm border-b-2 border-gray-400 outline-none opacity-80 focus:border-blue-400"
                onChange={(e) =>
                  setSizeWidth(
                    isNaN(Number(e.target.value)) ? "" : e.target.value
                  )
                }
              />
            </div>
            <div className="flex gap-5 ml-4">
              <p>{t("Высота")}</p>
              <input
                type="text"
                value={SizeHeight}
                className="w-full px-2 text-sm border-b-2 border-gray-400 outline-none opacity-80 focus:border-blue-400"
                onChange={(e) =>
                  setSizeHeight(
                    isNaN(Number(e.target.value)) ? "" : e.target.value
                  )
                }
              />
            </div>
            <div className="flex gap-5 ml-4">
              <p className="w-32">{t("Глубина")}</p>
              <input
                type="text"
                value={SizeDepth}
                className="w-full px-2 text-sm border-b-2 border-gray-400 outline-none opacity-80 focus:border-blue-400"
                onChange={(e) =>
                  setSizeDepth(
                    isNaN(Number(e.target.value)) ? "" : e.target.value
                  )
                }
              />
            </div>
          </div>
          <div className="flex mt-6 gap-4">
            <div className="flex items-center gap-5">
              <p>{t("Только сверху")}</p>
              <input
                type="checkbox"
                className="w-full px-2 text-sm border-b-2 border-gray-400 outline-none opacity-80 focus:border-blue-400"
                onChange={(e) => setOnlyTop(e.target.checked)}
              />
            </div>
            <div className="flex items-center gap-5">
              <p>{t("Вес")}</p>
              <input
                type="text"
                className="w-full px-2 text-sm border-b-2 border-gray-400 outline-none opacity-80 focus:border-blue-400"
                value={Weight}
                onChange={(e) =>
                  setWeight(isNaN(Number(e.target.value)) ? "" : e.target.value)
                }
              />
            </div>
            <div className="flex items-center gap-5">
              <p>{t("Цена")}</p>
              <input
                type="text"
                className="w-full px-2 text-sm border-b-2 border-gray-400 outline-none opacity-80 focus:border-blue-400"
                value={CurrentPrice}
                onChange={(e) => setCurrentPrice(e.target.value)}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center mt-6 gap-5">
              <p className="w-32">{t("Штрихкод")}</p>
              <input
                type="text"
                value={BarCode}
                className="w-full px-2 text-sm border-b-2 border-gray-400 outline-none opacity-80 focus:border-blue-400"
                onChange={(e) =>
                  setBarCode(
                    isNaN(Number(e.target.value)) ? "" : e.target.value
                  )
                }
              />
            </div>
            <div className="mt-6 flex gap-5">
              <p className="w-32">{t("Категория")}</p>
              <AsyncSelect change={(value) => setCategory_id(value)} />
            </div>
            <div className="flex gap-5 mt-8">
              <p>{t("Фотография")}</p>
              <input
                type="file"
                multiple
                className="w-full self-center"
                accept="image/*"
                onChange={(e) => {
                  //@ts-ignore
                  setPhoto(URL.createObjectURL(e.target.files[0]));
                  //@ts-ignore
                  setCurrentFileList(e.target.files[0]);
                }}
              />
            </div>
          </div>
        </div>
        <div className={st.popupFooter}>
          <div>
            <img
              src={photo}
              style={{ maxHeight: "200px", maxWidth: "200px" }}
              alt=""
            />
          </div>
          <div>
            <button className={st.popupButton} onClick={() => sendData()}>
              {t("Сохранить")}
            </button>
            {/* <button className={st.popupButton}>Отмена</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AddProductsPopup);

interface AsyncSelectProps {
  change: (value: string) => void;
}

const AsyncSelect: FC<AsyncSelectProps> = ({ change }) => {
  useEffect(() => {
    if (AddСategory.categories.length === 0) {
      getCategories();
    }
  });
  return (
    <>
      <select onChange={(e) => change(e.target.value)}>
        <option value="0">{t("Без категории")}</option>
        {AddСategory.categories.map((category) => (
          <option value={category.id}>{category.categoryname}</option>
        ))}
      </select>
    </>
  );
};
