import { observer } from 'mobx-react-lite'
import { FC, useRef, useState } from 'react'
import { ReactComponent as DeleteImg } from '../../assets/delete.svg'
import Products from '../../store/Products'
import { cutMaxTextLength } from '../../utils/maxText'
import Footer from '../MainProductSelected/Footer/Footer'
import { NavLink } from 'react-router-dom'
import { Language } from '../../utils/langSwitch'
import { dict } from './TranslationList'
import ProductName from '../../components/ProductName'
import Theme from '@/store/Theme'
import Barcode from 'react-barcode'
import { ReactComponent as ScanImg } from '../../assets/scan.svg'
import { $authHost } from '@/API'
import { IResponceProductList } from '@/types/createorder'
import ProductImg from '@/components/ProductImg/ProductImg'
const t = Language.translateWithDictionary(dict)
const BasketPage: FC = observer(() => {
	const [scan, setScan] = useState(false)
	const barcodeFounder = useRef<HTMLInputElement>(null)
	const findProductByBarcode = async () => {
		setScan(false)
		const { data } = await $authHost.get<IResponceProductList>(
			`api/products/?barcode=${barcodeFounder.current?.value}`
		)
		if (!data.products || !data.products.length) return
		const product = data.products[0]
		if (Products.basket.find(e => product.id)) {
			Products.addToBasket(product.id)
		} else {
			Products.Products.push(product)
			Products.addToBasket(product.id)
		}
	}
	return (
		<>
			<div className="font-['Open_Sans'] min-h-screen dark:bg-slate-900">
				<header>
					<div className='container px-4 mx-auto pt-10'>
						<div className='flex justify-between items-center'>
							<div className='h-full flex justify-center items-start'>
								<p className='text-3xl md:text-5xl dark:text-white'>
									{t('Корзина')}
								</p>
							</div>
							<div className='flex gap-4 h-full items-center'>
								<div
									className='w-full h-full'
									title={t('Удаление выбранных товаров')}>
									<DeleteImg
										fill={Theme.isDarkTheme ? 'white' : 'black'}
										className='w-9 h-9'
									/>
								</div>
								<div className='w-full h-full'>
									<ScanImg
										onClick={() => setScan(prev => !prev)}
										fill={Theme.isDarkTheme ? 'white' : 'black'}
										className='w-9 h-9'
									/>
								</div>
								<div>
									<input
										className='md:w-12 w-8 md:h-12 h-8 cursor-pointer'
										type='checkbox'
										onChange={() => Products.checkAllBasket()}
										checked={
											Products.basket.every(e => e?.checked) &&
											!!Products.basket.length
										}
									/>
								</div>
							</div>
						</div>
						{scan && (
							<div>
								<input
									onKeyDown={e => e.keyCode === 13 && findProductByBarcode()}
									type='text'
									ref={barcodeFounder}
								/>
							</div>
						)}
					</div>
				</header>
				<section>
					<div className='container mx-auto px-4'>
						<div>
							{Products.basket.map(product => (
								<div className='flex w-full mt-8' key={product.id}>
									<ProductImg product={product} />
									<div className='flex flex-col w-7/12 px-4 dark:text-white'>
										<p className='text-sm md:text-4xl font-medium'>
											<ProductName
												ProductName={product.productname}
												ProductNameEng={product.ProductNameEng}
											/>
										</p>
										<p className='font-light text-xs mdtext-3xl mt-4 dark:text-white'>
											{cutMaxTextLength(product.productdescr, 45)}
										</p>
										<span className='text-3xl font-bold my-4 text-gray-900 dark:text-white'>
											{product.CurrentPrice?.includes('.00')
												? product.CurrentPrice?.slice(0, -3)
												: product.CurrentPrice}
											{product.CurrentPrice && (
												<span className='font-sans'>₽</span>
											)}
										</span>
										<div className='flex gap-2 items-center border-2 w-fit mt-2 justify-center rounded-full px-1'>
											<div
												className='text-lg md:text-5xl mx-2 cursor-pointer rounded-full font-bold mt-[-4px] md:mt-[-10px]'
												onClick={() => Products.removeFromBasket(product.id)}>
												-
											</div>
											<div className='text-base md:text-4xl py-1'>
												{product.basketcount}
											</div>
											<div
												className='text-base md:text-4xl mx-2 cursor-pointer rounded-full font-bold'
												onClick={() => Products.addToBasket(product.id)}>
												+
											</div>
										</div>
									</div>
									<div className='w-1/12 flex justify-end'>
										<input
											type='checkbox'
											onClick={() => Products.checkProduct(product)}
											checked={product?.checked}
											className='md:w-12 w-6 md:h-12 h-6 cursor-pointer'
										/>
									</div>
								</div>
							))}
							<div className='h-36'></div>
						</div>
					</div>
				</section>

				<section>
					{(() => {
						if (!Products.basket.length) return <></>
						if (!Products.basket.find(product => product.checked)) {
							return (
								<div className='h-16 md:h-28 md:bottom-20 bg-white dark:bg-slate-800 rounded-t-lg w-full fixed bottom-14 shadow-inner'>
									<div
										className='bg-slate-800 md:text-3xl dark:bg-white dark:text-black m-2 h-12 md:h-16 font-bold flex rounded-full justify-center items-center text-white cursor-pointer'
										onClick={() => Products.checkAllBasket()}>
										<p>{t('Выбрать всё')}</p>
									</div>
								</div>
							)
						} else {
							return (
								<div className='h-16 md:h-28 md:bottom-20 bg-white dark:bg-slate-800 rounded-t-lg w-full fixed bottom-14 shadow-inner'>
									<NavLink
										to={'/qrgenerate'}
										className='bg-ty-gridient m-2 h-12 md:h-16 font-bold flex rounded-full justify-center items-center text-white cursor-pointer'>
										<div className='flex flex-col items-center justify-center'>
											<p className='text-xm'>{t('К оформлению')}</p>
											<p className='font-light text-xs'>
												{Products.basket.length} {t('товаров')}{' '}
												{Products.getSummary()}
											</p>
										</div>
									</NavLink>
								</div>
							)
						}
					})()}
				</section>
				<Footer />
			</div>
		</>
	)
})
export default BasketPage
