import Categories from '@/store/Categories/Categories'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
const getLinks = [
	{
		name: 'Admin',
		url: '/admin'
	},
	{
		name: 'Categories',
		url: ''
	}
]
const AddCategoryHeader: FC = () => {
	return (
		<>
			<Breadcrumbs links={getLinks} />
			<header
				className={`flex justify-center container
  				md:w-2/3 w-full mx-auto md:px-20 px-5 pb-5 gap-10`}
				data-testid='CategoriesPage'>
				<NavLink
					to={'/admin/category/create'}
					className={`bg-blue-500 text-white 
    				rounded-lg px-4 py-2 cursor-pointer 
						 md:text-xl text-sm`}>
					<p className='mt-[2px]'>Создать категорию</p>
				</NavLink>
				<SearchItem />
			</header>
		</>
	)
}

export default observer(AddCategoryHeader)

const SearchItem: FC = () => {
	const [quary, setQuary] = useState('')
	const search = useLocation().search
	const param = new URLSearchParams(search).get('query')
	useEffect(() => {
		if (!param) {
			change('')
		} else {
			change(param)
		}
	}, [param])

	const change = (str: string) => {
		setQuary(str)
		Categories.filterByQuery(str)
	}

	return (
		<div
			className={`flex border-2 border-gray-400 
		dark:border-gray-600 rounded-xl p-2 flex-1`}>
			<input
				className='bg-inherit outline-none w-full dark:text-white'
				type='text'
				value={quary}
				placeholder='Поиск...'
				onChange={e => change(e.target.value)}
			/>
		</div>
	)
}
