import { $authHost } from '@/API/index'
import { FetchStages } from '@/types'
import { IWHZone } from '@/types/Warehouse'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })

class WhZoneStore {
	// Список складских зон
	WHZoneList: IWHZone[] = []
	// Стадия запроса складских зон
	isWHZoneListFetch = FetchStages.waiting

	// Текущая складская зона
	WHZone: IWHZone | null = null
	// стадия запрос складской зоны
	isWHZoneFetch = FetchStages.waiting

	// Получение складской зоны
	fetchWHZone = async (WHZoneid?: number | string) => {
		try {
			if (this.isWHZoneListFetch === FetchStages.start) return
			if (this.WHZoneList.length > 0) return
			this.isWHZoneListFetch = FetchStages.start
			const { data } = await $authHost.get<IWHZone[]>('api/whzone')
			this.WHZoneList = data
			this.findWHZone(WHZoneid)
			this.isWHZoneListFetch = FetchStages.end
			return data
		} catch (e) {
			this.isWHZoneListFetch = FetchStages.error
			console.log('%c Error on api/whzone request', 'color:red')
		}
	}

	// Поиск складской зоны
	findWHZone = async (WHZoneId?: number | string) => {
		try {
			// Если WHZone не задан
			if (!WHZoneId) {
				console.log('%c WHZone undefined', 'color: red')
				return
			}
			// Поиск в списке складких зон по id
			const currentWHZone = this.WHZoneList.find(e => Number(e.id) === Number(WHZoneId))
			if (currentWHZone) {
				// Если найден то задаем
				this.WHZone = currentWHZone
			} else {
				// Если не найден то делаем запрос на получение
				const { data } = await $authHost.get<IWHZone>(`api/whzone${WHZoneId}`)
				if (data) {
					// Если запрос вернул складскую зонy, то я записываем
					this.WHZone = data
					this.WHZoneList.push(data)
				} else {
					// Если нет то ошибка
					console.log('%c Current whzone not found', 'color: red')
					return
				}
			}
		} catch (e) {
			console.log('%c Error on api/whone/:id', 'color:red')
		}
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new WhZoneStore()
