import { makeAutoObservable } from "mobx";
import { configure } from "mobx";
import { ICategories } from "../types/categoryChange";
import { FStages, FetchStages } from "../types";

configure({
  enforceActions: "never",
});

class Categories {
  isFetching: FStages = FetchStages.waiting;
  categoryies: ICategories[] = [];
  constructor() {
    makeAutoObservable(this);
  }
}

export default new Categories();
