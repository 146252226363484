import { IDistTranslation } from '../../../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Готов',
		eng: 'Ready'
	},
	{
		ru: 'Разместил',
		eng: 'Posted'
	},
	{
		ru: 'Сохранить',
		eng: 'Save'
	},
	{
		ru: 'Изменить',
		eng: 'Сhange'
	},
	{
		ru: 'Удалить',
		eng: 'Delete'
	},
	{
		ru: 'Удалить навсегда',
		eng: 'Delete permanently'
	}
]
