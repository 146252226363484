import { observer } from "mobx-react-lite";
import { FC } from "react";

interface IProps {
  children: string | JSX.Element | JSX.Element[];
}

const BlueBtn: FC<IProps> = ({ children }) => {
  return (
    <div className="bg-blue-400 select-none cursor-pointer hover:bg-blue-500 text-white flex justify-center items-center w-fit px-4 py-2 rounded-lg">
      <p>{children}</p>
    </div>
  );
};

export default observer(BlueBtn);
