import { $authHost, errorHandler } from '.'
import TYCollectGuides from '../store/TYCollectGuides'
import {
	IAssemblingOrders,
	IAssemblingProducts,
	IAssemblingTask,
	IGuide,
	IGuideStages,
	IProductStoreData
} from '../types/tycollectguides'
import { IPostCollectProps } from '../types/TYPicking'
import { reformImage } from '../utils/imageHandler'

export const getGuides = async (id?: number) => {
	try {
		let data
		if (id) {
			data = await $authHost.get<IGuide[]>(`api/guide/?guide=${id}`)
		} else {
			data = await $authHost.get<IGuide[]>('api/guide')
		}
		// @ts-ignore
		TYCollectGuides.Guides = data
		console.log(data)
		return data
	} catch (e) {
		await errorHandler(e, () => getGuides())
	}
}

export const getStages = async (id?: number) => {
	try {
		let data
		if (id) {
			data = await $authHost.get<IGuideStages[]>(`api/guidestages/?guide=${id}`)
		} else {
			data = await $authHost.get<IGuideStages[]>('api/guidestages/')
		}
		if (!data) return
		// @ts-ignore
		data = data.forEach((element: IGuideStages) => {
			element.StageImage = reformImage({ PhotoBytea: `${element.StageImage}` })
			return element
		})
		// @ts-ignore
		TYCollectGuides.guideStages = data
		console.log(data)
	} catch (e) {
		await errorHandler(e, () => getStages(id))
	}
}

export const getAllNeededData = async () => {
	try {
		if (TYCollectGuides.isFetched) return
		TYCollectGuides.fetch = true
		const response1 = await $authHost.get<IGuide[]>('api/guide')
		TYCollectGuides.Guides = response1.data

		const response4 =
			await $authHost.get<IAssemblingOrders[]>(`api/orderassembling`)
		TYCollectGuides.Orders = response4.data

		const response5 =
			await $authHost.get<IAssemblingTask[]>(`api/assemblingtask`)
		TYCollectGuides.Tasks = response5.data

		const response2 = await $authHost.get<IGuideStages[]>(`api/guidestages`)
		response2.data.sort((a, b) => {
			if (a?.SortOrder && b?.SortOrder) {
				return a?.SortOrder - b?.SortOrder
			} else {
				return 0
			}
		})
		// @ts-ignore
		response2.data.forEach((element: IGuideStages) => {
			element.StageImage = reformImage({ PhotoBytea: `${element.StageImage}` })
			return element
		})
		// @ts-ignore
		TYCollectGuides.guideStages = response2.data

		const response6 = await $authHost.get<IAssemblingProducts[]>(
			`api/assemblingproducts`
		)
		TYCollectGuides.Products = response6.data
		TYCollectGuides.fetch = false
		TYCollectGuides.isFetched = false
	} catch (e) {
		await errorHandler(e, getAllNeededData)
	}
}

export const getProductStoreData = async (product_id: number) => {
	try {
		if (product_id === 0) return
		const { data } = await $authHost.get<IProductStoreData[]>(
			`api/productstore_cellgroup/${product_id}`
		)
		TYCollectGuides.StoreData = data
	} catch (e) {
		await errorHandler(e, () => getProductStoreData(product_id))
	}
}

interface IPropsGuide {
	Collected_Quant?: number
	PickState_id: number
}

export const GuideCollect = async (props: IPostCollectProps) => {
	try {
		const { data } = await $authHost.post<IPropsGuide>(`api/collected_guide`, {
			rack: props.rack,
			product_id: props.product_id,
			rackcol: props.rackcol,
			rackrow: props.rackrow,
			assemblingproducts_id: props.orderproducts_id
		})
		return data
	} catch (e) {
		errorHandler(e, () => GuideCollect(props))
	}
}

export const sendFormData = async (formdata: any) => {
	const { data } = await $authHost.put<any>(`api/guidestages`, formdata)
	console.log(data)
}

interface IAddGuideOrderProps {
	name: string
	desc: string
	guide: IGuide
}

export const addGuideOrder = async (sendData: IAddGuideOrderProps) => {
	try {
		const { name, desc, guide } = sendData
		const { data } = await $authHost.post<any>(`api/orderassembling`, {
			OrderName: name,
			OrderDescr: desc,
			Guide: guide.id,
			OrderState_id: 0
		})
		console.log('added order')
		return data
	} catch (e) {
		errorHandler(e, () => addGuideOrder(sendData))
	}
}

export const getVoiceBase64 = async (text: string) => {
	const { data } = await $authHost.get<string>(`api/voicesyth/${text}`)
	return `data:audio/wav;base64,${data}`
}
