import st from '../../Fastomat.module.scss'
import { FC, useState, useEffect } from 'react'
import { getFreeWindows } from '@/API/fastomatWorkerAPI'
import { IFreeWindows } from '@/types/fastomatWorker'
import FastomatWorker from '@/store/FastomatWorker'
import { updateOrderAPI } from '@/API/fastomatWorkerAPI'
import { addwindow } from '@/API/fastomatWorkerAPI'
import { Language } from '@/utils/langSwitch'
import { dict } from './TranslationList'
// Переводчик
const t = Language.translateWithDictionary(dict)

const PickWin: FC = () => {
	const [window, selectWin] = useState<number>(0)
	const [win, setWins] = useState<IFreeWindows[]>([])
	const addFree = (value: IFreeWindows[]) => {
		setWins(value)
	}
	useEffect(() => {
		getFreeWindows({ addFree })
	}, [])

	return (
		<div className={st.winpick}>
			<div className={st.winHead}>
				<p>{t('TakeYours Фастомат')}</p>
				<p>
					{t('Ваше окно')}: {window}
				</p>
				<p
					className={st.closewin}
					onClick={() => {
						FastomatWorker.RdyOrder = null
						FastomatWorker.PickWin = false
					}}>
					X
				</p>
			</div>
			<div className={st.winContainer}>
				<div
					className={`${st.window1}  ${st.window} ${
						win.find(e => e.id === 1) ? st.active : st.disactive
					}
          ${window === 1 ? st.selected : null}`}
					title={t('Ячейка свободна')}
					onClick={() => selectWin(1)}>
					{' '}
					<p>1</p>{' '}
				</div>
				<div
					className={`${st.window2}  ${st.window} ${
						win.find(e => e.id === 2) ? st.active : st.disactive
					}
          ${window === 2 ? st.selected : null}`}
					title={t('Ячейка свободна')}
					onClick={() => selectWin(2)}>
					{' '}
					<p>2</p>{' '}
				</div>
				<div
					className={`${st.window3}  ${st.window} ${
						win.find(e => e.id === 3) ? st.active : st.disactive
					}
          ${window === 3 ? st.selected : null}`}
					title={t('Ячейка свободна')}
					onClick={() => selectWin(3)}>
					{' '}
					<p>3</p>{' '}
				</div>
			</div>
			<div className={st.selectwin}>
				<div></div>
				<div
					className={st.btnwin}
					onClick={async () => {
						if (!FastomatWorker.RdyOrder) return
						await updateOrderAPI({
							...FastomatWorker.RdyOrder,
							orderstate: 3,
							windows: []
						})
						await addwindow(
              FastomatWorker.RdyOrder.id, 
              win[window].id
            )
						FastomatWorker.RdyOrder.orderstate = 3
						FastomatWorker.RdyOrder.windows = [win[window].id]
						FastomatWorker.RdyOrder = null
						FastomatWorker.PickWin = false
					}}>
					<p>{t('Выбрать')}</p>
				</div>
			</div>
		</div>
	)
}

export default PickWin
