import { IGantDay } from '@/store/Gant/Gant.interface'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import GantSettings from '@/store/Settings/Gant/Gant'

const GantDaysLineItem: FC<{ day: IGantDay }> = ({ day }) => {
	return (
		<th
			style={{
				width: `${
					GantSettings.dayWidth > GantSettings.minimalDayWidth
						? GantSettings.dayWidth
						: GantSettings.minimalDayWidth
				}px`,
				height: `${
					GantSettings.dayHeight > GantSettings.minimalDayHeight
						? GantSettings.dayHeight
						: GantSettings.minimalDayHeight
				}px`
			}}>
			{day.id}
		</th>
	)
}

export default observer(GantDaysLineItem)
