import FastomatUserC from './pages/FastomatClient/FastomatC'
import LoginC from './pages/Login/LoginC'
import OrderC from './components/SelectOrdersCollected/SelectOrdersCollectedC'
import { IRoutes } from './types/routes'
import {
	FASTOMAT_ROUTE,
	LOGIN_ROUTE,
	TYPICKING_ROUTE,
	FASTOMAT_WORKER_ROUTE,
	MAIN_ROUTE,
	TYORDER_ROUTE,
	BASKET_ROUTE,
	CATEGORY_ROUTE,
	GENERATE_QRCODE_ROUTE,
	NOTIFICATION_ROUTE,
	PUSHPRODUCTSPAGE_ROUTE,
	ADMINISTRATOR_ROUTE,
	TYCHOISE_ROUTE,
	GUIDE_COLLECT_ROUTE,
	GUIDE_METHOD_ROUTE,
	TYCHOISEWAREHOUSE_ROUTE,
	ASSEMBLING_ORDER_ROUTE,
	ASSEMBLING_METHODTOO_ROUTE,
	ADDPRODUCTS_ROUTE,
	ADDCATEGORY_ROUTE,
	EMPLOYEES_ROUTE,
	ITEGRATION1C_ROUTE,
	TESTING_ROUTE,
	WSCLIENT_HORIZONTAL_ROUTE,
	WSCOLLECT_ROUTE
} from './utils/consts'
import NavigationForDev from './pages/MainPage/MainPage'
import ProductsC from './components/Products/ProductsC'
import FastomatC from './pages/FastomatWorkerUpdated/FastomatC'
import MainProductSelected from './pages/MainProductSelected/MainProductSelected'
import BasketPage from './pages/BasketPage/BasketPage'
import GenerateQRPage from "./pages/GenerateQR'Code/GenerateQR"
import NotificationPage from './pages/Notification/NotificationPage'
import PushProductsPage from './pages/PushProductsPage/PushProductsPage'
import WarehousePage from './pages/WarehousePage/WarehousePage'
import StoreZonesPageС from './pages/StoreZones/StoreZonesPageС'
import PickMethodPage from './pages/PickMethodPage/PickMethodPage'
import PickOrderPageC from './pages/PickOrderPage/PickOrderPageC'
import CollectProductsС from './pages/CollectProductsPage/CollectProductsС'
import EmployeesPickWarehouse from './pages/EmployeesPickWarehouse/EmployeesPickWarehouse'
import PickGuidePageC from './pages/PickGuidePage/PickGuidePageC'
import CollectPickMethodPage from './pages/CollectPickMethodPage/CollectPickMethodPage'
import AddProductsPage from './pages/AddProductsPage/AddProductsPage'
import CategoriesPage from './pages/CategoriesPage/CategoriesPage'
import Employess from './pages/EmployeesPage/Employess'
import Integration1C from './pages/Integration1C/Integration1C'
import TestingPage from './pages/TestingPage/TestingPage'
import WSCollect from './pages/WSCollect/WSCollect'
import WSCHorizontal from './pages/WSCHorizontal/WSCHorizontal'
import AdminPage from './pages/AdminPage/AdminPage'
import GantSkelet from './components/GantStatic/GantSkelet'
import PushProductRoute from './components/PushProductRoute/PushProductRoute'
import WarehouseCanvas from './components/WarehouseCanvas/WarehouseCanvas'
import WarehouseMapPage from './pages/WarehouseMapPage/WarehouseMapPage'
import ProductPage from './pages/ProductPage/ProductPage'

//Пути которые доступны всем пользователям
export const publicRoutes: IRoutes[] = [
	{
		path: LOGIN_ROUTE,
		Сomponent: LoginC
	},
	{
		path: FASTOMAT_ROUTE,
		Сomponent: FastomatUserC
	},
	{
		path: TESTING_ROUTE,
		Сomponent: TestingPage
	},
	{
		path: EMPLOYEES_ROUTE,
		Сomponent: Employess
	},
	{
		path: FASTOMAT_WORKER_ROUTE,
		Сomponent: FastomatC
	},
	{
		path: MAIN_ROUTE,
		Сomponent: NavigationForDev
	},
	{
		path: TYCHOISEWAREHOUSE_ROUTE,
		Сomponent: EmployeesPickWarehouse
	},
	{
		path: GENERATE_QRCODE_ROUTE,
		Сomponent: GenerateQRPage
	},

	{
		path: TYPICKING_ROUTE,
		Сomponent: OrderC
	},
	{
		path: TYCHOISE_ROUTE,
		Сomponent: PickMethodPage
	},
	{
		path: GUIDE_METHOD_ROUTE,
		Сomponent: PickOrderPageC
	},
	{
		path: GUIDE_COLLECT_ROUTE,
		Сomponent: PickGuidePageC
	},
	{
		path: ASSEMBLING_METHODTOO_ROUTE,
		Сomponent: CollectProductsС
	},
	{
		path: ASSEMBLING_ORDER_ROUTE,
		Сomponent: CollectPickMethodPage
	},
	{
		path: TYORDER_ROUTE,
		Сomponent: ProductsC
	},
	{
		path: BASKET_ROUTE,
		Сomponent: BasketPage
	},
	{
		path: CATEGORY_ROUTE,
		Сomponent: MainProductSelected
	},
	{
		path: NOTIFICATION_ROUTE,
		Сomponent: NotificationPage
	},
	{
		path: WSCLIENT_HORIZONTAL_ROUTE,
		Сomponent: WSCHorizontal
	},
	{
		path: PUSHPRODUCTSPAGE_ROUTE,
		Сomponent: PushProductRoute
	},
	{
		path: ADDPRODUCTS_ROUTE,
		Сomponent: AddProductsPage
	},
	{
		path: ITEGRATION1C_ROUTE,
		Сomponent: Integration1C
	},
	{
		path: ADDCATEGORY_ROUTE,
		Сomponent: CategoriesPage
	},
	{
		path: WSCOLLECT_ROUTE,
		Сomponent: WSCollect
	},
	{
		path: WSCLIENT_HORIZONTAL_ROUTE,
		Сomponent: WSCHorizontal
	},
	{
		path: ADMINISTRATOR_ROUTE,
		Сomponent: AdminPage
	},
	{
		path: '/gant-collect/*',
		Сomponent: GantSkelet
	},
	{
		path: '/product/:id',
		Сomponent: ProductPage
	},
	{
		path: '/warehouse-map/:id',
		Сomponent: WarehouseMapPage
	}
]

//Пути которые доступны только сборщикам
export const collectManRoutes: IRoutes[] = [
	{
		path: TYPICKING_ROUTE,
		Сomponent: OrderC
	}
]

//Пути которые доступны только кладовщикам
export const pullManRoutes: IRoutes[] = [
	{
		path: LOGIN_ROUTE,
		Сomponent: LoginC
	}
]

//Пути которые доступны только кладовщикам
export const fastomatRoutes: IRoutes[] = [
	{
		path: FASTOMAT_ROUTE,
		Сomponent: FastomatUserC
	},
	{
		path: FASTOMAT_WORKER_ROUTE,
		Сomponent: FastomatC
	}
]
