import { FC, useEffect } from "react";
import UsersList from "./components/UsersList";
import { getUsers } from "./api/UsersAPI";
import Users from "./store/Users";
import { observer } from "mobx-react-lite";
const UserInit: FC = () => {
  useEffect(() => {
    getUsers();
  }, []);
  return <UsersList />;
};
export default observer(UserInit);
