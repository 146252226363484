import { FC, useEffect, useState } from "react";
import st from "../EditGuides.module.scss";
import { observer } from "mobx-react-lite";
import { noPhoto } from "../../../utils/nophoto";
import GuideHandler from "../store/GuideHandler";
import StageHandler from "../store/StageHandler";

const Stage2: FC = () => {
  const [name, setName] = useState<string>("");
  const [descr, setDescr] = useState<string>("");

  useEffect(() => {
    if (GuideHandler.SelectedGuide === null) {
      StageHandler.stage = 1;
      StageHandler.error = "Выберите инструкцию или создайте новую";
    }
    setName(GuideHandler.SelectedGuide?.GuideName || "");
    setDescr(GuideHandler.SelectedGuide?.GuideDescr || "");
  }, []);

  useEffect(() => {
    if (GuideHandler.SelectedGuide && GuideHandler.Cancel) {
      GuideHandler.SelectedGuide.GuideName = name;
      GuideHandler.SelectedGuide.GuideDescr = descr;
      GuideHandler.Cancel = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GuideHandler.Cancel]);

  const handleNameGuide = (value: string) => {
    if (GuideHandler.SelectedGuide) {
      GuideHandler.SelectedGuide.GuideName = value;
    }
  };

  const handleDescrGuide = (value: string) => {
    if (GuideHandler.SelectedGuide) {
      GuideHandler.SelectedGuide.GuideDescr = value;
    }
  };

  const HandleFileRead = async (e: any) => {
    if (!GuideHandler.SelectedGuide) return;
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    //@ts-ignore
    GuideHandler.SelectedGuide.GuideImage = base64;
    //@ts-ignore
    GuideHandler.SelectedGuide.blob = file;
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div className={st.modalBody}>
      <p>Изменение инструкции:</p>
      <div className={st.halfs}>
        <div className={st.left}>
          <div className={st.inputField}>
            <label>Название</label>
            <input
              type="text"
              value={GuideHandler.SelectedGuide?.GuideName}
              onChange={(e) => handleNameGuide(e.target.value)}
            />
          </div>
          <div className={st.inputField} style={{ height: "100%" }}>
            <label>Описание</label>
            <textarea
              value={GuideHandler.SelectedGuide?.GuideDescr}
              onChange={(e) => handleDescrGuide(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className={st.right}>
          <div className={st.inputField}>
            <label>Фотография инструкции</label>
            <input type="file" onChange={(e) => HandleFileRead(e)} />
          </div>

          {GuideHandler.SelectedGuide?.GuideImage ? (
            <img
              src={`${GuideHandler.SelectedGuide?.GuideImage}`}
              alt="guidephoto"
            />
          ) : (
            <img src={`data:image/png;base64,  ${noPhoto}`} alt="guidephoto" />
          )}
        </div>
      </div>
    </div>
  );
};
export default observer(Stage2);
