import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { getAllNeededData } from '../../API/TYCollectGuides'
import PickGuidePage from './PickGuidePage'
import { useMount } from '@/hooks/useMount'

const PickGuidePageC: FC = () => {
	useMount(() => {
		getAllNeededData()
	})
	return <PickGuidePage />
}

export default observer(PickGuidePageC)
