import { IDistTranslation } from '../../../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Смотреть QR-код',
		eng: 'View QR code'
	},
	{
		ru: 'Сканировать',
		eng: 'Scan'
	},
	{
		ru: 'QR-code с камеры',
		eng: 'QR code from the camera'
	},
	{
		ru: 'Выбрать',
		eng: 'Select'
	},
	{
		ru: 'Все заказы',
		eng: 'All orders'
	},
	{
		ru: 'Сохранить',
		eng: 'Save'
	},
	{
		ru: 'QR не уникален',
		eng: 'QR is not unique'
	},
	{
		ru: 'Ваше окно',
		eng: 'Your window'
	},
	{
		ru: 'TakeYours Фастомат',
		eng: 'TakeYours Fastomat'
	},
	{
		ru: 'Ячейка свободна',
		eng: 'The cell is free'
	},
	{
		ru: 'Имя заказа',
		eng: 'Order name'
	},
	{
		ru: 'Описание',
		eng: 'Description'
	},
	{
		ru: 'Номер заказа',
		eng: 'Order number'
	},
	{
		ru: 'Заказ',
		eng: 'Order'
	},
	{
		ru: 'QR код',
		eng: 'QR code'
	},
	{
		ru: 'Введите QR',
		eng: 'Type the QR'
	},
	{
		ru: 'Сканировать с камеры',
		eng: 'Scan from the camera'
	},
	{
		ru: 'Статус',
		eng: 'Status'
	},
	{
		ru: 'Добавить заказ',
		eng: 'Add an order'
	}
]
