import Products from '../store/Products'
import { FC } from 'react'
import { observer } from 'mobx-react-lite'

const BasketIndicator: FC = () => {
	return (
		<>
			{!!Products.basket.length && (
				<div
					className={`absolute top-0 right-0 bg-purple-500 
            text-white rounded-full md:w-8 w-4 md:h-8 h-4 flex 
            justify-center items-center font-bold md:text-lg text-xs`}>
					<p>{Products.basket.length}</p>
				</div>
			)}
		</>
	)
}
export default observer(BasketIndicator)
