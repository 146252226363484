import { FC } from 'react'
import RackGrid from '../../store/RackGrid'
import { observer } from 'mobx-react-lite'
import { useMount } from '../../hooks/useMount'
import RackGridSelect from './RackGridSelect'
import Loader from '../../components/Loader/Loader'
import WebSocket from '@/store/WebSocket/WebSocketShelving'

const Fetch: FC = () => {
	useMount(() => {
		WebSocket.connect({
			DeviceTypeId: 3,
			DeviceModelId: 5
		})
	})
	if (RackGrid.racks.length) return <RackGridSelect />
	return (
		<div className='mt-5 mb-24'>
			<Loader loading={true}>
				<></>
			</Loader>
		</div>
	)
}

export default observer(Fetch)
