import { IGantTableCell } from '@/store/Gant/Gant.interface'
import ShipmentMark from './ShipmentMark'
import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import DragAndDrop from '@/store/Gant/DragAndDrop'
import GantSettings from '@/store/Settings/Gant/Gant'

const EmptyCell: FC<IGantTableCell> = ({
	task,
	day_id,
	mouth_id
}) => {
	return (
		<th
			draggable={true}
			style={{
				width: `${
					GantSettings.dayWidth >
					GantSettings.minimalDayWidth
						? GantSettings.dayWidth
						: GantSettings.minimalDayWidth
				}px`,
				height: `${
					GantSettings.dayHeight >
					GantSettings.minimalDayHeight
						? GantSettings.dayHeight
						: GantSettings.minimalDayHeight
				}px`
			}}
			className={`border-x-[1px] relative`}
			onDragStart={event =>
				DragAndDrop.dragStart({ event, task })
			}
			onDragOver={event =>
				DragAndDrop.dragOver({ event, task })
			}
			onDrop={event =>
				DragAndDrop.emptyAction({
					event,
					day_id,
					mouth_id,
					task
				})
			}>
			<ShipmentMark
				task={task}
				day_id={day_id}
				mouth_id={mouth_id}
			/>
		</th>
	)
}

export default observer(EmptyCell)
