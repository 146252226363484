import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'смартфона',
		eng: 'smartphone'
	},
	{
		ru: 'Сделай заказ со',
		eng: 'Make an order from'
	},
	{
		ru: 'Мир без очередей',
		eng: 'A world without queues'
	},
	{
		ru: 'Или закажи тут',
		eng: 'Or order here'
	}
]
