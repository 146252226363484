import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import st from './PickMethodPage.module.scss'
import fastImg from '@/assets/rocket.svg'
import boxImg from '@/assets/box place.svg'
import { ReactComponent as QuitImg } from '@/assets/quit.svg'
import { dict } from './TranslationList'
import { Language } from '@/utils/langSwitch'

// Переводчик
const t = Language.translateWithDictionary(dict)
const PickMethodPage: FC = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const url = window.location.pathname.split('/')[1]
	const goBack = () => navigate(-1)
	return (
				<div className={st.wrapper}>
					<p className='m-5'>
						{t('Выбирите ваш метод сборки')}:
					</p>
					<div className={st.linkWrapper}>
						<NavLink to={`/${url}/${id}/method/1`}>
							<div className={st.block}>
								<img src={fastImg} alt='' />
								<p>{t('Быстро')}</p>
							</div>
						</NavLink>

						<NavLink to={`/${url}/${id}/method/2`}>
							<div className={st.block}>
								<img src={boxImg} alt='' />
								<p>{t('Компактно')}</p>
							</div>
						</NavLink>

						<div onClick={goBack}>
							<div className={st.block}>
								<QuitImg 
									stroke='black' 
									style={{ maxWidth: '200px' }} 
								/>
								<p>{t('Вернуться')}</p>
							</div>
						</div>

					</div>
				</div>
	)
}

export default observer(PickMethodPage)
