import { makeAutoObservable, configure, toJS } from 'mobx'
import { IGantCurrnetTask, IOnDragGant } from './Gant.interface'
import GantTasks from './GantTasks'
import { $authHost } from '@/API'

configure({ enforceActions: 'never' })

class DragAndDrop {
	draggable = true
	currentTask: IGantCurrnetTask = null
	dragType: 'start' | 'end' | 'task' | 'ready' | null = null
	isFetch = false

	dragStart = ({ event, task }: IOnDragGant) => {
		if (!task) return
		this.currentTask = task
	}

	dragEnd = ({ event, task }: IOnDragGant) => {
		this.dragType = null
		// on drag end
	}
	dragLeave = ({ event, task }: IOnDragGant) => {
		// on drag leave
	}
	dragOver = ({ event, task }: IOnDragGant) => {
		event.preventDefault()
		// on drag over
	}
	dragDrop = ({ event, task }: IOnDragGant) => {
		event.preventDefault()
		console.log(task)
		if (!task) return
		const sortid = task.sortorder
		const obj = GantTasks.filteredTasks.find(e => e.id === this.currentTask?.id)
		if (obj) {
			task.sortorder = obj.sortorder
			obj.sortorder = sortid
		}
		GantTasks.sortTasks()
		this.currentTask = null
	}

	changeReadyDate = async ({ event, day_id, mouth_id, task }: any) => {
		if (!this.currentTask) return
		if (task.id !== this.currentTask.id) return
		event.preventDefault()
		event.stopPropagation()
		const day = `${day_id}`.length > 1 ? day_id : `0${day_id}`
		const mouth = `${mouth_id}`.length > 1 ? mouth_id : `0${mouth_id}`
		if (task.isProduct) {
			GantTasks.tasks.forEach((element: any) => {
				const product = element.products.find((e: any) => e.id === task.id)
				if (product) {
					product.ready = `${day}.${mouth}.2023`
					product.production_date = `${day}.${mouth}.2023`
				}
			})
		}
		task.ready = `${day}.${mouth}.2023`
		const date = new Date(2023, mouth_id - 1, day_id + 1)
		const production_date = date.toISOString()
		console.log(toJS(task))
		if (!this.isFetch) {
			if (task.isProduct) {
				this.isFetch = true
				await $authHost.put(`api/production_order/${task.id}`, {
					production_fact_date: production_date
				})
				this.isFetch = false
			} else {
				this.isFetch = true
				await $authHost.put(`api/production_order/${task.id}`, {
					production_fact_date: production_date
				})
				this.isFetch = false
			}
		}
	}

	changeShipmentDate = async ({ event, day_id, mouth_id, task }: any) => {
		if (!this.currentTask) return
		if (task.id !== this.currentTask.id) return
		event.preventDefault()
		event.stopPropagation()
		const day = `${day_id}`.length > 1 ? day_id : `0${day_id}`
		const mouth = `${mouth_id}`.length > 1 ? mouth_id : `0${mouth_id}`
		if (task.isProduct) {
			GantTasks.tasks.forEach((element: any) => {
				const product = element.products.find((e: any) => e.id === task.id)
				if (product) {
					product.shipment = `${day}.${mouth}.2023`
					product.shipment_date = `${day}.${mouth}.2023`
				}
			})
		}
		task.shipment = `${day}.${mouth}.2023`
		const date = new Date(2023, mouth_id - 1, day_id + 1)
		console.log(toJS(task))
		const shipment_date = date.toISOString()
		if (!this.isFetch) {
			this.isFetch = true
			await $authHost.put(`api/production_order/${task.id}`, {
				production_plan_date: shipment_date
			})
			this.isFetch = false
		}
	}

	changeCreatedDate = async ({ event, day_id, mouth_id, task }: any) => {
		if (!this.currentTask) return
		if (task.id !== this.currentTask.id) return
		event.preventDefault()
		event.stopPropagation()
		const day = `${day_id}`.length > 1 ? day_id : `0${day_id}`
		const mouth = `${mouth_id}`.length > 1 ? mouth_id : `0${mouth_id}`
		if (task.isProduct) {
			GantTasks.tasks.forEach((element: any) => {
				const product = element.products.find((e: any) => e.id === task.id)
				if (product) {
					product.created = `${day}.${mouth}.2023`
					product.created_date = `${day}.${mouth}.2023`
				}
			})
		}
		task.created = `${day}.${mouth}.2023`
		const date = new Date(2023, mouth_id - 1, day_id + 1)
		const statechangetime = date.toISOString()
		if (!this.isFetch) {
			if (task.isProduct) {
				this.isFetch = true
				await $authHost.put(`api/orderproducts/${task.id}`, {
					created_date: statechangetime
				})
				this.isFetch = false
			} else {
				this.isFetch = true
				await $authHost.put(`api/production_order/${task.id}`, {
					order_datetime: statechangetime
				})
				this.isFetch = false
			}
		}
	}

	emptyAction = ({ event, day_id, mouth_id, task }: any) => {
		if (this.dragType === 'start') {
			this.changeCreatedDate({
				event,
				day_id,
				mouth_id,
				task
			})
		}
		if (this.dragType === 'end') {
			this.changeShipmentDate({
				event,
				day_id,
				mouth_id,
				task
			})
		}
		if (this.dragType === 'ready') {
			this.changeReadyDate({
				event,
				day_id,
				mouth_id,
				task
			})
		}
		if (!this.dragType || this.dragType === 'task') {
			this.dragDrop({ event, task })
		}
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new DragAndDrop()
