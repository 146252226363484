import st from '../../Fastomat.module.scss'
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import FastomatWorker from '@/store/FastomatWorker'
import { insertOrderAPI } from '@/API/fastomatWorkerAPI'
import Selector from '../OrderInfo/Selector'
import { autorun, toJS } from 'mobx'
import { Language } from '@/utils/langSwitch'
import { dict } from './TranslationList'
import Logotype from '@/components/Logotype'
// Переводчик
const t = Language.translateWithDictionary(dict)

const ModalAddOrder: FC = observer(() => {
	const [lastOrderNumber, setLastOrderNumber] = useState<number>(1)
	const ordername = useRef() as MutableRefObject<HTMLInputElement>
	const ordertext = useRef() as MutableRefObject<HTMLInputElement>
	const ordernum = useRef() as MutableRefObject<HTMLInputElement>
	const orderqr = useRef() as MutableRefObject<HTMLInputElement>
	const [state, setState] = useState<number>(1)
	const closeModal = () => {
		FastomatWorker.addModal = false
	}
	const [camera, setCamera] = useState<boolean>(false)
	const ChangeState = (value: number) => setState(value)
	useEffect(() => {
		autorun(() => {
			if (FastomatWorker.orders.length !== 0) {
				let orders = JSON.stringify(toJS(FastomatWorker.orders))
				orders = JSON.parse(orders)
				// @ts-ignore
				orders.sort(function (a, b) {
					return (
						new Date(Date.parse(b.ordertime)).getTime() -
						new Date(Date.parse(a.ordertime)).getTime()
					)
				})
				// @ts-ignore
				if (orders[0].ordernum > 100) {
					setLastOrderNumber(1)
				} else {
					// @ts-ignore
					setLastOrderNumber(orders[0].ordernum + 1)
				}
			}
		})
	}, [])
	return (
		<div className={st.modal}>
			<div className={st.insertForm}>
				<div className={st.formHeader}>
					<Logotype />
					<p onClick={() => closeModal()}>X</p>
				</div>

				<div className={st.formContent}>
					<label>{t('Имя заказа')}</label>
					<input
						type='text'
						value={`${t('Заказ')} #${lastOrderNumber}`}
						ref={ordername}
					/>

					<label>{t('Описание')}</label>
					<input type='text' ref={ordertext} />

					<label>{t('Номер заказа')}</label>
					<input
						type='text'
						ref={ordernum}
						value={lastOrderNumber}
						onChange={e => {
							if (Number(parseInt(e.target.value.replace(/[^\d]/g, '')))) {
								setLastOrderNumber(
									Number(parseInt(e.target.value.replace(/[^\d]/g, '')))
								)
							} else {
								setLastOrderNumber(0)
							}
						}}
					/>

					<label>{t('QR код')}</label>
					{!camera ? (
						<input type='text' autoFocus ref={orderqr} />
					) : // <QR setEditData={setQr} />
					null}

					<div style={{ width: '50%' }}>
						<div className={st.addBtn} onClick={() => setCamera(prev => !prev)}>
							{camera ? t('Введите QR') : t('Сканировать с камеры')}
						</div>
					</div>

					<label>{t('Статус')}</label>
					<Selector state={state} ChangeState={ChangeState} />
				</div>

				<div className={st.sendForm}>
					<div
						className={st.addBtn}
						onClick={async () => {
							await insertOrderAPI({
								orderstate: state,
								ordertext: ordertext.current.value || '',
								ordername: ordername.current.value,
								ordernum: Number(ordernum.current.value),
								orderqr: orderqr.current.value
							})
							FastomatWorker.addModal = false
						}}>
						<p>{t('Добавить заказ')}</p>
					</div>
				</div>
			</div>
		</div>
	)
})

export default ModalAddOrder
