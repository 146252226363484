import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import st from './TestingPage.module.scss'
import playImg from '../../assets/play.svg'
import { Flashrack } from '../../API/Testing'
import { $authHost } from '../../API'
import { IRacks } from '../../types/Warehouse'
import CircleSending from './CircleSending'
import Loader from '../../components/Loader/Loader'
import { dict } from './TranslationList'
import { Language } from '../../utils/langSwitch'
import { useMount } from '@/hooks/useMount'
import SettingsCollectSystem from '@/store/Settings/SettingsCollectSystem/SettingsCollectSystem'
import AdBannerSettings from '@/store/Settings/AdBanner/AdBannerSettings'
import Company from '@/store/Company/Company'
import { lighting } from '@/API/fastomatClientAPI.d'

// Переводчик
const t = Language.translateWithDictionary(dict)

const TestingPage: FC = () => {
	const [countFastomat, setCountFastomat] = useState(10)
	const [countRack, setCountRack] = useState(10)
	const [rackSelect, setRackSelect] = useState<null | number>(null)
	const [racks, setRacks] = useState<null | IRacks[]>(null)
	const [fastFetch, setFastFetch] = useState(false)
	const [fastRack, setRackFetch] = useState(false)

	const updateRack = (val: number) => {
		setRackSelect(val)
	}
	const getRacks = async () => {
		const { data } = await $authHost.get<IRacks[]>('api/rack_view')
		setRacks(data)
	}
	useMount(() => {
		getRacks()
	})

	return (
		<>
			<div className={`${st.container} pt-36 dark:text-white`}>
				<div className='w-full text-4xl mb-4 text-center'>Fastomat test cell's</div>
				<div className='grid grid-cols-2'>
					<div
						onClick={() => lighting(1)}
						className='border border-black dark:border-white h-52 w-full hover:bg-green-400 cursor-pointer flex justify-center items-center'>
						<p>1</p>
					</div>
					<div
						onClick={() => lighting(2)}
						className='border border-black dark:border-white h-52 w-full hover:bg-green-400 cursor-pointer flex justify-center items-center'>
						<p>2</p>
					</div>
				</div>
				<div
					onClick={() => lighting(3)}
					className='border mb-10 border-black dark:border-white h-52 w-full hover:bg-green-400 cursor-pointer flex justify-center items-center'>
					<p>3</p>
				</div>

				<div className='flex border-2 p-12 flex-col'>
					<div>
						<p className='text-4xl text-center'>{t('Запустить мигание фастомата')}</p>
					</div>
					<div
						className={`text-2xl my-10 flex w-full 
            justify-center items-center`}>
						<input
							className={`border-b-2 w-32 dark:text-black
                text-center mx-12 text-5xl`}
							type='text'
							value={countFastomat}
							onChange={e => setCountFastomat(Number(e.target.value))}
						/>
						<p>{t('раз')}</p>
					</div>
					<div className='flex items-center justify-center'>
						<Loader loading={fastFetch}>
							<div
								className={`flex items-center gap-4 text-2xl 
                  border-2 rounded-full justify-center w-fit 
                  p-2 px-5 hover:bg-gray-200 cursor-pointer`}
								onClick={async () => {
									if (fastFetch) return
									setFastFetch(true)
									await CircleSending.send(countFastomat)
									setFastFetch(false)
								}}>
								<p>{t('Запустить')}</p>
								<img className={st.play} src={playImg} alt='' />
							</div>
						</Loader>
					</div>
				</div>

				<div className='flex border-2 p-12 flex-col mt-10'>
					<div>
						<p className='text-4xl text-center'>{t('Запустить мигание стеллажа')}</p>
					</div>
					<div
						className={`text-2xl my-10 flex w-full 
            justify-center items-center`}>
						<input
							className={`border-b-2 w-32 dark:text-black
                text-center mx-12 text-5xl`}
							type='text'
							value={countRack}
							onChange={e => setCountRack(Number(e.target.value))}
						/>
						<p>{t('раз')}</p>
					</div>
					<div className='my-10 w-full flex justify-center text-3xl'>
						<Loader loading={!racks}>
							<RacksAsync rackSelect={rackSelect} updateRack={updateRack} racks={racks} />
						</Loader>
					</div>
					<div className='flex items-center justify-center'>
						<Loader loading={fastRack}>
							<div
								className={`flex items-center gap-4 text-2xl 
                  border-2 rounded-full justify-center w-fit 
                  p-2 px-5 hover:bg-gray-200 cursor-pointer`}
								onClick={async () => {
									if (!countRack) return
									if (!rackSelect) return
									if (fastRack) return
									setRackFetch(true)
									await Flashrack(countRack, rackSelect)
									setRackFetch(false)
								}}>
								<p>{t('Запустить')}</p>
								<img className={st.play} src={playImg} alt='' />
							</div>
						</Loader>
					</div>
				</div>

				<div className='flex border-2 p-12 mt-10'>
					<p className='mx-10 text-4xl'>{t('Вычитать остатки при сборке')}</p>
					<label
						className={`relative inline-flex 
						items-center cursor-pointer`}>
						<input
							type='checkbox'
							className='sr-only peer'
							checked={SettingsCollectSystem.GetReduceCollect()}
							onChange={SettingsCollectSystem.ToggleReduceCollect}
						/>
						<div
							className={`w-20 h-11 bg-gray-200 rounded-full 
							peer dark:bg-gray-700 
							peer-checked:after:translate-x-full 
							peer-checked:after:border-white after:content-[''] 
							after:absolute after:top-0.5 after:left-[2px] 
							after:bg-white after:border-gray-300 after:border 
							after:rounded-full after:h-10 after:w-10 
							after:transition-all dark:border-gray-600 
							peer-checked:bg-blue-600`}></div>
					</label>
					<p className='text-2xl'></p>
				</div>

				<div className='flex border-2 p-12 mt-10'>
					<p className='mx-10 text-4xl'>{t('Рекламный банер')}</p>
					<label
						className={`relative inline-flex 
						items-center cursor-pointer`}>
						<input
							type='checkbox'
							className='sr-only peer'
							checked={AdBannerSettings.isBanner}
							onChange={AdBannerSettings.toogleBanner}
						/>
						<div
							className={`w-20 h-11 bg-gray-200 rounded-full 
							peer dark:bg-gray-700 
							peer-checked:after:translate-x-full 
							peer-checked:after:border-white after:content-[''] 
							after:absolute after:top-0.5 after:left-[2px] 
							after:bg-white after:border-gray-300 after:border 
							after:rounded-full after:h-10 after:w-10 
							after:transition-all dark:border-gray-600 
							peer-checked:bg-blue-600`}></div>
					</label>
					<p className='text-2xl'></p>
				</div>
			</div>
		</>
	)
}

export default observer(TestingPage)

interface IRacksAsyncProps {
	rackSelect: null | number
	updateRack(value: number): void
	racks: IRacks[] | null
}

const RacksAsync: FC<IRacksAsyncProps> = ({ rackSelect, updateRack, racks }) => {
	if (!racks || racks.length === 0) {
		return (
			<div
				className='text-red-400 dark:text-black'
				title={`Чтобы выбрать стеллаж для подсветки 
        нужно сначлаа внести стеллаж в систему`}>
				{t('Ошибка: Стеллажи не заданы')}
			</div>
		)
	}
	return (
		<select
			value={rackSelect || 0}
			onChange={e => {
				updateRack(Number(e.target.value))
			}}>
			{racks.map(e => (
				<option value={e.id}>{e.RackName}</option>
			))}
		</select>
	)
}
