import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ITreeWarehouse } from '../../types/Warehouse'
import st from './WareHouseItem.module.scss'
import { Language } from '../../utils/langSwitch'
import { dict } from './TranslationList'

// переводчик
const t = Language.translateWithDictionary(dict)
interface IWareHouseItemProps {
	e: ITreeWarehouse
}

const WareHouseItem: FC<IWareHouseItemProps> = ({ e: { zones } }) => {
	const [visible, setVisible] = useState<boolean>(false)

	return (
		<>
			<div onClick={() => setVisible(prev => !prev)}>
				<div
					className={`${st.item} text-center items-center`}
					style={{ textAlign: 'center' }}>
					<div className={st.name}>
						<p>{zones[0].whname}</p>
					</div>
					<div>
						<p>{zones[0].adress}</p>
						<p>{zones[0].phone}</p>
					</div>
					<div className='underline text-purple-500 hover:text-purple-700'>
						<NavLink to={`/warehouse-map/${zones[0].WH_id}`}>
							<p>Открыть карту склада</p>
						</NavLink>
					</div>
				</div>

				<div className={st.zones}>
					{zones.map(zone => {
						return (
							<NavLink
								to={`/admin/warehouse/zone/${zone.whzone_id}`}
								key={zone.ZoneName}
								style={visible ? { display: 'flex' } : { display: 'none' }}>
								<div className={st.zone}>
									<p>{t('Зона')}:</p>
									<div>{zone.ZoneName}</div>
								</div>
							</NavLink>
						)
					})}
				</div>
			</div>
		</>
	)
}

export default observer(WareHouseItem)
