import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { IBreadcrumbsProps } from '../Breadcrumbs.interface'
import { observer } from 'mobx-react-lite'
import { ReactComponent as ArrowImg } from '@/assets/breadcrums.svg'

const BreadCrumbItem: FC<{ link: IBreadcrumbsProps }> = ({ link }) => {
	return (
		<>
			<li>
				<div className='flex items-center'>
					<ArrowImg className='w-3 h-3 mx-1' />
					<NavLink
						to={link.url}
						className={`ml-1 text-sm font-medium
              text-gray-700 hover:text-blue-600 md:ml-2 
              dark:text-gray-400 dark:hover:text-white`}>
						{link.name}
					</NavLink>
				</div>
			</li>
		</>
	)
}

export default observer(BreadCrumbItem)
