import { observer } from "mobx-react-lite";
import { FC } from "react";
import st from "../EditGuides.module.scss";
import GuideHandler from "../store/GuideHandler";
import StageHandler from "../store/StageHandler";
const Footer: FC = () => {
  const cancelHandler = () => {
    switch (StageHandler.stage) {
      case 2:
        GuideHandler.Cancel = true;
        break;
      default:
        break;
    }
  };

  if (StageHandler.stage === 5) {
    return (
      <div className={st.modalFooter}>
        <p style={{ textAlign: "center" }}>Загрузка...</p>
      </div>
    );
  }

  return (
    <>
      <p style={{ textAlign: "center", color: "red" }}>
        {StageHandler.error ? StageHandler.error : null}
      </p>
      <div className={st.modalFooter}>
        <div className={st.btnsModalFooter}>
          {/* <div className={st.cancel} onClick={() => cancelHandler()}>
            <p>Отменить</p>
          </div> */}
          <div className={st.skip} onClick={() => StageHandler.prevGuide()}>
            <p>Назад</p>
          </div>
          {StageHandler.stage === 4 ? (
            <div className={st.save} onClick={() => StageHandler.nextGuide()}>
              <p>Сохранить</p>
            </div>
          ) : (
            <div className={st.skip} onClick={() => StageHandler.nextGuide()}>
              <p>Далее</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default observer(Footer);
