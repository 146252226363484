import { $authHost } from '@/API'
import { configure, makeAutoObservable } from 'mobx'
import {
	IFetchOrderProductsProps,
	IOrderPicking
} from './NotFoundProducts.types'
import { FetchStages } from '@/types'
import TYPicking from '@/store/TYPicking'
import { IResponceProductList } from '@/types/createorder'

configure({ enforceActions: 'never' })

class NotFoundProductsStore {
	productList: IOrderPicking[] = []
	isFetch: FetchStages = FetchStages.waiting
	isFetchProduct: FetchStages = FetchStages.waiting

	fetchOrderProducts = async ({ order_id }: IFetchOrderProductsProps) => {
		try {
			if (this.isFetch === FetchStages.start) return
			this.isFetch = FetchStages.start
			const { data } = await $authHost.get<IOrderPicking[]>(
				`api/orderproducts/${order_id}`
			)
			this.productList = data.filter(e => {
				const product = TYPicking.Products.find(product => product.id === e.id)
				if (!product) {
					return true
				}
				return false
			})

			this.isFetch = FetchStages.end
		} catch (e) {
			this.isFetch = FetchStages.error
			console.log('%c Error on not found product request', 'color: red')
		}
	}

	fetchProductData = async (product_id: number) => {
		try {
			if (this.isFetchProduct === FetchStages.start) return
			this.isFetchProduct = FetchStages.start
			const { data } = await $authHost.get<IResponceProductList>(
				`api/products/?id=${product_id}`
			)
			if (!data.products.length) {
				throw new Error('Product not found')
			}
			this.isFetchProduct = FetchStages.end
			return data.products[0]
		} catch (e) {
			this.isFetchProduct = FetchStages.error
			console.error(e)
			console.log('%c Error on func "fetchProductData"', 'color: red')
		}
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new NotFoundProductsStore()
