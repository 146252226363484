import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import spbImg from '../../assets/spb.svg'
import { createOrders } from '../../API/CreateOrderAPI'
import st from './GenerateQR.module.scss'
import { useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import Products from '../../store/Products'
import { dict } from './TranslationList'
import { Language } from '../../utils/langSwitch'

const t = Language.translateWithDictionary(dict)
const GenerateQRPage: FC = observer(() => {
	const [qr, setQr] = useState<string>('')
	const navigate = useNavigate()
	const [timer, setTimer] = useState<number>(30)
	useEffect(() => {
		const create = async () => {
			const result = await createOrders()
			setQr(result)
		}
		if (Products.basket.length === 0) return
		create()
	}, [])
	useEffect(() => {
		if (qr === '') return
		const timerInt = setInterval(() => {
			setTimer(prev => {
				if (prev > 0) {
					return prev - 1
				}
				return prev
			})
		}, 1000)
		return () => clearInterval(timerInt)
	}, [qr])
	useEffect(() => {
		if (timer === 1) {
			navigate('/')
		}
	}, [timer])
	return (
		<>
			<div className={st.center}>
				<p>
					{t('Заказ принят!')} <br />
					<br />
				</p>
				<p>
					{t('Сфотографируйте этот QR код')} <br />{' '}
					{t('для получения вашего заказа')}
				</p>
				{qr === '' ? <p>{t('Генерация QR')}</p> : <QRCode value={qr} />}
				<p style={{ marginTop: '160px' }}>
					{t('Оплатите Ваш заказ с помощью СБП')}
				</p>
				<img src={spbImg} alt='spb' />
				{qr === '' ? null : (
					<p style={{ marginTop: '160px' }}>
						{t('Возвращение на главную страницу')}: {timer}
					</p>
				)}
				{qr === '' ? null : (
					<NavLink to={'/'}>
						<div className={st.btnreturn}>
							<p>{t('Вернуться сейчас')}</p>
						</div>
					</NavLink>
				)}
			</div>
		</>
	)
})
export default GenerateQRPage
