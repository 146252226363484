import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { ICategories } from '../../../types/categoryChange'
import { ReactComponent as DeleteImg } from '@/assets/delete.svg'
import { NavLink } from 'react-router-dom'
import Categories from '@/store/Categories/Categories'

const getParent = (parent_id: number | null) => {
	if (!parent_id) return null
	const parent = Categories.CategoriesList.find(
		category => category.id === parent_id
	)
	if (!parent) return null
	return parent
}

const Category: FC<{ category: ICategories }> = ({ category }) => {
	useEffect(() => {
		Categories.categoryPhoto(category.id)
	}, [])
	const parent = getParent(category.parent_id)

	return (
		<div
			className={`border-[1px] min-h-[16rem]
			xl:w-52 items-center flex flex-col justify-between
			border-gray-300 h-full self-stretch`}>
			<div className={`xl:w-24 xl:h-24 w-24 h-24 mt-4`}>
				<img src={`${category.photo}`} alt='category_img' />
			</div>
			<div className={`my-4 dark:text-gray-300`}>
				<p className={`text-xl text-center`}>
					{`${category.categoryname.substr(0, 28)}`}
				</p>
				<NavLink
					to={`/admin/category?query=${
						parent ? parent?.categoryname : category?.categoryname
					}`}
					className={`text-sm font-light font-['Open_Sans'] 
					cursor-pointer hover:underline text-center w-full`}>
					{`${parent ? parent.categoryname.substr(0, 28) : ''}`}
				</NavLink>
			</div>
			<div className={`w-full flex`}>
				<NavLink
					className={`bg-blue-500 text-white w-full 
				flex justify-center cursor-pointer xl:text-md 
				hover:opacity-80 xl:h-10 h-8 items-center
				`}
					to={'/admin/category/edit/' + category.id}>
					<div>
						<p>Редактировать</p>
					</div>
				</NavLink>

				<div
					onClick={() => Categories.deleteCatergory(category.id)}
					className={`xl:w-12 w-10 flex justify-center 
					cursor-pointer hover:opacity-80
				items-center bg-red-500`}>
					<DeleteImg fill='white' className='w-5 h-5' />
				</div>
			</div>
		</div>
	)
}
export default observer(Category)
