import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import RackGrid from '../../store/RackGrid'
import { cutMaxTextLength } from '../../utils/maxText'
import SuccessImg from '../../assets/success-svgrepo-com.svg'
import { IProducts } from '../../types/TYPicking'
import { Language } from '@/utils/langSwitch'

const RackGridSelect: FC = () => {
	const light = (col: IProducts[]) => {
		if (col.length === 0) {
			return null
		} else {
			if (col.every(prod => prod.PickState_id === 1)) {
				return 'bg-green-500'
			} else {
				return 'bg-yellow-200 text-black'
			}
		}
	}

	function zxc(idxcol: number, idxrow: number) {
		if (!RackGrid.coords[0]) return null
		if (!RackGrid.coords[1]) return null
		if (idxcol === RackGrid.coords[0] - 1) {
			if (idxrow === RackGrid.coords[1] - 1) {
				return 'bg-purple-500'
			}
		}
		return null
	}

  const textReform = (productrow: IProducts) => {
    const ProductNameLenght = 15
    if(Language.current === 'ru'){
      return cutMaxTextLength(
        productrow.ProductName, 
        ProductNameLenght
      )
    }
    else{
      return cutMaxTextLength(
        productrow.ProductNameEng, 
        ProductNameLenght
      )
    }
  }

	return (
		<>
			<div
				className={`text-center text-gray-600 
        text-4xl mb-3`}>
				{
				RackGrid.thisRack 
				? RackGrid.thisRack.RackName  
				:	'Стеллаж'}
			</div>
			<div
				className={`flex flex-wrap justify-center 
        font-['Open_Sans'] text-3xl mb-20`}>
				{RackGrid.racks.map((element, idxrow) => {
					return (
						<div className={`w-full flex items-stretch 
              justify-items-stretch`}>
							{element.map((col, idxcol) => (
								<div
									className={`w-full min-h-[163px] border-2 
                    hover:bg-yellow-300 cursor-pointer border-black 
                    ${col.length && 'text-white'}  
                    ${zxc(idxcol, idxrow) ? zxc(idxcol, idxrow) : light(col)}
                  `}>
									<ul className='mt-2'>
										{col.map(row => (
											<li className={`flex text-xs 
                        items-center px-2`}>
												<span>
													{row.PickState_id === 1 && (
														<img
															src={SuccessImg}
															className='w-2 mx-2'
															alt='order-success'
														/>
													)}
												</span>
												{textReform(row)}
											</li>
										))}
									</ul>
								</div>
							))}
						</div>
					)
				})}
			</div>
		</>
	)
}

export default observer(RackGridSelect)
