import { IProduct } from '@/types/createorder'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import Barcode from 'react-barcode'
import ProductSliderList from './ProductSliderList'

const ProductImg: FC<{ product: IProduct }> = ({ product }) => {
	// Если фото есть
	if (product.photo.length) {
		return <ProductSliderList photo={product.photo} />
	}
	// Если фото нет, но есть баркод
	if (product.barcode) {
		// Если в баркоде есть буквы или символы
		if (isNaN(Number(product.barcode))) {
			// Тогда это QRCode
			return (
				<img
					src={`${process.env.REACT_APP_API_URL}api/qrcode_gen/${process.env.REACT_APP_API_URL}product/${product.id}`}
					alt='qrcode'
				/>
			)
		} else {
			// Если нет, то это баркод
			return <Barcode value={product.barcode} />
		}
	}
	// Если нет ничего, то это QRCode с ссылкой на товар
	return (
		<img
			src={`${process.env.REACT_APP_API_URL}api/qrcode_gen/${process.env.REACT_APP_API_URL}product/${product.id}`}
			alt='qrcode'
		/>
	)
}

export default observer(ProductImg)
