import { makeAutoObservable } from "mobx";
import { configure } from "mobx";

configure({
  enforceActions: "never",
});

class GuidesCreatePopup {
  isOpen: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
}

export default new GuidesCreatePopup();
