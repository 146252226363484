import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ReactComponent as HomeImg } from '@/assets/home.svg'
import { ReactComponent as CategoryImg } from '@/assets/category.svg'
import { ReactComponent as AvatarImg } from '@/assets/avatar.svg'
import { NavLink } from 'react-router-dom'
import Theme from '@/store/Theme'
import BasketBtnMobile from './BasketBtn'
import { dict } from '../TranslationList'
import { Language } from '@/utils/langSwitch'
// Переводчик
const t = Language.translateWithDictionary(dict)
const Footer: FC = () => {
	return (
		<>
			<footer
				className={`fixed h-14 py-1 bottom-0 
      w-screen md:h-28 bg-white 
      lv:hidden dark:bg-slate-800`}>
				<div className='container mx-auto flex justify-around'>
					<NavLink
						to={'/'}
						className={`flex flex-col justify-center 
            items-center cursor-pointer`}>
						<div className='block md:hidden'>
							<HomeImg
								width={'1.75rem'}
								height={'1.75rem'}
								fill={Theme.isDarkTheme ? 'white' : 'black'}
							/>
						</div>
						<div className='hidden md:block'>
							<HomeImg
								width={'4rem'}
								height={'4rem'}
								fill={Theme.isDarkTheme ? 'white' : 'black'}
							/>
						</div>
						<p className='text-xs md:text-lg mt-1 dark:text-white'>
							{t('Главная')}
						</p>
					</NavLink>

					<NavLink
						to={'/'}
						className={`flex flex-col justify-center 
            items-center cursor-pointer`}>
						<div className='block md:hidden'>
							<CategoryImg
								width={'1.75rem'}
								height={'1.75rem'}
								fill={Theme.isDarkTheme ? 'white' : 'black'}
							/>
						</div>
						<div className='hidden md:block'>
							<CategoryImg
								width={'4rem'}
								height={'4rem'}
								fill={Theme.isDarkTheme ? 'white' : 'black'}
							/>
						</div>
						<p className='text-xs md:text-xl mt-1 dark:text-white'>
							{t('Категории')}
						</p>
					</NavLink>

					<BasketBtnMobile />

					<NavLink
						to={'/'}
						className={`flex flex-col justify-center 
            items-center cursor-pointer`}>
						<div className='block md:hidden'>
							<AvatarImg
								width={'1.75rem'}
								height={'1.75rem'}
								fill={Theme.isDarkTheme ? 'white' : 'black'}
							/>
						</div>
						<div className='hidden md:block'>
							<AvatarImg
								width={'4rem'}
								height={'4rem'}
								fill={Theme.isDarkTheme ? 'white' : 'black'}
							/>
						</div>
						<p className='text-xs md:text-xl mt-1 dark:text-white'>
							{t('Профиль')}
						</p>
					</NavLink>
				</div>
			</footer>

			<footer className='hidden lv:block bg-gray-200 dark:bg-gray-600'>
				<div
					className={`max-w-screen-xl px-4 py-12 mx-auto 
        space-y-8 overflow-hidden sm:px-6 lg:px-8`}>
					<nav className='flex flex-wrap justify-center -mx-5 -my-2'>
						<div className='px-5 py-2'>
							<NavLink
								to='/selectcategory'
								className={`text-base leading-6 text-gray-500 
                hover:text-gray-900 dark:hover:text-white 
                dark:text-gray-200 dark:hover:underline`}>
								{t('Главная')}
							</NavLink>
						</div>
						<BasketBtnMobile />
						<div className='px-5 py-2'>
							<NavLink
								to='/profile'
								className={`text-base leading-6 text-gray-500 
                hover:text-gray-900 dark:hover:text-white 
                dark:text-gray-200 dark:hover:underline`}>
								{t('Профиль')}
							</NavLink>
						</div>
						<div className='px-5 py-2'>
							<NavLink
								to='/notification'
								className={`text-base leading-6 text-gray-500 
                hover:text-gray-900 dark:hover:text-white 
                dark:text-gray-200 dark:hover:underline`}>
								{t('Уведомления')}
							</NavLink>
						</div>
						<div className='px-5 py-2'>
							<NavLink
								to='/categories'
								className={`text-base leading-6 text-gray-500 
                hover:text-gray-900 dark:hover:text-white 
                dark:text-gray-200 dark:hover:underline`}>
								{t('Категории')}
							</NavLink>
						</div>
					</nav>
					<p
						className={`mt-8 text-base leading-6 
          text-center text-gray-400`}>
						© 2023 TakeYours
					</p>
				</div>
			</footer>
		</>
	)
}

export default observer(Footer)
