import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IInputFieldProps {
	title?: string
	placeholder?: string
	errorMessage?: string
	successMessage?: string
	status?: 'error' | 'success' | 'defualt'
	defaultValue?: string | number
	value?: string | number
	onChange: (value: string) => void
}

const InputField: FC<IInputFieldProps> = ({
	title,
	placeholder,
	errorMessage,
	successMessage,
	status = 'defualt',
	defaultValue,
	value,
	onChange
}) => {
	switch (status) {
		case 'success':
			return (
				<div>
					<label
						className={`block mb-2 text-sm 
            font-medium text-green-700 
            dark:text-green-500`}>
						{title ? title : null}
					</label>
					<input
						type='text'
						id='success'
						value={value}
						defaultValue={defaultValue}
						onChange={e => onChange(e.target.value)}
						className={`bg-green-50 border border-green-500 
              text-green-900 dark:text-green-400 
              placeholder-green-700 dark:placeholder-green-500 
              text-sm rounded-lg focus:ring-green-500 
              focus:border-green-500 block w-full p-2.5 
              dark:bg-gray-700 dark:border-green-500`}
						placeholder={placeholder ? placeholder : ''}
					/>
					<p
						className={`mt-2 text-sm 
           text-green-600 dark:text-green-500`}>
						{successMessage ? successMessage : null}
					</p>
				</div>
			)
		case 'error':
			return (
				<div>
					<label
						className={`block mb-2 text-sm 
            font-medium text-red-700 dark:text-red-500`}>
						{title ? title : null}
					</label>
					<input
						type='text'
						id='error'
						value={value}
						defaultValue={defaultValue}
						onChange={e => onChange(e.target.value)}
						className={`bg-red-50 border border-red-500 
              text-red-900 placeholder-red-700 text-sm 
              rounded-lg focus:ring-red-500 
              dark:bg-gray-700 focus:border-red-500 
              block w-full p-2.5 dark:text-red-500 
              dark:placeholder-red-500 dark:border-red-500`}
						placeholder={placeholder ? placeholder : ''}
					/>
					<p className='mt-2 text-sm text-red-600 dark:text-red-500'>
						{errorMessage ? errorMessage : null}
					</p>
				</div>
			)
		case 'defualt':
			return (
				<div>
					<label
						className={`block mb-2 text-sm 
            font-medium text-gray-900 dark:text-white`}>
						{title ? title : null}
					</label>
					<input
						type='text'
						defaultValue={value}
						value={value}
						onChange={e => onChange(e.target.value)}
						id='default-input'
						placeholder={placeholder ? placeholder : ''}
						className={`bg-gray-50 border 
              border-gray-300 text-gray-900 text-sm 
                rounded-lg focus:ring-blue-500 
              focus:border-blue-500 block w-full p-2.5 
              dark:bg-gray-700 dark:border-gray-600 
              dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500`}
					/>
				</div>
			)
	}
}

export default observer(InputField)
