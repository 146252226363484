import { $authHost } from '@/API'
import { IUsers } from '../types/users'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'

configure({
	enforceActions: 'never'
})

class User {
	Users: IUsers[] = []

	addUserDefualtConfig = {
		id: 0,
		is_superuser: false,
		username: '',
		first_name: '',
		last_name: '',
		email: '',
		is_staff: true,
		is_active: true
	}

	async getUsers() {
		try {
			let { data } = await $authHost.get<IUsers[]>('api/user')
			this.Users = data
			return data
		} catch (e) {}
	}

	constructor() {
		makeAutoObservable(this)
	}
}
export default new User()
