import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import {
	IGantTask,
	IProductionOrder,
	IProductionState,
	IProducts
} from './Gant.interface'
import { $authHost } from '@/API'
import { IResponceProductList } from '@/types/createorder'

configure({ enforceActions: 'never' })
export const fixDateZero = (str: number) => {
	return str.toString().length > 1 ? str : '0' + str
}
class GantTasks {
	sorttype = 'Все параметры'

	tasks: IGantTask[] = []

	filteredTasks: IGantTask[] = this.tasks

	fetchOrders = async () => {
		const result: IGantTask[] = []
		const { data } = await $authHost.get<IProductionOrder[]>(
			'api/production_order/'
		)
		const states = await $authHost.get<IProductionState[]>(
			'api/production_order_state/'
		)
		for(let i = 0 ; i < data.length; i++){
			const product = await $authHost.get<IResponceProductList>(
				`api/products/?id=${data[i].product_id}`
			)
			if(product.data.products.length > 0){
				// @ts-ignore
				data[i].product_name = product.data.products[0].productname
			}
		}


		if (!data) return
		data.map(e => {
			const createdDate = new Date(e.order_datetime)
			const shipmentDate = new Date(e.production_plan_date || '')
			const readyDate = new Date(e.production_fact_date || '')

			const created: string = `${fixDateZero(
				createdDate.getDate()
			)}.${fixDateZero(
				createdDate.getMonth() + 1
			)}.${createdDate.getFullYear()}`

			const shipment: string = `${fixDateZero(
				shipmentDate.getDate()
			)}.${fixDateZero(
				shipmentDate.getMonth() + 1
			)}.${shipmentDate.getFullYear()}`

			const ready: string = `${fixDateZero(readyDate.getDate())}.${fixDateZero(
				readyDate.getMonth() + 1
			)}.${readyDate.getFullYear()}`

			result.push({
				id: e.id,
				// @ts-ignore
				product: e.product_name,
				technology: '',
				products: [],
				isProduct: false,
				client: `заказ #${e.id}`,
				created: created,
				shipment: shipment,
				ready: ready,
				color:
					states.data.find(elem => elem.id === e.current_state_id)
						?.state_color || null,
				sortorder: e.id
			})
		})
		this.tasks = result
		this.filteredTasks = result
		this.sortTasks()
	}

	getProducts = async (task_id: number) => {
		const currentProduct = this.tasks.find(task => task.id === task_id)
		if (currentProduct) {
			if (currentProduct.products.length === 0) {
				try {
					const { data } =
						await $authHost.get<IProducts[]>('api/orderproducts/')

					currentProduct.products = data
						.filter(elem => elem.order === task_id)
						.map(product => {
							const createdDate = new Date(product.created_date || '')
							const shipmentDate = new Date(product.shipment_date || '')
							const readyDate = new Date(product.production_date || '')

							const created: string = `${fixDateZero(
								createdDate.getDate()
							)}.${fixDateZero(
								createdDate.getMonth() + 1
							)}.${createdDate.getFullYear()}`

							const shipment: string = `${fixDateZero(
								shipmentDate.getDate()
							)}.${fixDateZero(
								shipmentDate.getMonth() + 1
							)}.${shipmentDate.getFullYear()}`

							const ready: string = `${fixDateZero(
								readyDate.getDate()
							)}.${fixDateZero(
								readyDate.getMonth() + 1
							)}.${readyDate.getFullYear()}`

							product.shipment_date = shipment
							product.created_date = created
							product.production_date = ready
							return product
						})
					console.log(currentProduct.products)
					this.filteredTasks = this.tasks
				} catch (e) {
					console.log('error')
				}
			} else {
				currentProduct.products = []
			}
		}
	}

	filterTasks = (query: string) => {
		switch (this.sorttype) {
			case 'Номер заказа':
				this.filteredTasks = this.tasks.filter(e => {
					if (e.id === Number(query)) return true
					return false
				})
				return
			case 'Клиент':
				this.filteredTasks = this.tasks.filter(e => {
					if (e.client.toLowerCase().includes(query.toLowerCase())) return true
					return false
				})
				return
			case 'Создан':
				this.filteredTasks = this.tasks.filter(e => {
					if (e.created.toLowerCase().includes(query.toLowerCase())) return true
					return false
				})
				return
			case 'Производство':
				this.filteredTasks = this.tasks.filter(e => {
					if (e.ready.toLowerCase().includes(query.toLowerCase())) return true
					return false
				})
				return
			case 'Отгрузка':
				this.filteredTasks = this.tasks.filter(e => {
					if (e.shipment.toLowerCase().includes(query.toLowerCase()))
						return true
					return false
				})
				return
			case 'Приоритет':
				this.filteredTasks = this.tasks.filter(e => {
					if (e.sortorder === Number(query)) return true
					return false
				})
				return
			default:
				this.filteredTasks = this.tasks.filter(e => {
					if (e.id === Number(query)) return true
					if (e.client.includes(query)) return true
					if (e.created.includes(query)) return true
					if (e.ready.includes(query)) return true
					if (e.shipment.includes(query)) return true
					if (e.sortorder === Number(query)) return true
					return false
				})
				return
		}
	}

	sortTasks = () => {
		this.tasks = this.tasks.sort((a, b) => {
			if (a.sortorder < b.sortorder) {
				return -1
			}
			if (a.sortorder > b.sortorder) {
				return 1
			}
			return 0
		})
		this.filteredTasks = this.filteredTasks.sort((a, b) => {
			if (a.sortorder < b.sortorder) {
				return -1
			}
			if (a.sortorder > b.sortorder) {
				return 1
			}
			return 0
		})
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new GantTasks()
