import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "товаров",
    eng: "products",
  },
  {
    ru: "Добавить заказы",
    eng: "Add orders",
  },
  {
    ru: "товаров",
    eng: "products",
  },
];
