import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { save } from "../api/Guides";

const Stage5: FC = () => {
  useEffect(() => {
    save();
  }, []);
  return <div></div>;
};
export default observer(Stage5);
