import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Быстро',
		eng: 'Quickly'
	},
	{
		ru: 'Компактно',
		eng: 'Compact'
	},
	{
		ru: 'Вернуться',
		eng: 'To return'
	},
	{
		ru: 'Выбирите ваш метод сборки',
		eng: 'Choose your build method'
	}
]
