import ScreenSettings from '@/store/Settings/ScreenSettings/ScreenSettings'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef, useState } from 'react'

const ScreenSplit: FC<{ children: JSX.Element }> = ({ children }) => {
	const left = useRef(null)
	const right = useRef(null)
	const [clicked, setClicked] = useState(false)

	const [leftWindow, setLeftWindow] = useState(
		() => document.body.clientWidth / 2
	)

	const handleArrow = (event: MouseEvent) => {
		const XPos = event.pageX
		console.log('x positions' + XPos)
		console.log('display width' + document.body.clientWidth)
		setLeftWindow(XPos)
	}

	useEffect(() => {
		document.onmousemove = function (e) {
			if (!clicked) return
			handleArrow(e)
		}
		// document.addEventListener('mousemove', handleArrow)
		// return () => {
		// 	document.removeEventListener('mousemove', handleArrow)
		// }
	}, [clicked])
	if (!ScreenSettings.ScreenSplit) return children
	return (
		<div className='flex'>
			<div style={{ width: `${leftWindow}px` }}></div>
			<div
				ref={left}
				style={{ width: `${leftWindow}px` }}
				className={`
         fixed top-0 left-0
        h-[${document.body.clientHeight}px]`}>
				<iframe
					title='Monitor'
					src={`${process.env.REACT_APP_API_URL}/?screensplit=true`}
					width='100%'
					height='100%'
					style={{ minHeight: '100vh' }}></iframe>
			</div>
			<div
				onMouseUp={() => setClicked(false)}
				onMouseDown={() => setClicked(true)}
				className={`w-[50px] 
        cursor-col-resize
        bg-slate-400 
        h-${document.body.clientHeight}px`}></div>
			<div
				ref={right}
				style={{ width: `${document.body.clientWidth - leftWindow}px` }}
				className={`
        h-full`}>
				{children}
			</div>
		</div>
	)
}

export default observer(ScreenSplit)
