import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import { IDateReform, IGantDates, IGantTask } from './Gant.interface'
import { IInitDataProps } from '../Gant/Gant.interface'

configure({ enforceActions: 'never' })

export const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
]

class GantDates {
	scale = 1

	gantTasks: IGantDates[] = []

	// Получаем кол-во дней в месяце
	daysInMonth = (month: number, year: number): number => {
		return new Date(year, month, 0).getDate()
	}

	// Получаем текущий месяц
	getInitData = (props?: IInitDataProps) => {
		const result = []
		const currentMonth = props?.currentMonth || new Date().getMonth() + 1
		const currentYear = props?.currentYear || new Date().getFullYear()
		let Month = currentMonth
		let Year = currentYear
		for (let i = 0; i < 3; i++) {
			if (Month < 1) {
				Month = 12 + Month
				Year = Year - 1
			}
			if (Month > 12) {
				Month = 1
				Year = Year + 1
			}
			const days = []
			for (let i = 0; i < this.daysInMonth(Month, Year); i++) {
				days.push({
					id: i + 1
				})
			}
			result.push({
				mouth: {
					id: Month,
					name: months[Month - 1]
				},
				days,
				year: Year
			})
			Month = Month + 1
		}
		this.gantTasks = result
	}

	taskDateReform = (task: IGantTask): IDateReform => {
		const readyDay = Number(task.ready.slice(0, 2))
		const readyMouth = Number(task.ready.slice(3, 5))
		const createdDay = Number(task.created.slice(0, 2))
		const createdMouth = Number(task.created.slice(3, 5))
		const shipmentDay = Number(task.shipment.slice(0, 2))
		const shipmentMouth = Number(task.shipment.slice(3, 5))
		return {
			readyDay,
			readyMouth,
			createdDay,
			createdMouth,
			shipmentDay,
			shipmentMouth
		}
	}

	ComputedColorized = (task: IGantTask, day_id: number, mouth_id: number) => {
		const createdDay = Number(task.created.slice(0, 2))
		const shipmentDay = Number(task.shipment.slice(0, 2))

		const createdMouth = Number(task.created.slice(3, 5))
		const shipmentMouth = Number(task.shipment.slice(3, 5))

		if (createdMouth === shipmentMouth && shipmentMouth === mouth_id) {
			return createdDay <= day_id && day_id <= shipmentDay
		}
		if (createdMouth === mouth_id) {
			return createdDay <= day_id
		}
		if (shipmentMouth === mouth_id) {
			return shipmentDay >= day_id
		}
		if (createdMouth < mouth_id && mouth_id < shipmentMouth) {
			return true
		}
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new GantDates()
