import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Заказ принят!',
		eng: 'The order is accepted!'
	},
	{
		ru: 'Сфотографируйте этот QR код',
		eng: 'Take a picture of this QR code'
	},
	{
		ru: 'для получения вашего заказа',
		eng: 'to receive your order'
	},
	{
		ru: 'Генерация QR',
		eng: 'QR Generation'
	},
	{
		ru: 'Оплатите Ваш заказ с помощью СБП',
		eng: 'Pay for your order using the SBP'
	},
	{
		ru: 'Возвращение на главную страницу',
		eng: 'Return to the main page'
	},
	{
		ru: 'Вернуться сейчас',
		eng: 'Go back now'
	}
]
