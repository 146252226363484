import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductPageStore from "./ProductPage.store";
import ProductImg from "@/components/ProductImg/ProductImg";

const ProductPage : FC = () => {
    const {id} = useParams()
    
    useEffect(() => {
        ProductPageStore.fetchProduct(Number(id))
    }, [])

    return <div className="container mx-auto">
        <div className="w-full h-full flex mt-10">
            <div className="w-1/2 flex justify-center">
                {ProductPageStore.data && <ProductImg product={ProductPageStore.data} />}
            </div>
            <div className="w-1/2">
                <div>
                {ProductPageStore?.data?.productname}

                </div>
                <div>

                {ProductPageStore?.data?.rate}
                </div>
                <div>
                {ProductPageStore?.data?.weight}

                </div>
                <div>
                {ProductPageStore?.data?.sizewidth}

                </div>
                <div>
                {ProductPageStore?.data?.sizeheight}

                </div>
                <div>
                {ProductPageStore?.data?.sizedepth}

                </div>
                <div>

                {ProductPageStore?.data?.productdescr}
                </div>

                <div>
                {ProductPageStore?.data?.basketcount}

                </div>
            </div>
        </div>

    </div>
}

export default observer(ProductPage)