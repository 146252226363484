import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import PosterImg from '@/videos/preview.jpg'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
const getLinks = [
	{
		name: 'Admin',
		url: '/admin'
	},
	{
		name: 'Main',
		url: ''
	}
]
const AboutNewsPage: FC = () => {
	return (
		<section>
			<Breadcrumbs links={getLinks} />
			<div
				className={` px-4 mx-auto max-w-screen-xl 
				text-center z-10 relative`}>
				<div
					className={`inline-flex justify-between 
					items-center py-1 px-1 pr-4 mb-7 text-sm 
					text-blue-700 bg-blue-100 rounded-full 
					dark:bg-blue-900 dark:text-blue-300 h
					over:bg-blue-200 dark:hover:bg-blue-800
					cursor-pointer`}>
					<span
						className={`text-xs bg-blue-600 
						rounded-full text-white px-4 py-1.5 mr-3`}>
						New
					</span>
					<span className='text-sm font-medium'>
						The world's Best Order Build System Take Yours build
					</span>
					<svg
						className='w-2.5 h-2.5 ml-2'
						aria-hidden='true'
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 6 10'>
						<path
							stroke='currentColor'
							stroke-linecap='round'
							stroke-linejoin='round'
							stroke-width='2'
							d='m1 9 4-4-4-4'
						/>
					</svg>
				</div>
				<h2 className='mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white'>
					<span className='text-blue-600'>Revolution</span> in the assembly of
					orders
				</h2>
				<p className='mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200'>
					Our company develops and implements ultra-fast order assembly systems
					in FMCG and B2B warehouses - grocery, pharmaceutical, online store
					warehouses, wherever it is required to quickly service a large flow of
					customer requests, collect many items in boxes and transfer them for
					delivery.
				</p>
			</div>
			<div className='w-full flex justify-center'>
				<video
					className=' w-2/4 shadow-lg max-w-[1100px]'
					loop
					controls
					poster={PosterImg}>
					<source
						className='w-full h-full'
						src={require('../../videos/CollectSystem.mp4')}
						type='video/mp4'></source>
				</video>
			</div>
		</section>
	)
}

export default observer(AboutNewsPage)
