import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import { ICategories } from '../types/categoryChange'

configure({ enforceActions: 'never' })

class AddСategory {
	categories: ICategories[] = []
	isPageFetch: boolean = false
	popup: boolean = false
	modal: null | ICategories = null
	PagesStructure = {
		page: 1,
		total: 0,
		totalPages: 0
	}
	constructor() {
		makeAutoObservable(this)
	}
}

export default new AddСategory()
