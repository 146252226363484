import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })

class ScreenSettings {
	ScreenSplit = false

	toogleScreenSplit = () => {
		this.ScreenSplit = !this.ScreenSplit
	}
	handleScreenSplit = (param: string) => {
		if (param === 'true') {
			this.ScreenSplit = true
		} else {
			this.ScreenSplit = false
		}
	}
	constructor() {
		makeAutoObservable(this)
	}
}

export default new ScreenSettings()
