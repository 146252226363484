import { $authHost } from '@/API'
import { FetchStages } from '@/types'
import { IWarehouse, IWarehouseView } from '@/types/Warehouse'
import { makeAutoObservable, toJS } from 'mobx'
import { configure } from 'mobx'

configure({
	enforceActions: 'never'
})

class WarehouseStore {
	// Все склады
	allWarehouses: IWarehouseView[] = []
	warehousesList: IWarehouse[] = []
	isWarehouseFetch = FetchStages.waiting
	// Текущий (выбранный) склад
	warehouse: IWarehouseView | null = null

	// функиця получение вьюшки складов
	fetchWarehouseView = async () => {
		try {
			if (this.allWarehouses.length !== 0) return
			if (this.isWarehouseFetch === FetchStages.start) return
			this.isWarehouseFetch = FetchStages.start
			const { data } = await $authHost.get<IWarehouseView[]>('api/warehouse_view')
			if (data) {
				this.allWarehouses = data
			} else {
				console.log('%c Warehouses undefinded', 'color:red')
				return
			}
			this.isWarehouseFetch = FetchStages.end
			return this.allWarehouses
		} catch (e) {
			this.isWarehouseFetch = FetchStages.error
			console.log('%c Error on request api/warehouse_view', 'color:red')
		}
	}
	// функиця получения складов
	fetchWarehouse = async () => {
		try {
			if (this.allWarehouses.length !== 0) return
			if (this.isWarehouseFetch === FetchStages.start) return
			this.isWarehouseFetch = FetchStages.start
			const { data } = await $authHost.get<IWarehouse[]>('api/warehouse/')
			this.isWarehouseFetch = FetchStages.end
			if (data) {
				this.warehousesList = data
				return data
			} else {
				console.log('%c Warehouses undefinded', 'color:red')
				return null
			}
		} catch (e) {
			this.isWarehouseFetch = FetchStages.error
			console.log('%c Error on request api/warehouse', 'color:red')
		}
	}

	// функиця изменения склада
	changeWarehouse = async (warehouse: IWarehouseView) => {
		try {
			let currentRack = this.allWarehouses.find(e => Number(e.id) === Number(warehouse.id))
			if (currentRack) {
				const { data } = await $authHost.put('api/warehouse_view', warehouse)
				currentRack = data
			} else {
				console.log('%c Error on warehouse change (warehouse not found)', 'color:red')
			}
		} catch (e) {
			console.log('%c Error on api/warehouse_view request', 'color:red')
		}
	}

	// функиця фильтрации склада по зоне
	filterWarehouseByZone = (whzone_id: string | number) => {
		console.log(toJS(this.allWarehouses))
		console.log(toJS(whzone_id))
		// выбрать склад по складской зоне
		const currnetWarehouse = this.allWarehouses.find(e => `${e.whzone_id}` === `${whzone_id}`)
		if (currnetWarehouse) {
			this.warehouse = currnetWarehouse
		} else {
			console.log('%c Current warehouse not founded', 'color:red')
		}
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new WarehouseStore()
