import { $authHost, errorHandler } from './index'
import {
	ICreateOrderResponse,
	IProduct,
	IResponceProductList
} from '../types/createorder'
import CreateOrder from '../store/CreateOrder'
import Products from '../store/Products'
import { FetchStages } from '@/types'

export const getProducts = async () => {
	try {
		const { data } = await $authHost.get<IResponceProductList>('api/products/')
		Products.Products.push(...data.products)
		return data.products
	} catch (e) {
		await errorHandler(e, getProducts)
	}
}

export const pushProducts = async (
	page: number = 1,
	categoryId?: number
): Promise<IResponceProductList | undefined> => {
	try {
		if (Products.isFetching === FetchStages.start) return
		Products.isFetching = FetchStages.start
		let response
		if (page !== 1 && categoryId) {
			const { data } = await $authHost.get<IResponceProductList>(
				`api/products/?page=${page}&category=${categoryId}`
			)
			response = data
		} else if (categoryId) {
			const { data } = await $authHost.get<IResponceProductList>(
				`api/products/?category=${categoryId}`
			)
			response = data
		} else {
			const { data } = await $authHost.get<IResponceProductList>(
				`api/products/?page=${page}`
			)
			response = data
		}
		if (!response.products) return
		if (page === 1) {
			CreateOrder.PagesStructure.total = response.total
			CreateOrder.PagesStructure.totalPages = Math.ceil(
				response.total / response.products.length
			)
		}
		Products.Products.push(...response.products)
		CreateOrder.Products.push(...response.products)
		Products.getFiltredByCategory()

		Products.isFetching = FetchStages.end
		return response
	} catch (e) {
		Products.isFetching = FetchStages.error
		await errorHandler(e, () => pushProducts(page, categoryId))
	}
}

export const getRandom10 = async () => {
	try {
		const { data } = await $authHost.get<IProduct[]>(`api/random10/`)
		//@ts-ignore
		data.forEach(e => (e.id = e.product_id))
		Products.basket = [...Products.basket, ...data]
	} catch (e) {
		await errorHandler(e, getRandom10)
	}
}

export const createOrders = async (): Promise<string> => {
	try {
		const products = Products.basket.map(e => {
			return {
				quant: e.basketcount,
				pickstate_id: 0,
				collectedquant: 0,
				sortorder: null,
				product: e.id
			}
		})

		const { data } = await $authHost.post<ICreateOrderResponse>(
			'api/createorder/',
			{
				collected: true,
				fastomat: true,
				products
			}
		)
		return data.orderqr
	} catch (e) {
		console.log('Error on create order request')
		return ''
	}
}
