import st from '../../Fastomat.module.scss'
import { FC } from 'react'
import { Language } from '@/utils/langSwitch'
import { dict } from './TranslationList'

const t = Language.translateWithDictionary(dict)
// =========== Interfaces ===========
interface DeleteProps {
	status: number
	DeleteBtn(): void
}
interface DeleteButtonProps {
	DeleteBtn(): void
}
interface DeleteForeverButtonProps {
	DeleteBtn(): void
}
// =========== Interfaces ===========

const DelBtn: FC<DeleteProps> = ({ status, DeleteBtn }) => {
	if (status !== 10 && status !== 7)
		return <DeleteButton DeleteBtn={DeleteBtn} />
	else return <DeleteForeverButton DeleteBtn={DeleteBtn} />
}
export default DelBtn

const DeleteButton: FC<DeleteButtonProps> = ({ DeleteBtn }) => {
	return (
		<div className={st.addBtnDel} onClick={DeleteBtn}>
			<p>{t('Удалить')}</p>
		</div>
	)
}

const DeleteForeverButton: FC<DeleteForeverButtonProps> = ({ DeleteBtn }) => {
	return (
		<div className={st.addBtnDel} onClick={DeleteBtn}>
			<p>{t('Удалить навсегда')}</p>
		</div>
	)
}
