import { FC, useEffect, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { getProducts } from '@/API/TYPickingAPI'
import TYPicking from '@/store/TYPicking'
import WebSocket from '@/store/WebSocket/WebSocketCollector'
import { Language } from '@/utils/langSwitch'
import { dict } from './TranslationList'

// переводчик
const t = Language.translateWithDictionary(dict)

// add notification styles
if (typeof window !== 'undefined') injectStyle()

const WatchSocket: FC<{ children: ReactNode }> = ({ children }) => {
	// hooks
	const { id, method } = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		WebSocket.firstReSend = false
	}, [])

	// отслеживание наличий id/method
	useEffect(() => {
		if (!method || !id) {
			navigate(`/order/`)
		} else {
			WebSocket.id = Number(id)
			WebSocket.method = Number(method)
			getProducts(Number(id), Number(method))
		}
	}, [id, method])

	// подключение к WS серверу
	useEffect(() => {
		if (TYPicking.Products.length === 0) return
		WebSocket.connect({
			DeviceTypeId: 2,
			DeviceModelId: 5
		})
		// при прогрузке товаров
	}, [TYPicking.Products.length])

	useEffect(() => {
		if (!TYPicking.Product()) return
		if (TYPicking.Product().PickState_id !== 1) {
			// отправлять изменения в WS сервер
			WebSocket.change()
		}
		// при изменении выбранного продукта
	}, [TYPicking.idxSelectedProduct])

	return <>{TYPicking.Products.length && children}</>
}
export default observer(WatchSocket)
