import { makeAutoObservable } from 'mobx'
import { IUser } from './../types/user'
import { IFetchStages } from '../types/logintype'
import { configure } from 'mobx'
import { $authHost } from '../API'
import jwtDecode from 'jwt-decode'
import { updateToken } from '@/API/userAPI'

configure({ enforceActions: 'never' })
class User {
	UserData: IUser | null = null
	rememberMe: boolean = true
	Authorization: boolean = true
	isAuth: boolean = false
	role: number = 1
	// $fixme
	fetchStatus: IFetchStages = 'none'
	loginStatus: IFetchStages = 'none'
	loginedUsers: IUser[] = []
	/*
	PostId = идификатор роли пользователя
	1- Генеральный директор
	2- Коммерческий директор
	3- Программист
	8 - Сборщик заказов
	9 - Кладовщик
	*/

	isOnline(date: string | null): boolean {
		if (!date) return false
		const deltatime = Date.now() - Date.parse(date)
		return deltatime / 60000 < 5
	}

	getCurrentUser = async () => {
		try {
			if (this.UserData) {
				return this.UserData
			}
			const token = localStorage.getItem('refresh_token')
			if (!token) return null
			//@ts-ignore
			const { user_id, role } = jwtDecode(token)
			this.role = role
			if (!user_id) return null
			const { data } = await $authHost.get<IUser>(`api/user/${user_id}`)
			this.UserData = data
			return data
		} catch (e) {
			return null
		}
	}

	getLogUsersList = async () => {
		const users = localStorage.getItem('users')
		if (users && JSON.parse(users)) {
			return JSON.parse(users)
		} else {
			const user = await this.getCurrentUser()
			if (!user) return []
			const thisMoment = new Date()
			if (!user?.last_login) user.last_login = thisMoment.toISOString()
			const data = {
				id: user.id,
				username: user.username,
				email: user.email,
				last_login: user.last_login,
				refresh: localStorage.refresh_token
			}
			localStorage.setItem('users', JSON.stringify([data]))
			return data
		}
	}

	addLogUsersList = async (user?: IUser | null) => {
		if (!user) return
		let users = localStorage.getItem('users')
		const getData = () => {
			const thisMoment = new Date()
			return thisMoment.toISOString()
		}
		const data = {
			id: user.id,
			username: user.username,
			email: user.email,
			last_login: user.last_login || getData(),
			refresh: localStorage.refresh_token
		}
		if (!users) {
			localStorage.setItem('users', JSON.stringify([data]))
			return data
		} else {
			users = JSON.parse(users)
			// $fixme
			// @ts-ignore
			if (users.find(e => e.id === user.id)) return
			// @ts-ignore
			localStorage.setItem('users', JSON.stringify([...users, data]))
			return data
		}
	}
	removeLogUsersList = async (id: number) => {
		let users = localStorage.getItem('users')
		if (!users) return
		users = JSON.parse(users)
		if (!users) return
		// $fixme
		//@ts-ignore
		users = users.filter(user => user.id !== id)
		//@ts-ignore
		this.loginedUsers = users
		localStorage.setItem('users', JSON.stringify(users))
	}

	handleLogin() {
		const token = localStorage.getItem('refresh_token')
		updateToken(token)
	}
	constructor() {
		makeAutoObservable(this)
	}
}

export default new User()
