import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Начать рабочую смену на складе',
		eng: 'Start a work shift at the warehouse'
	},
	{
		ru: 'Зона',
		eng: 'Zone'
	}
]
