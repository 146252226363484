import { FC } from 'react'
import Header from './Subcomponents/Header'
import MainButtons from './Subcomponents/MainButtons'
import OrderList from './Subcomponents/OrderList'
import { observer } from 'mobx-react-lite'
import Modals from './Modals'

const Fastomat: FC = () => {
	return (
		<>
			<Header />
			<MainButtons />
			<OrderList />
			<Modals />
		</>
	)
}

export default observer(Fastomat)
