import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Интеграция 1 - Товары',
		eng: 'Integration 1 - Products'
	},
	{
		ru: 'Выберите файл xml который вы хотите загрузить в систему TakeYours из 1C',
		eng: 'Select the xml file that you want to upload to the TakeYours system from 1C'
	},
	{
		ru: 'Категория',
		eng: 'Category'
	},
	{
		ru: 'Загрузить',
		eng: 'Download'
	},
	{
		ru: 'Интеграция 2 - Складские остатки',
		eng: 'Integration 2 - Inventory balances'
	},
	{
		ru: 'Выберите файл xml который вы хотите загрузить в систему TakeYours из 1C',
		eng: 'Select the xml file that you want to upload to the TakeYours system from 1C'
	},
	{
		ru: 'Интеграция 3 - Заказы',
		eng: 'Integration 3 - Orders'
	},
	{
		ru: 'Выберите файл xml который вы хотите загрузить в систему TakeYours из 1C',
		eng: 'Select the xml file that you want to upload to the TakeYours system from 1C'
	}
]
