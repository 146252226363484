import AboutNewsPage from '@/pages/AboutNewsPage/AboutNewsPage'
import { Language } from '@/utils/langSwitch'
import { dict } from './TranslationList'
import UserProfile from '@/pages/UserProfile/UserProfile'
import AddProductsPage from '@/pages/AddProductsPage/AddProductsPage'
import UsersList from '@/modules/User/components/UsersList'
import WarehousePage from '@/pages/WarehousePage/WarehousePage'
import Integration1C from '@/pages/Integration1C/Integration1C'
import Bitrix24 from '@/pages/Bitrix24/Bitrix24'
import TestingPage from '@/pages/TestingPage/TestingPage'
import ProfileImg from '@/assets/account-avatar-profile-user-13-svgrepo-com.svg'
import ProductListImg from '@/assets/productlist.svg'
import CategoriesListImg from '@/assets/category.svg'
import UserListImg from '@/assets/workers.svg'
import WarehouseImg from '@/assets/warehouse.svg'
import OneCImg from '@/assets/1c logo.png'
import Bitrix24Img from '@/assets/bitrix24.png'
import TestingImg from '@/assets/code.svg'
import SettingsImg from '@/assets/settings.svg'
import HomeImg from '@/assets/homebread.svg'
// Переводчик
const t = Language.translateWithDictionary(dict)

export const InitPages = [
	{
		url: '/',
		name: t('Главная'),
		img: HomeImg,
		component: <AboutNewsPage />
	},
	{
		url: '/profile',
		name: t('Профиль'),
		img: ProfileImg,
		component: <UserProfile />
	},
	{
		url: '/products',
		name: t('Продукты'),
		img: ProductListImg,
		component: <AddProductsPage />
	},
	{
		url: '/category',
		name: t('Категории'),
		img: CategoriesListImg,
		component: <AboutNewsPage />
	},
	{
		url: '/users',
		name: t('Пользователи'),
		img: UserListImg,
		component: <UsersList />
	},
	{
		url: '/warehouse',
		name: t('Склад'),
		img: WarehouseImg,
		component: <WarehousePage />
	},
	{
		url: '/1c-integration',
		name: t('1С Интеграция'),
		img: OneCImg,
		component: <Integration1C />
	},
	{
		url: '/bitrix24',
		name: t('Битрикс 24'),
		img: Bitrix24Img,
		component: <Bitrix24 />
	},
	{
		url: '/testing',
		name: t('Тестирование'),
		img: TestingImg,
		component: <TestingPage />
	},
	{
		url: '/settings',
		name: t('Настройки'),
		img: SettingsImg,
		component: <></>
	}
]
