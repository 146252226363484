import { IDistTranslation } from './langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Создан',
		eng: 'Created'
	},
	{
		ru: 'Готовится',
		eng: 'Preparing'
	},
	{
		ru: 'Выдается',
		eng: 'Issued'
	},
	{
		ru: 'Выдан',
		eng: 'Issued'
	},
	{
		ru: 'Выполнен',
		eng: 'Completed'
	},
	{
		ru: 'Вернули',
		eng: 'Returned'
	},
	{
		ru: 'Удален',
		eng: 'Deleted'
	},
	{
		ru: 'Заказ готов',
		eng: 'The order is ready'
	},
	{
		ru: 'Можно забирать',
		eng: 'You can take it'
	},
	{
		ru: 'Не забрали',
		eng: 'Not taken away'
	},
	{
		ru: 'Редактировать текущй заказ',
		eng: 'Edit the current order'
	},
	{
		ru: 'Создать новый заказ',
		eng: 'Create a new order'
	},
	{
		ru: 'Выйти в меню',
		eng: 'Exit the menu'
	}
]
