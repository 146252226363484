import { $authHost, errorHandler } from "../../../API";
import {
  IGuide,
  IGuideStages,
  IRequestProducts,
  IStageComponent,
} from "../../../types/tycollectguides";
import GuideHandler from "../store/GuideHandler";
import { reformImage } from "../../../utils/imageHandler";
import TYCollectGuides from "../../../store/TYCollectGuides";
import { getAllNeededData } from "../../../API/TYCollectGuides";
import StageHandler from "../store/StageHandler";

export const getGuides2 = async () => {
  try {
    let { data } = await $authHost.get<IGuide[]>("/api/guide");
    data.forEach((guide) => {
      let img = reformImage({ PhotoBytea: guide.GuideImage });
      return (guide.GuideImage = img);
    });
    GuideHandler.Guides = data;
  } catch (e) {
    await errorHandler(e, () => getGuides2());
  }
};

export const getStages = async (id: number) => {
  try {
    if (id === 0) return;
    let { data } = await $authHost.get<IGuideStages[]>(
      `/api/guidestages?guide=${id}`
    );
    data.forEach((stage) => {
      let img = reformImage({ PhotoBytea: stage.StageImage });
      return (stage.StageImage = img);
    });
    GuideHandler.Stages = data;
  } catch (e) {
    await errorHandler(e, () => getStages(id));
  }
};

export const getProduts = async () => {
  try {
    let { data } = await $authHost.get<IStageComponent[]>(
      `/api/stagecomponent`
    );
    GuideHandler.Products = data;
  } catch (e) {
    await errorHandler(e, () => getProduts());
  }
};

export const getSearch = async (name: string) => {
  try {
    let { data } = await $authHost.get<IRequestProducts>(
      `api/products/?name=${name}`
    );
    if (data.products) {
      GuideHandler.Search = data.products;
    }
  } catch (e) {
    await errorHandler(e, () => getSearch(name));
  }
};

export const save = async () => {
  try {
    let form1 = new FormData();
    if (!GuideHandler.SelectedGuide) return;
    if (!(GuideHandler.SelectedGuide.id === 0)) {
      form1.append("id", `${GuideHandler.SelectedGuide.id}`);
    }
    form1.append("GuideName", GuideHandler.SelectedGuide.GuideName);
    form1.append("GuideDescr", GuideHandler.SelectedGuide.GuideDescr);
    if (GuideHandler.SelectedGuide.TargetProduct_id) {
      form1.append(
        "TargetProduct_id",
        `${GuideHandler.SelectedGuide.TargetProduct_id}`
      );
    }
    if (GuideHandler.SelectedGuide.BarCode) {
      form1.append("BarCode", `${GuideHandler.SelectedGuide.BarCode}`);
    }
    // form1.append("Group", `${GuideHandler.SelectedGuide.Group_id}`);
    if (GuideHandler.SelectedGuide.blob) {
      form1.append("GuideImage", GuideHandler.SelectedGuide.blob);
    }
    // form1.append("SortOrder", `${GuideHandler.SelectedGuide.SortOrder}`);
    if (!(GuideHandler.SelectedGuide.id === 0)) {
      await $authHost.put(`api/guide`, form1);
    } else {
      let {data} = await $authHost.post(`api/guide`, form1);
      GuideHandler.SelectedGuide.id = data.id;
    }


    for (let i = 0; i < GuideHandler.Stages.length; i++) {
      let form2 = new FormData();
      if (!(GuideHandler.Stages[i]?.id === 0)) {
        form2.append("id", `${GuideHandler.Stages[i]?.id}`);
      }
      form2.append("Guide", `${GuideHandler.SelectedGuide.id}`);
      form2.append("StageName", GuideHandler.Stages[i]?.StageName);
      form2.append("StageDescr", GuideHandler.Stages[i]?.StageDescr);
      if (GuideHandler.Stages[i].blob) {
        form2.append("StageImage", GuideHandler.Stages[i].blob);
      }
      if (GuideHandler.Stages[i]?.id === 0) {
        let {data} = await $authHost.post(`api/guidestages`, form2);
        GuideHandler.Stages[i].id = data.id
      } else {
        await $authHost.put(`api/guidestages`, form2);
      }
    }


    for (let i = 0; i < GuideHandler.Products.length; i++) {
      if (GuideHandler.Products[i]?.new === true) {
        let form3 = new FormData();
        form3.append("product", `${GuideHandler.Products[i].product_id}`);
        form3.append("stage", `${GuideHandler.Products[i].stage_id}`);
        await $authHost.post(`api/stagecomponent`, form3);
      }
    }

    TYCollectGuides.open = false;
    getAllNeededData();

    StageHandler.stage = 1;
    StageHandler.error = "";
    GuideHandler.SelectedGuide = null;
    GuideHandler.SelectedStage = null;
    GuideHandler.Guides = [];
    GuideHandler.Stages = [];
  } catch (e) {
    await errorHandler(e, () => save());
  }
};

export const delGuide = async (id: number) => {
  try {
    await $authHost.delete<IGuide[]>(`/api/guide/${id}`);
    GuideHandler.Guides = GuideHandler.Guides.filter(
      (elem) => elem.id !== id
    );
  } catch (e) {
    await errorHandler(e, () => delGuide(id));
    StageHandler.error = 'У данной инструкции есть стадии, которые вы должны удалить.';
  }
};

/*

http://dvg.sytes.net:8000/api/stagecomponent

{
	"id": 21,
	"quant": null,
	"SortOrder": null,
	"comment": null,
	"stage": null,
	"product": null,
	"ao": null
}

*/
