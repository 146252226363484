import UsersList from '@/modules/User/components/UsersList'
import ClientsTable from '@/pages/ClientsTable/ClientsTable'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

const UserRouter: FC = () => {
	return (
		<>
			<Routes>
				<Route path='/' element={<UsersList />} />
				<Route path='clients' element={<ClientsTable />} />
			</Routes>
		</>
	)
}

export default observer(UserRouter)
