import { useNavigate } from "react-router-dom";
import TYPicking from "../../store/TYPicking";
import st from "./Products.module.scss";
import { observer } from "mobx-react-lite";
import { Language } from "../../utils/langSwitch";
import { dict } from "./TranslationList";

const t = Language.translateWithDictionary(dict);

const Popup = () => {
  const navigate = useNavigate();
  if (!TYPicking.Popup) return <></>;
  return (
    <div className={st.popupcenter}>
      <p className={st.commmasda}>{t("Сборка заказа завершена")}</p>
      <p className={st.commmasda}>{t("Желаете разместить?")}</p>
      <div className={st.btns}>
        <div
          className={st.allowbtn}
          onClick={() => {
            TYPicking.Popup = false;
            navigate("/fastomatworker");
          }}
        >
          <p>{t("Да")}</p>
        </div>
        <div
          className={st.disallowbtn}
          onClick={() => {
            TYPicking.Popup = false;
          }}
        >
          <p>{t("Нет")}</p>
        </div>
      </div>
    </div>
  );
};

export default observer(Popup);
