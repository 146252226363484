import { getRackModels } from '@/API/wareHouseAPI'
import { IRackModels } from '@/types/Warehouse'
import { observer } from 'mobx-react-lite'
import Warehouse from '@/store/Warehouse'
import { FC } from 'react'
import { useMount } from '@/hooks/useMount'

interface IAsyncSelectCProps {
	options: IRackModels[]
	onChange: (option: string) => void
}

const AsyncSelectC = () => {
	useMount(() => {
		getRackModels()
	})
	const onChange = (option: string) => {
		if (!Warehouse.selectedRack) return
		const model = Warehouse.rackModels.find(e => e.id === Number(option))
		if (!model) return
		Warehouse.selectedRack.Model_id = Number(option)
		Warehouse.selectedRack.ModelName = model.modelname
		Warehouse.selectedRack.ColNumber = model.colnumber
		Warehouse.selectedRack.RowNumber = model.rownumber
		Warehouse.rerender = !Warehouse.rerender
	}

	return <AsyncSelect options={Warehouse.rackModels} onChange={onChange} />
}

const AsyncSelect: FC<IAsyncSelectCProps> = observer(
	({ options, onChange }) => {
		return (
			<select
				value={Warehouse.selectedRack?.Model_id}
				onChange={e => onChange(e.target.value)}>
				{renderOptions(options)}
			</select>
		)
	}
)

const renderOptions = (options: IRackModels[]) =>
	options.map(e => (
		<option key={e.id} value={e.id}>
			{e.modelname}
		</option>
	))

export default observer(AsyncSelectC)
