import { FC } from "react";
import st from './EditGuides.module.scss'
import Body from "./components/Body";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Stage from "./components/StageHandler";

const EditGuides: FC = () => {
  return (
    <div className={st.modalGuide}>
      <div className={st.modalGuideBody}>
        <Header />
        <Stage />
        <Body />
        <Footer />
      </div>
    </div>
  );
};
export default EditGuides;
