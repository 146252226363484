import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import UserDropdownMenu from '../UserDropdownMenu/UserDropdownMenu'
import { NavLink } from 'react-router-dom'
import Theme from '@/store/Theme'
import night from '@/assets/night-clear-svgrepo-com.svg'
import day from '@/assets/sun-svgrepo-com.svg'
import UserImg from '@/assets/19630511.png'
import AdminPanel from '@/store/Settings/AdminPanel/AdminPanel'
import MenuImg from '@/assets/menu.svg'
import Logotype from '../Logotype'

const GantHeader: FC = () => {
	const [dropdown, setDropdown] = useState(false)
	return (
		<header className='w-full h-16 bg-white dark:bg-gray-900 '>
			<div className='flex items-center h-full w-full justify-between'>
				<NavLink to={'/'}>
					<div className='ml-4 font-bold text-3xl cursor-pointer'>
						<Logotype className='h-12' />
					</div>
				</NavLink>

				<div
					className={`gap-11 text-gray-700 
          dark:text-white flex`}>
					<NavLink
						className={({ isActive }) =>
							isActive ? 'text-red-600 underline' : ''
						}
						to={'/gant-collect/commerce/'}>
						<p>Коммерция</p>
					</NavLink>
					<NavLink
						className={({ isActive }) =>
							isActive ? 'text-red-600 underline' : ''
						}
						to={'/gant-collect/production/'}>
						<p>Производство</p>
					</NavLink>
					<NavLink
						className={({ isActive }) =>
							isActive ? 'text-red-600 underline' : ''
						}
						to={'/gant-collect/supply/'}>
						<p>Снабжение</p>
					</NavLink>
					<NavLink
						className={({ isActive }) =>
							isActive ? 'text-red-600 underline' : ''
						}
						to={'/gant-collect/filtration/'}>
						<p>Фасовка</p>
					</NavLink>
					<NavLink
						className={({ isActive }) =>
							isActive ? 'text-red-600 underline' : ''
						}
						to={'settings'}>
						Настройки
					</NavLink>
				</div>

				<div className='relative lg:px-10 px-5'>
					<div className='flex items-center gap-5'>
						<img
							className='h-5 block dark:hidden cursor-pointer'
							src={night}
							onClick={Theme.changeLight}
							alt=''
						/>
						<img
							className={`h-7 hidden 
                dark:block cursor-pointer`}
							src={day}
							onClick={Theme.changeLight}
							alt=''
						/>
						<img
							onClick={() => setDropdown(prev => !prev)}
							id='avatarButton'
							data-dropdown-toggle='userDropdown'
							data-dropdown-placement='bottom-start'
							className={`w-10 h-10 rounded-full border-2 
                border-slate-500 dark:border-white cursor-pointer`}
							src={UserImg}
							alt='User dropdown'
						/>
						<img
							onClick={AdminPanel.toogleMenu}
							src={MenuImg}
							className={`h-8 hidden cursor-pointer`}
							alt='menu'
						/>
					</div>

					<UserDropdownMenu dropdown={dropdown} />
				</div>
			</div>
		</header>
	)
}

export default observer(GantHeader)
