import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Товар найден в базе",
    eng: "Product found in the database",
  },
  {
    ru: "Товар не найден в базе",
    eng: "Product not found in the database",
  },
  {
    ru: "Ошибка при сборе товара",
    eng: "Error when collecting the goods",
  },
  {
    ru: "Товар не найден на складе",
    eng: "The product was not found in stock",
  },
  {
    ru: "Нет нужного количества товара",
    eng: "There is no required quantity of goods",
  },
  {
    ru: "Товар перепутан на складе при размещении на складе",
    eng: "The product is mixed up in the warehouse when placed in the warehouse",
  },
  {
    ru: "нет на складе",
    eng: "out of stock",
  },
  {
    ru: "Собрал компонент",
    eng: "Assembled the component",
  },
  {
    ru: "Завершил этап",
    eng: "Completed the stage",
  },
];
