import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import MapCanvasElement from '@/components/MapCanvasElement/MapCanvasElement'
import WarehousePageSettings from '@/components/WarehousePageSettings/WarehousePageSettings'
import st from './StoreZonesPage.module.scss'
import { useParams } from 'react-router-dom'

const StoreZonesPage: FC = () => {
	const { id } = useParams()
	return (
		<div className={st.wrapper}>
			<div className={st.center}>
				<div className={st.map}>
					<MapCanvasElement zoneId={Number(id)} />
				</div>
				<WarehousePageSettings />
			</div>
		</div>
	)
}

export default observer(StoreZonesPage)
