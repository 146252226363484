import { observer } from 'mobx-react-lite'
import { FC } from 'react'

const AdminPageMain: FC<{ children: JSX.Element | React.ReactNode }> = ({
	children
}) => {
	return <main className='flex w-full min-h-screen'>{children}</main>
}

export default observer(AdminPageMain)
