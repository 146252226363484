import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef } from 'react'
import WarehouseStore from '../../store/Warehouse/Warehouse'
import st from './MapCanvasElement.module.scss'
import Canvas from './objects/Canvas'
import { useMount } from '@/hooks/useMount'
import { IMapCanvasStoreProps } from './MapCanvas.interface'
import Controls from './objects/Controls'

const MapCanvasStore: FC<IMapCanvasStoreProps> = ({
	// Максимальная ширина карты
	maxCanvasWidth = 500,
	// Максимальная высота карты
	maxCanvasHeight = 500,
	// Текущая зона
	zoneId,
	// Можно ли перемещять стеллажи
	production = false
}) => {
	const canvas = useRef<HTMLCanvasElement>(null)
	const context = canvas.current?.getContext('2d')

	// Установка приделов
	Canvas.maxCanvasHeight = maxCanvasHeight
	Canvas.maxCanvasWidth = maxCanvasWidth
	Canvas.production = production

	// Анимация стеллажей
	function animate() {
		Canvas.render()
		requestAnimationFrame(animate)
	}

	useMount(() => {
		// Получаем данные при подключении
		Canvas.fetchData({ zoneId, canvas }).then(() => {
			animate()
		})
	})

	useEffect(() => {
		// Вычитывание соотношения сторон
		Canvas.aspestCalc({
			maxCanvasWidth,
			maxCanvasHeight,
			canvas
		})
	}, [context, WarehouseStore.warehouse])

	useEffect(() => {
		if (!context || !canvas.current) return
		Canvas.canvas = canvas
		Canvas.context = context
	}, [context, canvas])

	return (
		<canvas
			ref={canvas}
			/* Взял объект */
			onMouseDown={Controls.handleMouseDown}
			/* Двигаешь объект */
			onMouseMove={Controls.handleMouseMove}
			/* Отпустил объект */
			onMouseUp={Controls.handleMouseUp}
			className={st.canvas}></canvas>
	)
}

export default observer(MapCanvasStore)
