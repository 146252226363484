import { IClients } from '@/store/Clients/Clients.interface'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import DeleteImg from '@/assets/delete.svg'
import EditImg from '@/assets/edit.svg'
import Clients from '@/store/Clients/Clients'

const TableItem: FC<{ client: IClients }> = ({ client }) => {
	const [edit, setEdit] = useState(false)

	const editHandler = () => {
		if (edit === true) {
			Clients.update(client.id)
		}
		setEdit(prev => !prev)
	}
	const deleteHandler = () => {
		Clients.delete(client.id)
	}

	return (
		<tr className='break-all text-sm my-3'>
			<td className='w-12'>{client.id}</td>
			<td>{client.username}</td>
			<td className='text-xs'>{client.recordchanged}</td>
			<td>
				{edit ? (
					<input
						type='text'
						className='text-black w-full h-full'
						value={`${client.org_name}`}
						onChange={e => (client.org_name = e.target.value)}
					/>
				) : (
					client.org_name
				)}
			</td>
			<td>
				{edit ? (
					<input
						type='text'
						className='text-black w-full h-full'
						value={`${client.org_fullname}`}
						onChange={e => (client.org_fullname = e.target.value)}
					/>
				) : (
					client.org_fullname
				)}
			</td>
			<td className='w-32 text-center'>{client.inn}</td>
			<td className='flex justify-center items-center'>
				<div
					onClick={editHandler}
					className={`bg-blue-500 w-8 h-8 mx-1 flex hover:opacity-75
    justify-center items-center cursor-pointer`}>
					<img className='w-5 h-5' src={EditImg} alt='delete' />
				</div>
				<div
					onClick={deleteHandler}
					className={`bg-red-500 w-8 h-8 mx-1 flex hover:opacity-75
    justify-center items-center cursor-pointer`}>
					<img className='w-5 h-5' src={DeleteImg} alt='delete' />
				</div>
			</td>
		</tr>
	)
}

export default observer(TableItem)
