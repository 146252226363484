import { useMount } from '@/hooks/useMount'
import Clients from '@/store/Clients/Clients'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import TableItem from './TableItem'

const ClientsList: FC = () => {
	useMount(() => {
		Clients.getClients()
	})
	return (
		<>
			{Clients.filtredClients.map(client => (
				<TableItem client={client} />
			))}
		</>
	)
}
export default observer(ClientsList)
