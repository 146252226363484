import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import Products from '../../store/Products'
import { pushProducts } from '../../API/CreateOrderAPI'
import CreateOrder from '../../store/CreateOrder'
import PhotoAgent from '../UI/PhotoAgent'
import Categories from '@/store/Categories/Categories'

interface Props {
	id: number
	name: string | null
	img: string | null
}

const CategoriesItem: FC<Props> = ({ id, name, img }) => {
	useEffect(() => {
		Categories.categoryPhoto(id)
	}, [])
	const Filtred = () => {
		Products.getFiltredByCategory()
		CreateOrder.Products = []
		Products.Products = []
		CreateOrder.PagesStructure = { page: 1, total: 0, totalPages: 0 }
		pushProducts(CreateOrder.PagesStructure.page, id)
		Products.Filtred = id
	}
	return (
		<>
			<div
				className={`flex flex-col text-center 
          items-center cursor-pointer`}
				onClick={Filtred}>
				<PhotoAgent
					src={img}
					className={`min-w-[50px] h-[50px] md:min-w-[125px] 
            md:h-[105px] p-2 bg-slate-100 dark:bg-slate-600 
            rounded-xl`}
					alt='category-item'
				/>
				<div>
					<p
						className={`md:font-extralight text-[7px] font-bold 
            md:text-base dark:text-white mt-1`}>
						{name}
					</p>
				</div>
			</div>
		</>
	)
}

export default observer(CategoriesItem)
