import { IOrder, IProducts } from './../types/TYPicking'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import TYPicking from './TYPicking'
import { postCollect } from '../API/TYPickingAPI'
import { notification } from '../utils/notification'
import { cutMaxTextLength } from '../utils/maxText'
import WebSocketShelving from './WebSocket/WebSocketShelving'

configure({
	enforceActions: 'never'
})

class TYCollectGuides {
	fetch = false
	page: number = 0
	Orders: IOrder[] = []
	Products: IProducts[] = []
	idxSelectedProduct: number = 0
	idxSelectedRack: number = 0
	image: string = ''
	Popup = false
	Warehouse: null | number = null

	Product() {
		return this.Products[this.idxSelectedProduct]
	}

	async collect() {
		if (this.fetch) return
		this.fetch = true
		const response = TYPicking.Product()
		if (!response.positions.length || !response.Product_id || !response.id)
			return
		const id: number = await postCollect({
			rack: response.positions[TYPicking.page].rack,
			product_id: response.Product_id,
			rackcol: response.positions[TYPicking.page].rackcol,
			rackrow: response.positions[TYPicking.page].rackrow,
			orderproducts_id: response.id
		})

		switch (id) {
			case 1:
				TYPicking.Product().PickState_id = id
				notification({
					text: 'Продукт собран'
				})
				break
			case 2:
				notification({
					text: 'Товар не найден на складе',
					type: 'error'
				})
				break
			case 3:
				notification({
					text: 'Не хватает нужного количества для сборки',
					type: 'error'
				})
				break
			case 4:
				notification({
					text: 'Товар перепутан при размещении',
					type: 'error'
				})
				break
			default:
				if (TYPicking.Product().PickState_id === 1) {
					notification({
						text: 'Данный товар уже собран',
						type: 'error'
					})
				} else {
					notification({
						text: 'Неизвестная ошибка',
						type: 'error'
					})
				}
				break
		}
		const idx = TYPicking.Products.findIndex(
			product => product.id === TYPicking.Product().id
		)

		if (TYPicking.Products.every(product => product.PickState_id === 1)) {
			TYPicking.Popup = true
		}
		if (idx !== TYPicking.Products.length - 1) {
			TYPicking.selectproduct(idx + 1)
		}
		this.fetch = false
	}

	async collectAllProducts(device_id: string | number | null) {
		if (!device_id) return
		let positions = {
			device_id: 0,
			rack: 0,
			rackcol: 0,
			rackrow: 0
		}
		const MainProduct = TYPicking.Products.find(
			product => product.positions[0].display_id === device_id
		)
		if (!MainProduct) {
			console.log('%cПродукт не найден', 'color: red')
		} else {
			if (!MainProduct.positions[0].display_id) {
				notification({ text: 'Device not found', type: 'error' })
				console.log('%Device не найден', 'color: red')
				return
			}
			positions = {
				device_id: MainProduct.positions[0].display_id,
				rack: MainProduct.positions[0].rack,
				rackcol: MainProduct.positions[0].rackcol,
				rackrow: MainProduct.positions[0].rackrow
			}
		}
		const displayForEach = TYPicking.Products.filter(product => {
			if (
				product.positions[0].display_id === positions.device_id &&
				product.positions[0].rack === positions.rack &&
				product.positions[0].rackcol === positions.rackcol &&
				product.positions[0].rackrow === positions.rackrow
			)
				return true
			return false
		})
		for (let i = 0; i < displayForEach.length; i++) {
			try {
				const id: number = await postCollect({
					rack: displayForEach[i].positions[TYPicking.page].rack,
					product_id: displayForEach[i].Product_id,
					rackcol: displayForEach[i].positions[TYPicking.page].rackcol,
					rackrow: displayForEach[i].positions[TYPicking.page].rackrow,
					orderproducts_id: displayForEach[i].id
				})
				switch (id) {
					case 1:
						notification({
							text: `Продукт собран ${cutMaxTextLength(
								displayForEach[i].ProductName,
								10
							)}`
						})
						displayForEach[i].PickState_id = 1
						break
					case 2:
						notification({
							text: 'Товар не найден на складе',
							type: 'error'
						})
						break
					case 3:
						notification({
							text: 'Не хватает нужного количества для сборки',
							type: 'error'
						})
						break
					case 4:
						notification({
							text: 'Товар перепутан при размещении',
							type: 'error'
						})
						break
					default:
						if (TYPicking.Product().PickState_id === 1) {
							notification({
								text: 'Данный товар уже собран',
								type: 'error'
							})
						} else {
							notification({
								text: 'Неизвестная ошибка',
								type: 'error'
							})
						}
						break
				}
			} catch (e) {
				notification({
					text: 'Ошибка при сборе продукта',
					type: 'error'
				})
				console.log(
					`%cОшибка при сборе продукта 
          ProductName:${displayForEach[i].ProductName}`,
					'color: red'
				)
			}
		}
		return displayForEach
	}

	async collectAllProductsWebsocket(device_id: string | number | null) {
		if (!device_id) return
		let positions = {
			device_id: 0,
			rack: 0,
			rackcol: 0,
			rackrow: 0
		}
		const MainProduct = WebSocketShelving.products.find(
			product => product.positions[0].display_id === device_id
		)
		if (!MainProduct) {
			console.log('%cПродукт не найден', 'color: red')
		} else {
			if (!MainProduct.positions[0].display_id) {
				notification({ text: 'Device not found', type: 'error' })
				console.log('%Device не найден', 'color: red')
				return
			}
			positions = {
				device_id: MainProduct.positions[0].display_id,
				rack: MainProduct.positions[0].rack,
				rackcol: MainProduct.positions[0].rackcol,
				rackrow: MainProduct.positions[0].rackrow
			}
		}
		console.log('%c meow', 'color: red')
		const displayForEach = WebSocketShelving.products.filter(product => {
			if (
				product.positions[0].display_id === positions.device_id &&
				product.positions[0].rack === positions.rack &&
				product.positions[0].rackcol === positions.rackcol &&
				product.positions[0].rackrow === positions.rackrow
			)
				return true
			return false
		})
		for (let i = 0; i < displayForEach.length; i++) {
			try {
				const id: number = await postCollect({
					rack: displayForEach[i].positions[TYPicking.page].rack,
					product_id: displayForEach[i].Product_id,
					rackcol: displayForEach[i].positions[TYPicking.page].rackcol,
					rackrow: displayForEach[i].positions[TYPicking.page].rackrow,
					orderproducts_id: displayForEach[i].id
				})
				switch (id) {
					case 1:
						notification({
							text: `Продукт собран ${cutMaxTextLength(
								displayForEach[i].ProductName,
								10
							)}`
						})
						displayForEach[i].PickState_id = 1
						break
					case 2:
						notification({
							text: 'Товар не найден на складе',
							type: 'error'
						})
						break
					case 3:
						notification({
							text: 'Не хватает нужного количества для сборки',
							type: 'error'
						})
						break
					case 4:
						notification({
							text: 'Товар перепутан при размещении',
							type: 'error'
						})
						break
					default:
						if (TYPicking.Product().PickState_id === 1) {
							notification({
								text: 'Данный товар уже собран',
								type: 'error'
							})
						} else {
							notification({
								text: 'Неизвестная ошибка',
								type: 'error'
							})
						}
						break
				}
			} catch (e) {
				notification({
					text: 'Ошибка при сборе продукта',
					type: 'error'
				})
				console.log(
					`%cОшибка при сборе продукта 
          ProductName:${displayForEach[i].ProductName}`,
					'color: red'
				)
			}
		}
		return displayForEach
	}

	selectproduct(id: number) {
		this.idxSelectedProduct = id
	}

	constructor() {
		makeAutoObservable(this)
	}
}
export default new TYCollectGuides()
