import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

const TabsAdmSettingsimport: FC = () => {
	return (
		<ul className='flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400'>
			<li className='mr-2'>
				<NavLink
					to='#'
					aria-current='page'
					className='inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'>
					Company
				</NavLink>
			</li>
			<li className='mr-2'>
				<NavLink
					to='#'
					className='inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'>
					Collect
				</NavLink>
			</li>
			<li className='mr-2'>
				<NavLink
					to='#'
					className='inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'>
					Fastomat
				</NavLink>
			</li>
		</ul>
	)
}

export default observer(TabsAdmSettingsimport)
