import { CollectedPayload, IWSConnectProps, WSMessage } from '@/types/WebSocket'
import { notification } from '../../utils/notification'
import TYPicking from '../TYPicking'
import { sleep } from '@/utils/sleep'
import { configure, makeAutoObservable } from 'mobx'
import { IProducts } from '@/types/TYPicking'
import SettingsCollectSystem from '../Settings/SettingsCollectSystem/SettingsCollectSystem'
import { $authHost } from '@/API'

configure({ enforceActions: 'never' })

class WebSocketClient {
	// вебсокет
	websocket: null | WebSocket = null
	// состояние подключения
	connectionState: boolean = false
	id: null | number = 0
	method: null | number = 0
	firstReSend = false
	// id монитора стеллажа
	collector_id: number | null | string = '1'
	// Экран стеллажа подключен
	hasRackDisplay = false
	// Подключение к WS севреру
	connect = ({ DeviceTypeId, DeviceModelId }: IWSConnectProps) => {
		const url = process.env.REACT_APP_WEBSOCKET_URL || ''
		this.websocket = new WebSocket(url)
		this.websocket.onopen = () => this.onOpen({ DeviceTypeId, DeviceModelId })
		this.websocket.onmessage = event => this.onMessage(event)
		this.websocket.onerror = () => this.onError({ DeviceTypeId, DeviceModelId })
		this.websocket.onclose = () => this.onClose({ DeviceTypeId, DeviceModelId })
	}

	// WebSocket Events
	// Открытие WS соединения
	onOpen = ({
		DeviceId = '12',
		DeviceTypeId,
		DeviceModelId
	}: IWSConnectProps) => {
		if (!this.websocket) return
		notification({ text: 'WS соединение открыто' })
		// Соединение
		this.connectionState = true
		this.send({
			action: 'connect',
			payload: {
				device_id: DeviceId,
				device_type: DeviceTypeId,
				device_model: DeviceModelId
			}
		})
		// Отправка инфо на стеллаж
		this.change()
		// Отправка инфо на дисплеи
		this.sendToDisplays(TYPicking.Products)
	}
	// Закрытие WS соединения
	onClose = async (props: IWSConnectProps) => {
		this.connectionState = false
		notification({ text: 'Ошибка в WS соединении', type: 'error' })
		console.log('Socket произошла ошибка')
		// Переподключение через 10 секунд
		await sleep(10000)
		this.connect(props)
	}
	// Ошибка при WS соединении
	onError = async (props: IWSConnectProps) => {
		this.connectionState = false
		notification({ text: 'Ошибка в WS соединении', type: 'error' })
		console.log('Socket произошла ошибка')
		// Переподключение через 10 секунд
		await sleep(10000)
		this.connect(props)
	}
	// Сообщение из WS соединении
	onMessage = (event: MessageEvent<any>) => {
		const message = JSON.parse(event.data)
		const { action, payload }: WSMessage = message
		switch (action) {
			case 'collected':
				this.collected(payload)
				break
			case 'collect_sync':
				console.log(payload)
		}
	}
	// WebSocket Functions
	send = (data: any) => {
		if (!this.websocket || !this.connectionState) return
		this.websocket.send(JSON.stringify(data))
	}

	// collected = async (device_id: string | number) => {
	// const product = TYPicking.Product()
	// if (!product || !product.positions.length) return
	// const rack = product.positions[0]
	// const collectedProducts = await TYPicking.collectAllProducts(device_id)
	// this.send({
	// 	action: 'collect_sync',
	// 	payload: {
	// 		device_id: rack.display_id || '1',
	// 		products: collectedProducts
	// 	}
	// })
	// }

	collected = async ({
		result,
		device_id,
		orderid,
		productid,
		quant,
		tray_quant
	}: CollectedPayload) => {
		const currentProduct = TYPicking.Products.find(
			product => product.id === productid
		)
		if (!currentProduct) {
			notification({ text: 'Товар в заказе не найден!', type: 'error' })
			console.log('%c Current product in order not found', 'color: red')
			return
		}
		switch (result) {
			case 1:
				try {
					if (!tray_quant || !quant) {
						notification({ text: 'не указаны tray_quant quant', type: 'error' })
						throw new Error('не указаны tray_quant quant')
					}
					// Собираем нужное кол-во и всё
					const { data } = await $authHost.post<any>(`api/collected/`, {
						rack: currentProduct.positions[0].rack,
						product_id: productid,
						rackcol: currentProduct.positions[0].rackcol,
						rackrow: currentProduct.positions[0].rackrow,
						orderproducts_id: orderid,
						quant: tray_quant + quant,
						ReduceQuant: SettingsCollectSystem.GetReduceCollect()
					})

					currentProduct.PickState_id = data.pickstate_id
				} catch (e) {
					notification({ text: 'Ошибка при сбороке', type: 'error' })
					console.log('%c Current product in order not found', 'color: red')
				}
				break
			case 0:
				const { data } = await $authHost.post<any>(`api/collected/`, {
					rack: currentProduct.positions[0].rack,
					product_id: productid,
					rackcol: currentProduct.positions[0].rackcol,
					rackrow: currentProduct.positions[0].rackrow,
					orderproducts_id: orderid,
					ReduceQuant: SettingsCollectSystem.GetReduceCollect()
				})

				notification({ text: 'Собрали макс. кол-во', type: 'error' })
				console.log('%c Current product not found in store', 'color: red')
				currentProduct.PickState_id = 2
				break
			case -1:
				// Нет нужного кол-ва на складе
				notification({ text: 'Нет нужного кол-ва на складе', type: 'error' })
				console.log('%c Quant is not exist', 'color: red')
				currentProduct.PickState_id = 3
				break
		}
		const idx = TYPicking.Products.findIndex(
			product => product.id === TYPicking.Product().id
		)

		if (TYPicking.Products.every(product => product.PickState_id === 1)) {
			TYPicking.Popup = true
		}
		if (idx !== TYPicking.Products.length - 1) {
			TYPicking.selectproduct(idx + 1)
		}
	}

	change = () => {
		const product = TYPicking.Product()
		if (!product || !product.positions.length) return
		const rack = product.positions[0]
		this.send({
			action: 'change',
			payload: {
				device_id: rack.display_id || '1',
				productid: product.id,
				productname: product.ProductName,
				tray_quant: product.TrayQuant,
				// Вместимость ящика
				tray_capacity: product.tray_capacity,
				quant: product.Quant,
				rack: rack.rack,
				col: rack.rackcol,
				row: rack.rackrow,
				orderid: this.id,
				method: this.method,
				// Кол-во товаров
				num_all: TYPicking.Products.length,
				// Кол-во собранных товаров
				num_collected: TYPicking.Products.filter(e => e.PickState_id === 1)
					.length
			}
		})
	}

	async sendToDisplays(data: IProducts[]) {
		if (this.firstReSend) return
		this.firstReSend = true
		const arr: IProducts[] = [
			...new Map(
				data.map(item => [item.positions[0].display_id, item])
			).values()
		]

		arr.forEach(element => {
			if (element.PickState_id !== 1) {
				this.send({
					action: 'change',
					payload: {
						device_id: element.positions[0].display_id,
						productid: element.id,
						productname: element.ProductName,
						quant: element.Quant,
						rack: element.positions[0].rack,
						col: element.positions[0].rackcol,
						row: element.positions[0].rackrow,
						orderid: this.id,
						method: 1,
						tray_quant: element.TrayQuant,
						// Вместимость ящика
						tray_capacity: element.tray_capacity,
						// Кол-во товаров
						num_all: TYPicking.Products.length,
						// Кол-во собранных товаров
						num_collected: TYPicking.Products.filter(e => e.PickState_id === 1)
							.length
					}
				})
			}
		})
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new WebSocketClient()
