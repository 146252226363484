import { IEditUserProps, IUsers, IUsersAdd } from "../types/users";
import { $authHost, errorHandler } from "../../../API";
import Users from "../store/Users";

export const getUsers = async () => {
  try {
    let { data } = await $authHost.get<IUsers[]>("api/user/");
    Users.Users = data;
    return data;
  } catch (e) {
    await errorHandler(e, () => getUsers());
  }
};

export const saveUser = async ({ user }: IEditUserProps) => {
  try {
    let { data } = await $authHost.put<IUsers>("api/user/", user);
    return data;
  } catch (e) {
    await errorHandler(e, () => saveUser({ user }));
  }
};

export const addUser = async (user: IUsersAdd) => {
  try {
    let { data } = await $authHost.post<IUsers>("api/user/", user);
    Users.Users.push(data);
    return data;
  } catch (e) {
    await errorHandler(e, () => addUser(user));
  }
};

export const deleteUser = async (id: number) => {
  try {
    let { data } = await $authHost.delete(`api/user/${id}`);
    return data;
  } catch (e) {
    await errorHandler(e, () => deleteUser(id));
  }
};