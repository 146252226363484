import { makeAutoObservable } from "mobx";
import { configure } from "mobx";
import { stages } from "../constanst/stages";
import GuideHandler from "./GuideHandler";

configure({
  enforceActions: "never",
});

class StageHandler {
  stage = 1;
  error: null | string = null;

  nextGuide() {
    if (this.stage < stages.length) {
      switch (this.stage) {
        case 1:
          if (GuideHandler.SelectedGuide) {
            this.stage += 1;
          }
          else{
            this.error = 'Выберите инструкцию или создайте новую'
          }
          break;
        default:
          this.stage += 1;
          break;
      }
    }
  }
  prevGuide() {
    if (this.stage > 1) {
      this.stage -= 1;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}
export default new StageHandler();
