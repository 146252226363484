import st from '../../Fastomat.module.scss'
import { FC } from 'react'
import FastomatWorker from '../../../../store/FastomatWorker'
import { observer } from 'mobx-react-lite'
import { Language } from '../../../../utils/langSwitch'
import { dict } from './TranslationList'

// Переводчик
const t = Language.translateWithDictionary(dict)

const Archive: FC = () => {
	const filterOrders = () => {
		FastomatWorker.filted = !FastomatWorker.filted
	}
	return (
		<div className={st.addBtnErr} onClick={filterOrders}>
			<p>{FastomatWorker.filted ? t('Архив') : t('Все заказы')}</p>
		</div>
	)
}

export default observer(Archive)
