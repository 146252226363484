import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import st from './Tabs.module.scss'
import { Language } from '../../utils/langSwitch'
import { dict } from './TranslationList'
import { useNavigate } from 'react-router-dom'

const t = Language.translateWithDictionary(dict)

const Tabs: FC = () => {
	const navigate = useNavigate()
	return (
		<div className={st.tabs}>
			<input
				type='radio'
				id='radio-1'
				name='tabs'
				onChange={() => navigate('')}
			/>
			<label className={st.tab} htmlFor='radio-1'>
				{t('Стеллажи')}
			</label>
			<input
				type='radio'
				id='radio-2'
				name='tabs'
				onChange={() => navigate('store-zone-settings')}
			/>
			<label className={st.tab} htmlFor='radio-2'>
				{t('Склад')}
			</label>
			<span className={st.glider}></span>
		</div>
	)
}

export default observer(Tabs)
