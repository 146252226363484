import { cutMaxTextLength } from '@/utils/maxText'
import { Language } from '@/utils/langSwitch'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProductNameProps {
	ProductName: string | null
	ProductNameEng: string | null
}

const ProductName: FC<IProductNameProps> = ({
	ProductName,
	ProductNameEng
}) => {
	const text = Language.current === 'ru' ? ProductName : ProductNameEng
	const textLenght = 45
	return <p>{cutMaxTextLength(text, textLenght)}</p>
}

export default observer(ProductName)
