import { observer } from 'mobx-react-lite'

const GantTasksHeader = () => {
	return (
		<div
			className={`w-full py-2 flex text-center
        items-center justify-center pl-4 mb-4 
      hover:bg-gray-100 cursor-pointer`}>
			<p className='w-1/12'>#</p>
			<p className='w-2/12'>Клиент</p>
			<p className='w-3/12'>Продукт</p>
			<p className='w-2/12'>Технология</p>
			<p className='w-2/12'>Создан</p>
			<p className='w-2/12'>Производство</p>
			<p className='w-2/12'>Отгрузка</p>
			<p className='w-1/12'>Приоритет</p>
		</div>
	)
}

export default observer(GantTasksHeader)
