import { observer } from 'mobx-react-lite'
import MapCanvasElement from '../MapCanvasElement/MapCanvasElement'
import st from './ProductInfo.module.scss'
import { FC, useEffect, useState } from 'react'
import { getImage } from '../../API/TYPickingAPI'
import { useMount } from '@/hooks/useMount'
import { $authHost } from '@/API'
import { IPickingTask } from '@/types/TYPicking'
import WarehouseMap from '../WarehouseMap/WarehouseMap'
import Barcode from 'react-barcode'
import Racks from '@/store/Warehouse/Racks'
interface IProductInfoProps {
	needcollect?: number | null
	collected?: number | null
	name?: string | null
	CellName?: string | null
	desc?: string | null
	barcode?: string | null
	Rack?: number | string | null
	Col?: number | string | null
	Row?: number | string | null
	image: number
}

const ProductInfo: FC<IProductInfoProps> = ({
	name,
	desc,
	needcollect,
	collected,
	CellName,
	Col,
	Row,
	barcode,
	image
}) => {
	const [zone, setZone] = useState<null | number>(null)
	useMount(() => {
		const fetch = async () => {
			const { data } = await $authHost.get<IPickingTask[]>('api/pickingtask')
			const whzone = data[data.length - 1].whzone
			if (whzone) {
				setZone(whzone)
			}
		}
		fetch()
	})

	console.log(image)
	return (
		<div>
			<div className={st.head}>
				<NameLocal name={name} desc={desc} />
			</div>
			<div className={st.razdelenie}>
				<div className={st.block}>
					{barcode && <Barcode value={barcode} />}
					<div className={st.statusinfo}>
						<ParagraphLocal str='Нужно собрать' val={needcollect || 0} />
						<ParagraphLocal str='Всего' val={collected} />
					</div>
				</div>
				<div className={st.block}>
					<div className={st.map}>
						<WarehouseMap width={250} height={250} id={7} />
					</div>
					<div className={st.statusinfo}>
						<ParagraphLocal str='' val={CellName} />
						<ParagraphLocal str='Колонка' val={Col} />
						<ParagraphLocal str='Ярус' val={Row} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default observer(ProductInfo)

interface IParagraphLocal {
	str?: string | null
	val?: string | number | null
}

interface INameLocal {
	name?: string | null
	desc?: string | null
}
interface IImageLocal {
	productid: number
}

const ParagraphLocal = ({ str, val }: IParagraphLocal) => {
	return (
		<p>
			{str && str} {val && val}
		</p>
	)
}

const ImageLocal = observer(({ productid }: IImageLocal) => {
	const [img, setImg] = useState('')
	useEffect(() => {
		;(async () => {
			const data = await getImage(productid)
			if (data) {
				setImg(data)
			}
		})()
	}, [productid])
	return (
		<img
			className={st.productimage}
			src={`${img}`}
			alt='product_image / component_image'
		/>
	)
})

const NameLocal = ({ name, desc }: INameLocal) => {
	return (
		<>
			<p>{name && name}</p>
			<p className={st.blockDesc}>{desc && desc}</p>
		</>
	)
}
