import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { getAllNeededData } from '../../API/TYCollectGuides'
import PickOrderPage from './PickOrderPage'
import { useMount } from '@/hooks/useMount'

const PickOrderPageC: FC = () => {
	useMount(() => {
		getAllNeededData()
	})
	return <PickOrderPage />
}

export default observer(PickOrderPageC)
