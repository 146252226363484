import { observer } from 'mobx-react-lite'
import quitImg from '@/assets/exit.svg'
import lightimg from '@/assets/lightsvg.svg'
import { useNavigate } from 'react-router-dom'
import TYPicking from '@/store/TYPicking'
import ProductsPage from '../Products.store'
import { Language } from '@/utils/langSwitch'
import { dict } from '../TranslationList'
// Переводчик
const t = Language.translateWithDictionary(dict)
const Buttons = () => {
	const navigate = useNavigate()
	const goBack = () => navigate(-1)
	return (
		<div className='absolute bottom-8 left-0 justify-center flex w-screen gap-3'>
			<div
				className={`w-52 h-16 flex hover:brightness-90
    items-center justify-center rounded-xl cursor-pointer
		${
			TYPicking.Product().quant_sum === 0
				? 'bg-red-400'
				: TYPicking.Product().CollectedQuant < TYPicking.Product().quant_sum
				? 'bg-green-400'
				: 'bg-orange-400'
		}`}
				onClick={TYPicking.collect}>
				<p className='text-2xl text-white'>{t('Собрал')}</p>
			</div>

			<div
				className={`bg-orange-400 flex items-center 
    justify-center rounded-xl cursor-pointer hover:brightness-90`}
				onClick={ProductsPage.light}>
				<img src={lightimg} className='w-12 mx-2' alt='lighting' />
			</div>

			<div
				className={`bg-purple-400 flex items-center 
    justify-center rounded-xl cursor-pointer hover:brightness-90`}
				onClick={goBack}>
				<img src={quitImg} className='w-12 mx-2' alt='quit' />
			</div>
		</div>
	)
}

export default observer(Buttons)
