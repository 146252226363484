import { FC, memo, useRef, KeyboardEvent } from 'react'

interface IBtnHandlerProps {
	setValue: React.Dispatch<React.SetStateAction<string>>
}

const BtnHandler: FC<IBtnHandlerProps> = memo(({ setValue }) => {
	const input = useRef<any>()
	const enterHandler = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			setValue(input.current.value)
			input.current.value = ''
		}
	}
	return (
		<input
			style={{
				border: 'none',
				height: '0px',
				width: '0px',
				outline: 'none'
			}}
			/*Почему это здесь и зачем? 

			Нам нельзя терять фокус с инпута
			потому что qr коды могут перестать
			читаться, следовательно , нужна была
			функция , которая возращает фокус при
			его потере, но оказывается функция 
			onBlur (ректовский onfocusout), асинхронный
			и не может вернуть фокус сразу
			следовательно пришлось писать такое.
			setTimeout бросает в стек вызовов
			функцию последней и так все работает
			*/
			onBlur={e => {
				setTimeout(() => {
					e?.target?.focus()
				}, 1)
			}}
			autoFocus
			onKeyDown={e => enterHandler(e)}
			ref={input}
		/>
	)
})
export default BtnHandler
