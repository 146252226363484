import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import Userfetch from '@/store/user'
import { IUser } from '@/types/user'
import { NavLink } from 'react-router-dom'
const UserDropdownMenu: FC<{ dropdown: boolean }> = ({ dropdown }) => {
	const [user, setUser] = useState<IUser | null>()
	useEffect(() => {
		;(async () => {
			const data = await Userfetch.getCurrentUser()
			setUser(data)
		})()
	}, [])
	return (
		<div
			id='userDropdown'
			className={`z-50 ${
				dropdown ? 'absolute' : 'hidden'
			} border-[1px] border-gray-400 dark:border-gray-500 
  right-11 top-13 bg-white divide-y divide-gray-100 
  rounded-lg shadow w-44 dark:bg-gray-700 
  dark:divide-gray-600`}>
			<div className='px-4 py-3 text-sm text-gray-900 dark:text-white'>
				<div>{user ? user.username : 'lodaing...'}</div>
				<div className='font-medium truncate'>
					{user ? user.email : 'lodaing...'}
				</div>
			</div>
			<ul
				className='py-2 text-sm text-gray-700 dark:text-gray-200'
				aria-labelledby='avatarButton'>
				<li>
					<NavLink
						to={'/admin/profile'}
						className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
						Profile
					</NavLink>
				</li>
				<li>
					<NavLink
						to={'/admin/'}
						className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
						Dashboard
					</NavLink>
				</li>
				<li>
					<NavLink
						to={'/admin/settings'}
						className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
						Settings
					</NavLink>
				</li>
			</ul>
			<div className='py-1'>
				<NavLink
					to='/'
					className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white'>
					Quit
				</NavLink>
			</div>
		</div>
	)
}

export default observer(UserDropdownMenu)
