import st from '../../Fastomat.module.scss'
import { FC } from 'react'
import QRCode from 'react-qr-code'
import { observer } from 'mobx-react-lite'
import { noPhoto } from '../../../../utils/nophoto'

interface IProps {
	qr: string
	edited: boolean
	ChangeQR(value: string): void
}
interface IQRNowChanges {
	ChangeQR(value: string): void
	qr: string
}
interface IQRStable {
	qr: string
}

const QRChanger: FC<IProps> = ({ qr, edited, ChangeQR }) => {
	if (edited) return <QRNowChanges ChangeQR={ChangeQR} qr={qr} />
	else return <QRStable qr={qr} />
}

export default observer(QRChanger)

const QRNowChanges: FC<IQRNowChanges> = observer(({ ChangeQR, qr }) => {
	return (
		<>
			<div className={st.qr} onClick={() => ChangeQR(qr)}>
				{qr ? (
					<QRCode viewBox={`0 0 256 256`} value={qr} />
				) : (
					<img 
            src={`data:image/jpeg;base64, ${noPhoto}`} 
            alt='nophoto set' />
				)}
			</div>
			<div className={st.wall}></div>
		</>
	)
})

const QRStable: FC<IQRStable> = observer(({ qr }) => {
	return (
		<>
			<div className={st.qr}>
				{qr ? (
					<QRCode viewBox={`0 0 256 256`} value={qr} />
				) : (
					<img 
            src={`data:image/jpeg;base64, ${noPhoto}`} 
            alt='qrcode'
          />
				)}
			</div>
			<div className={st.wall}></div>
		</>
	)
})
