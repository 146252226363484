import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import AsyncSelect from '../AsyncSelect/AsyncSelect'
import st from './RackSettings.module.scss'
import RackListItem from '../RackListItem/RackListItem'
import { Language } from '../../utils/langSwitch'
import { dict } from './TranslationList'
import { IRackNameHandler } from './RackSettings.interface'
import { NavLink } from 'react-router-dom'
import Racks from '@/store/Warehouse/Racks'

// Переводичик
const t = Language.translateWithDictionary(dict)

interface IProps {
	xCoordHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
	yCoordHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
	rotateHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
	rackNameHandler: (props: IRackNameHandler) => void
	deviceHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
	save: () => void
	cancel: () => void
	isFetchSave: boolean
	isFetchCancel: boolean
}

const RackSettings: FC<IProps> = ({
	xCoordHandler,
	yCoordHandler,
	rotateHandler,
	rackNameHandler,
	save,
	cancel,
	isFetchCancel,
	isFetchSave,
	deviceHandler
}) => {
	const TypeErrorHandler = (string?: string | number | null) => {
		if (string) {
			return t(`${string}`)
		} else {
			return ``
		}
	}

	return (
		<div className={st.container}>
			<div className={st.rackInfo}>
				<p>{t('Стеллажи')}</p>
				<NavLink to={'add-rack-to-zone'} className={st.addRack}>
					<p>+</p>
				</NavLink>
			</div>
			<div className={st.racksList}>
				{Racks.filtredRacks.map(e => (
					<RackListItem e={e} key={e.id} />
				))}
			</div>

			<div className={st.parametr}>
				<p>{t('Параметры стеллажа')}: </p>
			</div>
			<div className={st.currentRack}>
				<div className={st.parametr}>
					<p>{t('Название')}</p>
					<input
						type='text'
						value={TypeErrorHandler(Racks.selectedRack?.RackName)}
						onChange={e =>
							rackNameHandler({
								id: Racks.selectedRack?.id,
								rackname: e.target.value
							})
						}
					/>
				</div>

				<div className={st.parametr}>
					<p>
						{t('Поворот')}
						{TypeErrorHandler(Racks.selectedRack && Racks.selectedRack.rotated)}
					</p>
					<input
						type='range'
						min={0}
						max={360}
						step={90}
						value={TypeErrorHandler(Racks.selectedRack?.rotated)}
						onChange={rotateHandler}
					/>
				</div>

				<div className={st.parametr}>
					<p>{t('Модель')}</p>
					<AsyncSelect />
				</div>

				<div className={st.parametr}>
					<p>{t('Координата X')}</p>
					<input
						type='text'
						value={TypeErrorHandler(Racks.selectedRack?.xcoord)}
						onChange={xCoordHandler}
					/>
				</div>

				<div className={st.parametr}>
					<p>{t('Координата Y')}</p>
					<input
						type='text'
						value={TypeErrorHandler(Racks.selectedRack?.ycoord)}
						onChange={yCoordHandler}
					/>
				</div>
				<div className={st.parametr}>
					<p>{t('Device ID')}</p>
					<input type='text' onChange={deviceHandler} />
				</div>
			</div>

			<div className={st.saveButtons}>
				<div className={st.cancel} onClick={cancel}>
					{isFetchCancel ? <div id={st.loading}></div> : <p>{t('Отменить')}</p>}
				</div>
				<div className={st.save} onClick={save}>
					{isFetchSave ? <div id={st.loading}></div> : <p>{t('Сохранить')}</p>}
				</div>
			</div>
		</div>
	)
}

export default observer(RackSettings)
