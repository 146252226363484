import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import Products from '@/store/Products'
import ProductItem from './ProductItem'

const ProductList: FC = () => {
	return (
		<div
			className={`grid grid-cols-2 md:grid-cols-3 
    xl:grid-cols-4 justify-center flex-wrap gap-4 mt-4`}>
			{Products.FiltredProducts.map(product => (
				<ProductItem productItem={product} key={product.id} />
			))}
		</div>
	)
}
export default observer(ProductList)
