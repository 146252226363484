import { $authHost, errorHandler } from '.'

export const Flashrack = async (count: number, rack: number) => {
	try {
		await $authHost.post<any>('api/flashrack/', {
			count,
			rack
		})
	} catch (e) {
		await errorHandler(e, () => Flashrack(count, rack))
	}
}

export const FlashFastomat = async (fastomat: number) => {
	try {
		await $authHost.post<any>('api/flashfastomat', {
			count: fastomat
		})
	} catch (e) {
		await errorHandler(e, () => FlashFastomat(fastomat))
	}
}
