import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Заказ',
		eng: 'Order'
	},
	{
		ru: 'Статус',
		eng: 'Status'
	},
	{
		ru: 'Где забрать',
		eng: 'Where to pick up'
	},
	{
		ru: 'Заказ не найден',
		eng: 'Order not found'
	},
	{
		ru: 'Ваш заказ еще готовится',
		eng: 'Your order is still being prepared'
	},
	{
		ru: 'Ваш заказ уже готов, скоро его разместят, ожидайте',
		eng: 'Your order is ready, it will be placed soon, expect'
	},
	{
		ru: 'Этот заказ уже выдан',
		eng: 'This order has already been issued'
	},
	{
		ru: 'Ошибка',
		eng: 'Mistakee'
	}
]
