import { IRounded } from '@/store/Gant/Gant.interface'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })

class GantSettings {
	minimalDayWidth = 20
	minimalDayHeight = 30

	colorSuccess = '#4ade80'
	colorLater = '#f87171'
	rounded = 50
	dayWidth = 46 /* px */
	dayHeight = 30 /* px */
	mouthHeight = 2 /* px */
	weekends = false

	isDayPadding = false /* px */
	isMouthPadding = false /* px */

	dayPadding = 2 /* px */
	mouthPadding = 2 /* px */

	dayWidthHandler(value: number) {
		this.dayWidth = value
	}

	dayHeightHandler(value: number) {
		this.dayHeight = value
	}

	getRounded({ first, last, only }: IRounded) {
		if (first) {
			return {
				borderTopLeftRadius: `${this.rounded}px`,
				borderBottomLeftRadius: `${this.rounded}px`
			}
		}
		if (last) {
			return {
				borderTopRightRadius: `${this.rounded}px`,
				borderBottomRightRadius: `${this.rounded}px`
			}
		}
		if (only) {
			return { borderRadius: `${this.rounded}px` }
		}
	}

	roundedHandler(angle: number) {
		if (!angle) return
		this.rounded = angle
	}

	colorSuccessHandler(color: string) {
		if (!color) return
		this.colorSuccess = color
	}

	colorLaterHandler(color: string) {
		if (!color) return
		this.colorLater = color
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new GantSettings()
