import Company from '@/store/Company/Company'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
	className?: string
}

const Logotype: FC<IProps> = ({ className }) => {
	if (Company && Company.info?.logo) {
		return (
			<img
				className={`${className && className}`}
				src={Company.info.logo}
				alt='logotype'
			/>
		)
	}
	return <></>
}

export default observer(Logotype)
