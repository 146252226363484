import { FC } from "react";
import { observer } from "mobx-react-lite";
import TYPicking from "../../store/TYPicking";
import OrderItem from "./OrderItem";
import BlueBtn from "../UI/BlueBtn";
import { dict } from "./TranslationList";
import { Language } from "../../utils/langSwitch";

const t = Language.translateWithDictionary(dict);
const OrdersList: FC<{ state: number }> = ({ state }) => {
  return (
    <div>
      {!TYPicking.Orders.filter((e) => e.orderstate_id === state).length && (
        <BlueBtn>{t("Добавить заказы")}</BlueBtn>
      )}
      {TYPicking.Orders.map((item) => {
        if (item.orderstate_id !== state) return <></>;
        return <OrderItem {...item} key={item.id} />;
      })}
    </div>
  );
};
export default observer(OrdersList);
