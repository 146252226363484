import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import FastomatClient from '@/store/Fastomat'
import st from './AdBanner.module.scss'
import QrImg from '@/assets/qr-white.svg'
import TapImg from '@/assets/tap.svg'
import { dict } from './TranslationList'
import { Language } from '@/utils/langSwitch'
import PosterImg from '@/videos/preview.jpg'
import AdBannerSettings from '@/store/Settings/AdBanner/AdBannerSettings'
import { useMount } from '@/hooks/useMount'
import Company from '@/store/Company/Company'

const t = Language.translateWithDictionary(dict)
declare global {
	interface Window {
		timer: any
	}
}
const AdBanner: FC<{ children: JSX.Element }> = ({ children }) => {
	const [activity, setActivity] = useState(false)
	useMount(() => {
		AdBannerSettings.HandleBanner()
	})

	const changeActivity = () => {
		if (window.location.href.indexOf('horizontal-wsclient') !== -1) return
		if (window.location.href.indexOf('collect') !== -1) return
		if (window.location.href.indexOf('orders') !== -1) return
		if (FastomatClient.orders.length) return
		setActivity(false)
	}

	const reset = () => {
		clearTimeout(window.timer)
		setActivity(true)
		window.timer = setTimeout(changeActivity, 60000)
	}

	if (activity || !AdBannerSettings.isBanner) {
		return (
			<div className='clicked' onClick={reset}>
				{children}
			</div>
		)
	}

	return (
		<div className={st.waiting} data-testid='layout_testid' onClick={reset}>
			<p className={`${st.phone_order} ${st.text_middle}`}>
				{t('Сделай заказ со')} <br /> {t('смартфона')}
			</p>
			<img className={st.img_qr} src={QrImg} alt='QR' />
			<div className={`${st.order_here}`}>
				<p className={`${st.text_middle}`}>{t('Или закажи тут')}</p>
				<div data-testid='qrcode_testid'>
					<img className={st.img_tap} src={TapImg} alt='QR' />
				</div>
			</div>
			<div className={st.logo}>
				<p className={st.text_big}>
					{Company.info?.shortname && Company.info.shortname}
				</p>
			</div>
			<video
				className={st.video}
				loop
				controls
				poster={PosterImg}>
				<source
					className='w-full h-full'
					src={require('../../videos/CollectSystem.mp4')}
					type='video/mp4'></source>
			</video>
			<p className={`${st.text_small}`}>{t('Мир без очередей')}</p>
		</div>
	)
}

export default observer(AdBanner)
