/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ProductInfo from '../../components/ProductInfo/ProductInfo'
import StageHandler from '../../components/StageHandler/StageHandler'
import TYCollectGuides from '../../store/TYCollectGuides'
import st from './CollectProducts.module.scss'
import scan from '../../assets/scaan.svg'
import quitImg from '../../assets/quit.svg'
import { ToastContainer } from 'react-toastify'
import lightimg from '../../assets/lightsvg.svg'
import { getProductStoreData, GuideCollect } from '../../API/TYCollectGuides'
import { getLight } from '../../API/TYPickingAPI'
import { NavLink } from 'react-router-dom'
import BtnHandler from '../../components/btnHandler'
import { notification } from '../../utils/notification'
import { dict } from './TranslationList'
import { Language } from '../../utils/langSwitch'

const t = Language.translateWithDictionary(dict)
interface IProps {}

const CollectProducts: FC<IProps> = () => {
	const { id } = useParams()
	const [product, setProducts] = useState<null | number>(null)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [page, setPage] = useState(0)
	/* будущий пагинатор */
	// =========================QRCode Handler===========================
	const [value, setValue] = useState<string>('')
	useEffect(() => {
		console.log(value)
		if (value === '') return
		const finded = async () => {
			let e = TYCollectGuides.Products.filter(e => e.Order === Number(id))
				.filter(e => e.Stage === TYCollectGuides.guideStages[TYCollectGuides.stage].id)
				.findIndex(e => e.barcode === value)
			if (e !== -1) {
				console.log(t('Товар найден в базе'))
				setProducts(e)
				setTimeout(() => {
					collect()
				}, 1200)
				notification({ text: t('Товар найден в базе') })
				notification({ text: value })
				setValue('')
			} else {
				notification({ text: t('Товар не найден в базе'), type: 'error' })
				notification({ text: value, type: 'error' })
				setValue('')
			}
		}
		finded()
	}, [value])
	// ===================================================================

	useEffect(() => {
		;(async () => {
			if (actual()) {
				TYCollectGuides.StoreData = null
				await getProductStoreData(actual()?.Product || 0)
				lighting()
			}
		})()
	}, [product])

	useEffect(() => {
		if (
			TYCollectGuides.Products.filter(e => e.Order === Number(id)).filter(
				e => e.Stage === TYCollectGuides.guideStages[TYCollectGuides.stage].id
			).length > 0
		) {
			setProducts(0)
		} else {
			setProducts(null)
		}
		lighting()
	}, [TYCollectGuides.stage, TYCollectGuides.Products.length])

	const actual = () => {
		if (product || product === 0) {
			if (
				TYCollectGuides.Products.filter(e => e.Order === Number(id)).filter(
					e => e.Stage === TYCollectGuides.guideStages[TYCollectGuides.stage].id
				).length !== 0
			) {
				return TYCollectGuides.Products.filter(e => e.Order === Number(id)).filter(
					e => e.Stage === TYCollectGuides.guideStages[TYCollectGuides.stage].id
				)[product]
			}
		}
	}

	const collect = () => {
		let product_id = actual()?.Product
		let rack: number | null =
			TYCollectGuides.StoreData &&
			// @ts-ignore
			TYCollectGuides.StoreData[page].rack_id
		let rackcol: number | null =
			TYCollectGuides.StoreData &&
			// @ts-ignore
			TYCollectGuides.StoreData[page].rackcol
		let rackrow: number | null =
			TYCollectGuides.StoreData &&
			// @ts-ignore
			TYCollectGuides.StoreData[page].rackrow
		let orderproducts_id = actual()?.id
		if (!product_id) return
		if (!rackcol || !rackrow || !orderproducts_id || !rack) return
		;(async () => {
			let response = await GuideCollect({
				rack,
				product_id,
				rackcol,
				rackrow,
				orderproducts_id
			})
			if (!response) {
				notification({ text: t('Ошибка при сборе товара'), type: 'error' })
				return
			}
			switch (response.PickState_id) {
				case 1:
					let product = actual()
					if (!response.Collected_Quant) return
					if (product) {
						product.Quant = 0
						product.PickState_id = 1
						if (TYCollectGuides.StoreData) {
							TYCollectGuides.StoreData[page].quant_sum -= response.Collected_Quant
						}
					}
					break
				case 2:
					notification({ text: t('Товар не найден на складе'), type: 'error' })
					break
				case 3:
					notification({
						text: t('Нет нужного количества товара'),
						type: 'error'
					})
					break
				case 4:
					notification({
						text: t('Товар перепутан на складе при размещении на складе'),
						type: 'error'
					})
					break
			}

			setProducts(prev => {
				if (prev === null) return null
				if (
					prev <
					TYCollectGuides.Products.filter(e => e.Order === Number(id)).filter(
						e => e.Stage === TYCollectGuides.guideStages[TYCollectGuides.stage].id
					).length -
						1
				) {
					return prev + 1
				} else {
					return prev
				}
			})
		})()
	}

	const lighting = () => {
		let rack_id: number | string =
			(TYCollectGuides.StoreData &&
				// @ts-ignore
				TYCollectGuides.StoreData[page].rack_id) ||
			t('нет на складе')
		let rackcol: number | string =
			(TYCollectGuides.StoreData &&
				// @ts-ignore
				TYCollectGuides.StoreData[page].rackcol) ||
			t('нет на складе')
		let rackrow: number | string =
			(TYCollectGuides.StoreData &&
				// @ts-ignore
				TYCollectGuides.StoreData[page].rackrow) ||
			t('нет на складе')
		getLight({
			rack_id,
			rackcol,
			rackrow
		})
	}

	const NextStageHandler = () => {
		return TYCollectGuides.Products.filter(e => e.Order === Number(id))
			.filter(e => e.Stage === TYCollectGuides.guideStages[TYCollectGuides.stage].id)
			.every(e => e.PickState_id === 1)
	}

	return (
		<>
			<div className={st.container}>
				<div className={st.header}>
					<StageHandler />
				</div>
				<div className={st.postheader}>
					{product || product === 0 ? (
						<ProductInfo
							name={actual()?.productname || ''}
							desc={actual()?.Comment || ''}
							image={actual()?.Product || 0}
							needcollect={actual()?.Quant || 0}
							collected={
								(TYCollectGuides.StoreData && TYCollectGuides.StoreData[page].quant_sum) || 0
							}
							Rack={
								(TYCollectGuides.StoreData && TYCollectGuides.StoreData[page].rack_id) ||
								t('нет на складе')
							}
							Col={
								(TYCollectGuides.StoreData && TYCollectGuides.StoreData[page].rackcol) ||
								t('нет на складе')
							}
							Row={
								(TYCollectGuides.StoreData && TYCollectGuides.StoreData[page].rackrow) ||
								t('нет на складе')
							}
						/>
					) : null}
				</div>

				<div>
					<div className={st.productslist}>
						{TYCollectGuides.Products.filter(e => e.Order === Number(id))
							.filter(e => e.Stage === TYCollectGuides.guideStages[TYCollectGuides.stage].id)
							.map((e, idx) => {
								return (
									<div
										className={`
                    ${st.product} 
                    ${idx === product && st.active}
                    ${e.PickState_id === 1 && st.collectedproduct}
                    `}
										onClick={() => {
											setProducts(idx)
											if (idx === product) {
												lighting()
											}
										}}>
										<p>{e.productname}</p>
										<p>{e.Comment}</p>
										<img
											src={`data:image/svg+xml;base64, ${e.OperationImage}`}
											style={{ width: '25px' }}
											alt=''
										/>
									</div>
								)
							})}
					</div>
				</div>
			</div>
			<div className={st.collected}>
				<div className={st.collectBtn} onClick={() => collect()}>
					<p>{t('Собрал компонент')}</p>
				</div>
				<div
					className={`${!NextStageHandler() ? st.errorBtn : st.collectBtn}`}
					onClick={() => {
						if (!NextStageHandler()) return
						if (TYCollectGuides.guideStages.length > TYCollectGuides.stage + 1) {
							TYCollectGuides.stage = TYCollectGuides.stage + 1
						}
					}}>
					<p>{t('Завершил этап')}</p>
				</div>
				<div className={st.light} onClick={() => lighting()}>
					<img src={lightimg} alt='lighting' />
				</div>

				<div className={st.scan}>
					<img src={scan} alt='scan' />
				</div>
				<NavLink to={'/'}>
					<div className={st.light}>
						<img src={quitImg} alt='quit' />
					</div>
				</NavLink>
			</div>
			<BtnHandler setValue={setValue} />
			<ToastContainer />
		</>
	)
}

export default observer(CollectProducts)
