import { observer } from "mobx-react-lite";
import { FC } from "react";
import st from "../EditGuides.module.scss";
import logoImg from "../../../assets/logo.png";
import TYCollectGuides from "../../../store/TYCollectGuides";

const Header: FC = () => {
  return (
    <div className={st.modalHeader}>
      <div>
        <img src={logoImg} alt="" />
      </div>
      <div className={st.headerClose} onClick={() => {
        TYCollectGuides.open = false
      }}>
        <p>x</p>
      </div>
    </div>
  );
};
export default observer(Header);
