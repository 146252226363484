import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import GuidesCreatePopup from '../../store/GuidesCreatePopup'
import CreateOrderPopup from './CreateOrderPopup/CreateOrderPopup'

const ModalLists: FC = () => {
	return <>{GuidesCreatePopup.isOpen && <CreateOrderPopup />}</>
}

export default observer(ModalLists)
