import DragAndDrop from '@/store/Gant/DragAndDrop'
import { IGantTableCell } from '@/store/Gant/Gant.interface'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import ShipmentMark from './ShipmentMark'
import GantDates from '@/store/Gant/GantDates'
import GantSettings from '@/store/Settings/Gant/Gant'

const TableCell: FC<IGantTableCell> = ({ task, day_id, mouth_id }) => {
	const { shipmentDay, shipmentMouth, createdDay, createdMouth } =
		GantDates.taskDateReform(task)
	const condition =
		shipmentDay > Number(task.ready.slice(0, 2)) &&
		shipmentMouth >= Number(task.ready.slice(3, 5))
	const background = condition
		? { background: GantSettings.colorSuccess }
		: { background: GantSettings.colorLater }

	const only = shipmentDay === createdDay && createdMouth === shipmentMouth

	const last = day_id === shipmentDay && mouth_id === shipmentMouth && !only

	const first = day_id === createdDay && mouth_id === createdMouth && !only

	const style = {
		...GantSettings.getRounded({
			last,
			first,
			only
		}),
		...background,
		width: `${
			GantSettings.dayWidth > GantSettings.minimalDayWidth
				? GantSettings.dayWidth
				: GantSettings.minimalDayWidth
		}px`,
		height: `${
			GantSettings.dayHeight > GantSettings.minimalDayHeight
				? GantSettings.dayHeight
				: GantSettings.minimalDayHeight
		}px`
	}

	return (
		<>
			<th
				draggable={DragAndDrop.draggable}
				onDragStart={event => {
					DragAndDrop.dragStart({ event, task })
				}}
				onDragLeave={event => DragAndDrop.dragLeave({ event, task })}
				onDragEnd={event => DragAndDrop.dragEnd({ event, task })}
				onDragOver={event => DragAndDrop.dragOver({ event, task })}
				onDrop={event => {
					DragAndDrop.emptyAction({
						event,
						day_id,
						mouth_id,
						task
					})
				}}
				style={style}
				className={`${
					GantSettings.isDayPadding ? 'border' : null
				} cursor-pointer 
				relative hover:opacity-50`}>
				<ShipmentMark task={task} day_id={day_id} mouth_id={mouth_id} />
				{first && (
					<StartDragPoint task={task} day_id={day_id} mouth_id={mouth_id} />
				)}
				{last && (
					<EndDragPoint task={task} day_id={day_id} mouth_id={mouth_id} />
				)}
				{only && (
					<OnlyDragPoint task={task} day_id={day_id} mouth_id={mouth_id} />
				)}
			</th>
		</>
	)
}

export default observer(TableCell)

const EndDragPoint: FC<IGantTableCell> = observer(({ task, day_id, mouth_id }) => {
	return (
		<div
			style={{
				width: `${
					GantSettings.dayWidth > GantSettings.minimalDayWidth
						? GantSettings.dayWidth
						: GantSettings.minimalDayWidth
				}px`,
				height: `${
					GantSettings.dayHeight > GantSettings.minimalDayHeight
						? GantSettings.dayHeight
						: GantSettings.minimalDayHeight
				}px`
			}}
			className='w-full flex'>
			<div
				className='w-3 h-full cursor-e-resize absolute right-0 z-10'
				draggable={DragAndDrop.draggable}
				onDragStart={event => {
					DragAndDrop.dragType = 'end'
					DragAndDrop.dragStart({ event, task })
				}}
				onDragLeave={event => DragAndDrop.dragLeave({ event, task })}
				onDragEnd={event => DragAndDrop.dragEnd({ event, task })}
				onDragOver={event => DragAndDrop.dragOver({ event, task })}
				onDrop={event => {
					DragAndDrop.emptyAction({
						event,
						day_id,
						mouth_id,
						task
					})
				}}></div>
		</div>
	)
})

const StartDragPoint: FC<IGantTableCell> = observer(({ task, day_id, mouth_id }) => {
	return (
		<div className='w-full flex'>
			<div
				className='w-1 h-full cursor-e-resize absolute left-0 z-10'
				draggable={DragAndDrop.draggable}
				onDragStart={event => {
					DragAndDrop.dragType = 'start'
					DragAndDrop.dragStart({ event, task })
				}}
				onDragLeave={event => DragAndDrop.dragLeave({ event, task })}
				onDragEnd={event => DragAndDrop.dragEnd({ event, task })}
				onDragOver={event => DragAndDrop.dragOver({ event, task })}
				onDrop={event => {
					DragAndDrop.emptyAction({
						event,
						day_id,
						mouth_id,
						task
					})
				}}></div>
		</div>
	)
})

const OnlyDragPoint: FC<IGantTableCell> = observer(({ task, day_id, mouth_id }) => {
	return (
		<div className='w-full flex justify-between'>
			<div
				className='w-1 h-full cursor-e-resize'
				draggable={DragAndDrop.draggable}
				onDragStart={event => {
					DragAndDrop.dragType = 'start'
					DragAndDrop.dragStart({ event, task })
				}}
				onDragLeave={event => DragAndDrop.dragLeave({ event, task })}
				onDragEnd={event => DragAndDrop.dragEnd({ event, task })}
				onDragOver={event => DragAndDrop.dragOver({ event, task })}
				onDrop={event => {
					DragAndDrop.emptyAction({
						event,
						day_id,
						mouth_id,
						task
					})
				}}></div>
			<div
				className='w-1 h-full  cursor-e-resize'
				draggable={DragAndDrop.draggable}
				onDragStart={event => {
					DragAndDrop.dragType = 'end'
					DragAndDrop.dragStart({ event, task })
				}}
				onDragLeave={event => DragAndDrop.dragLeave({ event, task })}
				onDragEnd={event => DragAndDrop.dragEnd({ event, task })}
				onDragOver={event => DragAndDrop.dragOver({ event, task })}
				onDrop={event => {
					DragAndDrop.emptyAction({
						event,
						day_id,
						mouth_id,
						task
					})
				}}></div>
		</div>
	)
})
