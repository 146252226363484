import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })
class SettingsCollectSystem {
	// Убирает отбавление количества при сборке
	private ReduceCollectQuant = false
	// Getter
	GetReduceCollect = () => this.ReduceCollectQuant
	// Setter
	ToggleReduceCollect = () => {
		this.ReduceCollectQuant = !this.ReduceCollectQuant
		try {
			localStorage
			.setItem('ReduceCollectQuant', `${this.ReduceCollectQuant}`)
		} catch (e) {
			console.log(e)
			console.log('%c Error on localstorage', 'color: red')
		}
	}
	//Hanle on connect
	HandleReduceCollect = () => {
		const defaultValue = this.ReduceCollectQuant
		try {
			const ReduceValue = localStorage.getItem('ReduceCollectQuant')
			if (ReduceValue === null) {
				localStorage.setItem('ReduceCollectQuant', `${defaultValue}`)
			} else {
				this.ReduceCollectQuant = (ReduceValue === 'true')
			}
		} catch (e) {
			console.log(e)
			console.log('%c Error on localstorage', 'color: red')
		}
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new SettingsCollectSystem()
