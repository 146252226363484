import { observer } from 'mobx-react-lite'
import AvatarImg from '@/assets/19630511.png'
import { useEffect, useState } from 'react'
import user from '@/store/user'
import { $authHost } from '@/API'
import PhotoAgent from '@/components/UI/PhotoAgent'

export interface IEmployees {
	empl_id: number
	emp_lastname: string
	emp_name: string
	emp_surname: string
	phone: string
	username: string
	shortname: string
	FullName: string
	JobDescr: string
	depname: string
	depfullname: string
	post_id: number
	Department_ID: number
	Company_id: number
}

const UserProfile = () => {
	const [photo, setPhoto] = useState(null)
	const [empl, setEmpl] = useState<IEmployees | null>(null)
	useEffect(() => {
		;(async () => {
			if (!user.UserData) return
			const employer = await $authHost.get<IEmployees>(
				`api/empl_view/${user.UserData?.id}`
			)
			if (employer) {
				setEmpl(employer.data)
			}
		})()
	}, [user.UserData?.id])
	return (
		<div>
			<div className='container mx-auto font-["Open_Sans"]'>
				<div>
					<p className='font-semibold ml-7 tracking-wide my-3 text-3xl'>
						My Profile
					</p>
				</div>
				<div className='rounded-xl border-2 border-blue-300 w-full'>
					<div className='m-3 my-4 flex items-center w-full'>
						<PhotoAgent className='w-24 h-24 rounded-full mx-6' />
						<div className='flex flex-col'>
							<p>
								{user.UserData?.first_name} {user.UserData?.last_name}
							</p>
							<p>{empl && empl.FullName}</p>
							<p>{empl && empl.depfullname}</p>
						</div>
					</div>
				</div>

				<div className='rounded-xl border-[1px] mt-7 border-blue-300 w-full px-6 pb-12'>
					<div className='m-3 my-4 flex items-center w-full'>
						<p className='font-semibold tracking-wide my-3 text-3xl'>
							Personal Information
						</p>
					</div>
					<div className='grid grid-cols-2 text-xl'>
						<div className='mt-7'>
							<p>First Name</p>
							<p className='font-semibold'>{empl && empl.emp_name}</p>
						</div>
						<div className='mt-7'>
							<p>Last Name</p>
							<p className='font-semibold'>{empl && empl.emp_lastname}</p>
						</div>
						<div className='mt-7'>
							<p>Email</p>
							<p className='font-semibold'>{user.UserData?.email}</p>
						</div>
						<div className='mt-7'>
							<p>Phone</p>
							<p className='font-semibold'>{empl && empl.phone}</p>
						</div>
						<div className='mt-7'>
							<p>Post</p>
							<p className='font-semibold'>{empl && empl.depfullname}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default observer(UserProfile)
