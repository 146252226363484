import { IRackModels, IRacks, IWHZone } from './../types/Warehouse'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import { IWarehouseView } from '../types/Warehouse'

configure({
	enforceActions: 'never'
})

class Warehouse {
	store: IWarehouseView[] = []
	rackModels: IRackModels[] = []
	racks: IRacks[] = []
	rerender: boolean = false
	startDotPicking: boolean = false
	selectedRack: IRacks | null = null
	selectedZone: IWarehouseView | null = null
	tab: number = 1
	WHZone: IWHZone | null = null
	rackFlashing: number | null = null
	isRackFlash: boolean = false
	startX: number | null = null

	getFiltredProducts(id: number) {
		return this.racks.filter(e => e.Zone_id === Number(id))
	}

	getTreeProducts() {
		const resualt: any[] = []
		for (let i = 0; i < this.store.length; i++) {
			const resp = resualt.find(e => e.store_id === this.store[i].wh_id)
			if (resp) continue
			else {
				resualt.push({
					store_id: this.store[i].wh_id,
					zones: [...this.store.filter(e => e.wh_id === this.store[i].wh_id)]
				})
			}
		}
		return resualt
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new Warehouse()
