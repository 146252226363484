import Clients from '@/store/Clients/Clients'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import TabsAdminUser from '@/components/TabsAdminUser/TabsAdminUser'
import ClientsList from './ClientsList'
const getLinks = [
	{
		name: 'Admin',
		url: '/admin'
	},
	{
		name: 'Users',
		url: '/admin/users'
	},
	{
		name: 'Clients',
		url: ''
	}
]

const ClientsTable: FC = () => {
	const [desc, setDesk] = useState(true)
	const sort = () => {
		setDesk(prev => !prev)
		Clients.sort('id', desc ? 'desc' : 'asc')
	}
	return (
		<>
			<Breadcrumbs links={getLinks} />
			<TabsAdminUser />
			<div className='container mx-auto px-4 dark:text-white'>
				<div className='flex gap-3'>
					<Input />
					<button
						onClick={sort}
						className='bg-red-500 rounded-md cursor-pointer w-full py-2 px-4'>
						sort by id {desc ? 'desc' : 'asc'}
					</button>
				</div>
				<table className='w-full mt-4 border-separate border-spacing-y-4'>
					<thead>
						<tr>
							<th>id</th>
							<th>username</th>
							<th>record changed</th>
							<th>Organization name</th>
							<th>Organization fullname</th>
							<th className='w-32'>inn</th>
							<th>action</th>
						</tr>
					</thead>
					<tbody>
						<ClientsList />
					</tbody>
				</table>
			</div>
		</>
	)
}

export default observer(ClientsTable)

const Input = observer(() => {
	const [quary, setQuary] = useState('')
	useEffect(() => {
		console.log(quary)
		Clients.filter(quary)
	}, [quary])
	return (
		<input
			type='text'
			placeholder='Search by org_fullname'
			className='text-black rounded-lg w-full'
			value={quary}
			onChange={e => setQuary(e.target.value)}
		/>
	)
})
