import {
  IGuideStages,
  IProduct,
  IStageComponent,
} from "./../../../types/tycollectguides";
import { IGuide } from "../../../types/tycollectguides";
import { makeAutoObservable } from "mobx";
import { configure } from "mobx";

configure({
  enforceActions: "never",
});

class GuideHandler {
  // список всех гайдов
  Guides: IGuide[] = [];

  // список всех стадий
  Stages: IGuideStages[] = [];

  // выбранная стадия
  SelectedStage: IGuideStages | null = null;

  // выбранный гайд в форме
  SelectedGuide: IGuide | null = null;

  // Отменялось ли действие
  Cancel = false;

  // Продукты стадии
  Products: IStageComponent[] = [];

  //
  Search: IProduct[] = [];
  constructor() {
    makeAutoObservable(this);
  }
}
export default new GuideHandler();
