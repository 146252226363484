import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })

class AdminPanel {
	isMenu = false

	toogleMenu = () => {
		this.isMenu = !this.isMenu
	}
	constructor() {
		makeAutoObservable(this)
	}
}

export default new AdminPanel()
