import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

const TabsAdminUser: FC = () => {
	return (
		<ul className='flex justify-center w-full text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400'>
			<li className='mr-2'>
				<NavLink
					to='/admin/users'
					aria-current='page'
					className='inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'>
					Users
				</NavLink>
			</li>
			<li className='mr-2'>
				<NavLink
					to='/admin/users/clients'
					className='inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'>
					Clients
				</NavLink>
			</li>
		</ul>
	)
}

export default observer(TabsAdminUser)
