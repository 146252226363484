import { $authHost, errorHandler } from '.'
import { ImageProps, reformImage } from '@/utils/imageHandler'
import SettingsCollectSystem from '@/store/Settings/SettingsCollectSystem/SettingsCollectSystem'
import jwtDecode from 'jwt-decode'
import TYPicking from '@/store/TYPicking'
import Warehouse from '@/store/Warehouse'
import {
	IOrder,
	IPostCollectProps,
	IProducts,
	ILightingProps,
	IPickingTask
} from '@/types/TYPicking'
import { noPhoto } from '@/utils/nophoto'
import NotFoundProductsStore from '@/components/Products/NotFoundProducts/NotFoundProducts.store'

export const getOrders = async () => {
	try {
		const { data } = await $authHost.get<IOrder[]>('api/orderpicking')
		TYPicking.Orders = data
		return data
	} catch (e) {
		await errorHandler(e, getOrders)
	}
}

export const getProducts = async (id: number, method: number) => {
	try {
		await $authHost.post<any>(`api/orderproductdist/`, {
			sorttype: method,
			order_id: id
		})
		const { data } = await $authHost.get<IProducts[]>(
			`api/orderproductview/?id=${id}`
		)

		TYPicking.Products = data

		await NotFoundProductsStore.fetchOrderProducts({
			order_id: id
		})
		
		return data
	} catch (e) {
		errorHandler(e, () => getProducts(id, method))
	}
}

export const postCollect = async (props: IPostCollectProps) => {
	try {
		const { data } = await $authHost.post<any>(`api/collected/`, {
			rack: props.rack,
			product_id: props.product_id,
			rackcol: props.rackcol,
			rackrow: props.rackrow,
			orderproducts_id: props.orderproducts_id,
			ReduceQuant: SettingsCollectSystem.GetReduceCollect()
		})
		return data.pickstate_id
	} catch (e) {
		errorHandler(e, () => postCollect(props))
	}
}

export const getLight = async ({
	rack_id,
	rackcol,
	rackrow
}: ILightingProps) => {
	try {
		if (!rack_id || rack_id === 'нет на складе') return
		if (!rackcol || rackcol === 'нет на складе') return
		if (!rackrow || rackrow === 'нет на складе') return
		await $authHost.post<ILightingProps>(`api/lighting/`, {
			cellrow: rackrow,
			cellcol: rackcol,
			lightingscheme: 0,
			rack: rack_id
		})
		Warehouse.rackFlashing = Number(rack_id)
		return 'success'
	} catch (e) {
		console.log('Error on lighting')
	}
}

export const getImage = async (Product_id: number) => {
	try {
		// $fixme auth
		const { data } = await $authHost.get<ImageProps>(
			`api/productpic/${Product_id}`
		)
		const img = reformImage(data)
		TYPicking.image = img
		return img
	} catch (e) {
		errorHandler(e, () => getImage(Product_id))
		return noPhoto
	}
}

export const getWarehouseEmpl = async () => {
	try {
		const main: { user_id: number } = jwtDecode(localStorage.access_token)
		const id = main.user_id
		if (!id) throw new Error('Нет авторизации')
		const { data } = await $authHost.get<IPickingTask[]>(
			`api/pickingtask?empl_id=${id}`
		)
		if (data.length === 0) return 2
		let filtred = data.filter(
			item =>
				item.whzone !== null &&
				item.whzone !== undefined &&
				item.empl !== null &&
				item.empl !== undefined
		)
		filtred = filtred.sort((a, b) => a.id - b.id)
		if (filtred[filtred.length - 1].taskstate === 0 || filtred.length === 0) {
			return null
		}
		return filtred[filtred.length - 1].whzone
	} catch (e) {
		errorHandler(e, getWarehouseEmpl)
	}
}

interface jwtToken {
	user_id: number
}

export const postWarehouseEmpl = async (whzone: number) => {
	try {
		const user: jwtToken = jwtDecode(localStorage.access_token)
		const id = user.user_id
		if (!id) throw new Error('Нет авторизации')
		await $authHost.post<IPickingTask>(`api/pickingtask`, {
			whzone: whzone,
			empl: id,
			taskstate: 1
		})
		TYPicking.Warehouse = whzone
	} catch (e) {}
}
