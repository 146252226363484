import DragAndDrop from '@/store/Gant/DragAndDrop'
import { IGantShipmentMark } from '@/store/Gant/Gant.interface'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

const ShipmentMark: FC<IGantShipmentMark> = ({ task, day_id, mouth_id }) => {
	// Время готовности заказа
	const readyDay = task.ready.slice(0, 2)
	const readyMouth = task.ready.slice(3, 5)
	// Время выгрузки заказа
	const shipmentDay = task.shipment.slice(0, 2)
	const shipmentMouth = task.shipment.slice(3, 5)

	// Имеется ли марка на этой ячейке
	const condition =
		Number(task.ready.slice(0, 2)) === day_id &&
		Number(task.ready.slice(3, 5)) === mouth_id

	// Успевает ли марка до времени
	const inTime = readyDay > shipmentDay && readyMouth >= shipmentMouth

	const title = inTime ? 'Не усепавем собрать' : 'Успеваем собрать'
	const className = inTime ? 'bg-red-500' : 'bg-blue-500'

	if (!condition) {
		return <></>
	}
	return (
		<div
			draggable={DragAndDrop.draggable}
			onDragStart={event => {
				DragAndDrop.dragType = 'ready'
				DragAndDrop.dragStart({ event, task })
			}}
			onDragLeave={event => DragAndDrop.dragLeave({ event, task })}
			onDragEnd={event => DragAndDrop.dragEnd({ event, task })}
			onDragOver={event => DragAndDrop.dragOver({ event, task })}
			onDrop={event => {
				DragAndDrop.emptyAction({
					event,
					day_id,
					mouth_id,
					task
				})
			}}
			title={title}
			className={`${className} absolute top-1/2 
				left-1/2 transform -translate-x-1/2 
				-translate-y-1/2 w-2 h-2 rotate-45`}></div>
	)
}

export default observer(ShipmentMark)
