import { $authHost } from '@/API'
import { makeAutoObservable, toJS } from 'mobx'
import { configure } from 'mobx'
import { ClientSortProperty, IClients } from './Clients.interface'
import { FStages, FetchStages } from '@/types'

configure({ enforceActions: 'never' })

class Clients {
	allClients: IClients[] = []
	filtredClients: IClients[] = []
	isFetch: FStages = FetchStages.waiting

	getClients = async () => {
		try {
			if (this.allClients.length > 0) return
			if (this.isFetch === FetchStages.start) return
			this.isFetch = FetchStages.start
			const { data } = await $authHost.get('api/client/')
			this.allClients = data
			this.filtredClients = data
			this.isFetch = FetchStages.end
		} catch (e) {
			this.isFetch = FetchStages.error
			console.log(e)
		}
	}

	filter(quary: string) {
		if (quary === '' || !quary) {
			this.filtredClients = this.allClients
			return this.allClients
		}
		this.filtredClients = this.allClients.filter(client => {
			if (
				client.org_fullname?.toLocaleLowerCase().includes(quary.toLowerCase())
			) {
				return client
			}
			return false
		})
		return this.filtredClients
	}

	createCompareFn<T extends IClients>(
		property: keyof T,
		sort_order: 'asc' | 'desc'
	) {
		const compareFn = (a: T, b: T) => {
			const val1 = a[property]
			const val2 = b[property]
			const order = sort_order !== 'desc' ? 1 : -1

			switch (typeof val1) {
				case 'number': {
					const valb = val2 as number
					const result = val1 - valb
					return result * order
				}
				case 'string': {
					const valb = val2 as string
					const result = val1.localeCompare(valb)
					return result * order
				}
				default:
					return 0
			}
		}
		return compareFn
	}

	sort(property: ClientSortProperty, type: 'desc' | 'asc') {
		this.allClients = this.allClients.sort(
			this.createCompareFn<IClients>(property, type)
		)
		this.filtredClients = this.filtredClients.sort(
			this.createCompareFn<IClients>('id', type)
		)
		return this.filtredClients
	}

	delete = async (id: number) => {
		await $authHost.delete(`api/client/${id}`)
		this.allClients = this.allClients.filter(e => e.id !== id)
		this.filtredClients = this.filtredClients.filter(e => e.id !== id)
	}

	update = async (id: number) => {
		let client = this.allClients.find(e => e.id === id)
		if (!client) return
		client = toJS(client)
		// @ts-ignore
		delete client.id
		await $authHost.put(`api/client/${id}`, toJS(client))
		console.log('success')
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new Clients()
