import { addRack, getRackModels, getRacks } from '../../API/wareHouseAPI'
import { FC, useState } from 'react'
import { IRackModels, IRacks } from '../../types/Warehouse'
import { useParams } from 'react-router-dom'
import { Language } from '../../utils/langSwitch'
import { observer } from 'mobx-react-lite'
import { dict } from './TranslationList'
import Warehouse from '../../store/Warehouse'
import st from './RackAdd.module.scss'
import { useMount } from '@/hooks/useMount'

// Переводчик
const t = Language.translateWithDictionary(dict)

const RackAdd: FC = () => {
	const [name, setName] = useState<string>('')
	const [rotate, setRotate] = useState<number>(0)
	const [modal, setModal] = useState<number>(1)
	const [x, setX] = useState<string>('')
	const [y, setY] = useState<string>('')
	const { id } = useParams()
	return (
		<div className={st.container}>
			<div className={st.rackInfo}>
				<p>{t('Добавление стеллажа')}</p>
			</div>

			<div className={st.parametr}>
				<p>{t('Параметры стеллажа')}: </p>
			</div>
			<div className={st.currentRack}>
				<div className={st.parametr}>
					<p>{t('Название')}</p>
					<input
						type='text'
						value={name}
						onChange={e => setName(e.target.value)}
					/>
				</div>

				<div className={st.parametr}>
					<p>
						{t('Поворот')} {rotate}
					</p>
					<input
						type='range'
						min={0}
						max={360}
						step={90}
						value={rotate}
						onChange={e => setRotate(Number(e.target.value))}
					/>
				</div>

				<div className={st.parametr}>
					<p>{t('Модель')}</p>
					<AsyncSelectC modal={modal} setModal={setModal} />
				</div>

				<div className={st.parametr}>
					<p>{t('Координата X')}</p>
					<input type='text' value={x} onChange={e => setX(e.target.value)} />
				</div>

				<div className={st.parametr}>
					<p>{t('Координата Y')}</p>
					<input type='text' value={y} onChange={e => setY(e.target.value)} />
				</div>
			</div>

			<div className={st.saveButtons}>
				<div className={st.cancel}>
					<p>{t('Отменить')}</p>
				</div>
				<div
					className={st.save}
					onClick={() => {
						if (!id) return
						const model = Warehouse.rackModels.find(e => e.id === modal)
						if (!model) return
						;(async function () {
							const rackdata = {
								rackname: name,
								colnumber: model.colnumber,
								rownumber: model.rownumber,
								available: true,
								ligthingscheme: 0,
								deviceid: null,
								xcoord: Number(x),
								ycoord: Number(y),
								rotated: rotate,
								model: modal,
								zone: Number(id)
							}
							const response: IRacks | undefined = await addRack(rackdata)
							if (!response) return
							await getRacks()
							Warehouse.tab = 1
						})()
					}}>
					<p>{t('Добавить')}</p>
				</div>
			</div>
		</div>
	)
}

export default observer(RackAdd)

interface IAsyncSelect {
	options: IRackModels[]
	onChange: (option: string) => void
	modal: number
}

interface IAsyncSelectC {
	setModal: React.Dispatch<React.SetStateAction<number>>
	modal: number
}

const AsyncSelectC: FC<IAsyncSelectC> = ({ modal, setModal }) => {
	useMount(() => {
		getRackModels()
	})
	const onChange = (option: string) => {
		const modalName = Warehouse.rackModels.find(
			e => e.id === Number(option)
		)?.modelname
		if (!modalName) return
		setModal(Number(option))
	}

	return (
		<AsyncSelect
			modal={modal}
			options={Warehouse.rackModels}
			onChange={onChange}
		/>
	)
}

const AsyncSelect: FC<IAsyncSelect> = observer(
	({ modal, options, onChange }) => {
		return (
			<select value={modal} onChange={e => onChange(e.target.value)}>
				{renderOptions(options)}
			</select>
		)
	}
)

const renderOptions = (options: IRackModels[]) =>
	options.map(e => (
		<option key={e.id} value={e.id}>
			{e.modelname}
		</option>
	))
