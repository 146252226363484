import { observer } from 'mobx-react-lite'

const GantTasksHeader = () => {
	return (
		<div
			className={`w-full py-2 grid grid-cols-7
        items-center justify-center pl-4  
      hover:bg-gray-100 cursor-pointer`}>
			<p>#</p>
			<p>Клиент</p>
			<p>Менеджер</p>
			<p>Создан</p>
			<p>Производство</p>
			<p>Отгрузка</p>
			<p>Приоритет</p>
		</div>
	)
}

export default observer(GantTasksHeader)
