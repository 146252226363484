import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { IBreadcrumbsProps } from '../Breadcrumbs.interface'
import { observer } from 'mobx-react-lite'
import { ReactComponent as HomeImg } from '@/assets/homebread.svg'

const FirstBreadCrumb: FC<{ link: IBreadcrumbsProps }> = ({ link }) => {
	return (
		<li className='inline-flex items-center justify-center'>
			<NavLink
				to={link.url}
				className={`inline-flex items-center text-sm font-medium 
				text-gray-700 hover:text-blue-600 dark:text-gray-400
				dark:hover:text-white`}>
				<HomeImg className='w-3 h-3 mx-2 mb-1' />
				{link.name}
			</NavLink>
		</li>
	)
}

export default observer(FirstBreadCrumb)
