import Warehouse from '../store/Warehouse'

export function getBase64(file: any) {
	var reader = new FileReader()
	reader.readAsDataURL(file)
	reader.onload = function () {
		if (typeof reader.result === 'string') {
			if (!Warehouse.WHZone) return
			Warehouse.WHZone.background = reader.result
		}
	}
	reader.onerror = function (error) {
		console.log('Error: ', error)
	}
}
