import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "СБОРКА ПО ИНСТРУКЦИИ",
    eng: "ASSEMBLY ACCORDING TO THE INSTRUCTIONS",
  },
  {
    ru: "Этап сборки",
    eng: "Assembly stage",
  },
  {
    ru: "Изображение",
    eng: "Image",
  },
];
