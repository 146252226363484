import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import UserImg from '@/assets/19630511.png'
import { NavLink } from 'react-router-dom'
import Theme from '@/store/Theme'
import night from '@/assets/night-clear-svgrepo-com.svg'
import day from '@/assets/sun-svgrepo-com.svg'
import UserDropdownMenu from '../UserDropdownMenu/UserDropdownMenu'
import MenuImg from '@/assets/menu.svg'
import AdminPanel from '@/store/Settings/AdminPanel/AdminPanel'
import { InitPages } from '@/store/Settings/AdminPanel/InitPages'
import Logotype from '../Logotype'

const AdminHeader: FC = () => {
	const [dropdown, setDropdown] = useState(false)
	return (
		<header className='w-full h-16 bg-white dark:bg-gray-900 '>
			<div className='flex items-center h-full w-full justify-between'>
				<NavLink to={'/'}>
					<div className='ml-4 font-bold text-3xl cursor-pointer'>
						<Logotype className='h-8 w-42' />
					</div>
				</NavLink>

				<div className='hidden gap-11 text-gray-700 dark:text-white xl:flex'>
					{InitPages.map(page => (
						<NavLink to={'/admin' + page.url} key={page.url}>
							{page.name}
						</NavLink>
					))}
				</div>

				<div className='relative lg:px-10 px-5'>
					<div className='flex items-center gap-5'>
						<img
							className='h-5 block dark:hidden cursor-pointer'
							src={night}
							onClick={Theme.changeLight}
							alt=''
						/>
						<img
							className={`h-7 hidden 
                dark:block cursor-pointer`}
							src={day}
							onClick={Theme.changeLight}
							alt=''
						/>
						<img
							onClick={() => setDropdown(prev => !prev)}
							id='avatarButton'
							data-dropdown-toggle='userDropdown'
							data-dropdown-placement='bottom-start'
							className='w-10 h-10 rounded-full border-2 border-slate-500 dark:border-white cursor-pointer'
							src={UserImg}
							alt='User dropdown'
						/>
						<img
							onClick={AdminPanel.toogleMenu}
							src={MenuImg}
							className={`h-8 lg:hidden block cursor-pointer`}
							alt='menu'
						/>
					</div>

					<UserDropdownMenu dropdown={dropdown} />
				</div>
			</div>
		</header>
	)
}

export default observer(AdminHeader)
