import { FC } from 'react'
import { IOrder } from '../../types/TYPicking'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import st from './Orders.module.scss'
import { TYPICKING_ROUTE } from '../../utils/consts'
import { dict } from './TranslationList'
import { Language } from '../../utils/langSwitch'

const t = Language.translateWithDictionary(dict)

const OrderItem: FC<IOrder> = e => {
	return (
		<NavLink to={`${TYPICKING_ROUTE}/${e.id}`} className={st.link} key={e.id}>
			<div className={e.orderstate_id === 0 ? st.progress_Border : st.complate_Border}>
				<div className={`${st.order} dark:bg-slate-800 bg-white dark:text-white text-black`}>
					<div className={st.right}>
						<div className={st.state}></div>
						<div className={st.date}>{e.ordername}</div>
						<div className={st.desc}>{e.orderdescr}</div>
					</div>
					<div className={st.left}>
						<p>{10}</p>
						<p>{t('товаров')}</p>
					</div>
				</div>
			</div>
		</NavLink>
	)
}

export default observer(OrderItem)
