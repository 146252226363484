import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Выберите заказы на сборку',
		eng: 'Select assembly orders'
	},
	{
		ru: 'Создание заказа',
		eng: 'Creating an order'
	},
	{
		ru: 'Инструкция сборки',
		eng: 'Assembly Instructions'
	},
	{
		ru: 'Название заказа',
		eng: 'Order name'
	},
	{
		ru: 'Описание заказа',
		eng: 'Order Description'
	},
	{
		ru: 'Отмена',
		eng: 'Cancel'
	},
	{
		ru: 'Отправить',
		eng: 'Send'
	}
]
