import { IGantDates } from '@/store/Gant/Gant.interface'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import GantDaysLineItem from './GantDaysLineItem'

const GantDaysLineList: FC<{ mouth: IGantDates }> = ({ mouth }) => {
	return (
		<>
			{mouth.days.map(day => (
				<GantDaysLineItem day={day} />
			))}
		</>
	)
}

export default observer(GantDaysLineList)
