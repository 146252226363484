import { observer } from "mobx-react-lite";
import { FC } from "react";
import TYCollectGuides from "../../store/TYCollectGuides";
import StageInfo from "../StageInfo/StageInfo";

const StageHandler: FC = () => {


  const handlePrev = () => {
    if (TYCollectGuides.stage  === 0) return;
    TYCollectGuides.stage = TYCollectGuides.stage  -1
  };

  const handleNext = () => {
    if (TYCollectGuides.guideStages.length > TYCollectGuides.stage + 1) {
      TYCollectGuides.stage = TYCollectGuides.stage +1
    }
  };

  return (
    <StageInfo
      handlePrev={handlePrev}
      handleNext={handleNext}
      GuideName={TYCollectGuides.Guides[0]?.GuideName || "..."}
      StageName={TYCollectGuides.guideStages[TYCollectGuides.stage]?.StageName || "..."}
      StageDescr={TYCollectGuides.guideStages[TYCollectGuides.stage]?.StageDescr || "..."}
      StageImage={TYCollectGuides.guideStages[TYCollectGuides.stage]?.StageImage}
    />
  );
};

export default observer(StageHandler);
