import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { getOrders } from '../../API/TYPickingAPI'
import SelectOrdersCollected from './SelectOrdersCollected'

const SelectOrdersCollectedC: FC = () => {
	useEffect(() => {
		getOrders()
	}, [])
	return <SelectOrdersCollected />
}
export default observer(SelectOrdersCollectedC)
