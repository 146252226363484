/* eslint-disable react-hooks/exhaustive-deps */
import { autorun } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useState, useEffect, useRef } from 'react'
import { updateOrderAPI } from '../../../API/fastomatWorkerAPI'
import FastomatWorker from '../../../store/FastomatWorker'
import { IFastomatAPI } from '../../../types/fastomatWorker'
import { windowHandler } from '../../../utils/windowhandler'
import st from '../Fastomat.module.scss'
import DelBtn from './OrderButtons/DelButton'
import EditBtn from './OrderButtons/EditButton'
import RdyButton from './OrderButtons/RdyButton'
import DescChanger from './OrderInfo/DescChanger'
import QRChanger from './OrderInfo/QRChanger'
import StateChanger from './OrderInfo/StateChanger'
import { useNavigate } from 'react-router-dom'
import { Language } from '../../../utils/langSwitch'
import { dict } from './TranslationList'
import { useMount } from '../../../hooks/useMount'

// Переводчик
const t = Language.translateWithDictionary(dict)

interface Props {
	order: IFastomatAPI
	idx: number
}
const Order: FC<Props> = observer(({ order, idx }) => {
	const navigate = useNavigate()
	const [edited, setEdited] = useState<boolean>(false)
	const [isFetching, setFetching] = useState<boolean>(false)
	const [animdel, setAnimDel] = useState<boolean>(false)
	const [desc, setDesc] = useState<string>('')
	const [qr, setQR] = useState<string>('')
	const [state, setState] = useState<number>(1)
	const textareaData = useRef<any>()

	const setValues = () => {
		setDesc(order.ordertext)
		setQR(order.orderqr || '')
		setState(order.orderstate)
	}

	useMount(() => {
		setValues()
		autorun(() => {
			setValues()
		})
	})

	useEffect(() => {
		setValues()
	}, [order.orderqr, order.orderstate, order.ordertext])

	const ChangeBtn = async () => {
		if (edited) {
			setFetching(true)
			await updateOrderAPI({
				...order,
				orderqr: qr,
				ordertext: textareaData?.current?.value,
				orderstate: state
			})
			setFetching(false)
			FastomatWorker.orders[idx].orderqr = qr
			FastomatWorker.orders[idx].ordertext = desc
			FastomatWorker.orders[idx].orderstate = state
			setDesc(textareaData?.current?.value)
		}
		setEdited(prev => !prev)
	}

	const DeleteBtn = async () => {
		setFetching(true)
		await FastomatWorker.DeleteOrder(order)
		setAnimDel(true)
		if (order.orderstate !== 10) {
			setTimeout(() => {
				order.orderstate = 10
				setAnimDel(false)
			}, 1000)
		} else {
			setTimeout(() => {
				FastomatWorker.orders = 
        FastomatWorker.orders.filter(e => e !== order)
				setAnimDel(false)
			}, 1000)
		}
		setFetching(false)
	}

	const ChangeState = (value: number) => {
		setState(value)
	}

	const RdyBtnChange = async (order: IFastomatAPI) => {
		switch (order.orderstate) {
			case 2:
				setFetching(true)
				await updateOrderAPI({
					...order,
					orderqr: qr,
					ordertext: textareaData?.current?.value,
					orderstate: 3
				})
				setFetching(false)
				order.orderstate = 3
				break
			case 3:
				setFetching(true)
				await updateOrderAPI({
					...order,
					orderqr: qr,
					ordertext: textareaData?.current?.value,
					orderstate: 4
				})
				setFetching(false)
				order.orderstate = 4
				break
			default:
				console.log('return')
				break
		}
	}

	const ChangeQR = (value: string) => {
		FastomatWorker.isQrEdit = true
		FastomatWorker.EditQr = order
		setQR(value)
	}

	return (
		<>
			<div
				className={`
      ${st.item}
      ${isFetching && st.grayback}
      ${animdel && st.animdel}
      `}
				key={order.id}>
				<div className={`${isFetching && st.ldsDualRing}`}></div>
				<div className={st.headItem}>
					<p>{order.ordername}</p>
					<p>{windowHandler(order.windows)}</p>
				</div>
				{/*"=============="*/}
				<div className={st.infoItem}>
					<QRChanger qr={qr} ChangeQR={ChangeQR} edited={edited} />
					<DescChanger
						edited={edited}
						desc={desc}
						textareaData={textareaData}
					/>
					<StateChanger
						edited={edited}
						state={state}
						ChangeState={ChangeState}
						order={order}
					/>
				</div>
				{/*"=============="*/}
				<div className={st.btnsItem}>
					<div>
						<EditBtn 
              edited={edited} 
              ChangeBtn={ChangeBtn} />
						<DelBtn 
              status={order.orderstate} 
              DeleteBtn={DeleteBtn} />
						<RdyButton 
              order={order} 
              RdyBtnChange={RdyBtnChange} />
					</div>
					<div>
						<div
							className={st.gotocollecting}
							onClick={() => navigate('/orders')}>
							<p>{t('Собрать')}</p>
						</div>
					</div>
				</div>
				{/*"=============="*/}
			</div>
		</>
	)
})

export default Order
