import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC } from 'react'

interface IRangeSliderProps {
	value?: number
	max?: number
	onChange?: (value: number) => void
}

const RangeSlider: FC<IRangeSliderProps> = ({
	value,
	max = 100,
	onChange
}) => {
	const MiddleWareChanger = (
		e: ChangeEvent<HTMLInputElement>
	) => {
		const newNumber = Number(e.target.value)
		if (newNumber && onChange) {
			onChange(newNumber)
		}
	}

	return (
		<input
			type='range'
			value={value}
			onChange={MiddleWareChanger}
			max={max}
			min={0}
			className={`w-full h-2 bg-gray-200 
        rounded-lg appearance-none 
        cursor-pointer dark:bg-gray-700`}
		/>
	)
}

export default observer(RangeSlider)
