import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import NotFoundProductsStore from './NotFoundProducts.store'
import NotFoundProducts from './NotFoundProducts'

const NotFoundProductsList: FC = () => {
	return (
		<div className=''>
			<div className='text-center text-red-400 text-xl mt-10'>
				Продукты, которых на складе:
			</div>
			<div>
				{NotFoundProductsStore.productList.map(e => (
					<NotFoundProducts product_id={e.product} />
				))}
			</div>
		</div>
	)
}

export default observer(NotFoundProductsList)
