import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Запустить мигание фастомата',
		eng: 'Start fastomat flashing'
	},
	{
		ru: 'Запустить мигание стеллажа',
		eng: 'Start flashing the rack'
	},
	{
		ru: 'раз',
		eng: 'once'
	},
	{
		ru: 'Запустить',
		eng: 'To run'
	},
	{
		ru: 'Ошибка: Стеллажи не заданы',
		eng: 'Error: Racks are not specified'
	},
	{
		ru: 'Вычитать остатки при сборке',
		eng: 'Do subtract the residues during assembly'
	},
	{
		ru: 'Название компании',
		eng: 'Company name'
	}
]
