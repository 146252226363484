import { FC } from "react";
import NavHeaderC from "../../components/Navigation/NavHeaderC";
import st from "./Employees.module.scss";
import UserInit from '../../modules/User/index'

const Employees: FC = () => {
  return (
    <>
      <div className={st.container}>
        <UserInit />
      </div>
    </>
  );
};
export default Employees;
