import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef, useState } from 'react'
import GuidesCreatePopup from '../../../store/GuidesCreatePopup'
import st from '../PickOrderPage.module.scss'
import logoImg from '../../../assets/logo.png'
import AsyncSelect from './AsyncSelectGuides'
import TYCollectGuides from '../../../store/TYCollectGuides'
import { addGuideOrder } from '../../../API/TYCollectGuides'
import { dict } from '../TranslationList'
import { Language } from '../../../utils/langSwitch'

// Переводчик
const t = Language.translateWithDictionary(dict)
const CreateOrderPopup: FC = () => {
	const closeButton = useRef(null)
	const closeButton2 = useRef(null)
	const [name, setName] = useState('')
	const [desc, setDesc] = useState('')
	const [guide, setGuide] = useState(0)
	useEffect(() => {
		const obj = TYCollectGuides.Guides.find(e => e.id === guide)
		if (!obj) return
		setName(obj.GuideName)
		setDesc(obj.GuideDescr)
	}, [guide])
	useEffect(() => {}, [])
	const close = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (
      e.target === closeButton.current 
      || e.target === closeButton2.current
    ) {
			GuidesCreatePopup.isOpen = !GuidesCreatePopup.isOpen
		}
	}
	const changeGuide = (id: number) => {
		setGuide(id)
	}

	const sendData = () => {
		const obj = TYCollectGuides.Guides.find(e => e.id === guide)
		if (!obj) return
		addGuideOrder({ name, desc, guide: obj })
	}

	const gotoWebsite = () => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Вы уверены, что хотите перейти на сайт TakeYours?')) {
			window.open('https://www.take-yours.online/')
		}
	}

	const cancel = () => {
		const obj = TYCollectGuides.Guides.find(e => e.id === guide)
		if (!obj) return
		setName(obj.GuideName)
		setDesc(obj.GuideDescr)
	}

	return (
		<div ref={closeButton} className={st.popup} onClick={e => close(e)}>
			<div className={st.popupContent}>
				<div className={st.popupHeader}>
					<img 
            src={logoImg} 
            onClick={() => gotoWebsite()} 
            alt='logotype' />
					<div className={st.content}>{t('Создание заказа')}</div>
					<div className={st.close} ref={closeButton2}>
						<p>X</p>
					</div>
				</div>
				<div className={st.popupBody}>
					<div>
						<p>{t('Инструкция сборки')}</p>
						<AsyncSelect guide={guide} changeGuide={changeGuide} />
					</div>
					<div>
						<p>{t('Название заказа')}</p>
						<input
							type='text'
							value={name}
							onChange={e => setName(e.target.value)}
						/>
					</div>
					<div>
						<p>{t('Описание заказа')}</p>
						<input
							type='text'
							value={desc}
							onChange={e => setDesc(e.target.value)}
						/>
					</div>
				</div>
				<div className={st.popupFooter}>
					<button className={st.popupButton} onClick={() => cancel()}>
						{t('Отмена')}
					</button>
					<button 
            className={st.popupButton} 
            onClick={() => sendData()}>
						{t('Отправить')}
					</button>
				</div>
			</div>
		</div>
	)
}

export default observer(CreateOrderPopup)
