import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Параметры зоны",
    eng: "Zone Parameters",
  },
  {
    ru: "Имя",
    eng: "Name",
  },
  {
    ru: "Описание",
    eng: "Description",
  },
  {
    ru: "Ширина",
    eng: "Width",
  },
  {
    ru: "Высота",
    eng: "Height",
  },
  {
    ru: "Выбор начальной точки сборки",
    eng: "Selecting the starting point of the assembly",
  },
  {
    ru: "Y",
    eng: "Y",
  },
  {
    ru: "Задний фон",
    eng: "Background",
  },
  {
    ru: "Рекомендуем загружать изображения соответствующее разрешению склада",
    eng: "We recommend uploading images corresponding to the warehouse resolution",
  },
];