import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Изменить продукт",
    eng: "Change the product",
  },
  {
    ru: "Вы уверены, что хотите удалить эту продукт?",
    eng: "Are you sure you want to remove this product?",
  },
  {
    ru: "Вы уверены, что хотите перейти на сайт TakeYours?",
    eng: "Are you sure you want to go to the Take Your website?",
  },
  {
    ru: "Имя",
    eng: "Name",
  },
  {
    ru: "Описание",
    eng: "Description",
  },
  {
    ru: "Ширина",
    eng: "Width",
  },
  {
    ru: "Высота",
    eng: "Height",
  },
  {
    ru: "Глубина",
    eng: "Depth",
  },
  {
    ru: "Только сверху",
    eng: "Only from above",
  },
  {
    ru: "Вес",
    eng: "Weight",
  },
  {
    ru: "Цена",
    eng: "Price",
  },
  {
    ru: "Штрихкод",
    eng: "Barcode",
  },
  {
    ru: "Категория",
    eng: "Category",
  },
  {
    ru: "Фотография",
    eng: "Photo",
  },
  {
    ru: "Удалить",
    eng: "Remove",
  },
  {
    ru: "Сохранить",
    eng: "Save",
  },
  {
    ru: "Без категории",
    eng: "Without category",
  },
  {
    ru: "Добавить продукт",
    eng: "Add a product",
  },
  {
    ru: "Товар найден в базе" ,
    eng: "The product was found in the database",
  },
  {
    ru: "Товар не найден в базе",
    eng: "The product was not found in the database",
  },
];

