import { FC, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import {
	collectManRoutes,
	fastomatRoutes,
	publicRoutes,
	pullManRoutes
} from '../Routes'
import User from '../store/user'
import { IRoutes } from '../types/routes'
import { observer } from 'mobx-react-lite'
import user from '../store/user'
import { useMount } from '../hooks/useMount'

// $fixme
const AppRouter: FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		if (!user.Authorization) {
			navigate('/login')
		}
	}, [user.Authorization, window.location.pathname])

	useMount(() => {
		if (!localStorage.access_token && !localStorage.refresh_token) {
			navigate('/selectcategory')
		}
	})

	return (
		<Routes>
			{/* {User.UserInfo.postId === 9 && */}
			{/* {collectManRoutes.map(({ path, Сomponent }: IRoutes): React.ReactNode => {
				return <Route key={path} path={path} element={<Сomponent />} />
			})} */}
			{/* {User.UserInfo.postId === 8 &&
				pullManRoutes.map(({ path, Сomponent }: IRoutes): React.ReactNode => {
					return <Route key={path} path={path} element={<Сomponent />} />
				})}
			{User.UserInfo.postId === 3 &&
				fastomatRoutes.map(({ path, Сomponent }: IRoutes): React.ReactNode => {
					return <Route key={path} path={path} element={<Сomponent />} />
				})}
			{User.UserInfo.postId === 4 &&
				fastomatRoutes.map(({ path, Сomponent }: IRoutes): React.ReactNode => {
					return <Route key={path} path={path} element={<Сomponent />} />
				})}*/}
			{publicRoutes.map(({ path, Сomponent }: IRoutes): React.ReactNode => {
				return <Route key={path} path={path} element={<Сomponent />} />
			})}
		</Routes>
	)
}
export default observer(AppRouter)
