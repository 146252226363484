import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import Categories from '../../store/Categories/Categories'
import Loader from '../Loader/Loader'
import { FetchStages } from '../../types'
import { useMount } from '../../hooks/useMount'
import CategoriesList from './CategoriesList'

const CategoriesComponent: FC = () => {
	useMount(() => {
		Categories.getCategories()
	})
	return (
		<div
			className={`container mx-auto pt-4 mt-1 flex 
      overflow-y-auto no-scrollbar gap-8 pl-2`}>
			<Loader loading={Categories.FetchStage === FetchStages.start}>
				<CategoriesList />
			</Loader>
		</div>
	)
}

export default observer(CategoriesComponent)
