import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import GantTasksHeader from './GantTasksHeader'
import GantTasksList from './GantTasksList'

const TableListTasks: FC = () => {
	return (
		<div className='w-2/6'>
			<GantTasksHeader />
			<GantTasksList />
		</div>
	)
}

export default observer(TableListTasks)
