import GantDates from '@/store/GantProduction/GantDates'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import arrowImg from '@/assets/arrow.svg'

const GantPagination: FC = () => {
	const [mouth, setMouth] = useState(new Date().getMonth())
	const [year, setYear] = useState(new Date().getFullYear())
	const onPlus = () => {
		if (mouth === 12) {
			setMouth(1)
			setYear(year + 1)
		} else {
			setMouth(prev => prev + 1)
		}

		GantDates.getInitData({
			currentMonth: mouth,
			currentYear: year
		})
	}
	const onMinus = () => {
		if (mouth === 1) {
			setMouth(12)
			setYear(prev => prev - 1)
		} else {
			setMouth(prev => prev - 1)
		}

		GantDates.getInitData({
			currentMonth: mouth,
			currentYear: year
		})
	}
	return (
		<div className='flex gap-5 mt-5 justify-center'>
			<div
				className='w-8 h-8 flex justify-center items-center bg-gray-300 rounded-lg text-white cursor-pointer'
				onClick={() => onMinus()}>
				<img src={arrowImg} className='w-4 h-4 rotate-[-90deg]' alt='arrow' />
			</div>
			<div className='text-xl mt-1'>Месяц</div>
			<div
				className='w-8 h-8 flex justify-center items-center bg-gray-300 rounded-lg text-white cursor-pointer'
				onClick={() => onPlus()}>
				<img src={arrowImg} className='w-4 h-4 rotate-[90deg]' alt='arrow' />
			</div>
		</div>
	)
}

export default observer(GantPagination)
