import { FC } from 'react'
import st from '../PickOrderPage.module.scss'
import { NavLink, useParams } from 'react-router-dom'
import { IAssemblingOrders } from '../../../types/tycollectguides'
import { observer } from 'mobx-react-lite'
import TYCollectGuides from '../../../store/TYCollectGuides'

const OrderPart: FC = () => {
	const { id } = useParams()
	return (
		<div className={st.wrapplist}>
			{TYCollectGuides.Orders.map(e => {
				if (!e?.Guide_id || !id) return <></>
				return <OrderItem {...e} key={e.id} />
			})}
		</div>
	)
}
export default observer(OrderPart)

const OrderItem: FC<IAssemblingOrders> = e => {
	const { id } = useParams()
	return (
		<div className={st.border}>
			<NavLink
				key={e.id}
				to={`/assemblingorder/${e.id}/guide/${Number(id)}`}
				className={st.link}>
				<div className={st.order}>
					<div className={st.name}>{e.OrderName}</div>
					<div className={st.desc}>{e.OrderDescr}</div>
				</div>
			</NavLink>
		</div>
	)
}
