import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import CreateOrderImg from '@/assets/create-order.svg'
import ProductionImg from '@/assets/production.svg'
import FiltrationImg from '@/assets/packing.svg'
import SupplyImg from '@/assets/logistik.svg'
import EcommerceImg from '@/assets/ecommerce.svg'
import QuitImg from '@/assets/exit.svg'
import AdminImg from '@/assets/admin-img.svg'
import WSRackImg from '@/assets/warehouse-rack.svg'
import User from '@/store/user'

configure({
	enforceActions: 'never'
})

export interface IMainPageRouteItem {
	id: number
	role: boolean
	name: string
	onClick: () => void
	img: string
	url: string
}

class MainItems {
	logout = () => {
		localStorage.access_token = ''
		localStorage.refresh_token = ''
		User.isAuth = false
	}
	pages = [
		{
			id: 1,
			role: true,
			name: 'Создание заказа',
			onClick: () => {},
			img: CreateOrderImg,
			url: '/selectcategory'
		},
		{
			id: 2,
			role: User.role === 1,
			name: 'Администратор',
			onClick: () => {},
			img: AdminImg,
			url: '/admin'
		},
		{
			id: 3,
			role: User.role > 0,
			name: 'Коммерция',
			img: EcommerceImg,
			onClick: () => {},
			url: '/gant-collect/commerce/collect-order'
		},
		{
			id: 4,
			role: User.role > 0,
			name: 'Производство',
			img: ProductionImg,
			onClick: () => {},
			url: '/gant-collect/production/gant'
		},
		{
			id: 5,
			role: User.role > 0,
			name: 'Снабжение',
			img: SupplyImg,
			onClick: () => {},
			url: '/gant-collect/supply/gant'
		},
		{
			id: 6,
			role: User.role > 0,
			name: 'Фасовка',
			onClick: () => {},
			img: FiltrationImg,
			url: '/gant-collect/filtration/gant'
		},
		{
			id: 7,
			role: User.role !== 0,
			name: 'WebSocket стеллаж',
			onClick: () => {},
			img: WSRackImg,
			url: '/horizontal-wsclient/'
		},
		{
			id: 8,
			role: User.role === 0,
			name: 'Войти',
			img: QuitImg,
			onClick: this.logout,
			url: '/login'
		},
		{
			id: 9,
			role: User.role !== 0,
			name: 'Выйти',
			img: QuitImg,
			onClick: this.logout,
			url: '/login'
		}
	]

	constructor() {
		makeAutoObservable(this)
	}
}

export default new MainItems()
