import { IProduct, IPutProductsListProps, IResponceProductList } from '@/types/createorder'
import { $authHost, $host, errorHandler } from '.'
import AddProductPage from '../store/AddProductPage'
import Products from '../store/Products'
import { FetchStages } from '../types'

export const getProducts = async () => {
	try {
		// $fixme auth
		const { data } = await $authHost.get(`/api/products/`)
		AddProductPage.products = data
		return data
	} catch (e) {
		errorHandler(e, getProducts)
	}
}

export const delProducts = async (id: number) => {
	try {
		await $authHost.delete(`/api/products/?id=${id}`)
	} catch (e) {
		errorHandler(e, delProducts)
	}
}

interface IProductsListProps {
	ProductName: string
	ProductDescr: string
	CurrentPrice: string
	SizeWidth: number
	SizeHeight: number
	SizeDepth: number
	OnlyTop: boolean
	BarCode: string
	Weight: number
	productphoto?: any
	Category_id: number
}

export const postProduct = async (product: IProductsListProps) => {
	try {
		const formData = new FormData()
		formData.append('productname', product.ProductName)
		formData.append('productdescr', product.ProductDescr)
		formData.append('sizewidth', `${product.SizeWidth}`)
		formData.append('sizeheight', `${product.SizeHeight}`)
		formData.append('sizedepth', `${product.SizeDepth}`)
		formData.append('onlytop', `${product.OnlyTop ? 'True' : 'False'}`)
		formData.append('barcode', product.BarCode)
		if (product.CurrentPrice) {
			formData.append('CurrentPrice', product.CurrentPrice)
		}
		formData.append('weight', `${product.Weight}`)
		if (product.Category_id) {
			formData.append('category_id', `${product.Category_id}`)
		}
		if (product.productphoto) {
			formData.append('photobytea', product.productphoto)
		}
		const { data } = await $authHost.post<IProduct>(
			`/api/products/`,
			formData
		)
		AddProductPage.products?.products.push(data)

		// const products = await $authHost.get(`/api/products/`);
		// AddProductPage.products = products.data;
		// return data;
	} catch (e) {
		errorHandler(e, () => postProduct(product))
	}
}


export const putProduct = async (product: IPutProductsListProps) => {
	try {
		const formData = new FormData()
		formData.append('id', `${product.id}`)
		formData.append('productname', product.ProductName)
		formData.append('productdescr', product.ProductDescr)
		formData.append('sizewidth', `${product.SizeWidth}`)
		formData.append('sizeheight', `${product.SizeHeight}`)
		formData.append('sizedepth', `${product.SizeDepth}`)
		formData.append('onlytop', `${product.OnlyTop ? 'True' : 'False'}`)
		formData.append('barcode', product.BarCode)
		if (product.CurrentPrice) {
			formData.append('CurrentPrice', product.CurrentPrice)
		}
		formData.append('weight', `${product.Weight}`)
		if (product.Category_id) {
			formData.append('category_id', `${product.Category_id}`)
		}
		if (product.productphoto) {
			formData.append('photobytea', product.productphoto)
		}
		await $authHost.put<IProduct>(`/api/products/`, formData)
	} catch (e) {
		errorHandler(e, () => postProduct(product))
	}
}

export const pushProducts = async (
	page: number = 1,
	categoryId?: number
): Promise<IResponceProductList | null> => {
	try {
		if (Products.isFetching === FetchStages.start) return null
		Products.isFetching = FetchStages.start
		let response: IResponceProductList
		if (page !== 1 && categoryId) {
			const { data } = await $authHost.get<IResponceProductList>(
				`api/products/?page=${page}&category=${categoryId}`
			)
			response = data
		} else if (categoryId) {
			const { data } = await $authHost.get<IResponceProductList>(
				`api/products/?category=${categoryId}`
			)
			response = data
		} else {
			const { data } = await $authHost.get<IResponceProductList>(
				`api/products/?page=${page}`
			)
			response = data
		}
		if (!response.products) return null
		if (page === 1) {
			AddProductPage.PagesStructure.total = response.total
			AddProductPage.PagesStructure.totalPages = Math.ceil(
				response.total / response.products.length
			)
		}
		if (AddProductPage.products) {
			AddProductPage.products.products.push(...response.products)
		} else {
			// @ts-ignore
			AddProductPage.products = response
		}
		AddProductPage.PagesStructure.page = AddProductPage.PagesStructure.page + 1
		return response
	} catch (e) {
		console.log(e)
	} finally {
		Products.isFetching = FetchStages.end
		return null
	}
}
