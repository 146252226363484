import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import {
  post1Cproducts,
  post1CStore,
  post1COrders,
} from "../../API/Integration1CAPI";
import st from "./Integration1C.module.scss";
import { dict } from "./TranslationList";
import { Language } from "../../utils/langSwitch";

const t = Language.translateWithDictionary(dict);
const Bitrix24: FC = () => {
  const [file1, setFile1] = useState<any>(null);
  const [file2, setFile2] = useState<any>(null);
  const [file3, setFile3] = useState<any>(null);
  const [category_id, setCategory_id] = useState<string>("");
  const addFile = (file: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setFile1(file.target.files[0]);
  };
  const addFile2 = (file: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setFile2(file.target.files[0]);
  };
  const addFile3 = (file: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setFile3(file.target.files[0]);
  };

  return (
    <>
      <div className={`${st.wrapper} font-['Open_Sans']`}>
        <div className="border-2 w-full border-sky-200 py-12 hover:border-sky-400 mt-16">
          <div className={st.description}>
            <p className="text-4xl text-center font-bold">
              {t("Интеграция 1 - Товары")}
            </p>
          </div>
          <div className=" p-6 font-light text-2xl text-center">
            <p>
              {t("Выберите файл xml который вы хотите загрузить в систему TakeYours из Битрикс24")}
            </p>
          </div>
          <div className="w-full flex justify-center items-center text-2xl">
            <input type="file" className="ml-16" onChange={(e) => addFile(e)} />
          </div>
          <div className="flex items-center justify-center py-6 my-8">
            <p className="mr-6 text-2xl">{t("Категория")}</p>
            <input
              type="text"
              className="w-36 border-b-2 text-2xl"
              value={category_id}
              onChange={(e) => setCategory_id(e.target.value)}
            />
          </div>
          <div
            className={`${st.upload} text-center`}
            onClick={() =>
              post1Cproducts({
                category_id: Number(category_id),
                products: file1,
              })
            }
          >
            <p className="text-3xl font-bold">{t("Загрузить")}</p>
          </div>
        </div>

        <div className="border-2 w-full border-sky-200 py-12 hover:border-sky-400 mt-16">
          <div className={st.description}>
            <p className="text-4xl text-center font-bold">
              {t("Интеграция 2 - Складские остатки")}
            </p>
          </div>
          <div className=" p-6 font-light text-2xl text-center">
            <p>
              {t("Выберите файл xml который вы хотите загрузить в систему TakeYours из Битрикс24")}
            </p>
          </div>
          <div className="w-full flex justify-center items-center text-2xl l my-12">
            <input
              type="file"
              className="ml-16"
              onChange={(e) => addFile2(e)}
            />
          </div>
          <div
            className={`${st.upload} text-center`}
            onClick={() => post1CStore(file2)}
          >
            <p className="text-3xl font-bold">{t("Загрузить")}</p>
          </div>
        </div>

        <div className="border-2 w-full border-sky-200 py-12 hover:border-sky-400 mt-16">
          <div className={st.description}>
            <p className="text-4xl text-center font-bold">
              {t("Интеграция 3 - Заказы")}
            </p>
          </div>
          <div className=" p-6 font-light text-2xl text-center">
            <p>
              {t(`Выберите файл xml который вы хотите загрузить в систему TakeYours из Битрикс24`)}
            </p>
          </div>
          <div className="w-full flex justify-center items-center text-2xl my-12">
            <input
              type="file"
              className="ml-16"
              onChange={(e) => addFile3(e)}
            />
          </div>
          <div
            className={`${st.upload} text-center`}
            onClick={() => post1COrders(file3)}
          >
            <p className="text-3xl font-bold">{t("Загрузить")}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Bitrix24);
