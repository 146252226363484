import { IProductPhoto } from '@/types/createorder'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import Loader from '../Loader/Loader'

const ProductPhotoItem: FC<{ photo: IProductPhoto }> = ({ photo }) => {
	return (
		<Loader loading={!photo.photobytea}>
			<div
				className='w-full h-full rounded-xl bg-cover'
				style={{
					background: `url('data:${photo.PhotoFileType};base64, ${photo.photobytea}')`,
          backgroundSize: 'cover'
				}}></div>
		</Loader>
	)
}

export default observer(ProductPhotoItem)
