import { $authHost, errorHandler } from '.'
import Warehouse from '../store/Warehouse'
import { IRack, IRackModels, IRacks, IWarehouseView, IWHZone } from '../types/Warehouse'

export const getWarehouse = async () => {
	try {
		const { data } = await $authHost.get<IWarehouseView[]>('api/warehouse_view')
		Warehouse.store = data
		Warehouse.getTreeProducts()
		return data
	} catch (e) {
		await errorHandler(e, getWarehouse)
	}
}

export const getRackModels = async () => {
	try {
		const { data } = await $authHost.get<IRackModels[]>('api/rackmodel')
		Warehouse.rackModels = data
	} catch (e) {
		await errorHandler(e, getRackModels)
	}
}

export const getRacks = async () => {
	try {
		const { data } = await $authHost.get<IRacks[]>('api/rack_view')
		data.forEach(e => (e.active = false))
		Warehouse.racks = data
	} catch (e) {
		await errorHandler(e, getRacks)
	}
}

export const getWhzone = async (id: number) => {
	try {
		const { data } = await $authHost.get<IWHZone[]>('api/whzone')
		const obj = data.find(e => e.id === id)
		if (!obj) return
		Warehouse.WHZone = obj
		return obj
	} catch (e) {
		await errorHandler(e, () => getWhzone(id))
	}
}

export const updateRacks = async (data: IRack) => {
	try {
		await $authHost.put<IRack>('api/rack/', data)
	} catch (e) {
		await errorHandler(e, () => updateRacks(data))
	}
}

export const updateZone = async (FormData: any) => {
	try {
		await $authHost.put<IRack>('api/whzone/', FormData, {
			headers: { 'Content-Type': 'multipart/form-data' }
		})
	} catch (e) {
		await errorHandler(e, () => updateRacks(FormData))
	}
}

export const deleteRack = async (id: number) => {
	try {
		await $authHost.delete<IRack>(`api/rack/?id=${id}`)
	} catch (e) {
		await errorHandler(e, () => deleteRack(id))
	}
}

export const lightRack = async (id: number) => {
	try {
		await $authHost.post(`api/lighting/`, {
			cellrow: 1,
			cellcol: 1,
			lightingscheme: 1,
			color: 0,
			rack: id
		})
	} catch (e) {
		await errorHandler(e, () => lightRack(id))
	}
}

interface IAddRackProps {
	rackname: string
	colnumber: number
	rownumber: number
	available: boolean
	ligthingscheme: null | number
	deviceid: null
	xcoord: number
	ycoord: number
	rotated: number
	model: number
	zone: number
}

export const addRack = async (rackdata: IAddRackProps) => {
	try {
		const { data } = await $authHost.post<IRacks>(`api/rack/`, rackdata)
		return data
	} catch (e) {
		await errorHandler(e, () => addRack(rackdata))
	}
}

export const mapCanvasRestore = async (zoneid: number) => {
	try {
		const response = await $authHost.get<IWHZone>(`api/whzone/?id=${zoneid}`)

		Warehouse.WHZone = response.data
		const response1 = await $authHost.get<IWarehouseView[]>('api/warehouse_view')

		Warehouse.store = response1.data
		const response2 = await $authHost.get<IRackModels[]>('api/rackmodel')

		Warehouse.rackModels = response2.data
		const response3 = await $authHost.get<IRacks[]>('api/rack_view')

		response3.data.forEach(e => (e.active = false))
		Warehouse.racks = response3.data
		const racksZone = Warehouse.racks.filter(rack => rack.WH_id === zoneid)
		if (racksZone.length) {
			Warehouse.selectedRack = racksZone[0]
		}
		Warehouse.rerender = !Warehouse.rerender
	} catch (e) {
		errorHandler(e, () => mapCanvasRestore(zoneid))
	}
}

export const cancelStore = async (zoneid: number) => {
	try {
		const response = await $authHost.get<IWHZone[]>(`api/whzone/?id=${zoneid}`)

		const response1 = await $authHost.get<IWarehouseView[]>('api/warehouse_view')

		Warehouse.WHZone = response.data[0]
		Warehouse.store = response1.data
		const sel = response1.data.find(e => e.whzone_id === zoneid)
		if (!sel) return
		Warehouse.selectedZone = sel
	} catch (e) {
		errorHandler(e, cancelStore(zoneid))
	}
}

export const cancelRacks = async (zoneid: number) => {
	try {
		const response2 = await $authHost.get<IRackModels[]>('api/rackmodel')
		Warehouse.rackModels = response2.data
		const response3 = await $authHost.get<IRacks[]>('api/rack_view')
		response3.data.forEach(e => (e.active = false))
		Warehouse.racks = response3.data
		const racksZone = Warehouse.racks.filter(rack => rack.WH_id === zoneid)
		if (racksZone.length) {
			Warehouse.selectedRack = racksZone[0]
		}
		Warehouse.rerender = !Warehouse.rerender
	} catch (e) {
		errorHandler(e, cancelStore(zoneid))
	}
}
