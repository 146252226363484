import { $authHost, $host, errorHandler } from '.'
import PushProducts from '../store/PushProducts'
import {
	IPostLight,
	IResponceProductList,
	IStoreProducts
} from '../types/createorder'
import { IPostitionProps, IProductStore } from '../types/pushproduct'

export const getProducts = async (category?: number) => {
	try {
		if (category) {
			let { data } = await $authHost.get<IResponceProductList>(
				`api/products/?category=${category}`
			)
			if (!data.products) return
			PushProducts.Products = data.products
			PushProducts.Total = data.total
		} else {
			let { data } = await $authHost.get<IResponceProductList>('api/products/')
			if (!data.products) return
			PushProducts.Products = data.products
			PushProducts.Total = data.total
		}
	} catch (e) {
		await errorHandler(e, () => getProducts())
	}
}

export const pushProducts = async (
	page: number = 1,
	categoryId?: number
): Promise<IResponceProductList | undefined> => {
	try {
		let response
		// $fixme auth
		if (page !== 1 && categoryId) {
			let { data } = await $authHost.get<IResponceProductList>(
				`api/products/?page=${page}&category=${categoryId}`
			)
			response = data
		} else if (categoryId) {
			let { data } = await $authHost.get<IResponceProductList>(
				`api/products/?category=${categoryId}`
			)
			response = data
		} else {
			let { data } = await $authHost.get<IResponceProductList>(
				`api/products/?page=${page}`
			)
			response = data
		}
		if (!response.products) return
		if (page === 1) {
			PushProducts.PagesStructure.total = response.total
			PushProducts.PagesStructure.totalPages = Math.ceil(
				response.total / response.products.length
			)
		}
		PushProducts.Products.push(...response.products)
		return response
	} catch (e) {
		await errorHandler(e, () => pushProducts())
	}
}

export const findProduct = async (value: string) => {
	try {
		let { data } = await $authHost.get<IResponceProductList>(
			`api/products/?barcode=${value}`
		)
		if (!data.products) return
		return data.products[0]
	} catch (e) {
		await errorHandler(e, () => findProduct(value))
	}
}

export const findProductName = async (value: string) => {
	try {
		let { data } = await $authHost.get<IResponceProductList>(
			`api/products/?name=${value}`
		)
		if (!data.products) return
		PushProducts.Products = data.products
		PushProducts.Total = data.total
	} catch (e) {
		await errorHandler(e, () => findProductName(value))
	}
}

export const getStoreProducts = async () => {
	try {
		let { data } = await $authHost.get<IStoreProducts[]>(
			'api/productstore_groupview '
		)
		PushProducts.StoreProducts = data
	} catch (e) {
		await errorHandler(e, () => getStoreProducts())
	}
}

export const getPosition = async (id: number) => {
	try {
		// eslint-disable-next-line array-callback-return
		let { data } = await $authHost.get<IPostitionProps[] | string>(
			`api/optimalposition/productid=${id}`
		)
		if (typeof data === 'string') return
		PushProducts.ProductPosition = data
	} catch (e) {
		await errorHandler(e, () => getPosition(id))
	}
}

export const postPlacement = async (data: IProductStore) => {
	try {
		// eslint-disable-next-line array-callback-return
		await $authHost.post<IProductStore>(`api/productstore/`, data)
	} catch (e) {
		await errorHandler(e, () => postPlacement(data))
	}
}

export const postLight = async ({ Col, Row, ID }: IPostLight) => {
	try {
		await $authHost.post<IPostLight[]>(`api/lighting/`, {
			cellrow: Row,
			cellcol: Col,
			lightingscheme: 1,
			color: 0,
			rack: ID
		})
	} catch (e) {
		errorHandler(e, () => postLight({ Col, Row, ID }))
	}
}
