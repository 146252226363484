import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Логин',
		eng: 'Login'
	},
	{
		ru: 'Пароль',
		eng: 'Password'
	},
	{
		ru: 'Запомнить меня',
		eng: 'Remember me'
	},
	{
		ru: 'Авторизация',
		eng: 'Authorization'
	},
	{
		ru: 'Неверные данные!',
		eng: 'Incorrect data!'
	},
	{
		ru: 'Успешно',
		eng: 'Successfully'
	},
	{
		ru: 'Удалить',
		eng: 'Delete'
	},
	{
		ru: 'Пользователь онлайн',
		eng: 'User online'
	},
	{
		ru: 'Выйти',
		eng: 'Quit'
	},
	{
		ru: 'Не в сети',
		eng: 'Offline'
	}
]
