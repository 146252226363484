import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef, useState } from 'react'
import { $authHost } from '@/API'
import { IProductPhoto } from '@/types/createorder'
import ProductPhotoItem from './ProductPhotoItem'

const ProductSliderList: FC<{ photo: number[] }> = ({ photo }) => {
	const [idx, setIdx] = useState(0)
	const [photos, setPhotos] = useState<IProductPhoto[]>(() => {
		const resualt: IProductPhoto[] = []
		for (let i = 0; i < photo.length; i++) {
			resualt.push({
				id: photo[i],
				product_photo: null,
				photobytea: null,
				PhotoFileType: null,
				product_id: null
			})
		}
		return resualt
	})

	useEffect(() => {
		const fetchPhoto = async () => {
			for (let i = 0; i < photos.length; i++) {
				const { data } = await $authHost.get<IProductPhoto[]>(
					`api/productpic/${photo[i]}`
				)

				setPhotos(prev =>
					prev.map(photo => {
						if (photo.id === data[0].id) {
							return { ...data[0] }
						}
						return photo
					})
				)
			}
		}
		fetchPhoto()
	}, [])

	return (
		<div className='flex flex-col w-full'>
			<div className='relative w-full h-52 cursor-pointer'>
				<div className='absolute left-0 top-0 h-full w-full flex'>
					{photos.map((e, index) => (
						<div onMouseEnter={() => setIdx(index)} className='w-full'></div>
					))}
				</div>
				{photos.map((e, index) => {
					if (index !== idx) return <></>
					return <ProductPhotoItem photo={e} />
				})}
			</div>
			<div className='gap-2 flex w-full justify-center mt-4'>
				{photos.map((e, index) => (
					<div
						className={`w-2 h-2 rounded-full ${
							idx === index ? 'bg-gray-600' : 'bg-gray-400'
						}`}></div>
				))}
			</div>
		</div>
	)
}

export default observer(ProductSliderList)
