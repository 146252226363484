import { FC } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo.png";
import st from "./NavHeader.module.scss";

interface INavHeaderC {
  links?: ILink[];
}

interface ILink {
  img: string;
  title: string;
  url?: string;
  callback?: () => void;
}

const NavHeaderC: FC<INavHeaderC> = ({ links }) => {
  return (
    <div className={st.container}>
      <div></div>
      <div className={st.logo}>
        <NavLink to={"/"}>
          <img src={logo} alt="logotype" />
        </NavLink>
      </div>
      <div className={st.wrapper}>
        {links &&
          links.map((link, index) => <NavActoins key={index} {...link} />)}
      </div>
    </div>
  );
};
export default NavHeaderC;

const NavActoins: FC<ILink> = ({ img, title, url, callback }) => {
  if (url) {
    return (
      <NavLink to={url}>
        <img
          src={img}
          onClick={() => callback && callback()}
          title={title}
          style={{ width: "25px", height: "25px", marginLeft: "10px" }}
          alt="link_icon"
        />
      </NavLink>
    );
  }
  return (
    <img
      src={img}
      onClick={() => callback && callback()}
      title={title}
      style={{ width: "25px", height: "25px", marginLeft: "10px" }}
      alt="link_icon"
    />
  );
};
