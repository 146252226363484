import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Выбирите ваш метод сборки",
    eng: "Choose your build method",
  },
  {
    ru: "Быстро",
    eng: "Quickly",
  },
  {
    ru: "Компактно",
    eng: "Compact",
  },
  {
    ru: "Вернуться",
    eng: "To return",
  },
];
