import { IGantTableCell } from '@/store/GantProduction/Gant.interface'
import GantDates from '@/store/GantProduction/GantDates'
import { FC } from 'react'
import GantTableCell from './GantTableCell'
import EmptyCell from './EmptyCell'
import { observer } from 'mobx-react-lite'

const CellSort: FC<IGantTableCell> = ({ task, day_id, mouth_id }) => {
	const isColorized = GantDates.ComputedColorized(task, day_id, mouth_id)
	return isColorized ? (
		<GantTableCell task={task} day_id={day_id} mouth_id={mouth_id} />
	) : (
		<EmptyCell task={task} day_id={day_id} mouth_id={mouth_id} />
	)
}

export default observer(CellSort)
