import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef } from 'react'
import { pushProducts } from '../../API/CreateOrderAPI'
import Products from '../../store/Products'
import Loader from '../Loader/Loader'
import { useIsInViewport } from '../../hooks/useIsInViewport'
import CreateOrder from '../../store/CreateOrder'
import ProductList from './ProductList'
import { FetchStages } from '../../types'
import { Language } from '../../utils/langSwitch'
import { dict } from './TranslationList'

const t = Language.translateWithDictionary(dict)

const ProductsSection: FC = () => {
	const loadingfooter = useRef(null)
	const isInViewport1 = useIsInViewport(loadingfooter)
	useEffect(() => {
		if (isInViewport1) {
			pushProducts(CreateOrder.PagesStructure.page, Products.Filtred)
			CreateOrder.PagesStructure.page += 1
		}
	}, [isInViewport1])

	return (
		<div className='container mx-auto px-4'>
			<div className='flex justify-between mt-3 md:justify-start'>
				<div className='w-[50%] md:w-fit flex justify-center items-center'>
					<div className='dark:text-white dark:hover:bg-slate-600 hover:bg-slate-200 transition ease-out px-3 py-1 rounded-full border-2 text-xs cursor-pointer md:text-2xl'>
						<p>{t('Все товары')}</p>
					</div>
				</div>
				<div className='w-[50%] md:w-fit md:ml-5 flex justify-center items-center'>
					<div className='dark:text-white dark:hover:bg-slate-600 hover:bg-slate-200 transition ease-out px-3 py-1 rounded-full border-2 text-xs cursor-pointer md:text-2xl'>
						<p>{t('Рекомендуемое')}</p>
					</div>
				</div>
			</div>
			<Loader loading={Products.isFetching === FetchStages.start && !Products.Products.length}>
				<ProductList />
			</Loader>
			<div className='h-36' ref={loadingfooter}></div>
		</div>
	)
}
export default observer(ProductsSection)
