import { $authHost } from '@/API'
import { IProductionState } from '@/store/GantProduction/Gant.interface'
import Gant from '@/store/Settings/Gant/Gant'
import { Compact } from '@uiw/react-color'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import Loader from '../Loader/Loader'

const StateColorSettings: FC<{ preview?: boolean }> = ({ preview }) => {
	const [state, setState] = useState<IProductionState[]>([])
	const [fetch, setFetch] = useState(false)
	const fetchData = async () => {
		// Получение массива состояний
		if (fetch) return
		setFetch(true)
		const { data } = await $authHost.get<IProductionState[]>(
			'api/production_order_state'
		)
		setFetch(false)
		setState(data)
	}

	const stateCreate = async () => {
		const newState = {
			id: 0,
			state_color: '#fff',
			state_name: 'В работеF',
			descr: ''
		}
		// Добавление нового состояния
		const { data } = await $authHost.post<IProductionState>(
			'api/production_order_state',
			newState
		)
		setState(prev => [...prev, data])
	}

	const stateUpdate = (state: IProductionState) => {
		setState(prev => {
			return prev.map(elem => {
				if (elem.id === state.id) {
					return state
				}
				return elem
			})
		})
	}

	useEffect(() => {
		// Вы
		fetchData()
	}, [])
	return (
		<>
			<div className='flex flex-wrap gap-1 flex-col md:flex-row'>
				<Loader loading={fetch}>
					{state.map(e => (
						<StateEditor
							preview={!!preview}
							state={e}
							stateUpdate={stateUpdate}
						/>
					))}
				</Loader>
			</div>
			{!preview && (
				<div
					onClick={stateCreate}
					className='w-full mt-7 h-24 cursor-pointer border text-blue-500 hover:bg-blue-300 hover:text-white hover:border-white border-blue-500 text-xl flex justify-center items-center'>
					<p> + Создать новое состояние заказа</p>
				</div>
			)}
		</>
	)
}

export default observer(StateColorSettings)

interface IStateEditorProps {
	state: IProductionState
	preview: boolean
	stateUpdate: (val: IProductionState) => void
}

export const StateEditor: FC<IStateEditorProps> = ({
	state,
	stateUpdate,
	preview
}) => {
	const [opened, setOpened] = useState(false)

	const toggleMenu = () => {
		if (preview) return
		setOpened(prev => !prev)
	}

	return (
		<div
			className='mt-5 border border-gray-100 hover:bg-gray-50 px-2 py-8 flex-1 cursor-pointer'
			onClick={toggleMenu}>
			<p className='text-center'>
				Цвет ячейки при состоянии
				<br />
				<span className='text-blue-600'>
					{state.state_name.toLocaleLowerCase()}
				</span>
			</p>
			<div className='w-full items-center justify-start flex-col flex mt-3'>
				<div className='w-full h-full flex justify-center items-center'>
					<div
						style={{
							background: state.state_color || '',
							borderRadius: `${Gant.rounded}px`
						}}
						className='w-12 h-4'></div>
				</div>
				{opened && (
					<Compact
						className='mt-4'
						color={state.state_color || ''}
						onChange={() => stateUpdate(state)}
					/>
				)}
			</div>
		</div>
	)
}
