import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Language } from "../../utils/langSwitch";
import { dict } from "./TranslationList";

const t = Language.translateWithDictionary(dict);
const AddBasketBtn: FC<{ addToBasket: () => void }> = ({ addToBasket }) => {
  return (
    <>
      <div
        onClick={addToBasket}
        className={`text-white w-full mt-2 bg-blue-700 hover:bg-blue-800 
        focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium 
        rounded-lg text-sm md:text-2xl p-2.5 text-center dark:bg-blue-600 
        dark:hover:bg-blue-700 dark:focus:ring-blue-800 cursor-pointer 
        select-none`}
      >
        {t("В корзину")}
      </div>
    </>
  );
};

export default observer(AddBasketBtn);
