import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Стеллажи",
    eng: "Shelving",
  },
  {
    ru: "Склад",
    eng: "Warehouse",
  },
];