import editImg from '../assets/edit.svg'
import createImg from '../assets/create.svg'
import quitImg from '../assets/quit.svg'
import GuidesCreatePopup from '../store/GuidesCreatePopup'
import TYCollectGuides from '../store/TYCollectGuides'
import { dict } from './TranslationList'
import { Language } from './langSwitch'

const t = Language.translateWithDictionary(dict)

const CreateOrder = () => {
	GuidesCreatePopup.isOpen = !GuidesCreatePopup.isOpen
}

const EditOrder = () => {}
export const NavLinks = [
	{
		img: editImg,
		title: t('Редактировать текущй заказ'),
		url: '',
		callback: EditOrder
	},
	{
		img: createImg,
		title: t('Создать новый заказ'),
		url: '',
		callback: CreateOrder
	},
	{
		img: quitImg,
		title: t('Выйти в меню'),
		url: '/'
	}
]

const EditGuide = () => {
	TYCollectGuides.open = true
}

export const NavLinksGuide = [
	{
		img: editImg,
		title: t('Редактировать текущй заказ'),
		url: '',
		callback: EditGuide
	}
]
