import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import Category from '../../components/PushProduct/Category/Category'
import { useMount } from '@/hooks/useMount'
import AddCategoryHeader from '@/components/AddCategoryHeader/AddCategoryHeader'
import Categories from '@/store/Categories/Categories'
import ToastDelete from '@/components/Toasts/ToastDelete'

const CategoriesPage: FC = () => {
	useMount(() => {
		if (Categories.CategoriesList.length === 0) Categories.getCategories()
	})

	return (
		<>
			<AddCategoryHeader />
			<div
				className={`container mx-auto grid lv:grid-cols-7 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2`}>
				{Categories.FiltredCategoriesList.map(category => (
					<Category category={category} key={category.id} />
				))}
			</div>

			{Categories.ShowModal ? (
				<ToastDelete
					onCancel={() => (Categories.ShowModal = false)}
					onRemove={Categories.OnRemove}
				/>
			) : null}
		</>
	)
}

export default observer(CategoriesPage)
