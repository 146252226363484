import Login from "./Login";
import { FC } from "react";
import { Authorization } from "@/API/userAPI";
import user from "@/store/user";
import { useNavigate } from "react-router-dom";
import { autorun } from "mobx";
import useInput from "@/hooks/useInput";
import { useMount } from "@/hooks/useMount";
const LoginC: FC = () => {
  const navigate = useNavigate();
  const username = useInput("");
  const password = useInput("");

  const auth = () => {
    Authorization({ username: username.value, password: password.value });
  };
  useMount(
    () =>
      autorun(() => {
        user.isAuth && navigate("/");
      })
  );

  return (
    <div>
      <Login
        login={username}
        password={password}
        auth={auth}
      />
    </div>
  );
};

export default LoginC;
