import { FC } from 'react'
import { observer } from 'mobx-react-lite'

const SkeletBreadCrumb: FC<{ children: JSX.Element | React.ReactNode }> = ({
	children
}) => {
	return (
		<nav
			className={`flex px-5 py-3 text-gray-700 border 
			border-gray-200 rounded-lg bg-gray-50 mb-5
			dark:bg-gray-800 dark:border-gray-700`}
			aria-label='Breadcrumb'>
			<ol className='inline-flex items-center space-x-1 md:space-x-3'>
				{children}
			</ol>
		</nav>
	)
}

export default observer(SkeletBreadCrumb)
