import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import { IProduct, IResponceProductList } from '@/types/createorder'

configure({ enforceActions: 'never' })

class AddProductPage {
	products: IResponceProductList | null = null
	popup: boolean = false
	modal: null | IProduct = null
	isPageFetch: boolean = false
	category = 0
	getFiltred() {
		if (!this.products?.products) return []
		if (this.category === 0) return this.products.products
		return this.products
		.products.filter(p => p.category_id === this.category)
	}
	PagesStructure = {
		page: 1,
		total: 0,
		totalPages: 0
	}
	constructor() {
		makeAutoObservable(this)
	}
}

export default new AddProductPage()
