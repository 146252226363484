import { $authHost } from "../API";
import { makeAutoObservable, toJS } from "mobx";
import { configure } from "mobx";
import { IRacks } from "../types/Warehouse";
import { FetchStages } from "../types";
import { IProducts } from "../types/TYPicking";
import WebSocketShelving from "./WebSocket/WebSocketShelving";
import TYPicking from "./TYPicking";

configure({
  enforceActions: "never",
});



class RackGrid {
  racks: IProducts[][][] = [];
  thisRack: IRacks | null = null;
  firstReSend = false;
  wsorderid: number | null = null;
  isRacksGetted: IRacks[] = []
  isFetch: FetchStages = FetchStages.waiting;
  /* [ x , y ]*/
  coords: [null | number, null | number] = [null, null];

  flash(col: number, row: number) {
    const interfal = setInterval(() => {
      if (this.coords[0] === col && this.coords[1] === row) {
        this.coords = [null, null];
      } else {
        this.coords = [col, row];
      }
    }, 300);
    setTimeout(() => {
      this.coords = [null, null];
      clearInterval(interfal);
    }, 2500);
  }

  async drawRacks(
    rack: number, 
    orderid: number | null, 
    device_id?: string | number
    ) {
    try {
      if (!rack || typeof rack !== "number") {
        if (this.thisRack) {
          rack = this.thisRack?.id;
        } else {
          return;
        }
      }
      if (orderid) {
        this.wsorderid = orderid;
      } else {
        if (!this.wsorderid) {
          return;
        } else {
          orderid = this.wsorderid;
        }
      }
      if (this.isFetch === FetchStages.start) return;
      console.log("Проверки пройдены");
      const racks: any = [];
      this.thisRack = null;
      console.log("начало запроса");
      this.isFetch = FetchStages.start;
      if(this.isRacksGetted.length === 0 && rack){
        const { data } = await $authHost.get<IRacks[]>(
          `api/rack_view/?id=${rack}`
        );
        this.isRacksGetted = data
      }

      
      if (!this.isRacksGetted || this.isRacksGetted.length === 0) {
        console.log("Стеллажа не существует");
        return;
      }
      this.thisRack = this.isRacksGetted[0];
      console.log(toJS(this.thisRack));
      for (let i = 0; i < this.thisRack.ColNumber; i++) {
        racks.push([]);
        for (let j = 0; j < this.thisRack.RowNumber; j++) {
          racks[i].push([]);
        }
      }
      if(WebSocketShelving.products.length === 0){
        const response = await $authHost.get<IProducts[]>(
          `api/orderproductview/?id=${orderid}`
        );
        WebSocketShelving.products = response.data
        TYPicking.Products = response.data
      }

      WebSocketShelving.products.forEach((product) => {
        if (
          product.positions &&
          product.positions.length > 0 &&
          product.positions[0].rackcol
        ) {
          if (
            product.positions &&
            product.positions.length > 0 &&
            product.positions[0].rackrow
          ) {
            racks[product.positions[0].rackrow - 1][
              product.positions[0].rackcol - 1
            ].push(product);
          }
        }
      });
      this.racks = racks;
      this.isFetch = FetchStages.end;
      if (device_id) {
        const element = WebSocketShelving.products.find(
          (product) => product.positions[0].display_id === device_id
        );
        if (element) {
          const { rackcol, rackrow } = element.positions[0];
          this.flash(rackcol, rackrow);
        }
      }
    } catch (e) {
      this.isFetch = FetchStages.error;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default new RackGrid();
