import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ReactComponent as SearchImg } from '../../assets/search.svg'
import Theme from '../../store/Theme'
import { Language } from '../../utils/langSwitch'
import { dict } from './TranslationList'
import { getRandom10 } from '@/API/CreateOrderAPI'

// Переводчик
const t = Language.translateWithDictionary(dict)

const SearchUI: FC = () => {
	const stroke = Theme.isDarkTheme ? 'white' : '#535358'
	return (
		<div className='container mx-auto px-5 mt-1 dark:bg-slate-800'>
			<div className='flex h-10 md:h-16'>
				<div className='w-[80%] relative pr-4'>
					<div
						className={`absolute w-6 h-6 top-2 
            left-3 md:h-10 md:w-10 md:top-3`}>
						<SearchImg stroke={stroke} />
					</div>
					<input
						className={`w-full dark:text-white border-2 
              rounded-full h-full md:pl-16 pl-10 text-base 
              md:text-xl font-extralight dark:bg-slate-800`}
						type='text'
						placeholder={t('Система TakeYours')}
					/>
				</div>
				<div
					onClick={getRandom10}
					className={`w-[20%] flex text-white text-sm 
            md:text-2xl select-none cursor-pointer 
            justify-center items-center rounded-full 
            bg-ty-gridient`}>
					<p>+10</p>
				</div>
			</div>
		</div>
	)
}

export default observer(SearchUI)
