import { makeAutoObservable } from "mobx";
import { configure } from "mobx";

configure({
  enforceActions: "never",
});

class Theme {
  isDarkTheme: boolean = false;
  changeLight = () => {
    if (document.documentElement.classList.contains("dark")) {
      document.documentElement.classList.remove("dark");
      this.isDarkTheme = false;
      localStorage.theme = "light";
    } else {
      document.documentElement.classList.add("dark");
      this.isDarkTheme = true;
      localStorage.theme = "dark";
    }
  };

  handleThemeChange = () => {
    if (!("theme" in localStorage)) {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        document.documentElement.classList.add("dark");
        this.isDarkTheme = true;
        localStorage.theme = "dark";
      } else {
        this.isDarkTheme = false;
        localStorage.theme = "light";
        document.documentElement.classList.remove("dark");
      }
    }
    if (localStorage.theme === "dark") {
      document.documentElement.classList.add("dark");
      this.isDarkTheme = true;
      localStorage.theme = "dark";
    } else {
      this.isDarkTheme = false;
      localStorage.theme = "light";
      document.documentElement.classList.remove("dark");
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new Theme();
