import { getLight } from '@/API/TYPickingAPI'
import TYPicking from '@/store/TYPicking'
import Racks from '@/store/Warehouse/Racks'
import { configure, makeAutoObservable } from 'mobx'
configure({ enforceActions: 'never' })

class ProductsPage {
	// Подсветить стеллаж
	light = async () => {
		const product = TYPicking.Product()
		if (!product || !product.positions.length) return
		const position = product.positions[TYPicking.page]
		Racks.fleshRack(position?.rack)
		await getLight({
			rack_id: position.rack,
			rackcol: position.rackcol,
			rackrow: position.rackrow,
			lightingscheme: 0
		})
	}

	productBackgroundHandler = (pickstate_id: number) => {
		/* pickstate : {
				0: Добавлен
				1: Собран
				2: Нет на складе
				3: Нет нужного кол-ва
				4: Пересортица
		} */
		return [
			'bg-gray-50 hover:bg-gray-200',
			'bg-green-400 hover:bg-green-500',
			'bg-yellow-400 hover:bg-yellow-500',
			'bg-red-400 hover:bg-red-500',
			'bg-blue-400 hover:bg-blue-500'
		][pickstate_id]
	}

	productNameHandler = (pickstate_id: number) => {
		/* pickstate : {
				0: Добавлен
				1: Собран
				2: Нет на складе
				3: Нет нужного кол-ва
				4: Пересортица
		} */
		return [
			'Добавлен',
			'Собран',
			'Нет на складе',
			'Нет нужного кол-ва',
			'Пересортица'
		][pickstate_id]
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new ProductsPage()
