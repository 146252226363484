import { configure, makeAutoObservable, toJS } from "mobx";
import { IProducts } from "../../types/TYPicking";
import { getLight } from "../../API/TYPickingAPI";

configure({
  enforceActions: "never",
});

class WSCollector {
  websocket: null | WebSocket = null;
  products: IProducts[] = [];
  ws_payload = null;
  ordernum: null | number = null;
  order_id: number | null = null;
  selected: number | null = null;
  connectionState: boolean = false;
  orderFetch: boolean = false;

  connect = () => {
    this.websocket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL || "");
    this.websocket.onopen = () => {
      this.connectionState = true;
      this.send({
        action: "connect",
        payload: {
          device_id: "1",
          device_model_id: 5,
          device_type_id: 3,
        },
      });
    };

    this.websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const { action, payload } = message;
      switch (action) {
        case "change":
          this.ws_payload = payload;
          this.order_id = payload.orderid;
          this.selected = this.products.findIndex(
            (e) => e.id === payload.productid
          );
          break;
      }
    };

    this.websocket.onclose = () => {
      this.connectionState = false;
      console.log("Socket закрыт");
      // window.location.reload()
    };
    this.websocket.onerror = (e) => {
      this.connectionState = false;
      console.log("Socket произошла ошибка");
      console.log(e);
    };
  };

  send = (data: any) => {
    if (!this.websocket || !this.connectionState) return;
    this.websocket.send(JSON.stringify(data));
  };

  sendAllDisplayes = () => {
    let arr: any = [];
    for (let i = 0; i < this.products.length; i++) {
      if (
        // @ts-ignore
        !arr.find(
          (e: any) => e.display_id === this.products[i].positions[0].display_id
        )
      ) {
        arr.push({
          device_id: this.products[i].positions[0].display_id,
          device_model: 2,
          device_type: 3,
          productid: this.products[i].id,
          productname: this.products[i].ProductName,
          quant: this.products[i].positions[0].quant,
          rack: this.products[i].positions[0].rack,
          col: this.products[i].positions[0].rackcol,
          row: this.products[i].positions[0].rackrow,
          orderid: this.order_id,
          method: 1,
        });
      }
    }
    for (let i = 0; i < arr.length; i++) {
      this.send({
        action: "change",
        payload: {
          ...arr[i],
        },
      });
      getLight({
        rackrow: this.products[i].positions[0].rackrow,
        rackcol: this.products[i].positions[0].rackcol,
        lightingscheme: 0,
        rack_id: this.products[i].positions[0].rack,
      });
    }
    // setTimeout(() => {
    //   this.ignore = false;
    // }, 2000);
    console.log(arr);
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new WSCollector();
