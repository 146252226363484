import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Активные заказы",
    eng: "Active orders",
  },
  {
    ru: "История заказов",
    eng: "Order history",
  },
];
