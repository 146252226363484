import QRCode from 'react-qr-code'
import scan from '@/assets/scan.svg'
import st from '../../Fastomat.module.scss'
import { FC, KeyboardEvent, MutableRefObject, useRef, useState } from 'react'
import FastomatWorker from '@/store/FastomatWorker'
import { noPhoto } from '@/utils/nophoto'
import { updateOrderAPI } from '@/API/fastomatWorkerAPI'
import { Language } from '@/utils/langSwitch'
import { dict } from './TranslationList'
import Logotype from '@/components/Logotype'
// Переводчик
const t = Language.translateWithDictionary(dict)

const QRcode: FC = () => {
	const [qr, setQr] = useState<string>(FastomatWorker?.EditQr?.orderqr || '')
	const textarea = useRef() as MutableRefObject<HTMLTextAreaElement>
	const keyHandler = (e: KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.keyCode === 13) {
			setQr(textarea.current?.value || '')
		}
	}
	return (
		<div className={st.modalwindow}>
			<div>
				<div className={st.modalheader}>
					<Logotype />
					<p
						onClick={() => {
							FastomatWorker.EditQr!.orderqr = qr
							FastomatWorker.isQrEdit = false
						}}>
						X
					</p>
				</div>
				<div className={st.modalcontent}>
					<div className={st.datachanger}>
						<textarea
							onKeyDown={e => keyHandler(e)}
							ref={textarea}
							defaultValue={qr}
						/>
						<div className={st.qrbtn}>
							{false ? (
								<div className={st.addBtnAddictionalErr}>
									{t('QR не уникален')}
								</div>
							) : (
								<div
									className={st.addBtnAddictional}
									onClick={async () => {
										setQr(textarea.current?.value || '')
										if (!FastomatWorker.EditQr) return
										await updateOrderAPI({
											...FastomatWorker.EditQr,
											orderqr: textarea.current.value
										})
										FastomatWorker.EditQr.orderqr = textarea.current.value
										FastomatWorker.isQrEdit = false
									}}>
									{t('Сохранить')}
								</div>
							)}
						</div>
					</div>
					<div className={st.display}>
						{false ? (
							<img src={qr} alt='scan' />
						) : qr ? (
							<QRCode viewBox={`0 0 256 256`} value={qr} />
						) : (
							<img
								src={`data:image/jpeg;base64, ${noPhoto}`}
								alt='nophoto set'
							/>
						)}
						<div className={`${st.scan} ${st.camerahandler}`}>
							<img src={scan} alt='scan' />

							{false ? (
								<p>{t('Смотреть QR-код')}</p>
							) : (
								<p>
									{t('Сканировать')} <br />
									{t('QR-code с камеры')}
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default QRcode
