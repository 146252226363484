import { $authHost } from '@/API'
import user from '@/store/user'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })

class AdBannerSettings {
	isBanner = true

	// Setter
	toogleBanner = async () => {
		this.isBanner = !this.isBanner
		try {
			localStorage.setItem('isBanner', `${this.isBanner}`)
			const usersettings = await $authHost.get(`api/usersettings/`)
			await $authHost.put(`api/usersettings/`, {
				...usersettings.data,
				adbanner: this.isBanner
			})
		} catch (e) {
			console.log(e)
			console.log('%c Error on localstorage', 'color: red')
		}
	}
	//Hanle on connect
	HandleBanner = async () => {
		const defaultValue = this.isBanner
		try {
			const ReduceValue = localStorage.getItem('isBanner')
			if (ReduceValue === null) {
				localStorage.setItem('isBanner', `${defaultValue}`)
			} else {
				this.isBanner = ReduceValue === 'true'
			}
			const { data } = await $authHost.get(`api/usersettings/`)
			if (this.isBanner !== data.adbanner) {
				this.toogleBanner()
			}
		} catch (e) {
			console.log(e)
			console.log('%c Error on localstorage', 'color: red')
		}
	}
	constructor() {
		makeAutoObservable(this)
	}
}

export default new AdBannerSettings()
