import { NavLink } from 'react-router-dom'
import night from '../../assets/night-clear-svgrepo-com.svg'
import day from '../../assets/sun-svgrepo-com.svg'
import USAFlag from '../../assets/usa_flag.svg'
import RUFlag from '../../assets/ru_flag.svg'
import { observer } from 'mobx-react-lite'
import Theme from '../../store/Theme'
import { dict } from './TranslationList'
import { Language } from '../../utils/langSwitch'
import Logotype from '@/components/Logotype'
import MainPageItem from './MainPageItem'

// Переводчик
const t = Language.translateWithDictionary(dict)

const NavigationForDev = () => {
	return (
		<>
			<div
				className={`h-full min-h-screen w-full 
      bg-ty-gridient dark:bg-ty-gridient-dark`}>
				<header className='bg-white h-24 w-full dark:bg-slate-800 px-3'>
					<div
						className={`container mx-auto py-4 h-full flex 
          	items-center justify-between`}>
						<NavLink
							to='/'
							className={`text-4xl sm:text-5xl 
              font-bold dark:text-white`}>
							<Logotype className='h-16 ' />
						</NavLink>
						<div className='flex items-center'>
							<img
								className='h-7 block dark:hidden cursor-pointer'
								src={night}
								onClick={Theme.changeLight}
								alt=''
							/>
							<img
								className={`h-10 hidden 
                					dark:block cursor-pointer`}
								src={day}
								onClick={Theme.changeLight}
								alt=''
							/>
							{Language.current === 'ru' ? (
								<img
									className='h-10 cursor-pointer ml-5'
									src={USAFlag}
									onClick={Language.changeLanguage}
									alt='USA Flag'
								/>
							) : (
								<img
									className='h-10 cursor-pointer ml-5'
									src={RUFlag}
									onClick={Language.changeLanguage}
									alt='Russian Federation Flag'
								/>
							)}
						</div>
					</div>
				</header>
				<section
					className={`h-full min-h-[calc(100vh-144px)] 
            py-12 flex items-center`}>
					<div
						className={`container mx-auto px-7 flex justify-center 
              content-center items-center gap-7 flex-wrap`}>
						<MainPageItem />
					</div>
				</section>
			</div>
		</>
	)
}
export default observer(NavigationForDev)
