import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Сборка заказа завершена",
    eng: "Order assembly completed",
  },
  {
    ru: "Желаете разместить?",
    eng: "Would you like to place?",
  },
  {
    ru: "Да",
    eng: "Yes",
  },
  {
    ru: "Нет",
    eng: "No",
  },
  {
    ru: "Собран",
    eng: "Assembled",
  },
  {
    ru: "Не собран",
    eng: "Not assembled",
  },
  {
    ru: "Отсутствует",
    eng: "Absent",
  },
  {
    ru: "Собрал",
    eng: "Collected",
  },
  {
    ru: "Товар найден",
    eng: "Product found",
  },
  {
    ru: "Товар не найден",
    eng: "Product not found",
  },
  {
    ru: "Отправить",
    eng: "Send",
  },
];
