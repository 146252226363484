import { observer } from 'mobx-react-lite'
import editImg from '@/assets/edit.svg'
import WarehouseMap from '@/components/WarehouseMap/WarehouseMap'
import WhZone from '@/store/Warehouse/WhZone'
import { useNavigate, useParams } from 'react-router-dom'
import { cutMaxTextLength } from '@/utils/maxText'
import { NavLink } from 'react-router-dom'

const WarehouseMapPage = () => {
	const { id } = useParams()
	const navigate = useNavigate()

	return (
		<div className='w-screen max-h-screen flex'>
			<div className='w-5/6 h-full'>
				<WarehouseMap id={Number(id)} />
			</div>
			<div className='w-1/6 border-l h-screen text-center'>
				{/* <div className='h-1/2'>
					<p>Стеллажи</p>
					<div className='h-full overflow-y-auto'>
						<div className='flex justify-between px-4'>
							<div>1</div>
							<div>rack # 1</div>
							<div>
								<img src={lightImg} className='w-4 h-4' alt='' />
							</div>
						</div>
						<div className='flex justify-between px-4'>
							<div>1</div>
							<div>rack # 1</div>
							<div>
								<img src={lightImg} className='w-4 h-4' alt='' />
							</div>
						</div>
						<div className='flex justify-between px-4'>
							<div>1</div>
							<div>rack # 1</div>
							<div>
								<img src={lightImg} className='w-4 h-4' alt='' />
							</div>
						</div>
						<div className='flex justify-between px-4'>
							<div>1</div>
							<div>rack # 1</div>
							<div>
								<img src={lightImg} className='w-4 h-4' alt='' />
							</div>
						</div>
					</div>
				</div> */}
				<div className='h-full'>
					<p>Складские зоны</p>
					<div className='h-full '>
						<div>
							{WhZone.WHZoneList.map((zone, idx) => {
								if (zone.wh !== Number(id) || zone.invisible) return null
								return (
									<div className='flex justify-between mt-2 px-6 text-xs'>
										<div>{idx}</div>
										<div className='mx-4'>
											{cutMaxTextLength(zone.zonename, 10)}
										</div>
										<div className='cursor-pointer'>
											<NavLink to={`/admin/warehouse/zone/${zone.id}`}>
												<img src={editImg} className='w-4 h-4' alt='' />
											</NavLink>
										</div>
									</div>
								)
							})}
						</div>
						<div
							onClick={() => {
								navigate(-1)
							}}
							className=' bg-purple-400 text-white cursor-pointer'>
							<p>Выйти</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default observer(WarehouseMapPage)
