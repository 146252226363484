import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import Fastomat from './Fastomat'
import { useEffect } from 'react'
import { getIcons, getOrders } from '../../API/Fastomat'
import FastomatClient from '../../store/Fastomat'
import {
	longpollingFrontend,
	updateOrderAPI
} from '../../API/fastomatWorkerAPI'
import BtnHandler from '../../components/btnHandler'
import { getTextMessage, lighting } from '../../API/fastomatClientAPI.d'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { IFastomatAPI } from '../../types/fastomatWorker'
import { ToastContainer } from 'react-toastify'
import { notification } from '../../utils/notification'
import { dict } from './TranslationList'
import { Language } from '../../utils/langSwitch'

// Переводчик
const t = Language.translateWithDictionary(dict)
const FastomatC = () => {
	const [value, setValue] = useState('')
	const [sound, setSound] = useState<string | null>(null)
  
	if (typeof window !== 'undefined') {
		injectStyle()
	}

	useEffect(() => {
		(async () => {
			await getIcons()
			await getOrders()
		})()
		const interval = setInterval(() => {
			longpollingFrontend()
		}, 5000)
		return () => {
			clearInterval(interval)
			FastomatClient.orders = []
			setTimeout(() => {
				window.location.reload()
			}, 600000)
		}
	}, [])
	useEffect(() => {
		if (value !== '') {
			sendScan()
			setValue('')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	const playAudio = async (text: string) => {
		getTextMessage(text, setSound)
	}

	const sendScan = (): void => {
		const item = FastomatClient.orders.find(e => e.orderqr === value)
		console.log(item)
		if (!item) {
			const text = t('Заказ не найден')
			playAudio(text)
			notification({ text, type: 'error' })
			notification({ text: value, type: 'error' })
			return
		}
		let text: string = ''
		switch (item.orderstate) {
			case 1:
			case 2:
				text = t('Ваш заказ еще готовится')
				playAudio(text)
				notification({ text })
				notification({ text: value })
				setValue('')
				return
			case 3:
				text = t('Ваш заказ уже готов, скоро его разместят, ожидайте')
				playAudio(text)
				notification({ text })
				notification({ text: value })
				setValue('')
				return
			case 4:
			case 5:
				lighting(item?.windows[0])
				text = `Заказ номер ${item?.ordernum} можно забрать ${
					item?.windows.length
						? 'в окне' + String(item?.windows[0])
						: 'у продавца'
				}`
				playAudio(text)
				notification({ text })
				notification({ text: value })

				const getUpdate = async (item: IFastomatAPI) => {
					await updateOrderAPI({
						...item,
						orderstate: 9
					})
					item.orderstate = 9
				}
				getUpdate(item)
				setValue('')
				return
			case 6:
			case 7:
			case 8:
			case 9:
				text = t('Этот заказ уже выдан')
				playAudio(text)
				notification({ text })
				notification({ text: value })
				setValue('')
				return
			default:
				text = t('Ошибка')
				playAudio(text)
				notification({ text, type: 'error' })
				notification({ text: value })
				setValue('')
				return
		}
	}
	return (
		<>
			<Fastomat />
			<BtnHandler setValue={setValue} />
			<ToastContainer />
		</>
	)
}

export default observer(FastomatC)
