import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Добавить",
    eng: "Add",
  },
  {
    ru: "Модель",
    eng: "Modelted",
  },
  {
    ru: "Координата X",
    eng: "X coordinate",
  },
  {
    ru: "Координата Y",
    eng: "Y coordinate",
  },
  {
    ru: "Отменить",
    eng: "Canceld",
  },
  {
    ru: "Поворот",
    eng: "Rotate",
  },
  {
    ru: "Название",
    eng: "Title",
  },
  {
    ru: "Добавление стеллажа",
    eng: "Adding a rack",
  },
  {
    ru: "Параметры стеллажа",
    eng: "Rack Parameters",
  },
];
