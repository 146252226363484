import { $authHost } from '@/API'
import Canvas from '@/components/MapCanvasElement/objects/Canvas'
import { FetchStages } from '@/types'
import { IRackModels, IRacks } from '@/types/Warehouse'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import WhZone from './WhZone'
import { getLight } from '@/API/TYPickingAPI'

configure({
	enforceActions: 'never'
})

class RacksStore {
	isRacksFetch: FetchStages = FetchStages.waiting
	isRackModelsFetch: FetchStages = FetchStages.waiting
	racks: IRacks[] = []
	rackModels: IRackModels[] = []
	selectedRack: IRacks | null = null
	filtredRacks: IRacks[] = []
	iteration: number = 0

	fetchRacks = async (zoneid?: number | string) => {
		try {
			if (this.isRacksFetch === FetchStages.start) return
			if (this.racks.length > 0) return
			this.isRacksFetch = FetchStages.start
			const { data } = await $authHost.get<IRacks[]>('api/rack_view')
			data.forEach(rack => {
				if (!rack.xcoord) rack.xcoord = 0
				if (!rack.ycoord) rack.ycoord = 0
			})
			this.racks = data
			if (zoneid) {
				this.filterRacksByZone(Number(zoneid))
			} else {
				this.filtredRacks = data
			}

			this.isRacksFetch = FetchStages.end
		} catch (e) {
			console.log(
				`%c Error on get racks data (api/rack_view)`,
				'color: #bada55'
			)
			this.isRacksFetch = FetchStages.error
		}
	}

	canvasAdaptation = () => {
		this.filtredRacks.forEach(rack => {
			const rackModel = this.rackModels.find(
				model => model.id === rack.Model_id
			)
			if (!rackModel) return
			this.draw(rack, rackModel)
		})
	}

	fetchRackModels = async () => {
		try {
			if (this.isRackModelsFetch === FetchStages.start) return
			if (this.rackModels.length > 0) return
			this.isRackModelsFetch = FetchStages.start
			const { data } = await $authHost.get<IRackModels[]>('api/rackmodel')
			this.rackModels = data
			this.isRackModelsFetch = FetchStages.end
			return data
		} catch (e) {
			this.isRackModelsFetch = FetchStages.error
			console.log('%c Error on rack models fetch', 'color: red')
		}
	}

	fleshRack = (id: number) => {
		this.racks.forEach(e => {
			this.iteration = 0
			clearInterval(e.fleshFunc)
			e.flash = false
		})

		const currentRack = this.racks.find(e => e.id === id)
		if (!currentRack) return

		currentRack.fleshFunc = setInterval(() => {
			this.iteration += 500
			currentRack.flash = !currentRack?.flash
			if (this.iteration === 3000) {
				this.iteration = 0
				clearInterval(currentRack.fleshFunc)
				currentRack.flash = false
			}
		}, 500)
	}

	filterRacksByZone = (zoneid: number | string) => {
		// Ищем все стеллажи в зоне
		this.filtredRacks = this.racks.filter(e => e.Zone_id === Number(zoneid))
		if (this.filtredRacks.length > 0) {
			this.selectedRack = this.filtredRacks[0]
		}
	}

	changeRack = (props: IRacks) => {
		let rack = this.racks.find(e => e.id === props.id)
		if (rack) {
			rack = props
		} else {
			console.log(
				`%c Do not finded rack with id = ${props.id} for change `,
				'color: #bada55'
			)
		}
	}

	draw(rack: IRacks, model: IRackModels) {
		if (!Canvas.context) return
		let coffX = 1
		let coffY = 1
		if (Canvas.canvas && Canvas.canvas.current && WhZone.WHZone) {
			if (Canvas.canvas.current.clientWidth < WhZone.WHZone.width) {
				coffX = Canvas.canvas.current.clientWidth / WhZone.WHZone.width
			}
			if (Canvas.canvas.current.clientHeight < WhZone.WHZone.length) {
				coffY = Canvas.canvas.current.clientHeight / WhZone.WHZone.length
			}
		}

		Canvas.context.save()
		if (
			this.selectedRack &&
			rack.id === this.selectedRack.id &&
			!Canvas.production
		) {
			Canvas.context.strokeStyle = 'red'
		} else {
			Canvas.context.strokeStyle = 'black'
		}
		const zoneHeight = Number(WhZone.WHZone?.whzone_x)
		const zoneWidth = Number(WhZone.WHZone?.whzone_y)
		// отрисовка стеллажа по заданным координатам
		Canvas.context.translate(
			(Number(rack.xcoord) - zoneWidth) * coffX,
			(Number(rack.ycoord) - zoneHeight) * coffY
		)
		// Поворот стеллажа
		Canvas.context.rotate((rack.rotated * Math.PI) / 180)
		Canvas.context.beginPath()

		/*
			Отрисовка стеллажа, деление на два
			нужно чтобы её координаты оказались
			в центре
		*/
		Canvas.context.rect(
			(-model.cellwidth * model.colnumber * coffX) / 2,
			(-model.celldepth / 2) * coffY,
			model.cellwidth * model.colnumber * coffX,
			(model.celldepth / 2) * coffY
		)

		Canvas.context.stroke()

		for (let j = 0; j < model.colnumber; j++) {
			const x = (-model.cellwidth * model.colnumber * coffX) / 2
			const y = (-model.celldepth / 2) * coffY

			const rackWidth = model.cellwidth * model.colnumber * coffX
			const rackHeight = (model.celldepth / 2) * coffY

			Canvas.context.beginPath()
			const addpx = rackWidth / rack.ColNumber
			Canvas.context.moveTo(x + addpx * j, y)
			Canvas.context.lineTo(x + addpx * (j + 1), y + rackHeight)
			Canvas.context.stroke()

			Canvas.context.beginPath()
			Canvas.context.moveTo(x + addpx * j, y + rackHeight)
			Canvas.context.lineTo(x + addpx * (j + 1), y)
			Canvas.context.stroke()

			Canvas.context.beginPath()
			Canvas.context.moveTo(x + addpx * j, y)
			Canvas.context.lineTo(x + addpx * j, y + rackHeight)
			Canvas.context.stroke()
		}

		Canvas.context.restore()
	}

	unMountAll() {
		// Обнуляем всё
		this.isRacksFetch = FetchStages.waiting
		this.racks = []
		this.filtredRacks = []
		this.selectedRack = null
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new RacksStore()
