import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import TYPicking from '@/store/TYPicking'
import st from './Products.module.scss'
import { Language } from '@/utils/langSwitch'
import { dict } from './TranslationList'
import ProductName from '../ProductName'
import { IProductItem } from './Products.types'
import ProductsPage from './Products.store'
// Переводчик
const t = Language.translateWithDictionary(dict)

const Product: FC = () => {
	return (
		<>
			<div className={`flex justify-between px-12`}>
				<p>Ячейка</p>
				<p>Имя продукта</p>
				<p>Статус</p>
			</div>
			{TYPicking.Products.map((product, idx) => (
				<ProductItem
					product={product}
					key={product.id}
					idx={idx}
					id={product.id}
				/>
			))}
		</>
	)
}

export default observer(Product)

const ProductItem: FC<IProductItem> = observer(({ product, id, idx }) => {
	const selectedBorder =
		id === TYPicking.Product()?.id && 'border-8 border-gray-400'

	const background = ProductsPage.productBackgroundHandler(product.PickState_id)
	return (
		<div
			className={`${st.product} ${selectedBorder} ${background}`}
			onClick={() => TYPicking.selectproduct(idx)}>
			<p>{product.positions.length && product.positions[0].cell_name}</p>
			<ProductName
				ProductName={product.ProductName}
				ProductNameEng={product.ProductNameEng}
			/>
			<p className='underline'>
				{ProductsPage.productNameHandler(product.PickState_id)}
			</p>
		</div>
	)
})
