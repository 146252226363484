import { NavLink } from 'react-router-dom'
import Theme from '@/store/Theme'
import { ReactComponent as BasketImg } from '@/assets/basket.svg'
import { FC } from 'react'
import { BASKET_ROUTE } from '@/utils/consts'
import { observer } from 'mobx-react-lite'
import BasketIndicator from '@/components/BasketIndicator'

const BasketBtnHeader: FC = () => {
	return (
		<>
			<div
				className={`border-2 rounded-full p-2 
        cursor-pointer hover:bg-slate-200 block 
        md:hidden dark:hover:bg-slate-500 relative`}>
				<NavLink to={BASKET_ROUTE}>
					<BasketIndicator />
					<BasketImg
						width={'1.25rem'}
						height={'1.25rem'}
						fill={Theme.isDarkTheme ? 'white' : '#535358'}
					/>
				</NavLink>
			</div>
			<div
				className={`border-2 rounded-full p-2 
        cursor-pointer hover:bg-slate-200 hidden 
        md:block dark:hover:bg-slate-500 relative`}>
				<BasketIndicator />
				<NavLink to={BASKET_ROUTE}>
					<BasketImg
						width={'3.5rem'}
						height={'3.5rem'}
						fill={Theme.isDarkTheme ? 'white' : '#535358'}
					/>
				</NavLink>
			</div>
		</>
	)
}
export default observer(BasketBtnHeader)
