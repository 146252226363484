import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Корзина",
    eng: "Basket",
  },
  {
    ru: "Выбрать всё",
    eng: "Select all",
  },
  {
    ru: "К оформлению",
    eng: "To the registration",
  },
  {
    ru: "товаров",
    eng: "of goods",
  },
  {
    ru: "Удаление выбранных товаров",
    eng: "Deleting selected products",
  },
];

