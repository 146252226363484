import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Выберите файл xml который вы хотите загрузить в систему TakeYours из Битрикс24",
    eng: "Select the xml file that you want to upload to the TakeYours system from Bitrix24",
  },
  {
    ru: "Интеграция 1 - Товары",
    eng: "Integration 1 - Products",
  },
  {
    ru: "Выберите файл xml который вы хотите загрузить в систему TakeYours из Битрикс24",
    eng: "Select the xml file that you want to upload to the TakeYours system from Bitrix24",
  },
  {
    ru: "Категория",
    eng: "Category",
  },
  {
    ru: "Загрузить",
    eng: "Download",
  },
  {
    ru: "Интеграция 2 - Складские остатки",
    eng: "Integration 2 - Inventory balances",
  },
  {
    ru: "Интеграция 3 - Заказы",
    eng: "Integration 3 - Orders",
  },

  {
    ru: "Выберите файл xml который вы хотите загрузить в систему TakeYours из Битрикс24",
    eng: "Select the xml file that you want to upload to the TakeYours system from Bitrix24",
  },

];
