import st from '../../Fastomat.module.scss'
import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { cutMaxTextLength } from '../../../../utils/maxText'

interface IDescChangerProps {
	desc: string
	edited: boolean
	textareaData: any
}
interface IDescStableProps {
	desc: string
}
interface IDescNowChanges {
	textareaData: any
	desc: string
}

const DescChanger: FC<IDescChangerProps> = ({ desc, edited, textareaData }) => {
	if (edited) return (
  <DescNowChanges 
    desc={desc} 
    textareaData={textareaData} />
  )
	else return <DescStable desc={desc} />
}

export default observer(DescChanger)

const DescNowChanges: FC<IDescNowChanges> = observer(
	({ textareaData, desc }) => {
		return (
			<>
				<textarea
					className={st.desc}
					ref={textareaData}
					defaultValue={desc}></textarea>
				<div className={st.wall}></div>
			</>
		)
	}
)

const DescStable: FC<IDescStableProps> = observer(({ desc }) => {
	return (
		<>
			<p className={st.desc}>{cutMaxTextLength(desc)}</p>
			<div className={st.wall}></div>
		</>
	)
})
