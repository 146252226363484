import { FC } from 'react'
import ChangeBasketCount from '../UI/ChangeBasketCount'
import AddBasketBtn from '../UI/AddBasketBtn'
import { observer } from 'mobx-react-lite'
import Products from '@/store/Products'

interface IProps {
	id: number
	basketcount?: number
}
const BtnHandler: FC<IProps> = ({ id, basketcount }) => {
	const removeFromBasket = () => Products.removeFromBasket(id)
	const addToBasket = () => Products.addToBasket(id)
	return (
		<>
			{basketcount ? (
				<ChangeBasketCount
					removeFromBasket={removeFromBasket}
					addToBasket={addToBasket}
					basketcount={basketcount}
				/>
			) : (
				<AddBasketBtn addToBasket={addToBasket} />
			)}
		</>
	)
}

export default observer(BtnHandler)
