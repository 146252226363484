import { $authHost } from '@/API'
import { IProduct, IResponceProductList } from '@/types/createorder'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })

class ProductItemPage {
    data: IProduct | null = null

    fetchProduct = async (id: number) => {
        try{
            // $fixme
            // @ts-ignore
            const {data} = await $authHost.get<IResponceProductList>(`api/products/?id=${id}`)
            if(data && data.products.length > 0){
                this.data = data.products[0]
            }
        }
        catch(e){
            console.log(e)
            console.log('%c Error on product fetch','color:red')
        }
    }


	constructor() {
		makeAutoObservable(this)
	}
}

export default new ProductItemPage()
