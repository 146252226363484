import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import Users from '../store/Users'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import AvatarImg from '@/assets/account-avatar-profile-user-13-svgrepo-com.svg'
import user from '@/store/user'
import TabsAdminUser from '@/components/TabsAdminUser/TabsAdminUser'
const getLinks = [
	{
		name: 'Admin',
		url: '/admin'
	},
	{
		name: 'Users',
		url: ''
	}
]
const UsersList: FC = () => {
	useEffect(() => {
		Users.getUsers()
	}, [])
	return (
		<>
			<Breadcrumbs links={getLinks} />
			<TabsAdminUser />
			<section className='bg-slate-100 dark:bg-gray-900'>
				<div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
					<div className='flex items-center justify-between pb-4 bg-white dark:bg-gray-900'>
						<div>
							<button
								id='dropdownActionButton'
								data-dropdown-toggle='dropdownAction'
								className='inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700'
								type='button'>
								<span className='sr-only'>Action button</span>
								Action
								<svg
									className='w-2.5 h-2.5 ml-2.5'
									aria-hidden='true'
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 10 6'>
									<path
										stroke='currentColor'
										stroke-linecap='round'
										stroke-linejoin='round'
										stroke-width='2'
										d='m1 1 4 4 4-4'
									/>
								</svg>
							</button>
							<div
								id='dropdownAction'
								className='z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600'>
								<ul
									className='py-1 text-sm text-gray-700 dark:text-gray-200'
									aria-labelledby='dropdownActionButton'>
									<li>
										<a
											href='#'
											className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
											Reward
										</a>
									</li>
									<li>
										<a
											href='#'
											className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
											Promote
										</a>
									</li>
									<li>
										<a
											href='#'
											className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
											Activate account
										</a>
									</li>
								</ul>
								<div className='py-1'>
									<a
										href='#'
										className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white'>
										Delete User
									</a>
								</div>
							</div>
						</div>
						<label className='sr-only'>Search</label>
						<div className='relative'>
							<div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
								<svg
									className='w-4 h-4 text-gray-500 dark:text-gray-400'
									aria-hidden='true'
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 20 20'>
									<path
										stroke='currentColor'
										stroke-linecap='round'
										stroke-linejoin='round'
										stroke-width='2'
										d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
									/>
								</svg>
							</div>
							<input
								type='text'
								id='table-search-users'
								className='block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
								placeholder='Search for users'
							/>
						</div>
					</div>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th scope='col' className='p-4'>
									<div className='flex items-center'>
										<input
											id='checkbox-all-search'
											type='checkbox'
											className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
										/>
										<label className='sr-only'>checkbox</label>
									</div>
								</th>
								<th scope='col' className='px-6 py-3'>
									Name
								</th>
								<th scope='col' className='px-6 py-3'>
									Position
								</th>
								<th scope='col' className='px-6 py-3'>
									Status
								</th>
								<th scope='col' className='px-6 py-3'>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							{Users.Users.map(e => {
								return (
									<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'>
										<td className='w-4 p-4'>
											<div className='flex items-center'>
												<input
													id='checkbox-table-search-1'
													type='checkbox'
													className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
												/>
												<label className='sr-only'>checkbox</label>
											</div>
										</td>
										<th
											scope='row'
											className='flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white'>
											<img className='w-10 h-10' src={AvatarImg} alt='avatar' />
											<div className='pl-3'>
												<div className='text-base font-semibold'>
													{e.first_name}
												</div>
												<div className='font-normal text-gray-500'>
													{e.email || e.username}
												</div>
											</div>
										</th>
										<td className='px-6 py-4'>
											{e.is_superuser ? 'Admin' : 'User'}
										</td>
										{user.isOnline(`${e.last_login}`) ? (
											<td className='px-6 py-4'>
												<div className='flex items-center'>
													<div className='h-2.5 w-2.5 rounded-full bg-green-500 mr-2'></div>
													Online
												</div>
											</td>
										) : (
											<td className='px-6 py-4'>
												<div className='flex items-center'>
													<div className='h-2.5 w-2.5 rounded-full bg-red-500 mr-2'></div>
													Offline
												</div>
											</td>
										)}

										<td className='px-6 py-4'>
											<div className='font-medium text-blue-600 dark:text-blue-500 hover:underline'>
												Delete user
											</div>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</section>
		</>
	)
}
export default observer(UsersList)
