import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Продукты',
		eng: 'Products'
	},
	{
		ru: 'Всего продуктов',
		eng: 'Total products'
	},
	{
		ru: 'Продуктов на складе',
		eng: 'Products in stock'
	},
	{
		ru: 'Список продуктов',
		eng: 'List of products'
	}
]
