import { IDistTranslation } from '@/utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Архив',
		eng: 'Archive'
	},
	{
		ru: 'Все заказы',
		eng: 'All orders'
	}
]
