import { FC } from 'react'
import { ReactComponent as QuitImg } from '@/assets/quit.svg'
import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import { InitPages } from '@/store/Settings/AdminPanel/InitPages'
import Theme from '@/store/Theme'
import { Language } from '@/utils/langSwitch'
import { dict } from '@/store/Settings/AdminPanel/TranslationList'

const LeftNavPanel: FC = () => {
	const t = Language.translateWithDictionary(dict)
	return (
		<section
			className='w-52 text-center mr-2 xl:hidden lg:block hidden dark:bg-slate-800 '
			style={{ height: '100vh-96px' }}>
			{InitPages.map(page => (
				<NavLink
					className='xl:text-lg lg:text-3xl text-lg font-medium text-black dark:text-gray-200'
					to={'/admin' + page.url}
					key={page.url}>
					<div className='flex xl:flex-row lg:flex-col flex-row items-center gap-2 hover:bg-slate-200 dark:hover:hover:bg-slate-800 p-2 pl-5 rounded-r-lg cursor-pointer'>
						<img
							src={page.img}
							className='xl:w-6 xl:h-6 lg:w-16 lg:h-16 w-8 h-8 mr-2'
							alt=''
						/>
						{t(page.name)}
					</div>
				</NavLink>
			))}

			<NavLink
				to='/'
				className='xl:text-lg w-full flex justify-center items-center flex-col gap-2 lg:text-3xl text-lg font-medium text-black dark:text-gray-200'>
				<QuitImg
					className='xl:w-6 xl:h-6 lg:w-16 lg:h-16 w-8 h-8 mr-2'
					stroke={Theme.isDarkTheme ? 'white' : 'black'}
				/>
				Quit
			</NavLink>
		</section>
	)
}

export default observer(LeftNavPanel)
