import { dict } from './TranslationList'
import { Language } from './langSwitch'

const t = Language.translateWithDictionary(dict)
export const stateHandler = (state: number | string) => {
	if (typeof state === 'number') {
		switch (state) {
			case 1:
				return t('Создан')
			case 2:
				return t('Готовится')
			case 3:
				return t('Заказ готов')
			case 4:
				return t('Можно забирать')
			case 5:
				return t('Выдается')
			case 6:
				return t('Выдан')
			case 7:
				return t('Выполнен')
			case 8:
				return t('Не забрали')
			case 9:
				return t('Вернули')
			case 10:
				return t('Удален')
			default:
				return ''
		}
	}
	switch (state) {
		case 'Создан':
			return 1
		case 'Готовится':
			return 2
		case 'Заказ готов':
			return 3
		case 'Можно забирать':
			return 4
		case 'Выдается':
			return 5
		case 'Выдан':
			return 6
		case 'Выполнен':
			return 7
		case 'Не забрали':
			return 8
		case 'Вернули':
			return 9
		case 'Удален':
			return 10
		default:
			return 1
	}
}
