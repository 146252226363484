import { stateHandler } from '@/utils/statehandler'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import st from '../../Fastomat.module.scss'

interface ISelectorProps {
	state: number
	ChangeState(state: number): void
}
const Selector: FC<ISelectorProps> = ({ state, ChangeState }) => {
	return (
		<select
			className={st.editor}
			onChange={e => {
				// @ts-ignore
				ChangeState(Number(stateHandler(e.target.value)))
			}}
			onBlur={e => {
				ChangeState(Number(stateHandler(e.target.value)))
			}}
			value={stateHandler(state)}>
			<option>{stateHandler(1)}</option>
			<option>{stateHandler(2)}</option>
			<option>{stateHandler(3)}</option>
			<option>{stateHandler(4)}</option>
			<option>{stateHandler(5)}</option>
			<option>{stateHandler(6)}</option>
			<option>{stateHandler(7)}</option>
			<option>{stateHandler(8)}</option>
			<option>{stateHandler(9)}</option>
			<option>{stateHandler(10)}</option>
		</select>
	)
}

export default observer(Selector)
