import { $authHost, $host } from '@/API'
import { FetchStages } from '@/types'
import { ICategories, ICategoryPhoto } from '@/types/categoryChange'
import { reformImage } from '@/utils/imageHandler'
import { noPhoto } from '@/utils/nophoto'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })

class Categories {
	FetchStage = FetchStages.waiting
	CategoriesList: ICategories[] = []
	FiltredCategoriesList: ICategories[] = []
	ShowModal: boolean = false
	OnRemove: () => void = () => {}

	async deleteCatergory(id?: number | string) {
		if (!id) throw Error('id not found')
		id = Number(id)
		this.ShowModal = true
		this.OnRemove = async () => {
			await $authHost.delete('api/productcategory?id=' + id)
			this.ShowModal = false
			this.FiltredCategoriesList = this.FiltredCategoriesList.filter(
				category => category.id !== id
			)
		}
		return this.OnRemove
	}

	filterByQuery = async (query: string) => {
		if (query === '') this.FiltredCategoriesList = this.CategoriesList
		this.FiltredCategoriesList = this.CategoriesList.filter(
			category =>
				category.categoryname.toLowerCase().includes(query.toLowerCase()) ||
				category.categorydescr.toLowerCase().includes(query.toLowerCase())
		)
	}

	getCategories = async () => {
		try {
			if (this.CategoriesList.length > 0) return this.CategoriesList
			if (this.FetchStage === 'start') return
			this.FetchStage = FetchStages.start
			// $fixme auth
			let { data } = await $authHost.get<ICategories[]>('api/productcategory/')

			data.forEach(category => {
				category.PhotoFetchStage = FetchStages.waiting
				category.photo = reformImage({
					PhotoBytea: null
				})
			})

			this.CategoriesList = data
			this.FiltredCategoriesList = this.CategoriesList
			this.FetchStage = FetchStages.end
			this.CategoriesList.forEach(e => {
				this.categoryPhoto(e.id)
			})
			return data
		} catch (err) {
			this.FetchStage = FetchStages.error
		}
	}

	categoryPhoto = async (id: number) => {
		const category = this.CategoriesList.find(category => category.id === id)
		if (category) {
			if (
				category.PhotoFetchStage === FetchStages.start ||
				category.PhotoFetchStage === FetchStages.end
			)
				return
			category.PhotoFetchStage = FetchStages.start
			try {
				const resualt = await $authHost.get<ICategoryPhoto[]>(
					`api/categoryphoto/${id}`
				)
				category.PhotoFetchStage = FetchStages.end
				category.photo = reformImage({
					PhotoBytea: resualt.data[0].photo,
					ImageType: 'image/svg+xml'
				})
				return category.photo
			} catch (e) {
				category.PhotoFetchStage = FetchStages.error
				category.photo = reformImage({
					PhotoBytea: 'error',
					ImageType: 'image/svg+xml'
				})
			}
		}
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new Categories()
