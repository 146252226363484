import GantPage from '@/pages/GantPage/GantPage'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import GantHeader from './GantHeader'
import GantSettings from '@/pages/GantSettings/GantSettings'
import GantPostHeader from './GantPostHeader'
import PushProductsPage from '@/pages/PushProductsPage/PushProductsPage'
import PushProductPage from '@/pages/PushProductPage/PushProductPage'
import SelectOrdersCollectedC from '../SelectOrdersCollected/SelectOrdersCollectedC'
import PickGuidePageC from '@/pages/PickGuidePage/PickGuidePageC'
import MainProductSelected from '@/pages/MainProductSelected/MainProductSelected'
import GantProductionPage from '@/pages/GantProductionPage/GantProductionPage'

const GantSkelet: FC = () => {
	return (
		<>
			<GantHeader />
			<GantPostHeader />
			<Routes>
				<Route path='/commerce' element={<GantPage />} />
				<Route
					path='/commerce/collect-order'
					element={<SelectOrdersCollectedC />}
				/>
				<Route
					path='/commerce/create-order'
					element={<MainProductSelected />}
				/>
				<Route path='/commerce/order-managment' element={<GantPage />} />
				<Route
					path='/commerce/push-products/*'
					element={<PushProductsPage />}
				/>

				<Route
					path='/commerce/push-products/push/:id'
					element={<PushProductPage />}
				/>
				<Route path='/production/instraction' element={<PickGuidePageC />} />
				<Route
					path='/production/create-instraction'
					element={<PickGuidePageC />}
				/>
				<Route
					path='/production/create-instraction'
					element={<PickGuidePageC />}
				/>
				<Route path='/production/gant' element={<GantProductionPage />} />

				<Route path='/supply' element={<GantPage />} />
				<Route path='/supply/gant' element={<GantPage />} />

				<Route path='/filtration' element={<GantPage />} />
				<Route path='/filtration/gant' element={<GantPage />} />

				<Route path='/settings' element={<GantSettings />} />
				<Route path='/create' element={<GantPage />} />
				<Route path='/create' element={<GantPage />} />
			</Routes>
		</>
	)
}

export default observer(GantSkelet)
