import { observer } from 'mobx-react-lite'
import { FC } from 'react'

const SectionSkelet: FC<{ children: JSX.Element | React.ReactNode }> = ({
	children
}) => {
	return (
		<section className={`rounded-tl-xl w-full p-3`}>
			<div className='content'>{children}</div>
		</section>
	)
}

export default observer(SectionSkelet)
