import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { getVoiceBase64 } from "../../API/TYCollectGuides";

interface IVoiceServiceProps{
  voice: string;
  start: boolean;
}

const VoiceService :FC<IVoiceServiceProps> = ({voice, start}) => {
  const [text, setText] = useState<string>('')
  useEffect(() => {
    (async () => {
      let data = await getVoiceBase64(voice)
      setText(data)
    })()
  }, [start])

  return (
    <audio autoPlay src={text}></audio>
  )
}

export default observer(VoiceService);