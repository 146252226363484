import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import st from './Orders.module.scss'
import Orders from '../Order/OrdersList'
import { Language } from '../../utils/langSwitch'
import { dict } from './TranslationList'

const t = Language.translateWithDictionary(dict)
const SelectOrdersCollected: FC = () => {
	return (
		<>
			<div className={`${st.wrapper} dark:bg-slate-800 dark:text-white`}>
				<div className={st.header}>
					<h3 className={st.active}>{t('Активные заказы')}</h3>
					<div>
						<Orders state={0} />
					</div>
				</div>

				<div className={st.header}>
					<h3 className={st.active}>{t('История заказов')}</h3>
					<div className='mt-3'>
						<Orders state={1} />
					</div>
				</div>
			</div>
		</>
	)
}
export default observer(SelectOrdersCollected)
