import { FC } from 'react'
import st from '../PickOrderPage.module.scss'
import { NavLink } from 'react-router-dom'
import { IGuide } from '../../../types/tycollectguides'

const OrderPart: FC<{ orders: IGuide[] }> = ({ orders }) => {
	return (
		<div className={st.wrapplist}>
			{orders.map(e => (
				<OrderItem e={e} key={e.id} />
			))}
		</div>
	)
}
export default OrderPart

const OrderItem: FC<{e: IGuide}> = ({ e }) => {
  const photo = 'data:image/png;base64, ' + e?.GuideImage
	return (
		<div className={st.border}>
			<NavLink 
        key={e.id} 
        to={`/guidecollect/${e.id}`} 
        className={st.link}>
				<div className={st.order}>
					<div className={st.img}>
						<img 
              src={photo} 
              alt='' />
					</div>
					<div className={st.name}>{e.GuideName}</div>
					<div className={st.desc}>{e.GuideDescr}</div>
				</div>
			</NavLink>
		</div>
	)
}
