import { toJS } from "mobx";
import { $authHost } from ".";
import Fastomat from "../store/Fastomat";
import FastomatClient from "../store/Fastomat";
import { IFIconsAPI } from "../types/fastomat";
import { IFastomatAPI } from "../types/fastomatWorker";
import { errorHandler } from "./index";

export const getOrders = async () => {
  try {
    let { data } = await $authHost.get<IFastomatAPI[]>(
      "api/fastomatworker/?client=True"
    );
    data.sort(function (a, b) {
      return (
        new Date(Date.parse(b.statechangetime)).getTime() -
        new Date(Date.parse(a.statechangetime)).getTime()
      );
    });
    Fastomat.orders = data;
    return data;
  } catch (e) {
    await errorHandler(e, () => getOrders());
  }
};

export const getIcons = async () => {
  try {
    let { data } = await $authHost.get<IFIconsAPI[]>("api/getorderstatus");
    data.sort((a, b) => {
      return a.id - b.id;
    });
    Fastomat.icons = data;
    return data;
  } catch (e) {
    await errorHandler(e, () => getIcons());
  }
};

export const longpollingFrontend = async () => {
  // let { data } = await $authHost.get<IFastomatAPI[]>("api/fastomatworker/?client=True");
  // await $authHost.get<IFastomatAPI[]>("api/checkstates");
  // data.sort(function (a, b) {
  //   return (
  //     new Date(Date.parse(b.statechangetime)).getTime() -
  //     new Date(Date.parse(a.statechangetime)).getTime()
  //   );
  // });
  // if (JSON.stringify(data) === JSON.stringify(toJS(FastomatClient.orders))) {
  //   console.log("data equal");
  //   return;
  // } else {
  //   let added = [];
  //   let deleted = [];
  //   let updated = [];
  //   if (data.length !== FastomatClient .orders.length) {
  //   }
  //   for (let i = 0; i < data.length; i++) {
  //     // Берем id текущенго заказа
  //     let id = data[i].id;
  //     // e = element
  //     // ищем такой в ткущем стейте заказ с таким же id
  //     let order = FastomatClient.orders.find((e) => e.id === id);
  //     if (!order) {
  //       //если такой заказ не найден
  //       //то он удален
  //       deleted.push(data[i]);
  //     } else {
  //       // проверяем сходяться ли ключи у заказов
  //       for (const property in data[i]) {
  //         console.log();
  //         // @ts-ignore
  //         if (order[`${property}`] === data[i][property]) {
  //         } else {
  //           if (`${property}` === "orderstate") {
  //             if (!(order.orderstate === data[i].orderstate)) {
  //               // если стейт разный
  //               updated.push(data[i]);
  //               data[i].isCheck = true;
  //               setTimeout(() => {
  //                 delete data[i].isCheck;
  //               }, 1000);
  //               break;
  //             } else {
  //               break;
  //             }
  //           }
  //           if (`${property}` === "windows") {
  //             if (data[i].windows.length !== order.windows.length) {
  //               // если кол-во окон не совпадет с сервером
  //               updated.push(data[i]);
  //               data[i].isCheck = true;
  //               setTimeout(() => {
  //                 delete data[i].isCheck;
  //               }, 1000);
  //               break;
  //             }
  //             for (let j = 0; j < order.windows.length; j++) {
  //               if (order.windows[j] === data[i].windows[j]) {
  //                 // с окнами все ок
  //               } else {
  //                 // окна не совпали
  //                 updated.push(data[i]);
  //                 data[i].isCheck = true;
  //                 setTimeout(() => {
  //                   delete data[i].isCheck;
  //                 }, 1000);
  //                 break;
  //               }
  //               // проверка всех окон
  //             }
  //             break;
  //           }
  //           updated.push(data[i]);
  //           data[i].isCheck = true;
  //           setTimeout(() => {
  //             delete data[i].isCheck;
  //           }, 1000);
  //           break;
  //         }
  //       }
  //     }
  //   }
  //   FastomatClient.orders = data;
  //   return updated;
  // }
};
