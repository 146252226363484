import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { getAllNeededData } from '../../API/TYCollectGuides'
import TYCollectGuides from '../../store/TYCollectGuides'
import CollectProducts from './CollectProducts'

const CollectProductsС: FC = () => {
	const navigate = useNavigate()
	const { id, method } = useParams()
	if (typeof window !== 'undefined') {
		injectStyle()
	}

	useEffect(() => {
		if (!TYCollectGuides.isFetched && id) {
			getAllNeededData()
		}
	}, [])

	useEffect(() => {
		if (!method && id) {
			navigate(`/order/${id}`)
		}
		if (!method && !id) {
			navigate(`/orders`)
		}
		if (method && !id) {
			navigate(`/orders`)
		}
		if (isNaN(Number(method)) || Number(method) > 2) {
			navigate(`/orders`)
		}
	}, [id, method])

	return <CollectProducts />
}

export default observer(CollectProductsС)
