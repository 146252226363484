import { $authHost } from '@/API'
import { useMount } from '@/hooks/useMount'
import Categories from '@/store/Categories/Categories'
import { ICategories } from '@/types/categoryChange'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { useNavigate } from 'react-router-dom'

const CreateCategoryPage = () => {
	const [category, setCategory] = useState<ICategories>({
		categoryname: '',
		categorydescr: '',
		parent_id: null,
		sortorder: null,
		photo: null,
		PhotoFetchStage: 'waiting',
		PhotoFileType: null
	} as ICategories)
	useMount(() => {
		Categories.getCategories()
	})

	const save = async () => {
		const parentid = Categories.CategoriesList.find(
			cat => cat.categoryname === parentCategory
		)
		const productcategory = new FormData()
		productcategory.append('categoryname', `${category.categoryname}`)
		productcategory.append('categorydescr', `${category.categorydescr}`)
		if (parentid) {
			productcategory.append('parent_id', `${parentid.id}`)
		}
		if (file) {
			productcategory.append('photo', file)
		}
		const newCategory = await $authHost.post<ICategories>(
			`api/productcategory/`,
			productcategory
		)
		//@ts-ignore
		Categories.CategoriesList.push(newCategory)
		navigate('/admin/category')
	}

	const [parentCategory, setParentCategory] = useState('Нет')
	const [file, setFile] = useState(null)
	const handleChange = (file: any) => {
		setFile(file)
	}

	const navigate = useNavigate()
	return (
		<div className='container mx-auto'>
			<div>
				<div className='mt-8'>
					<div className='mx-auto w-2/3 text-center'>
						<h2 className='text-base font-semibold leading-7 text-gray-900'>
							Создание категории
						</h2>
						<p className='mt-1 text-sm leading-6 text-gray-600'>
							Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ducimus
							reiciendis nobis recusandae sint soluta. Eum deleniti accusantium
							repellendus amet atque obcaecati eaque, itaque iusto quasi aliquid
							quaerat blanditiis laborum corporis.
						</p>
					</div>

					<div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
						<div className='sm:col-span-3'>
							<label className='block text-sm font-medium leading-6 text-gray-900'>
								Название
							</label>
							<div className='mt-2'>
								<input
									type='text'
									onChange={e => {
										setCategory(prev => {
											return {
												...prev,
												categoryname: e.target.value
											}
										})
									}}
									value={category.categoryname}
									name='first-name'
									id='first-name'
									className='block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
								/>
							</div>
						</div>

						<div className='sm:col-span-3'>
							<label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
								Выберите родительскую категорию
							</label>
							<select
								value={parentCategory}
								onChange={e => setParentCategory(e.target.value)}
								id='countries'
								className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
								<option selected>Нет</option>
								{Categories.CategoriesList.map(category => {
									return (
										<option value={category.categoryname}>
											{category.categoryname}
										</option>
									)
								})}
							</select>
						</div>
					</div>
				</div>
				<div className='space-y-12'>
					<div className='border-b border-gray-900/10 pb-12'>
						<div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
							<div className='col-span-full'>
								<label className='block text-sm font-medium leading-6 text-gray-900'>
									Описание
								</label>
								<div className='mt-2'>
									<textarea
										id='about'
										name='about'
										onChange={e => {
											setCategory(prev => {
												return {
													...prev,
													categorydescr: e.target.value
												}
											})
										}}
										value={category.categorydescr}
										className='block pl-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'></textarea>
								</div>
								<p className='mt-3 text-sm leading-6 text-gray-600'>
									Напишите пару слов о товаре
								</p>
							</div>

							<div className='col-span-full'>
								<label className='block text-sm font-medium leading-6 text-gray-900 mb-4'>
									Фотография товара
								</label>
								<div className=' flex justify-between'>
									<FileUploader
										hoverTitle={''}
										handleChange={handleChange}
										label={'Загрузите или перетащите файл до 10 Mb'}
										types={['JPG', 'PNG', 'GIF', 'SVG']}
										maxSize={10}
									/>
									<img
										className='w-full max-w-[300px] text-center'
										src={(() => {
											if (!file && category.photo) return category.photo
											if (!file) return
											return URL.createObjectURL(file)
										})()}
										alt='Upload the photo'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='mt-6 flex items-center justify-end gap-x-6'>
					<button
						onClick={() => navigate('/admin/category')}
						type='button'
						className='text-sm font-semibold leading-6 text-gray-900'>
						Отменить
					</button>
					<button
						onClick={save}
						className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
						Сохранить
					</button>
				</div>
			</div>
		</div>
	)
}

export default observer(CreateCategoryPage)
