import { observer } from "mobx-react-lite";
import { FC } from "react";
import { stages } from "../constanst/stages";
import st from "../EditGuides.module.scss";
import StageHandler from "../store/StageHandler";

const Stage: FC = () => {
  return (
    <div className={st.modalProgress}>
      <div className={st.processWrapper}>
        <div id={st.progressBarContainer}>
          <ul>
            {stages.map((stage, index) => (
              <li
                className={`${
                  StageHandler.stage === index + 1 ? st.active : null
                }`}
              >
                <div className={st.stepInner}>{stage.name}</div>
              </li>
            ))}
          </ul>
          <div id={st.line}>
            <div style={{width: `${((100 / (stages.length-1)) * (StageHandler.stage-1)) + 4}%`}} id={st.lineProgress}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default observer(Stage);
