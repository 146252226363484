import { notification } from '@/utils/notification'
import { FC, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { useUnmount } from '@/hooks/useUnmount'
import { Language } from '@/utils/langSwitch'
import { observer } from 'mobx-react-lite'
import TYPicking from '@/store/TYPicking'
import { dict } from './TranslationList'
import WatchSocket from './WatchSocket'
import BtnHandler from '../btnHandler'
import Products from './Products'
import Popup from './Popup'

// Переводчик
const t = Language.translateWithDictionary(dict)

const ProductsC: FC = () => {
	const [value, setValue] = useState<string>('')
	useUnmount(() => {
		TYPicking.Products = []
	})
	useEffect(() => {
		if (!value) return
		// Поиск QR кода
		const selected = TYPicking.Products.findIndex(p => p.BarCode === value)
		if (selected !== -1) {
			TYPicking.idxSelectedProduct = selected
			TYPicking.collect()
			notification({ text: t('Товар найден'), type: 'success' })
		} else {
			notification({ text: t('Товар не найден'), type: 'error' })
		}
		setValue('')
	}, [value])

	return (
		<WatchSocket>
			<Popup />
			<Products />
			<BtnHandler setValue={setValue} />
			<ToastContainer />
		</WatchSocket>
	)
}
export default observer(ProductsC)
