import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { $authHost } from "../../API";
import { IProducts } from "../../types/TYPicking";
import WSCollector from "./WSCollector";
import { cutMaxTextLength } from "../../utils/maxText";
import SuccessImg from "../../assets/success-svgrepo-com.svg";
import { getLight } from "../../API/TYPickingAPI";

const WSCollect: FC = () => {
  useEffect(() => {
    (async () => {
      if (!WSCollector.order_id) return;
      if (WSCollector.orderFetch) return;
      WSCollector.orderFetch = true;
      const { data } = await $authHost.get<IProducts[]>(
        `api/orderproductview/?id=${WSCollector.order_id}`
      );
      WSCollector.orderFetch = false;
      WSCollector.products = data;
      WSCollector.sendAllDisplayes();
    })();
  }, [WSCollector.order_id]);

  useEffect(() => WSCollector.connect(), []);

  return (
    <>
      <div className="w-full h-screen">
        <div className="container mx-auto w-full h-full">
          <div className="text-center">
            <p className="text-6xl py-2 font-light">Заказ</p>
            <p className="text-[18rem] my-[-120px] mt-[-80px] font-bold lg:text-[32rem] lg:my-[-190px] lg:mt-[-130px]">
              {WSCollector.order_id || "..."}
            </p>
          </div>
          <div className="text-center w-full overflow-auto">
            {WSCollector.products.map((product, idx) => {
              return (
                <div
                  className={`flex w-full flex-1 justify-center items-center gap-5 ${idx === WSCollector.selected && "bg-yellow-500 text-white"}`}
                >
                  <p className="text-xl lg:text-2xl lg:py-5 py-4 w-full hover:bg-green-400 hover:text-white cursor-pointer rounded false">
                    {cutMaxTextLength(product.ProductName, 30)}
                  </p>
                  {product.PickState_id === 1 ? (
                    <img className="w-8 h-8" src={SuccessImg} alt="" />
                  ) : null}
                </div>
              );
            })}
            <div className="h-96"></div>
            <div className="h-24"></div>
          </div>
        </div>
        <div className="fixed bottom-1 w-full text-center">
          <div className="flex gap-16 bg-white justify-center cursor-pointer border-2 py-7 my-6 text-5xl w-full">
            <div className="flex flex-col">
              <p>Стеллаж</p>
              <p>
                {WSCollector.selected && WSCollector.products.length
                  ? WSCollector.products[WSCollector.selected]?.positions[0]
                      .rack
                  : "..."}
              </p>
            </div>
            <div className="flex flex-col">
              <p>Ярус</p>
              <p>
                {WSCollector.selected && WSCollector.products.length
                  ? WSCollector.products[WSCollector.selected]?.positions[0]
                      .rackrow
                  : "..."}
              </p>
            </div>
            <div className="flex flex-col">
              <p>Колонка</p>
              <p>
                {WSCollector.selected && WSCollector.products.length
                  ? WSCollector.products[WSCollector.selected]?.positions[0]
                      .rackcol
                  : "..."}
              </p>
            </div>
          </div>
          {/* <div className="bg-green-500 hover:bg-green-700 cursor-pointer text-white py-4 text-4xl w-full">
            <p>Собрал</p>
          </div> */}
          <div 
          onClick={() => {
            if(!WSCollector.selected) return
            getLight({
              rack_id:WSCollector.products[WSCollector.selected]?.positions[0].rack,
              rackcol: WSCollector.products[WSCollector.selected]?.positions[0].rackcol,
              rackrow: WSCollector.products[WSCollector.selected]?.positions[0].rackrow
              
            })
          }}
          className="bg-yellow-400 hover:bg-yellow-700 cursor-pointer text-black my-2 py-7 text-6xl w-full">
            <p>Подсветка</p>
          </div>
          <NavLink to={"/"}>
            <div className="bg-red-500 hover:bg-red-700 cursor-pointer text-white py-7 text-6xl w-full">
              <p>Выйти</p>
            </div>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default observer(WSCollect);
