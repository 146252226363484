import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Стеллажи",
    eng: "Shelving",
  },
  {
    ru: "Параметры стеллажа",
    eng: "Rack Parameters",
  },
  {
    ru: "Название",
    eng: "Title",
  },
  {
    ru: "Поворот",
    eng: "Turn",
  },
  {
    ru: "Модель",
    eng: "Model",
  },
  {
    ru: "Координата X",
    eng: "X coordinate",
  },
  {
    ru: "Координата Y",
    eng: "Y coordinate",
  },
  {
    ru: "Device ID",
    eng: "Device ID",
  },
  {
    ru: "Отменить",
    eng: "Cancel",
  },
  {
    ru: "Сохранить",
    eng: "Save",
  },
];
