import { observer } from "mobx-react-lite";
import { FC, useRef } from "react";
import user from "../../store/user";
import { ReactComponent as UserImg } from "../../assets/user.svg";
import { Ilogin } from "../../types/logintype";
import { useNavigate } from "react-router-dom";
import { MAIN_ROUTE } from "../../utils/consts";
import SpinnerBtn from "../../components/UI/SpinnerBtn";
import { updateToken } from "../../API/userAPI";
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";
import Theme from "../../store/Theme";
import { dict } from "./TranslationList";
import { Language } from "../../utils/langSwitch";
import { useMount } from "@/hooks/useMount";

// Переводчик
const t = Language.translateWithDictionary(dict);

const Login: FC<Ilogin> = observer(({ login, password, auth }) => {
  const section1 = useRef<HTMLInputElement>(null);
  const section2 = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  useMount(() => {
    (async () => {
      user.loginedUsers = (await user.getLogUsersList()) || [];
    })();
  });

  if (typeof window !== "undefined") {
    injectStyle();
  }
  const handleClick = () => {
    section2.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickToStart = () => {
    section1.current?.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      navigate(MAIN_ROUTE);
    }, 700);
  };

  return (
    <div className="scroll-smooth">
      <section
        ref={section1}
        className="relative bg-gradient-to-r w-full from-indigo-300 via-purple-300 to-pink-300 dark:from-indigo-700 dark:via-purple-700 dark:to-pink-700 flex justify-center items-center h-screen leading-tight"
      >
        <div className="text-gray-900 dark:text-white w-full">
          <div className="flex justify-center items-center text-4xl sm:text-7xl mb-6">
            <span className="text-fuchsia-500 dark:text-fuchsia-600">T</span>
            <span className="text-blue-400 dark:text-blue-600">Y</span>
            <span>&nbsp;-&nbsp;</span>
            <p>TakeYours</p>
          </div>
          <form className="md:w-full max-w-3xl md:mx-auto mx-2 bg-gray-50 border border-gray-2	00 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700 p-2 sm:p-8">
            <label className="block text-2xl font-medium text-gray-900 dark:text-white m-4 md:text-4xl md:m-9 sm:m-6 sm:text-3xl ml-6">
              {t("Логин")}
            </label>
            <input
              type="text"
              name="login"
              id="login"
              value={login.value}
              onChange={login.onChange}
              placeholder="TakeYours@dvg-project.com"
              className="bg-gray-50 border m-5 mt-1 md:m-9 border-gray-300 text-gray-900 sm:text-xl md:text-2xl lg:text-4xl rounded-lg focus:ring-primary-600 focus:border-primary-600 block mx-auto w-11/12 p-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 sm:mt-6"
            />
            <label className="block text-2xl font-medium text-gray-900 dark:text-white m-4 md:text-4xl md:m-9 sm:m-6 sm:text-3xl ml-6">
              {t("Пароль")}
            </label>
            <input
              type="password"
              name="password"
              id="password"
              value={password.value}
              onChange={password.onChange}
              placeholder="••••••••••••••••"
              className="bg-gray-50 border m-5 mt-1 md:m-9 border-gray-300 text-gray-900 sm:text-xl md:text-2xl lg:text-4xl rounded-lg focus:ring-primary-600 focus:border-primary-600 block mx-auto w-11/12 p-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 sm:mt-6"
            />
            <div className="items-center flex sm:hidden ml-8">
              <input
                type="checkbox"
                id="hs-medium-switch"
                className="relative shrink-0 w-[3.25rem] h-7 bg-gray-100 checked:bg-none checked:bg-blue-600 rounded-full cursor-pointer transition-colors ease-in-out duration-200 border border-transparent ring-1 ring-transparent focus:border-blue-600 focus:ring-blue-600 ring-offset-white focus:outline-none appearance-none dark:bg-gray-700 dark:checked:bg-blue-600 dark:focus:ring-offset-gray-800
			before:inline-block before:w-6 before:h-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400 dark:checked:before:bg-blue-200"
              />
              <label
                htmlFor="hs-medium-switch"
                className="text-lg sm:text-xl md:text-3xl text-gray-900 ml-3 dark:text-gray-300"
              >
                {t("Запомнить меня")}
              </label>
            </div>
            <div className="items-center hidden sm:flex ml-8">
              <input
                checked={user.rememberMe}
                onChange={(e) => {
                  user.rememberMe = e.target.checked;
                  console.log(user.rememberMe);
                }}
                type="checkbox"
                id="hs-large-switch"
                className="relative shrink-0 w-[4.25rem] h-9 bg-gray-100 checked:bg-none checked:bg-blue-600 rounded-full cursor-pointer transition-colors ease-in-out duration-200 border border-transparent ring-1 ring-transparent focus:border-blue-600 focus:ring-blue-600 ring-offset-white focus:outline-none appearance-none dark:bg-gray-700 dark:checked:bg-blue-600 dark:focus:ring-offset-gray-800
			before:inline-block before:w-8 before:h-8 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400 dark:checked:before:bg-blue-200"
              />
              <label
                htmlFor="hs-large-switch"
                className="text-lg sm:text-xl md:text-3xl text-gray-900 ml-3 dark:text-gray-300"
              >
                {t("Запомнить меня")}
              </label>
            </div>

            <div
              onClick={(e) => auth(e)}
              className={`transition select-none flex justify-center w-11/12 mx-auto my-8 cursor-pointer text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-xl sm:text-2xl md:text-4xl px-5 py-3.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
                user.fetchStatus === "error" &&
                "bg-red-500 hover:bg-red-400 focus:ring-white  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-gray-500 animate-shake"
              }`}
            >
              {
                {
                  none: <>{t("Авторизация")}</>,
                  fetch: <SpinnerBtn />,
                  error: <>{t("Неверные данные!")}</>,
                  success: <>{t("Успешно")}!</>,
                }[user.fetchStatus]
              }
            </div>
          </form>
        </div>
        <div
          onClick={handleClick}
          className="animate-bounce absolute cursor-pointer bg-white dark:bg-slate-800 p-2 w-8 md:w-12 h-8 md:h-12 ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center bottom-10 left-1/2"
        >
          <svg
            className="w-6 h-6 text-violet-500"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
          </svg>
        </div>
      </section>
      <section
        ref={section2}
        className="relative bg-gradient-to-r w-full from-indigo-300 via-purple-300 to-pink-300 dark:from-indigo-700 dark:via-purple-700 dark:to-pink-700 flex justify-center items-center h-screen leading-tight"
      >
        <div className="flex w-full justify-center items-center text-4xl sm:text-7xl mb-6">
          <div className="px-4 w-full max-w-3xl">
            <div className="w-full bg-gray-50 border border-gray-2 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700 p-2 sm:p-8">
              <ul className="p-1">
                {user.loginedUsers.map((e) => (
                  <li
                    onClick={() => {
                      updateToken(e.refresh);
                    }}
                    key={e.id}
                    className={`flex justify-between ${
                      user.loginStatus === "error" && "animate-shake"
                    } items-center px-2 my-2 border-2 border-gray-50 dark:border-gray-800 dark:hover:border-white hover:border-gray-800 cursor-pointer py-4`}
                  >
                    <div className="relative">
                      <UserImg
                        fill={Theme.isDarkTheme ? "white" : "black"}
                        className="h-14 w-14 md:h-32 md:w-32 rounded-full border-[1px] p-1 border-black dark:border-white"
                      />
                      <div
                        title={`${
                          user.isOnline(e?.last_login)
                            ? t("Пользователь онлайн")
                            : t("Не в сети")
                        }`}
                        className={`absolute rounded-full w-3 md:w-5 h-3 md:h-5 bottom-1 md:bottom-3 right-1 md:right-3 ${
                          user.isOnline(e?.last_login)
                            ? "bg-green-500"
                            : "bg-red-500"
                        }`}
                      ></div>
                    </div>
                    <div className="ml-3 overflow-hidden">
                      <p className="text-lg md:text-4xl font-medium text-slate-900 dark:text-white">
                        {e.username}
                      </p>
                      <p className="text-xs md:text-3xl md:mt-3 text-slate-500 truncate">
                        {e.email}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={() => user.removeLogUsersList(e.id)}
                      className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-xs sm:text-lg md:text-xl px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                    >
                      {t("Удалить")}
                    </button>
                  </li>
                ))}
              </ul>
              <div
                onClick={handleClickToStart}
                className="focus:outline-none cursor-pointer w-full block text-center text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-xs md:text-3xl px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
              >
                {t("Выйти")}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </div>
  );
});
export default Login;
