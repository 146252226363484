import Warehouse from '@/store/Warehouse/Warehouse'
import Racks from '@/store/Warehouse/Racks'
import WarehouseMapStore from './Warehouse.map'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef } from 'react'
import WhZone from '@/store/Warehouse/WhZone'
import st from './Warehouse.module.scss'
import { IWarehouseFetchProps, IWarehouseMapProps } from './WarehousMap.types'

const fecthWarehouse = async ({
	id,
	maxWidth,
	maxHeight
}: IWarehouseFetchProps) => {
	await Warehouse.fetchWarehouse()
	await WhZone.fetchWHZone()
	await Racks.fetchRacks()
	await Racks.fetchRackModels()
	const currentWarehouse = Warehouse.warehousesList.find(
		store => store.id === id
	)
	if (!currentWarehouse) return null
	const resualt = WarehouseMapStore.aspestCalc({
		maxWidth,
		maxHeight,
		realHeight: currentWarehouse.length,
		realWidth: currentWarehouse.width
	})
	return resualt
}

const WarehouseMap: FC<IWarehouseMapProps> = ({ id, width, height }) => {
	const mapCanvas = useRef<HTMLDivElement>(null)
	useEffect(() => {
		canvasUpdate()
	}, [])

	// получаем размеры
	const canvasUpdate = async () => {
		if (!mapCanvas?.current) return
		const maxHeight = width || mapCanvas.current.clientHeight
		const maxWidth = height || mapCanvas.current.clientWidth
		const sizes = await fecthWarehouse({ id, maxHeight, maxWidth })
		mapCanvas.current.style.width = `${sizes?.width}`
		mapCanvas.current.style.height = `${sizes?.height}`
	}

	return (
		<div className='flex justify-center flex-col items-center w-full h-full'>
			<p className='text-center'>Карта склада #{id}</p>
			<div
				ref={mapCanvas}
				style={{
					width,
					height
				}}
				className={`relative border border-purple-400 w-[500px] h-[500px]`}>
				{Racks.racks.map(rack => {
					if (rack.WH_id !== id) return null
					const currentModel = Racks.rackModels.find(
						model => model.id === rack.Model_id
					)
					let height = 0
					let width = 0
					if (currentModel) {
						width =
							currentModel.cellwidth *
							currentModel.colnumber *
							WarehouseMapStore.aspectRatio.x
						height = currentModel.celldepth * WarehouseMapStore.aspectRatio.y
					}
					return (
						<div
							key={rack.id}
							style={{
								width: `${width}px`,
								height: `${height}px`,
								top: `${
									WarehouseMapStore.aspectRatio.y * (rack.ycoord || 0)
								}px`,
								left: `${
									WarehouseMapStore.aspectRatio.x * (rack.xcoord || 0)
								}px`,
								rotate: `${rack.rotated}deg`,
								background: rack.flash ? 'red' : 'white'
							}}
							className={`absolute border border-black z-10 flex`}>
							{Array.from(Array(rack.ColNumber)).map(e => (
								<div className={`${st.deceased} w-full h-full`}></div>
							))}
						</div>
					)
				})}
				{WhZone.WHZoneList.map(zone => {
					if (zone.wh !== id || zone.invisible) return null
					return (
						<div
							key={zone.id}
							style={{
								width: `${zone.width * WarehouseMapStore.aspectRatio.x}px`,
								height: `${zone.length * WarehouseMapStore.aspectRatio.y}px`,
								top: `${zone.whzone_y * WarehouseMapStore.aspectRatio.y}px`,
								left: `${zone.whzone_x * WarehouseMapStore.aspectRatio.x}px`
							}}
							className='absolute border'>
							<p className='text-xs font-light text-gray-500 text-left'>
								{zone.zonename}
							</p>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default observer(WarehouseMap)
