import InputField from '@/components/UI/InputField'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import st from './CompanySettings.module.scss'
import Company from '@/store/Company/Company'
import { $authHost } from '@/API'

const CompanySettings: FC = () => {
	const [shortname, setShortname] = useState(`${Company.info?.shortname || ''}`)
	const [fullname, setFullname] = useState(`${Company.info?.fullname || ''}`)
	const [adress, setAdress] = useState(`${Company.info?.adress || ''}`)
	const [inn, setInn] = useState(`${Company.info?.inn || ''}`)
	const [kpp, setKpp] = useState(`${Company.info?.kpp || ''}`)
	const [website, setWebsite] = useState(`${Company.info?.website || ''}`)
	const [phone, setPhone] = useState(`${Company.info?.phone || ''}`)
	const [base64Logo, setBase64Logo] = useState(`${Company.info?.logo || null}`)
	const [logo, setLogo] = useState<File | null>(null)

	useEffect(() => {
		reset()
	}, [Company.info, Company.info?.shortname])

	const save = () => {
		const form = new FormData()
		form.append('shortname', shortname)
		form.append('fullname', fullname)
		form.append('adress', adress)
		form.append('inn', inn)
		form.append('kpp', kpp)
		form.append('website', website)
		form.append('phone', phone)
		if (logo) {
			form.append('logo', logo)
		}
		$authHost.put('api/user_company/', form)
	}

	const reset = () => {
		setShortname(Company.info?.shortname || '')
		setFullname(Company.info?.fullname || '')
		setAdress(Company.info?.adress || '')
		setInn(Company.info?.inn || '')
		setKpp(Company.info?.kpp || '')
		setWebsite(Company.info?.website || '')
		setPhone(Company.info?.phone || '')
		setBase64Logo(Company.info?.logo || '')
	}

	return (
		<div>
			<div className='grid grid-cols-2'>
				<div className='px-6 mt-2 flex w-full justify-center'>
					<div className={`${st.leftInfoHandler}`}>
						<div className={`${st.photo}`}>
							<img src={base64Logo} alt='logotype' />
						</div>
						<div className={`${st.photo} ${st.uploadWarning}`}>
							<input
								type='file'
								className={`${st.fileInput}`}
								onChange={e => {
									//@ts-ignore
									setBase64Logo(URL.createObjectURL(e.target.files[0]))
									//@ts-ignore
									setLogo(e.target.files[0])
								}}
							/>
							<p>Upload photo</p>
						</div>
						<div className='text-center text-white'>
							<p>{Company.info?.shortname}</p>
							<p>{Company.info?.fullname}</p>
							<p>{Company.info?.adress}</p>
							<p>{Company.info?.inn}</p>
							<p>{Company.info?.kpp}</p>
							<p>{Company.info?.website}</p>
							<p>{Company.info?.phone}</p>
						</div>
						<div className='flex gap-5'>
							<button className={`${st.btn} bg-blue-500`} onClick={save}>
								<p>Save</p>
							</button>
							<button className={`${st.btn} bg-red-500`} onClick={reset}>
								<p>Reset</p>
							</button>
						</div>
					</div>
				</div>
				<div>
					<InputField
						placeholder='Сокращенное название'
						value={shortname}
						onChange={setShortname}
					/>
					<textarea
						className={
							'block mt-2 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
						}
						value={fullname}
						onChange={e => setFullname(e.target.value)}
						placeholder='Полное название'
					/>
					<InputField
						placeholder='Адресс'
						value={adress}
						onChange={setAdress}
					/>
					<InputField placeholder='inn' value={inn} onChange={setInn} />
					<InputField placeholder='kpp' value={kpp} onChange={setKpp} />
					<InputField
						placeholder='website'
						value={website}
						onChange={setWebsite}
					/>
					<InputField placeholder='phone' value={phone} onChange={setPhone} />
				</div>
			</div>
		</div>
	)
}
export default observer(CompanySettings)
