import { observer } from "mobx-react-lite";
import { FC } from "react";
import NavHeaderC from "../../components/Navigation/NavHeaderC";
import EditGuides from "../../modules/EditGuides";
import TYCollectGuides from "../../store/TYCollectGuides";
import { NavLinksGuide } from "../../utils/collectNavLink";
import OrderPart from "./OrderPart/OrderPart";
import st from "./PickOrderPage.module.scss";
import { dict } from "./TranslationList";
import { Language } from "../../utils/langSwitch";

// Переводчик
const t = Language.translateWithDictionary(dict);

const PickGuidePage: FC = () => {
  const { Guides } = TYCollectGuides;
  return (
    <>
      <NavHeaderC links={NavLinksGuide} />
      {TYCollectGuides.open && <EditGuides />}
      <div className={st.wrapper}>
        <p>{t("Выбрать схему сборки")}:</p>
        <OrderPart orders={Guides} />
      </div>
    </>
  );
};

export default observer(PickGuidePage);
