import { observer } from "mobx-react-lite";
import st from "./StageInfo.module.scss";
import { FC, useState } from "react";
import arrowImg from "../../assets/arrow.svg";
import soundImg from "../../assets/sound.svg";
import VoiceBtn from "./VoiceBtn";
import { Language } from "../../utils/langSwitch";
import { dict } from "./TranslationList";

const t = Language.translateWithDictionary(dict);
interface IProps {
  GuideName: string;
  StageName: string;
  StageDescr: string;
  StageImage: string | null;
  handlePrev(): void;
  handleNext(): void;
}

const StageInfo: FC<IProps> = ({
  GuideName,
  StageName,
  StageDescr,
  StageImage,
  handlePrev,
  handleNext,
}) => {
  const [open, setOpen] = useState(false);
  const [playVoice, setPlayVoice] = useState<boolean>(false);
  return (
    <>
      <p style={{ textAlign: "center" }}>{t("СБОРКА ПО ИНСТРУКЦИИ")} {GuideName}</p>

      <div className={st.razdelenie}>
        <div>
          <div className={st.panel}>
            <div className={st.stage}>
              <div className={st.stage}>
                <p>{t("Этап сборки")}:</p>
              </div>
              <p className={st.stagename}>{StageName}</p>
            </div>
            <div className={st.arrowBtns}>
              <img
                src={arrowImg}
                onClick={() => handlePrev()}
                style={{ width: "25px", transform: "rotate(-90deg)" }}
                alt="arrow"
              />
              <img
                src={arrowImg}
                onClick={() => handleNext()}
                style={{ width: "25px", transform: "rotate(90deg)" }}
                alt="arrow"
              />
            </div>
          </div>
          <div className={st.desc}>
            <p>{StageDescr}</p>
            <img
              src={soundImg}
              onClick={() => setPlayVoice((prev) => !prev)}
              id={st.img}
            />
          </div>
        </div>
        <div>
          <div className={st.panel}>
            <p className={st.stage}>{t("Изображение")}</p>
          </div>
          <div className={st.desc}>
            <img
              onClick={() => setOpen((prev) => !prev)}
              className={`${st.image}`}
              src={`${StageImage}`}
              alt=""
            />
            {open && (
              <img
                onClick={() => setOpen((prev) => !prev)}
                className={`${st.scaleimg}`}
                src={`${StageImage}`}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
      <VoiceBtn voice={StageDescr} start={playVoice} />
    </>
  );
};

export default observer(StageInfo);
