/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { cancelStore, updateZone } from '../../API/wareHouseAPI'
import Warehouse from '../../store/Warehouse'
import { getBase64 } from '../../utils/getBase64'
import StoreZoneSettings from './StoreZoneSettings'
import WhZone from '@/store/Warehouse/WhZone'

const StoreZoneSettingsC: FC = () => {
	const [file, setFile] = useState()
	const [isFetchSave, setFetchSave] = useState(false)
	const [isFetchCancel, setFetchCancel] = useState(false)

	const { id } = useParams()
	useEffect(() => {
		if (!id) return
		let elem = Warehouse.store.find(e => e.whzone_id === Number(id))
		if (elem) {
			Warehouse.selectedZone = elem
		}
	}, [])

	const uploadFile = (val: any) => {
		console.log(getBase64(val.target.files[0]))
		setFile(val.target.files[0])
	}

	useEffect(() => {
		if (isFetchSave || isFetchCancel) {
			document.body.style.filter = 'brightness(0.85)'
		} else {
			document.body.style.filter = 'brightness(1)'
		}
	}, [isFetchSave, isFetchCancel])

	const save = async () => {
		if (!WhZone.WHZone) return
		let formData = new FormData()
		formData.append('id', `${id}`)
		formData.append('zonename', `${WhZone.WHZone?.zonename}`)
		formData.append('zonedescr', `${WhZone.WHZone?.zonedescr}`)
		formData.append('width', `${WhZone.WHZone?.width}`)
		formData.append('length', `${WhZone.WHZone?.length}`)
		formData.append('startx', `${WhZone.WHZone?.startx || 0}`)
		formData.append('starty', `${WhZone.WHZone?.starty || 0}`)
		// @ts-ignore
		if (file) {
			formData.append('background', file)
		}
		console.log(formData)
		setFetchSave(true)
		await updateZone(formData)
		setFetchSave(false)
	}

	const cancel = async () => {
		setFetchCancel(true)
		await cancelStore(Number(id))
		setFetchCancel(false)
	}
	return (
		<StoreZoneSettings
			cancel={cancel}
			isFetchSave={isFetchSave}
			isFetchCancel={isFetchCancel}
			save={save}
			uploadFile={uploadFile}
		/>
	)
}

export default observer(StoreZoneSettingsC)
