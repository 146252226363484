import { FC } from "react";
import { observer } from "mobx-react-lite";

interface IProps {
  removeFromBasket: () => void;
  addToBasket: () => void;
  basketcount?: number;
}

const ChangeBasketCount: FC<IProps> = ({
  removeFromBasket,
  addToBasket,
  basketcount,
}) => {
  return (
    <>
      <div className="flex justify-between items-center w-full">
        <div
          onClick={removeFromBasket}
          className="text-white w-full mt-2 select-none bg-red-400 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm md:text-2xl p-2.5  text-center  cursor-pointer"
        >
          <p>-</p>
        </div>
        <div className="h-full w-full flex justify-center items-center text-xl font-bold dark:text-white">
          <p>{basketcount}</p>
        </div>
        <div
          onClick={addToBasket}
          className="text-white w-full mt-2 select-none bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg  text-sm md:text-2xl p-2.5  text-center  cursor-pointer"
        >
          <p>+</p>
        </div>
      </div>
    </>
  );
};

export default observer(ChangeBasketCount);
