import GantTasks from '@/store/Gant/GantTasks'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'


const SearchInput = () => {
	const [isOpen, setIsOpen] = useState(false)
	return (
		<>
			<div className='flex relative'>
				<button
					onClick={() => setIsOpen(prev => !prev)}
					className={`flex-shrink-0 z-10 inline-flex items-center 
            py-2.5 px-4 text-sm font-medium text-center 
            text-gray-900 bg-gray-100 border 
            border-gray-300 rounded-l-lg hover:bg-gray-200 
            focus:ring-4 focus:outline-none focus:ring-gray-100 
            dark:bg-gray-700 dark:hover:bg-gray-600 
            dark:focus:ring-gray-700 dark:text-white 
            dark:border-gray-600`}
					type='button'>
					{GantTasks.sorttype}
					{/* arrow svg */}
				</button>
				<div
					id='dropdown'
					className={`z-10 top-11 ${
						!isOpen && 'hidden'
					} absolute bg-white divide-y 
          divide-gray-100 rounded-lg 
          shadow w-44 dark:bg-gray-700`}>
					<ul
						className={`py-2 text-sm text-gray-700 
              dark:text-gray-200`}>
						<li>
							<button
								onClick={() => {
									GantTasks.sorttype = 'Все параметры'
									setIsOpen(false)
								}}
								type='button'
								className={`inline-flex w-full px-4 
                py-2 hover:bg-gray-100 dark:hover:bg-gray-600 
                dark:hover:text-white`}>
								Все параметры
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									GantTasks.sorttype = 'Номер заказа'
									setIsOpen(false)
								}}
								type='button'
								className={`inline-flex w-full px-4 
                py-2 hover:bg-gray-100 dark:hover:bg-gray-600 
                dark:hover:text-white`}>
								Номер заказа
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									GantTasks.sorttype = 'Клиент'
									setIsOpen(false)
								}}
								type='button'
								className={`inline-flex w-full px-4 
                py-2 hover:bg-gray-100 dark:hover:bg-gray-600 
                dark:hover:text-white`}>
								Клиент
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									GantTasks.sorttype = 'Менеджер'
									setIsOpen(false)
								}}
								type='button'
								className={`inline-flex w-full px-4 
                py-2 hover:bg-gray-100 dark:hover:bg-gray-600 
                dark:hover:text-white`}>
								Менеджер
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									GantTasks.sorttype = 'Создан'
									setIsOpen(false)
								}}
								type='button'
								className={`inline-flex w-full px-4 
                py-2 hover:bg-gray-100 dark:hover:bg-gray-600 
                dark:hover:text-white`}>
								Создан
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									GantTasks.sorttype = 'Производство'
									setIsOpen(false)
								}}
								type='button'
								className={`inline-flex w-full px-4 
                py-2 hover:bg-gray-100 dark:hover:bg-gray-600 
                dark:hover:text-white`}>
								Производство
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									GantTasks.sorttype = 'Отгрузка'
									setIsOpen(false)
								}}
								type='button'
								className={`inline-flex w-full px-4 
                py-2 hover:bg-gray-100 dark:hover:bg-gray-600 
                dark:hover:text-white`}>
								Отгрузка
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									GantTasks.sorttype = 'Приоритет'
									setIsOpen(false)
								}}
								type='button'
								className={`inline-flex w-full px-4 
                py-2 hover:bg-gray-100 dark:hover:bg-gray-600 
                dark:hover:text-white`}>
								Приоритет
							</button>
						</li>
					</ul>
				</div>
				<div className='relative w-full'>
					<input
						onChange={e =>
							GantTasks.filterTasks(e.target.value)
						}
						type='search'
						id='search-dropdown'
						className={`block p-2.5 w-full z-20 text-sm 
              text-gray-900 bg-gray-50 rounded-r-lg 
              border-l-gray-50 border-l-2 border border-gray-300 
              focus:ring-blue-500 focus:border-blue-500 
              dark:bg-gray-700 dark:border-l-gray-700  
              dark:border-gray-600 dark:placeholder-gray-400 
              dark:text-white dark:focus:border-blue-500`}
						placeholder='Фильтровать по значению параметра'
						required
					/>
					<button
						type='submit'
						className={`absolute top-0 right-0 p-2.5 text-sm 
              font-medium h-full text-white bg-blue-700 
              rounded-r-lg border border-blue-700 
              hover:bg-blue-800 focus:ring-4 focus:outline-none 
              focus:ring-blue-300 dark:bg-blue-600 
              dark:hover:bg-blue-700 dark:focus:ring-blue-800`}>
						{/* лупа svg */}
						Поиск
					</button>
				</div>
			</div>
		</>
	)
}

export default observer(SearchInput)
