import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import MainItemsStore from './MainItems.store'
import { dict } from './TranslationList'
import { Language } from '@/utils/langSwitch'
import { FC } from 'react'
// Переводчик
const t = Language.translateWithDictionary(dict)

const MainPageItem: FC = () => {
	return (
		<>
			{MainItemsStore.pages.map(item => {
				if (!item.role) return null
				return (
					<NavLink
					key={item.id}
						className={`w-44 h-48 shadow-ty-shadow 
										md:w-72 md:h-80 text-center border-x-2 
										transition-all duration-500 text-white 
										border-y-2 flex justify-center
										items-center hover:bg-white 
										hover:text-black rounded-md`}
						to={item.url}>
						<div
							className={`flex justify-center 
                    						flex-col items-center p-10`}
							onClick={item.onClick}>
							<img 
								className='h-20 md:h-40' 
								src={item.img} 
								alt={item.name}
							/>
							<p
								className={`font-semibold mt-6 
                  text-base md:text-3xl`}>
								{t(item.name)}
							</p>
						</div>
					</NavLink>
				)
			})}
		</>
	)
}

export default observer(MainPageItem)
