import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import Layout from './Layout'
import { Route, Routes } from 'react-router-dom'
import CompanySettings from '../CompanySettings/CompanySettings'

const SettingsPage: FC = () => {
	return (
		<Layout>
			<Routes>
				<Route path='/' element={<CompanySettings />} />
				<Route path='/collect' element={<></>} />
				<Route path='/fastomat' element={<></>} />
			</Routes>
		</Layout>
	)
}

export default observer(SettingsPage)
