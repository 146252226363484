import { FC } from 'react'
import { IBreadcrumbsProps } from '../Breadcrumbs.interface'
import { observer } from 'mobx-react-lite'
import { ReactComponent as ArrowImg } from '@/assets/breadcrums.svg'

const LastBreadCrumb: FC<{ link: IBreadcrumbsProps }> = ({ link }) => {
	return (
		<li aria-current='page'>
			<div className='flex items-center'>
				<ArrowImg className='w-3 h-3 mx-1' />
				<span
					className={`ml-1 text-sm font-medium 
				text-gray-500 md:ml-2 dark:text-gray-400`}>
					{link.name}
				</span>
			</div>
		</li>
	)
}

export default observer(LastBreadCrumb)
