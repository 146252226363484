import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import TabsAdmSettings from '@/components/TabsAdmSettings/TabsAdmSettings'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode } from 'react'
import { mainLinks } from './breadcrumbs-data'

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<div className='container mx-auto'>
			<Breadcrumbs links={mainLinks} />
			<TabsAdmSettings />
			{children}
		</div>
	)
}
export default observer(Layout)
