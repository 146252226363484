import { $authHost } from '@/API'
import InputField from '@/components/UI/InputField'
import WarehouseMap from '@/components/WarehouseMap/WarehouseMap'
import Products from '@/store/Products'
import Racks from '@/store/Warehouse/Racks'
import { IRackCell } from '@/types/Warehouse'
import { IProduct } from '@/types/createorder'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import Barcode from 'react-barcode'
import { useNavigate, useParams } from 'react-router-dom'

type IMatrix = any[][][]

const generateMatrix = (col: number, row: number) => {
	const matrix: IMatrix = []
	for (let j = 0; j < row; j++) {
		matrix.push([])
	}
	matrix.forEach((e, idx) => {
		for (let i = 0; i < col; i++) {
			e.push([])
		}
	})
	return matrix
}

const PushProductPage: FC = () => {
	const navigate = useNavigate()
	const [product, setProduct] = useState<IProduct | null>(null)
	const [rackMatrix, setRackMatrix] = useState<any[][][]>([])
	const [rackCells, setRackCells] = useState<IRackCell[]>([])
	// ID продукта
	const [rackid, setRackId] = useState(0)
	const rackIdHandler = (str: string) => {
		console.log(str)
		setRackId(Number(str))
		Racks.fleshRack(Number(str))
	}
	const [expireDate, setExpireDate] = useState('')
	const [selectedRow, setSelectedRow] = useState<number | null>(null)
	const selectRowHandler = (value: string | number) => {
		const currentValue = Number(value)
		if (!currentValue) return
		if (rackMatrix.length < currentValue) return
		setSelectedRow(currentValue)
	}
	const [selectedCol, setSelectedCol] = useState<number | null>(null)
	const selectColHandler = (value: string | number) => {
		const currentValue = Number(value)
		if (!currentValue) return
		if (rackMatrix[0].length < currentValue) return
		setSelectedCol(currentValue)
	}
	const [totalCount, setTotalCount] = useState(1)
	const countHandler = (str: string) => {
		const currentCount = Number(str)
		if (!currentCount) return
		setTotalCount(currentCount)
	}

	function padTo2Digits(num: number) {
		return num.toString().padStart(2, '0')
	}

	// Срок годности
	const { id } = useParams()
	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = async () => {
		fetchProducts()
		await Racks.fetchRacks()
		setRackId(Racks.racks[0].id)
		Racks.fetchRackModels()

		const date = new Date()
		const str = [
			date.getFullYear() + 1,
			padTo2Digits(date.getMonth() + 1),
			padTo2Digits(date.getDate())
		].join('-')
		setExpireDate(str)
	}

	useEffect(() => {
		setRackMatrix([])
		setRackCells([])
		fetchRackCell(rackid)
	}, [rackid])

	const fetchProducts = async () => {
		const product = await Products.getProduct(Number(id))
		setProduct(product)
	}

	const fetchRackCell = async (rack_id: number) => {
		// получаем колонки стеллажа
		const { data } = await $authHost.get<IRackCell[]>(
			`api/rack_cell/?rack_id=${rack_id}`
		)
		setRackCells(data)
		const currentRack = Racks.racks.find(rack => rack.id === rackid)
		if (!currentRack) return
		const currentModel = Racks.rackModels.find(
			model => model.id === currentRack.Model_id
		)
		if (!currentModel) return
		setRackMatrix(
			generateMatrix(currentModel.colnumber, currentModel.rownumber)
		)
		return data
	}

	const save = async () => {
		await $authHost.post('api/productstore', {
			rackcol: selectedCol,
			rackrow: selectedRow,
			expiredate: expireDate,
			quant: totalCount,
			rack: rackid,
			product_id: id
		})
		navigate('/warehoue-products')
	}

	return (
		<>
			<div className='container mx-auto'>
				<div>
					<div className='mt-8'>
						<div className='mx-auto w-full flex text-center'>
							<div className='w-1/2'>
								<h2 className='text-base font-semibold leading-7 text-gray-900'>
									Добавление товара на склад
								</h2>
								<p className='text-light font-["Open_Sans"]'>
									{product?.productname}
								</p>
							</div>
							<div className='w-1/2 flex justify-center'>
								{product?.barcode && <Barcode value={product?.barcode} />}
							</div>
						</div>

						<div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
							<div className='sm:col-span-3'>
								<label className='block text-sm font-medium leading-6 text-gray-900'>
									Количество
								</label>
								<div className='mt-2'>
									<InputField value={totalCount} onChange={countHandler} />
								</div>
							</div>

							<div className='sm:col-span-3'>
								<label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
									Выберите стеллаж
								</label>
								<select
									onChange={e => rackIdHandler(e.target.value)}
									value={rackid}
									defaultValue={0}
									className='w-full p-2 mt-1 py-3 rounded-xl border border-slate-600 bg-gray-50'>
									<option value={0} className='w-full p-2.5'>
										Стеллаж не выбран
									</option>
									{Racks.racks.map(rack => (
										<option value={rack.id} className='w-full p-2.5'>
											{rack.RackName}
										</option>
									))}
								</select>
							</div>

							<div className='sm:col-span-3'>
								<label className='block text-sm font-medium leading-6 text-gray-900'>
									Срок годности
								</label>
								<div className='w-full text-center p-2 mt-1 py-3 rounded-xl border border-slate-600 bg-gray-50'>
									<input
										className='w-full'
										value={expireDate}
										onChange={date => setExpireDate(date.target.value)}
										type='date'
									/>
								</div>
							</div>
							<div className='sm:col-span-3 flex justify-between gap-4'>
								<div className='w-full'>
									<label className='block text-sm font-medium leading-6 text-gray-900'>
										Колонка
									</label>
									<InputField
										value={selectedCol || 'Не выбрано'}
										onChange={str => selectColHandler(str)}
									/>
								</div>
								<div className='w-full'>
									<label className='block text-sm font-medium leading-6 text-gray-900'>
										Ярус
									</label>
									<InputField
										value={selectedRow || 'Не выбрано'}
										onChange={str => selectRowHandler(str)}
									/>
								</div>
							</div>
						</div>
					</div>

					{rackMatrix.length > 0 && (
						<p className='my-8 text-center text-4xl'>
							Выберите ячеку стеллажа для размещения
						</p>
					)}
					<div className='flex'>
						<div className='w-1/2'>
							<div className='w-full mx-auto max-w-96 '>
								{rackMatrix.map((e, row) => {
									return (
										<div className='flex w-full justify-between gap-1 mt-1'>
											{e.map((elem, col) => {
												const isIsset = rackCells.find(
													e =>
														e.Rack_id === rackid &&
														e.Rackcol === col + 1 &&
														e.Rackrow === row + 1
												)

												return (
													<div
														onClick={() => {
															selectRowHandler(row + 1)
															selectColHandler(col + 1)
														}}
														className={`flex justify-center ${
															!isIsset ? 'bg-red-500' : 'bg-green-500'
														} items-center w-full h-full text-white h-32 hover:brightness-125 cursor-pointer`}>
														<p>
															{isIsset
																? isIsset.CellName
																: 'Ячейка отсутствует'}
														</p>
													</div>
												)
											})}
										</div>
									)
								})}
							</div>
						</div>
						<div className='w-1/2'>
							<div className='flex justify-center items-center'>
								<WarehouseMap id={7} width={500} height={500} />
							</div>
						</div>
					</div>

					<div className='mt-6 flex items-center justify-end gap-x-6'>
						<button
							onClick={() => navigate('/warehoue-products')}
							type='button'
							className='text-sm font-semibold leading-6 text-gray-900'>
							Отменить
						</button>
						<button
							onClick={save}
							className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
							Сохранить
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default observer(PushProductPage)
