import PushProductPage from '@/pages/PushProductPage/PushProductPage'
import PushProductsPage from '@/pages/PushProductsPage/PushProductsPage'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

const PushProductRoute: FC = () => {
	return (
		<Routes>
			<Route path='/' element={<PushProductsPage />} />
			<Route path='/push/:id' element={<PushProductPage />} />
		</Routes>
	)
}

export default observer(PushProductRoute)
