import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import noimg from '../../assets/none-image.svg'

interface IPhotoAgent {
	src?: string | null
	className?: string | null
	alt?: string | null
}

const PhotoAgent: FC<IPhotoAgent> = ({ src, className, alt }) => {
	if (src) {
		return (
			<img
				className={`w-full h-full ${className && className}`}
				src={src}
				alt={alt ? alt : 'landscape-not-set'}
			/>
		)
	}
	return (
		<img
			className={`w-full h-full ${className && className}`}
			src={noimg}
			alt={alt ? alt : 'landscape-not-set'}
		/>
	)
}

export default observer(PhotoAgent)
