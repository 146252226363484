import st from '../../Fastomat.module.scss'
import { FC } from 'react'
import { IFastomatAPI } from '@/types/fastomatWorker'
import { observer } from 'mobx-react-lite'
import FastomatWorker from '@/store/FastomatWorker'
import { Language } from '@/utils/langSwitch'
import { dict } from './TranslationList'
// Переводчик
const t = Language.translateWithDictionary(dict)

interface IRdyButtonProps {
	order: IFastomatAPI
	RdyBtnChange(order: IFastomatAPI): void
}
const RdyButton: FC<IRdyButtonProps> = ({ order, RdyBtnChange }) => {
	if (order.orderstate === 2) {
		return (
			<div
				className={st.addBtnReady}
				onClick={() => {
					FastomatWorker.PickWin = true
					FastomatWorker.RdyOrder = order
				}}>
				{t('Готов')}
			</div>
		)
	} else if (order.orderstate === 3) {
		return (
			<div 
        className={st.addBtnPut} 
        onClick={() => RdyBtnChange(order)}>
				{t('Разместил')}
			</div>
		)
	} else return <></>
}
export default observer(RdyButton)
