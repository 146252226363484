import './App.css'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './components/AppRouter'
import AdBanner from './components/AdBanner/AdBanner'
import { useMount } from './hooks/useMount'
import Theme from './store/Theme'
import { Language } from './utils/langSwitch'
import User from './store/user'
import SettingsCollectSystem from './store/Settings/SettingsCollectSystem/SettingsCollectSystem'
import ScreenSplit from './components/ScreenSplit/ScreenSplit'
import Company from './store/Company/Company'

const App = () => {
	useMount(() => {
		// Handlers
		Theme.handleThemeChange()
		Language.handleLanguage()
		Company.handleCompany()
		User.handleLogin()
		SettingsCollectSystem.HandleReduceCollect()
	})
	return (
		<AdBanner>
			<ScreenSplit>
				<BrowserRouter>
					<AppRouter />
				</BrowserRouter>
			</ScreenSplit>
		</AdBanner>
	)
}
export default App
