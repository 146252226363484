import GantSettings from '@/store/Settings/Gant/Gant'
import DragAndDrop from '@/store/Gant/DragAndDrop'
import GantTasks from '@/store/Gant/GantTasks'
import { cutMaxTextLength } from '@/utils/maxText'
import { observer } from 'mobx-react-lite'
import { IGantTask } from '@/store/Gant/Gant.interface'
import { toJS } from 'mobx'

const GantTasksList = () => {
	const toogleProducts = async (task: IGantTask) => {
		GantTasks.getProducts(task.id)
	}
	return (
		<>
			{GantTasks.filteredTasks.map(task => (
				<div className='flex flex-col'>
					<div
						draggable={DragAndDrop.draggable}
						onDragStart={event => DragAndDrop.dragStart({ event, task })}
						onDragLeave={event => DragAndDrop.dragLeave({ event, task })}
						onDragEnd={event => DragAndDrop.dragEnd({ event, task })}
						onDragOver={event => DragAndDrop.dragOver({ event, task })}
						onDrop={event => {
							DragAndDrop.dragDrop({ event, task })
						}}
						onClick={() => toogleProducts(task)}
						style={{
							height: `${
								GantSettings.dayHeight > GantSettings.minimalDayHeight
									? GantSettings.dayHeight
									: GantSettings.minimalDayHeight
							}px`
						}}
						className={`w-full py-2 mt-[18px] grid grid-cols-7 
            items-center justify-center pl-4  
          hover:bg-gray-100 cursor-pointer`}>
						<p>{task.id}</p>
						<p>{cutMaxTextLength(task.client, 10)}</p>
						<p>{task.manager.name}</p>
						<p>{task.created}</p>
						<p>{task.ready}</p>
						<p>{task.shipment}</p>
						<p>{task.sortorder}</p>
					</div>
					<div>
						{task.products.map(product => {
							return (
								<div
									className={`w-full mt-[14px] py-2 grid grid-cols-7 
								items-center justify-center pl-4  
							hover:bg-gray-100 cursor-pointer`}>
									<p></p>
									<p>{product.ProductNameEng}</p>
									<p>{task.manager.name}</p>
									<p>
										{product.created_date?.includes('NaN')
											? 'Нет'
											: product.created_date}
									</p>
									<p>
										{product.production_date?.includes('NaN')
											? 'Нет'
											: product.production_date}
									</p>
									<p>
										{product.shipment_date?.includes('NaN')
											? 'Нет'
											: product.shipment_date}
									</p>
									<p>{product.sortorder}</p>
								</div>
							)
						})}
					</div>
				</div>
			))}
		</>
	)
}

export default observer(GantTasksList)
