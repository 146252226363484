import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import RackAddC from '../RackAdd/RackAddC'
import RackSettingsC from '../RackSettings/RackSettingsC'
import StoreZoneSettingsC from '../StoreZoneSettings/StoreZoneSettingsC'
import { ReactComponent as QuitImg } from '../../assets/quit.svg'
import st from './WarehousePageSettings.module.scss'
import { NavLink, Route, Routes } from 'react-router-dom'
import Tabs from '../Tabs/Tabs'

const WarehousePageSettings: FC = () => {
	return (
		<div className={st.container}>
			<div className={st.settings} data-testid='WarehousePage'>
				<Tabs />
				<Routes>
					<Route path='/' element={<RackSettingsC />} />
					<Route path='store-zone-settings' element={<StoreZoneSettingsC />} />
					<Route path='add-rack-to-zone' element={<RackAddC />} />
				</Routes>
			</div>
			<NavLink className={st.quitBtn} to={'/'}>
				<p>Выйти</p>
				<QuitImg className='w-7 h-full' stroke='white' />
			</NavLink>
		</div>
	)
}

export default observer(WarehousePageSettings)
