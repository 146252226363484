import { observer } from "mobx-react-lite";
import { FC } from "react";
import { getWarehouse } from "../../API/wareHouseAPI";
import WareHouseItem from "../../components/WareHouseItem/WareHouseItem";
import Warehouse from "../../store/Warehouse";
import st from "./WarehousePage.module.scss";
import { dict } from "./TranslationList";
import { Language } from "../../utils/langSwitch";
import { useMount } from "../../hooks/useMount";

// Переводчик
const t = Language.translateWithDictionary(dict);

const WarehousePage: FC = () => {
  useMount(() => {
    getWarehouse()
  });
  return (
    <div className={st.wrapper}>
      <p className={st.stores}>{t("Выберите склад для редактирования")}:</p>
      {Warehouse.getTreeProducts().map((e) => {
        return <WareHouseItem e={e} key={e.id} />;
      })}
    </div>
  );
};

export default observer(WarehousePage);
