import { observer } from 'mobx-react-lite'
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { cancelRacks, updateRacks } from '../../API/wareHouseAPI'
import Warehouse from '../../store/Warehouse'
import RackSettings from './RackSettings'
import { IRackNameHandler } from './RackSettings.interface'
import Racks from '@/store/Warehouse/Racks'

const RackSettingsC: FC = () => {
	const { id } = useParams()

	const [isFetchSave, setFetchSave] = useState<boolean>(false)
	const [isFetchCancel, setFetchCancel] = useState<boolean>(false)
	const [deviceID, setDeviceID] = useState<string>('')

	useEffect(() => {
		if (isFetchSave || isFetchCancel) {
			document.body.style.filter = 'brightness(0.85)'
		} else {
			document.body.style.filter = 'brightness(1)'
		}
	}, [isFetchSave, isFetchCancel])

	const xCoordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!Racks.selectedRack) return
		Racks.selectedRack.xcoord = Number(e.target.value)
	}

	const yCoordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!Racks.selectedRack) return
		Racks.selectedRack.ycoord = Number(e.target.value)
	}

	const rotateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!Racks.selectedRack) return
		Racks.selectedRack.rotated = Number(e.target.value)
	}
	const deviceHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!Racks.selectedRack) return
		setDeviceID(e.target.value)
	}

	const rackNameHandler = ({ id, rackname }: IRackNameHandler) => {
		if (!id) return
		const rack = Warehouse.racks.find(e => e.id === Number(id))
		if (rack) {
			rack.RackName = rackname
		} else {
			console.log(`%c Rack with id = ${id} not found`, 'color: red')
		}
	}

	const save = async () => {
		setFetchSave(true)
		for (let i = 0; i < Racks.filtredRacks.length; i++) {
			await updateRacks({
				id: Racks.filtredRacks[i].id,
				rackname: Racks.filtredRacks[i].RackName,
				colnumber: Racks.filtredRacks[i].ColNumber,
				rownumber: Racks.filtredRacks[i].RowNumber,
				available: true,
				ligthingscheme: 0,
				xcoord: Racks.filtredRacks[i].xcoord || 0,
				ycoord: Racks.filtredRacks[i].ycoord || 0,
				rotated: Racks.filtredRacks[i].rotated,
				model: Racks.filtredRacks[i].Model_id,
				deviceid: deviceID,
				zone: Racks.filtredRacks[i].Zone_id
			})
		}
		setFetchSave(false)
	}

	const cancel = async () => {
		if (!id) return
		setFetchCancel(true)
		await cancelRacks(Number(id))
		setFetchCancel(false)
	}

	const handlers = {
		xCoordHandler,
		yCoordHandler,
		rotateHandler,
		rackNameHandler,
		deviceHandler
	}

	return (
		<RackSettings
			{...handlers}
			save={save}
			cancel={cancel}
			isFetchSave={isFetchSave}
			isFetchCancel={isFetchCancel}
		/>
	)
}

export default observer(RackSettingsC)
