import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { ReactComponent as AvatarImg } from '../../assets/avatar.svg'
import { NavLink } from 'react-router-dom'
import Theme from '../../store/Theme'
import BasketBtn from './Header/BasketBtn'
import { $authHost } from '@/API'
import { useMount } from '@/hooks/useMount'

const Header: FC = () => {
	return (
		<div>
			<div
				className={`container mx-auto h-16 
          md:h-28 pt-2 bg-white dark:bg-slate-800`}>
				<div className='flex w-full justify-between px-5 items-start'>
					<div
						className={`border-2 rounded-full p-2 cursor-pointer 
            hover:bg-slate-200 block md:hidden dark:hover:bg-slate-500`}>
						<NavLink to={'/'}>
							<AvatarImg
								width={'1.25rem'}
								height={'1.25rem'}
								fill={Theme.isDarkTheme ? 'white' : '#535358'}
							/>
						</NavLink>
					</div>
					<div
						className={`border-2 rounded-full p-2 cursor-pointer 
            hover:bg-slate-200 hidden md:block dark:hover:bg-slate-500`}>
						<NavLink to={'/'}>
							<AvatarImg
								width={'3.5rem'}
								height={'3.5rem'}
								fill={Theme.isDarkTheme ? 'white' : '#535358'}
							/>
						</NavLink>
					</div>
					<GetNameC />
					<BasketBtn />
				</div>
			</div>
		</div>
	)
}

export default observer(Header)

const GetNameC: FC = observer(() => {
	const [fullName, setFullName] = useState('')
	const [name, setName] = useState('')
	useMount(() => {
		const getName = async () => {
			try {
				const { data } = await $authHost.get('api/user_company')
				if (data && data.shortname) {
					setName(data.shortname)
				}
				if (data && data.fullname) {
					setFullName(data.fullname)
				}
			} catch (e) {
				console.log('error on request')
			}
		}
		getName()
	})
	return (
		<div
			className={`shrink grow px-4 mt-1 h-full 
		flex flex-col justify-center items-center`}>
			<p
				className={`font-light text-xs 
			md:text-2xl dark:text-white`}>
				{fullName}
			</p>
			<p
				className={`font-bold text-xs 
			md:text-2xl dark:text-white`}>
				{name}
			</p>
		</div>
	)
})
