import { IDistTranslation } from "../../utils/langSwitch";

export const dict: IDistTranslation[] = [
  {
    ru: "Рекомендуемое",
    eng: "Recommended",
  },
  {
    ru: "Все товары",
    eng: "All products",
  },
];
