import { toJS } from 'mobx'
import { $authHost, $host } from '.'
import worker from '../store/worker'
import { IFIconsAPI } from '../types/fastomat'
import { IFastomatAPI } from '../types/fastomatWorker'
import FastomatWorker from '../store/FastomatWorker'
import axios from 'axios'

const errorHandler = async (err: any) => {
	console.log(err)
	let message: string = ''
	if ((err?.response?.status || 0) === 401) {
		// window.location.href = "/login";
		const refresh = localStorage.getItem('refresh_token')
		try {
			let res = await $host.post('api/login/refresh/', { refresh })
			localStorage.setItem('access_token', res.data.access)
			message = 'reload'
		} catch (e) {
			message = 'nonAuth'
			user.isAuth = false
			user.Authorization = false
		}
	}
	return message
}

export const getOrders = async (
	setOrders: React.Dispatch<React.SetStateAction<IFastomatAPI[]>>
): Promise<string> => {
	try {
		let response = await $authHost.get<IFastomatAPI[]>('api/fastomatworker/')
		data.sort(function (a, b) {
			return (
				new Date(Date.parse(b.statechangetime)).getTime() -
				new Date(Date.parse(a.statechangetime)).getTime()
			)
		})
		setOrders(response.data)
		return 'success'
	} catch (e) {
		return errorHandler(e)
	}
}

export const getTextMessage = async (
	text: string,
	setSound: React.Dispatch<React.SetStateAction<string | null>>
): Promise<string> => {
	try {
		let { data } = await $authHost.get(`api/voicesyth/${text}`)
		setSound(data)
		return 'success'
	} catch (e) {
		return errorHandler(e)
	}
}

export const lighting = async (win: number) => {
	try {
		axios.post(
			`http://localhost:8000/api/postfastlighting`,
			{ WindowNum: win },
			{
				headers: { Authorization: `Bearer ${localStorage.access_token}` }
			}
		)
		// await $authHost.post<IFastomatAPI[]>("api/postfastligthing", {});
	} catch (e) {
		return errorHandler(e)
	}
}

export const longpolling = async (
	setOrders: React.Dispatch<React.SetStateAction<IFastomatAPI[]>>,
	playAudio?: (text: string) => Promise<void>
) => {
	//   try {
	//     /*
	//     long pooling has 6 ticks of 5 seconds each
	//     (in total 30 seconds = the lifetime of the request). If there
	//     was no response within 6 ticks = sending a new request, if
	//     there was, the ticks are reset and the request is refilled on
	//     the trail.tick (after 5 seconds)
	//     */
	//     if (worker.request !== 6) {
	//       worker.request = worker.request + 1;
	//     }
	//     if (worker.delay === true) {
	//       worker.delay = false;
	//       return;
	//     }
	//     if (worker.delay === false && worker.request === 6) {
	//       // reset the timings
	//       worker.request = 0;
	//       let response = await $authHost.get<IFastomatAPI[]>("api/checkstates/");
	//       // update the timings
	//       worker.delay = true;
	//       worker.request = 5;
	//       // If there was no response -> exit
	//       if (response.data.length === 0) return;
	//       let orders = response.data;
	//       for (let i = 0; i < orders.length; i++) {
	//         // mark to track modified orders
	//         orders[i].isCheck = true;
	//         setOrders((prevState) => [
	//           orders[i],
	//           ...prevState.filter((state) => state.id !== orders[i].id),
	//         ]);
	//         //deleting the mark of a modified order
	//         setTimeout(() => {
	//           delete orders[i].isCheck;
	//           setOrders((prevState) => [
	//             orders[i],
	//             ...prevState.filter((state) => state.id !== orders[i].id),
	//           ]);
	//           //after a second to work out the animation
	//         }, 1000);
	//         // skipping remote orders
	//         if (orders[i].orderstate === 10) break;
	//         //Algorithm for voicing the text of fastomat windows
	//         // ================================================
	//         if (orders[i].orderstate >= 4) {
	//           if (orders[i].windows.length === 1) {
	//             let text = `Заказ номер ${orders[i].ordernum} можно забрать в окне ${orders[i].windows[0].windownum}`;
	//             playAudio(text);
	//           } else if (orders[i].windows.length > 1) {
	//             let get = "в окнах";
	//             for (let j = 0; j < orders[i].windows.length; j++) {
	//               get += orders[i].windows[j].windownum;
	//               get += orders[i].windows.length - 1 === j ? "" : "и";
	//             }
	//             let text = `Заказ номер ${orders[i].ordernum} можно забрать ${get}`;
	//             playAudio(text);
	//           } else {
	//             let text = `Заказ номер ${orders[i].ordernum} можно забрать у продавца`;
	//             playAudio(text);
	//           }
	//         }
	//         // ================================================
	//       }
	//     }
	//   } catch (e) {
	//     // reset request on error
	//     longpolling(setOrders);
	//     errorHandler(e);
	//   }
	// };
	// export const getIcons = async (
	//   setIcons: React.Dispatch<React.SetStateAction<IFIconsAPI[]>>
	// ): Promise<string> => {
	//   try {
	//     let response = await $authHost.get<IFIconsAPI[]>("api/getorderstatus");
	//     response.data.sort((a, b) => (a.id > b.id ? 1 : -1));
	//     setIcons(response.data);
	//     return "success";
	//   } catch (e) {
	//     return errorHandler(e);
	//   }
	// };
	// export const longpollingFrontend = async (
	//   orders: IFastomatAPI[],
	//   setOrders: React.Dispatch<React.SetStateAction<IFastomatAPI[]>>,
	//   playAudio?: (text: string) => Promise<void>
	// ) => {
	//   let { data } = await $authHost.get<IFastomatAPI[]>("api/fastomatworker/");
	//   data.sort(function (a, b) {
	//     return (
	//       new Date(Date.parse(b.statechangetime)).getTime() -
	//       new Date(Date.parse(a.statechangetime)).getTime()
	//     );
	//   });
	//   if (JSON.stringify(data) === JSON.stringify(toJS(orders))) {
	//     console.log("data equal");
	//     return;
	//   } else {
	//     let added = [];
	//     let deleted = [];
	//     let updated = [];
	//     if (data.length !== orders.length) {
	//     }
	//     for (let i = 0; i < data.length; i++) {
	//       // Берем id текущенго заказа
	//       let id = data[i].id;
	//       // e = element
	//       // ищем такой в ткущем стейте заказ с таким же id
	//       let order = orders.find((e) => e.id === id);
	//       if (!order) {
	//         //если такой заказ не найден
	//         //то он удален
	//         deleted.push(data[i]);
	//       } else {
	//         // проверяем сходяться ли ключи у заказов
	//         for (const property in data[i]) {
	//           console.log();
	//           // @ts-ignore
	//           if (order[`${property}`] === data[i][property]) {
	//           } else {
	//             if (`${property}` === "orderstate") {
	//               if (!(order.orderstate === data[i].orderstate)) {
	//                 // если стейт разный
	//                 updated.push(data[i]);
	//                 data[i].isCheck = true;
	//                 setTimeout(() => {
	//                   delete data[i].isCheck;
	//                 }, 1000);
	//                 break;
	//               } else {
	//                 break;
	//               }
	//             }
	//             if (`${property}` === "windows") {
	//               if (data[i].windows.length !== order.windows.length) {
	//                 // если кол-во окон не совпадет с сервером
	//                 updated.push(data[i]);
	//                 data[i].isCheck = true;
	//                 setTimeout(() => {
	//                   delete data[i].isCheck;
	//                 }, 1000);
	//                 break;
	//               }
	//               for (let j = 0; j < order.windows.length; j++) {
	//                 if (order.windows[j].id === data[i].windows[j].id) {
	//                   // с окнами все ок
	//                 } else {
	//                   // окна не совпали
	//                   updated.push(data[i]);
	//                   data[i].isCheck = true;
	//                   setTimeout(() => {
	//                     delete data[i].isCheck;
	//                   }, 1000);
	//                   break;
	//                 }
	//                 // проверка всех окон
	//               }
	//               break;
	//             }
	//             updated.push(data[i]);
	//             data[i].isCheck = true;
	//             setTimeout(() => {
	//               delete data[i].isCheck;
	//             }, 1000);
	//             break;
	//           }
	//         }
	//       }
	//     }
	//     setOrders(data);
	//     console.log(data);
	//     if (updated.length !== 0) {
	//       for (let i = 0; i < updated.length; i++) {
	//         // skipping remote orders
	//         if (updated[i].orderstate === 10) break;
	//         //Algorithm for voicing the text of fastomat windows
	//         // ================================================
	//         if (updated[i].orderstate >= 4) {
	//           if (updated[i].windows.length === 1) {
	//             let text = `Заказ номер ${updated[i].ordernum} можно забрать в окне ${updated[i].windows[0].windownum}`;
	//             playAudio(text);
	//           } else if (updated[i].windows.length > 1) {
	//             let get = "в окнах";
	//             for (let j = 0; j < updated[i].windows.length; j++) {
	//               get += updated[i].windows[j].windownum;
	//               get += updated[i].windows.length - 1 === j ? "" : "и";
	//             }
	//             let text = `Заказ номер ${updated[i].ordernum} можно забрать ${get}`;
	//             playAudio(text);
	//           } else {
	//             let text = `Заказ номер ${updated[i].ordernum} можно забрать у продавца`;
	//             playAudio(text);
	//           }
	//         }
	//         // ================================================
	//       }
	//     }
	//     updated = [];
	//     return;
	//   }
}
