import InputField from '@/components/UI/InputField'
import RangeSlider from '@/components/UI/RangeSlider'
import Switch from '@/components/UI/Switch'
import { Compact } from '@uiw/react-color'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import Gant from '@/store/Settings/Gant/Gant'
import StateColorSettings from '@/components/GantProductionTable/StateColorSettings'

const GantSettings: FC = () => {
	return (
		<div className='dark:bg-slate-800 dark:text-white min-h-screen'>
			<div className='container mx-auto px-2'>
				<div className='grid grid-cols-2 pt-12 items-center gap-5'>
					<div className='w-full'>
						<p>
							Цвет ячейки дня при успешном выполнении плана
						</p>
						<div className='w-full items-center flex mt-3'>
							<Compact
								color={Gant.colorSuccess}
								onChange={e => {
									Gant.colorSuccessHandler(e.hex)
								}}
							/>
							<div className='w-full h-full flex justify-center items-center'>
								<div
									style={{
										background: Gant.colorSuccess,
										borderRadius: `${Gant.rounded}px`
									}}
									className='w-12 h-4'></div>
							</div>
						</div>
					</div>
					<div className='w-full'>
						<p>
							Цвет ячейки дня при просрочивании выполнении
							плана
						</p>
						<div className='w-full items-center flex mt-3'>
							<Compact
								color={Gant.colorLater}
								onChange={e => {
									Gant.colorLaterHandler(e.hex)
								}}
							/>
							<div className='w-full h-full flex justify-center items-center'>
								<div
									style={{
										background: Gant.colorLater,
										borderRadius: `${Gant.rounded}px`
									}}
									className='w-12 h-4'></div>
							</div>
						</div>
					</div>
				</div>
				<StateColorSettings />
				<div className='grid grid-cols-2 pt-12 items-center gap-5'>
					<p>Закругление задач</p>
					<RangeSlider
						max={20}
						value={Gant.rounded}
						onChange={e => Gant.roundedHandler(e)}
					/>
				</div>
				<div className='grid grid-cols-2 pt-12 items-center gap-5'>
					<p>Ширина дня</p>
					<InputField
						status={
							Gant.dayWidth < Gant.minimalDayWidth
								? 'error'
								: 'defualt'
						}
						errorMessage={
							Gant.dayWidth < Gant.minimalDayWidth
								? `Минимальное значение ${Gant.minimalDayWidth}`
								: ''
						}
						value={Gant.dayWidth}
						onChange={e => {
							Gant.dayWidthHandler(Number(e))
						}}
					/>
				</div>
				<div className='grid grid-cols-2 pt-12 items-center gap-5'>
					<p>Высота дня</p>
					<InputField
						status={
							Gant.dayHeight < Gant.minimalDayHeight
								? 'error'
								: 'defualt'
						}
						errorMessage={
							Gant.dayHeight < Gant.minimalDayHeight
								? `Минимальное значение ${Gant.minimalDayHeight}`
								: ''
						}
						value={Gant.dayHeight}
						onChange={e => {
							Gant.dayHeightHandler(Number(e))
						}}
					/>
				</div>
				<div className='grid grid-cols-2 pt-12 items-center gap-5'>
					<div className='grid grid-cols-2'>
						<p>Выходные дни</p>
						<Switch
							value={true}
							title={''}
							callback={() => {}}
						/>
					</div>
					<input
						type='date'
						className='dark:bg-slate-600'
					/>
				</div>
				<div className='grid grid-cols-2 pt-12 items-center gap-5'>
					<div className='grid grid-cols-2'>
						<p>Отступы между днями</p>
						<Switch
							value={Gant.isDayPadding}
							title={''}
							callback={() => {
								Gant.isDayPadding = !Gant.isDayPadding
							}}
						/>
					</div>
					<RangeSlider max={100} />
				</div>
				<div className='grid grid-cols-2 pt-12 items-center gap-5'>
					<div className='grid grid-cols-2'>
						<p>Отступы между месяцами</p>
						<Switch
							value={Gant.isMouthPadding}
							title={''}
							callback={() => {
								Gant.isMouthPadding = !Gant.isMouthPadding
							}}
						/>
					</div>
					<RangeSlider max={100} />
				</div>
			</div>
		</div>
	)
}

export default observer(GantSettings)
