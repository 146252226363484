import { observer } from 'mobx-react-lite'
import { IBreadcrumbsProps } from './Breadcrumbs.interface'
import SkeletBreadCrumb from './CrumbLists/SkeletBreadCrumb'
import FirstBreadCrumb from './CrumbLists/FirstBreadCrumb'
import LinksList from './CrumbLists/LinksList'
import LastBreadCrumb from './CrumbLists/LastBreadCrumb'
import { FC } from 'react'

const Breadcrumbs: FC<{ links: IBreadcrumbsProps[] }> = ({ links }) => {
	if (links.length === 0) return <></>
	return (
		<SkeletBreadCrumb>
			<FirstBreadCrumb link={links[0]} />
			<LinksList links={links} />
			<LastBreadCrumb link={links[links.length - 1]} />
		</SkeletBreadCrumb>
	)
}

export default observer(Breadcrumbs)
