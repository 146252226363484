import { observer } from "mobx-react-lite";
import { FC } from "react";
import { NavLink, useParams } from "react-router-dom";
import st from "./CollectPickMethodPage.module.scss";
import fastImg from "../../assets/rocket.svg";
import boxImg from "../../assets/box place.svg";
import quitimg from "../../assets/quit.svg";
import { dict } from "./TranslationList";
import { Language } from "../../utils/langSwitch";

const t = Language.translateWithDictionary(dict);
const CollectPickMethodPage: FC = () => {
  const { id, guide } = useParams();
  return (
    <>
      <div className={st.wrapper}>
        <div>
          <p style={{ margin: "20px" }}>{t("Выбирите ваш метод сборки")}:</p>
          <div className={st.item}>
            <NavLink to={`/assemblingorder/${id}/guide/${guide}/method/1`}>
              <div className={st.block}>
                <img src={fastImg} alt="" />
                <p>{t("Быстро")}</p>
              </div>
            </NavLink>

            <NavLink to={`/assemblingorder/${id}/guide/${guide}/method/2`}>
              <div className={st.block}>
                <img src={boxImg} alt="" />
                <p>{t("Компактно")}</p>
              </div>
            </NavLink>

            <NavLink to={`/`}>
              <div className={st.block}>
                <img src={quitimg} alt="" />
                <p>{t("Вернуться")}</p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(CollectPickMethodPage);
