import {
	IAssemblingOrders,
	IAssemblingProducts,
	IAssemblingTask,
	IGuideStages,
	IProductStoreData
} from './../types/tycollectguides'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import { IGuide } from '../types/tycollectguides'

configure({
	enforceActions: 'never'
})

class TYCollectGuides {
	fetch: boolean = false
	isFetched: boolean = false
	Warehouse: null | number = null
	name: string = ''
	desc: string = ''
	guideStages: IGuideStages[] = []
	Guides: IGuide[] = []
	guide: number = 0
	stage: number = 0
	Tasks: IAssemblingTask[] = []
	StoreData: null | IProductStoreData[] = null
	Orders: IAssemblingOrders[] = []
	Products: IAssemblingProducts[] = []
	open: boolean = false
	constructor() {
		makeAutoObservable(this)
	}
}

export default new TYCollectGuides()
