import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import { MAIN_ROUTE } from '../../utils/consts'
import Fetch from './Fetch'
import { FC, useEffect } from 'react'
import WebSocketShelving from '@/store/WebSocket/WebSocketShelving'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { ToastContainer } from 'react-toastify'


// add notification styles
if (typeof window !== 'undefined') injectStyle()

const WSCHorizontal: FC = () => {
	// Цикличный поиск collector'a
	useEffect(() => {
		const interval = setInterval(() => {
			WebSocketShelving.send({
				action: 'getclients',
				payload: {}
			})
		}, 1500)
		return () => {
			clearInterval(interval)
		}
	})
	return (
		<>
			<div className='dark:bg-slate-800 dark:text-white'>
				<p className='text-[3vw] pl-5'>Заказ</p>
				<p className='w-screen text-center text-[10vw]'>
					{WebSocketShelving.orderid 
					? WebSocketShelving.orderid 
					: '...'}
				</p>

				<div className='container mx-auto'>
					<Fetch />
				</div>

				<NavLink to={MAIN_ROUTE}>
					<div
						className={`flex justify-center text-white 
            items-center bg-red-500 w-full py-5 hover:bg-red-700`}>
						<p>Выйти</p>
					</div>
				</NavLink>
			</div>
			<ToastContainer />
		</>
	)
}

export default observer(WSCHorizontal)
