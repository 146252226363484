import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Выйти',
		eng: 'Logout'
	},
	{
		ru: 'Войти',
		eng: 'Login'
	},
	{
		ru: 'WebSocket стеллаж',
		eng: 'WebSocket Shelving'
	},
	{
		ru: 'Фасовка',
		eng: 'Packing'
	},
	{
		ru: 'Снабжение',
		eng: 'Supply'
	},
	{
		ru: 'Производство',
		eng: 'Production'
	},
	{
		ru: 'Коммерция',
		eng: 'Commerce'
	},
	{
		ru: 'Администратор',
		eng: 'Administrator'
	},
	{
		ru: 'Создание заказа',
		eng: 'Creating an order'
	},
]
