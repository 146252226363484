import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import st from './RackListItem.module.scss'
import lightImg from '../../assets/light.svg'
import deleteImg from '../../assets/delete.svg'
import { IRacks } from '../../types/Warehouse'
import Warehouse from '@/store/Warehouse'
import Racks from '@/store/Warehouse/Racks'
import { deleteRack, lightRack } from '../../API/wareHouseAPI'
import { toJS } from 'mobx'

interface IProps {
	e: IRacks
}

const RackListItem: FC<IProps> = ({ e }) => {
	const clickHandler = () => {
		console.log(toJS(e))
		Racks.selectedRack = e
	}

	const deleteHandler = async () => {
		await deleteRack(e.id)
		Racks.racks = Racks.racks.filter(element => element.id !== e.id)
	}

	const lightHandler = async () => {
		await lightRack(e.id)
		Racks.fleshRack(e.id)
	}

	const condition = () => {
		if (!Racks.selectedRack) return false
		if (Racks.selectedRack.id === e.id) return true
		return false
	}
	return (
		<div
			className={`${st.rack} ${condition() ? st.active : null}`}
			onClick={clickHandler}>
			<p>{e.RackName}</p>
			<div className={`${st.rackBtn} flex`}>
				<img src={deleteImg} alt='delete button' onClick={deleteHandler} />
				<img src={lightImg} alt='light button' onClick={lightHandler} />
			</div>
		</div>
	)
}
export default observer(RackListItem)
