import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import Header from './Header'
import Footer from './Footer/Footer'
import SearchUI from '@/components/SearchUI/SearchUI'
import Categories from '@/components/Categories/Categories'
import ProductsSection from '@/components/ProductList/ProductsSection'

const MainProductSelected: FC = () => {
	return (
		<div
			className={`font-['Open_Sans'] 
    bg-white dark:bg-slate-800 
      min-h-screen`}>
			<Header />
			<SearchUI />
			<Categories />
			<ProductsSection />
			<Footer />
		</div>
	)
}
export default observer(MainProductSelected)
