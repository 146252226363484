import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import Warehouse from '@/store/Warehouse/Warehouse'
import WHZone from '@/store/Warehouse/WhZone'
import st from './StoreZoneSettings.module.scss'
import { Language } from '../../utils/langSwitch'
import { dict } from './TranslationList'

const t = Language.translateWithDictionary(dict)
interface IProps {
	save: () => void
	uploadFile: (value: any) => void
	isFetchSave: boolean
	isFetchCancel: boolean
	cancel: () => void
}

const StoreZoneSettings: FC<IProps> = ({
	save,
	cancel,
	isFetchSave,
	isFetchCancel,
	uploadFile
}) => {
	return (
		<div>
			<p style={{ textAlign: 'center', margin: '20px', fontSize: '18pt' }}>
				{t('Параметры зоны')}:
			</p>

			<div className={st.params}>
				<p>{t('Имя')}</p>
				<input
					type='text'
					value={`${WHZone.WHZone?.zonename || ''}`}
					onChange={e => {
						if (!WHZone.WHZone) return
						WHZone.WHZone.zonename = `${e.target.value}`
					}}
				/>
			</div>
			<div className={st.params}>
				<p>{t('Описание')}</p>
				<input
					type='text'
					value={`${WHZone.WHZone?.zonedescr || ''}`}
					onChange={e => {
						if (!WHZone.WHZone) return
						WHZone.WHZone.zonedescr = `${e.target.value}`
					}}
				/>
			</div>

			<div className={st.params}>
				<p>{t('Ширина')}</p>
				<input
					type='text'
					value={`${WHZone.WHZone?.width || ''}`}
					onChange={e => {
						console.log(WHZone.WHZone)
						if (!WHZone.WHZone) return
						WHZone.WHZone.width = Number(e.target.value)
					}}
				/>
			</div>
			<div className={st.params}>
				<p>{t('Высота')}</p>
				<input
					type='text'
					value={`${WHZone.WHZone?.length || ''}`}
					onChange={e => {
						if (!WHZone.WHZone) return
						WHZone.WHZone.length = Number(e.target.value)
					}}
				/>
			</div>
			<p style={{ textAlign: 'center', margin: '20px', fontSize: '18pt' }}>
				{t('Выбор начальной точки сборки')}:
			</p>

			<div className={st.params}>
				<p>X</p>
				<input
					type='text'
					value={`${WHZone?.WHZone && WHZone.WHZone.startx}`}
					onChange={e => {
						if (!WHZone?.WHZone) return
						WHZone.WHZone.startx = Number(e.target.value)
					}}
				/>
			</div>

			<div className={st.params}>
				<p>{t('Y')}</p>
				<input
					type='text'
					value={`${WHZone.WHZone && WHZone.WHZone.starty}`}
					onChange={e => {
						if (!WHZone.WHZone) return
						WHZone.WHZone.starty = Number(e.target.value)
					}}
				/>
			</div>

			<div className={st.params}>
				<p>{t('Задний фон')}</p>
				<input type='file' accept='image/png, image/jpeg' onChange={uploadFile} />
			</div>
			<div className={st.params}>
				<p>
					{t(`Рекомендуем загружать изображения 
          соответствующее разрешению склада`)}
					:
				</p>
				<p style={{ marginTop: '20px' }}>
					{`${WHZone?.WHZone && WHZone.WHZone.width}`}x{`${WHZone?.WHZone && WHZone.WHZone.length}`}
				</p>
			</div>

			<div className={st.saveButtons}>
				<div className={st.cancel} onClick={cancel}>
					{isFetchCancel ? <div id={st.loading}></div> : <p>{t('Отменить')}</p>}
				</div>

				<div className={st.save} onClick={() => save()}>
					{isFetchSave ? <div id={st.loading}></div> : <p>{t('Сохранить')}</p>}
				</div>
			</div>
		</div>
	)
}

export default observer(StoreZoneSettings)
