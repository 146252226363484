import { FC, useEffect } from "react";
import st from "../EditGuides.module.scss";
import { observer } from "mobx-react-lite";
import GuideHandler from "../store/GuideHandler";
import { delGuide, getGuides2 } from "../api/Guides";
import deleteImg from "../../../assets/delete.svg";
import { IGuide } from "../../../types/tycollectguides";
import StageHandler from "../store/StageHandler";
import { getAllNeededData } from "../../../API/TYCollectGuides";

const CustomSelect: FC = () => {
  useEffect(() => {
    if (GuideHandler.Guides.length === 0) {
      getGuides2();
    }
  });

  const SelectGuide = (guide: IGuide) => {
    GuideHandler.SelectedGuide = guide;
    StageHandler.error = null;
    GuideHandler.SelectedStage = null;
    GuideHandler.Stages = [];
  };

  const DeleteGuide = (guide: IGuide) => {
    (async () => {
      await delGuide(guide.id);
      getAllNeededData()
    })()
  };

  return (
    <div className={st.customSelect}>
      {GuideHandler.Guides.map((guide, index) => {
        return (
          <div className={st.guideitem} onClick={() => SelectGuide(guide)}>
            <p>{guide.GuideName}</p>
            <div>
              <img src={deleteImg} alt="" onClick={() => DeleteGuide(guide)} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default observer(CustomSelect);
