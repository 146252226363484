export type $fixMe = any

export enum FetchStages {
	start = 'start',
	end = 'end',
	error = 'error',
	waiting = 'waiting'
}

export type FStages = 'start' | 'end' | 'error' | 'waiting'


// $fixme