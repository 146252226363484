export const TYCHOISEWAREHOUSE_ROUTE = '/warehouse/'
export const CREATEORDER_ROUTE = '/ordercreater'
export const STOREKEEPER_ROUTE = '/pull'
export const REGISTRATION_ROUTE = '/registration'
export const PROFILE_ROUTE = '/profile'
export const PRODUCT_ROUTE = '/product'
export const FASTOMAT_ROUTE = '/fastomat'
export const CREATE_ORDER_ROUTE = '/ordercreater'

// заказы на сборку
export const TYPICKING_ROUTE = '/orders'
// конкретный заказ
export const TYORDER_ROUTE = '/orders/:id/method/:method'
// выбор метода сборки у заказа
export const TYCHOISE_ROUTE = '/orders/:id'
// страница авторизации в систему
export const LOGIN_ROUTE = '/login'
// страница с корзиной
export const BASKET_ROUTE = '/basket'
// страница создания заказа у оператора фастомата
export const FASTOMAT_WORKER_ROUTE = '/fastomatworker'
// страница создания заказа для незарегистрированного пользователя
export const CATEGORY_ROUTE = '/selectcategory'
// страница категорий $fixme
export const CURRENT_CATEGORY_ROUTE = '/category/'
// страница генирации QR кода после создания заказа
export const GENERATE_QRCODE_ROUTE = '/qrgenerate'
// страница уведомлений
export const NOTIFICATION_ROUTE = '/notification'
// страница добавления продуктов на склад
export const PUSHPRODUCTSPAGE_ROUTE = '/warehoue-products/*'
// страница администратора
export const ADMINISTRATOR_ROUTE = '/admin/*'
// страница администратора - выбора продуктов $fixme
export const ADDPRODUCTS_ROUTE = '/admin/addproducts'
// страница администратора - выбора категорий $fixme
export const ADDCATEGORY_ROUTE = '/admin/addcategory'
// страница администратора - выбора работников $fixme
export const EMPLOYEES_ROUTE = '/admin/employees'
//(в разработке) страница интеграции 1С  $fixme
export const ITEGRATION1C_ROUTE = '/1cintegration'
//(в разработке) страница тестирования  $fixme
export const TESTING_ROUTE = '/testing'
// (в разработке) страница интеграции битрикс24 $fixme
export const BITRIX24_ROUTE = '/bitrix24'
// вебсокет страница с списком продуктов
export const WSCOLLECT_ROUTE = '/collect'
// вебсокет страница-стеллаж с продуктами
export const WSCLIENT_HORIZONTAL_ROUTE = '/horizontal-wsclient/'
// выбор гайда для сборки по инструкции
export const GUIDE_COLLECT_ROUTE = '/guidecollect'
// выбор заказа сборки по инструкции
export const GUIDE_METHOD_ROUTE = '/guidecollect/:id'
// выбор метода сборки по инструкции
export const ASSEMBLING_ORDER_ROUTE = 'assemblingorder/:id/guide/:guide/'
// страница сборки по инструкции
export const ASSEMBLING_METHODTOO_ROUTE = `${ASSEMBLING_ORDER_ROUTE}method/:method`
// главная страница
export const MAIN_ROUTE = '/'
