import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import NotFoundProductsStore from './NotFoundProducts.store'
import { IProduct } from '@/types/createorder'
import Loader from '@/components/Loader/Loader'
import { FetchStages } from '@/types'
import st from '../Products.module.scss'

const NotFoundProducts: FC<{ product_id: number }> = ({ product_id }) => {
	const [product, setProduct] = useState<IProduct | null>(null)

	const fetchProduct = async () => {
		const response = await NotFoundProductsStore.fetchProductData(product_id)
		if (!response) return
		setProduct(response)
	}

	useEffect(() => {
		fetchProduct()
	}, [])

	return (
		<Loader
			loading={NotFoundProductsStore.isFetchProduct === FetchStages.start}>
			<div className={`${st.product} bg-red-900 text-white`}>
				<div>Отсутствует</div>
				<div>{product?.productname}</div>
				<div>Нет нужного кол-ва</div>
			</div>
			
		</Loader>
	)
}

export default observer(NotFoundProducts)
