import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { cutMaxTextLength } from '../../utils/maxText'
import { IProduct } from '../../types/createorder'
import BtnHandler from './BtnHandler'
import ProductName from './ProductName'
import Logotype from '../Logotype'
import ProductImg from '../ProductImg/ProductImg'

const ProductItem: FC<{ productItem: IProduct }> = ({ productItem }) => {
	const {
		id,
		productname,
		productdescr,
		CurrentPrice,
		ProductNameEng,
		basketcount
	} = productItem

	// цена продукта
	const ProductPrice = CurrentPrice?.includes('.00')
		? CurrentPrice?.slice(0, -3)
		: CurrentPrice

	return (
		<div
			key={id}
			className={`flex flex-col relative bg-white border 
        justify-between border-gray-200 rounded-lg shadow 
      dark:bg-gray-800 dark:border-gray-700 `}>
			<Logotype className='absolute top-2 left-2 w-12 md:w-24' />
			<div className='mx-auto md:h-full h-44 w-10/12 pt-8 pb-3 flex justify-center'>
				<ProductImg product={productItem} />
			</div>
			<div className='px-5 h-full grow shrink flex flex-col'>
				<ProductName
					id={id}
					productname={productname}
					ProductNameEng={ProductNameEng}
				/>
				<div className='flex items-center mt-2.5 mb-5'>
					<p
						className={`font-extralight text-xs md:text-base
             dark:text-white`}>
						{cutMaxTextLength(productdescr, 20)}
					</p>
				</div>
			</div>

			<div className='px-5 pb-5'>
				<div className='flex items-center justify-between flex-col'>
					<span
						className={`text-xm font-bold 
            text-gray-900 dark:text-white text-2xl`}>
						{ProductPrice ? (
							<span className='font-sans'>{ProductPrice}₽</span>
						) : null}
					</span>
					<BtnHandler id={id} basketcount={basketcount} />
				</div>
			</div>
		</div>
	)
}
export default observer(ProductItem)
