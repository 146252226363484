import TYCollectGuides from '@/store/TYCollectGuides'
import { getGuides } from '@/API/TYCollectGuides'
import { useMount } from '@/hooks/useMount'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IAsyncSelectGuidesProps {
	guide: number
	changeGuide: (id: number) => void
}

const AsyncSelectGuides: FC<IAsyncSelectGuidesProps> = 
  ({ guide, changeGuide }) => {
	useMount(() => {
		if (!TYCollectGuides.Guides) {
			(async () => {
				await getGuides()
				if (TYCollectGuides.Guides.length > 0) {
					changeGuide(TYCollectGuides.Guides[0].id)
				}
			})()
		} else {
			changeGuide(TYCollectGuides.Guides[0].id)
		}
	})

	return (
		<select 
      value={guide} 
      onChange={e => changeGuide(Number(e.target.value))}>
			{TYCollectGuides.Guides.map(guide => (
				<option key={guide.id} value={guide.id}>
					{guide.GuideName}
				</option>
			))}
		</select>
	)
}

export default observer(AsyncSelectGuides)
