import { observer } from 'mobx-react-lite'
import { FC, useState, useEffect } from 'react'

interface ICounterProps {
	count: number
}

const Counter: FC<ICounterProps> = ({ count }) => {
	const [num, setNum] = useState<number>(0)

	useEffect(() => {
		const timer1 = setTimeout(() => {
			if (count > num) {
				setNum(prev => Math.ceil(prev + count / 200))
			}
		}, 1)
		return () => {
			clearTimeout(timer1)
		}
	}, [num])
	return <>{num}</>
}

export default observer(Counter)
