import { observer } from 'mobx-react-lite'
import CategoriesItem from './CategoriesItem'
import { FC } from 'react'
import Categories from '../../store/Categories/Categories'
import AllImg from '../../assets/all.svg'

const CategoriesList: FC = () => {
	return (
		<>
			<CategoriesItem key={0} id={0} name={`Всё`} img={AllImg} />
			{Categories.CategoriesList.map(category => (
				<CategoriesItem
					key={category.id}
					id={category.id}
					name={category.categoryname}
					img={category.photo}
				/>
			))}
		</>
	)
}

export default observer(CategoriesList)
