
/* eslint-disable array-callback-return */
import { IProduct, IStoreProducts } from "@/types/createorder";
import { makeAutoObservable } from "mobx";
import { configure } from "mobx";
import { IPostitionProps, IPushModalProps } from "../types/pushproduct";

configure({
  enforceActions: "never",
});

interface IFilterProps {
  productname?: string;
  barcode?: string;
  id?: number;
}

class PushProducts {
  Page = 0
  Total = 0
  Products: IProduct[] = [];
  Filtred: IProduct[] = [];
  StoreProducts: IStoreProducts[] = [];
  ProductPosition: IPostitionProps[] = [];
  PushModal: IPushModalProps = {
    isOpen: false,
    index: 0,
    e: null,
  };


  isPageFetch: boolean = false;
  PagesStructure = {
    page: 1,
    total: 0,
    totalPages: 0,
  };

  filterBy(filter: IFilterProps) {
    const { productname, barcode, id } = filter;
    if (id) return this.Products.filter((e) => e.id === id);
    if (barcode) return this.Products.filter((e) => e.barcode === barcode);
    if (productname)
      return this.Products.filter(
        (e) => e.productname.search(productname) !== -1
      );
    return this.Products;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default new PushProducts();
