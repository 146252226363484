import { toast } from 'react-toastify'
import { INotification } from '../types/fastomat'

export const notification = ({ text, type }: INotification) => {
	if (type === 'error') {
		toast.error(text, {
			position: 'top-right',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		})
	} else {
		toast(text, {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light'
		})
	}
}
