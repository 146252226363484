import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import NavHeaderC from '../../components/Navigation/NavHeaderC'
import { NavLinks } from '../../utils/collectNavLink'
import OrderPart from './OrderPart/OrderPart'
import ModalLists from './ModalLists'
import { dict } from './TranslationList'
import { Language } from '../../utils/langSwitch'

// Переводчик
const t = Language.translateWithDictionary(dict)
const PickOrderPage: FC = () => {
	return (
		<>
			<NavHeaderC links={NavLinks} />
			<div className='container mx-auto mt-9'>
				<p>{t('Выберите заказы на сборку')}:</p>
				<OrderPart />
			</div>
			<ModalLists />
		</>
	)
}

export default observer(PickOrderPage)
