import { observer } from "mobx-react-lite";
import fastomatImg from "../../assets/fastomat.png";
import { NavLink } from "react-router-dom";
import FastomatClient from "../../store/Fastomat";
import { dict } from "./TranslationList";
import { Language } from "../../utils/langSwitch";

const t = Language.translateWithDictionary(dict);
const Fastomat = () => {
  return (
    <>
      <div className="h-full min-h-screen w-full bg-white dark:bg-ty-gridient-dark">
        <section className="h-[calc(100vh)] py-12">
          <div className="container mx-auto px-7 h-4/5 overflow-y-auto">
            <div className="grid grid-cols-3 border-b-2 py-4 text-black dark:text-white">
              <div className="flex justify-center items-center text-base md:text-5xl text-center font-bold">
                {t("Заказ")}
              </div>
              <div className="flex justify-center items-center text-base md:text-5xl text-center font-bold">
                {t("Статус")}
              </div>
              <div className="flex justify-center items-center text-base md:text-5xl text-center font-bold">
                {t("Где забрать")}
              </div>
            </div>
            <div>
              {FastomatClient.orders.map((e) => {
                return (
                  <div className="grid grid-cols-3 shadow-ty-shadow py-4 md:py-10 mt-5 md:mt-10 border-4 dark:border-2">
                    <div className="flex justify-center items-center  text-black dark:text-white text-base md:text-4xl font-semibold">
                      {e.ordername}
                    </div>
                    <div className="flex justify-center items-center font-semibold">
                      <img
                        src={`data:image/svg+xml;base64,${
                          FastomatClient.icons[e.orderstate - 1].stateicon
                        }`}
                        className="h-6 w-6 md:h-12 md:w-12"
                        alt=""
                      />
                    </div>
                    <div className="flex justify-center items-center  text-black dark:text-white text-base md:text-4xl font-semibold">
                      {
                        FastomatClient.icons[e.orderstate - 1].statename
                      }
                      {/* {e.windows[0]
                        ? "Окно " + e.windows[0]
                        : FastomatClient.icons[e.orderstate - 1].statename} */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="container mx-auto px-7 h-1/5 flex">
            <div className="flex w-full justify-between">
              <div className="grid grid-cols-4 grid-rows-2 w-7/12 text-center py-5">
                {FastomatClient.icons.map((e) => {
                  if (e.id === 10 || e.id === 7) return <></>;
                  return (
                    <div className="grid grid-rows-2 items-center justify-center p-3 gap-2 justify-items-center">
                      <img
                        src={`data:image/svg+xml;base64, ${e.stateicon}`}
                        className="h-6 w-6 md:h-12 md:w-12"
                        alt=""
                      />
                      <p className="text-black dark:text-white text-xs md:text-xl font-semibold">
                        {e.statename}
                      </p>
                    </div>
                  );
                })}
              </div>
              <NavLink
                to={"/"}
                className="w-5/12 flex justify-center items-center"
              >
                <img className="lg:h-full" src={fastomatImg} alt="" />
              </NavLink>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default observer(Fastomat);
