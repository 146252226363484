import { makeAutoObservable } from "mobx";
import { configure } from "mobx";
import { IProduct, IResponceProductList } from "../types/createorder";
import { categoryies } from "../utils/categories";

configure({
  enforceActions: "never",
});

class CreateOrder {
  Categories = [];
  Products: IProduct[] = [];
  Basket: IProduct[] = [];
  Filtred: IProduct[] = [];

  isPageFetch: boolean = false;
  PagesStructure = {
    page: 1,
    total: 0,
    totalPages: 0,
  };

  getCategoryName(id: number) {
    return categoryies.find((e) => e.id === id)?.categoryname;
  }
  getCategory(filter: string) {
    return categoryies.find((e) => e.categoryname === filter);
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default new CreateOrder();
