import { FC, useEffect } from 'react'
import Product from './Product'
import st from './Products.module.scss'
import ProductInfo from '../ProductInfo/ProductInfo'
import TYPicking from '@/store/TYPicking'
import { observer } from 'mobx-react-lite'
import { Language } from '@/utils/langSwitch'
import { dict } from './TranslationList'
import ProductsPage from './Products.store'
import Buttons from './Elements/Buttons'
import NotFoundProductsList from './NotFoundProducts/NotFoundProductsList'
import Loader from '../Loader/Loader'
import NotFoundProductsStore from './NotFoundProducts/NotFoundProducts.store'
import { FetchStages } from '@/types'

// Переводчик
const t = Language.translateWithDictionary(dict)

const Products: FC = () => {
	useEffect(() => {
		ProductsPage.light()
	}, [TYPicking.Products.length, TYPicking.idxSelectedProduct])
	// Выбранный продукт
	const currentProduct = () => TYPicking.Product()
	// Позиция выбранного продукта
	const currentPosition = () => TYPicking.Product().positions[0]

	return (
		<>
			<div className={st.container}>
				<div className={st.wrapper}>
					<ProductInfo
						name={currentProduct()?.ProductName}
						desc={currentProduct()?.CategoryName}
						image={currentProduct()?.Product_id}
						needcollect={currentProduct()?.Quant}
						collected={currentProduct()?.quant_sum}
						barcode={currentProduct()?.BarCode}
						CellName={currentPosition()?.cell_name || t('Отсутствует')}
						Col={currentPosition()?.rackcol || t('Отсутствует')}
						Row={currentPosition()?.rackrow || t('Отсутствует')}
					/>
					<div className={st.productslist}>
						<Product />
						<Loader
							loading={NotFoundProductsStore.isFetch === FetchStages.start}>
							<NotFoundProductsList />
						</Loader>
					</div>
				</div>
				<Buttons />
			</div>
		</>
	)
}
export default observer(Products)
