import { FC, useEffect } from "react";
import st from "../EditGuides.module.scss";
import { observer } from "mobx-react-lite";
import { noPhoto } from "../../../utils/nophoto";
import GuideHandler from "../store/GuideHandler";
import { getStages } from "../api/Guides";

const Stage3: FC = () => {
  useEffect(() => {
    (async () => {
      await getStages(GuideHandler.SelectedGuide?.id || 0);
      if (GuideHandler.Stages.length !== 0) {
        GuideHandler.SelectedStage = GuideHandler.Stages[0];
      }
    })();
  }, []);

  const AddNewStage = () => {
    GuideHandler.Stages.push({
      id: 0,
      Guide_id: GuideHandler.SelectedGuide?.id || 0,
      StageName: "Новая стадия инструкции",
      StageDescr: "",
      StageImage: null,
      SortOrder: null,
      GuideName: "",
    });
  };

  const NameHandler = (value: string) => {
    if (GuideHandler.SelectedStage) {
      GuideHandler.SelectedStage.StageName = value;
    }
  };
  const DescrHandler = (value: string) => {
    if (GuideHandler.SelectedStage) {
      GuideHandler.SelectedStage.StageDescr = value;
    }
  };

  const HandleFileRead = async (e: any) => {
    if (!GuideHandler.SelectedStage) return;
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    // @ts-ignore
    GuideHandler.SelectedStage.StageImage = base64;
    GuideHandler.SelectedStage.blob = file;
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  return (
    <div className={st.modalBody}>
      <p>Изменение стадий:</p>
      <div className={st.halfs}>
        <div className={st.left}>
          <div className={st.inputField}>
            <label>Название</label>
            <input
              type="text"
              value={`${GuideHandler.SelectedStage?.StageName || ""}`}
              onChange={(e) => NameHandler(e.target.value)}
            />
          </div>
          <div className={st.inputField} style={{ height: "100%" }}>
            <label>Описание</label>
            <textarea
              value={`${GuideHandler.SelectedStage?.StageDescr || ""}`}
              onChange={(e) => DescrHandler(e.target.value)}
            ></textarea>
          </div>

          <div className={st.inputField}>
            <label>Фотография стадии</label>
          </div>
          <div className={st.guideImage}>
            {GuideHandler.SelectedStage?.StageImage ? (
              <img
                src={`${GuideHandler.SelectedStage?.StageImage}`}
                alt="guidephoto"
              />
            ) : (
              <img
                src={`data:image/png;base64,  ${noPhoto}`}
                alt="guidephoto"
              />
            )}
            <input type="file" onChange={(e) => HandleFileRead(e)} />
          </div>
        </div>
        <div className={st.right}>
          <div className={st.customSelectPage3}>
            <p>Стадии</p>
            <div className={st.customSelect}>
              {GuideHandler.Stages.map((stage) => (
                <div
                className={`${st.guideitem} ${stage.id === GuideHandler.SelectedStage?.id ? st.activestage : null}`}
                  onClick={() => (GuideHandler.SelectedStage = stage)}
                >
                  {stage.StageName}
                </div>
              ))}
            </div>
          </div>

          <div className={st.centerAddPage3}>
            <div onClick={() => AddNewStage()}>
              <p style={{ height: "fit-content" }}>+</p>
              <p>Создать новую</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default observer(Stage3);
