import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import {
	getOrders,
	longpolling,
	longpollingFrontend
} from '@/API/fastomatWorkerAPI'
import Fastomat from './Fastomat'
import { useMount } from '@/hooks/useMount'
import { useUnmount } from '@/hooks/useUnmount'

const FastomatC: FC = observer(() => {
	// Longpolling timer
	const [timer, setTimer] = useState<NodeJS.Timer | null>(null)
	// All long polling functions
	const poolingTimer = () => {
		longpollingFrontend()
		longpolling()
	}

	useMount(() => {
		getOrders()
		// Set longpolling timer
		setTimer(setInterval(poolingTimer, 5000))
		setTimeout(window.location.reload, 600000)
	})

	// Clear longpolling timer
	useUnmount(() => {
		if (!timer) return
		clearInterval(timer)
	})

	return <Fastomat />
})

export default FastomatC
