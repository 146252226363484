import CellSort from '@/components/GantTable/CellSort'
import GantDaysLineList from '@/components/GantTable/GantDaysLineList'
import TableListTasks from '@/components/GantTable/TableListTasks'
import SearchInput from '@/components/UI/SearchInput'
import { useMount } from '@/hooks/useMount'
import GantDates from '@/store/Gant/GantDates'
import GantTasks from '@/store/Gant/GantTasks'
import Gant from '@/store/Settings/Gant/Gant'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'

const GantPage = () => {
	useMount(() => {
		GantTasks.sortTasks()
		GantTasks.fetchOrders()
		GantDates.getInitData()
	})
	const element = useRef<HTMLDivElement | null>(null)
	const dayList = useRef<HTMLDivElement>(null)
	// const isInViewport1 = useIsInViewport(element)
	// useEffect(() => {

	// 	const mouth = GantDates.gantTasks[0].mouth.id
	// 	const year = GantDates.gantTasks[0].year
	// 	console.table({ mouth, year })
	// 	if (dayList.current) {
	// 		dayList.current.scrollTo(dayList.current.clientWidth / 1.5, 0)
	// 	}
	// 	GantDates.getInitData({
	// 		currentMonth: mouth - 1,
	// 		currentYear: year
	// 	})
	// }, [isInViewport1])
	return (
		<div className='dark:bg-slate-800 dark:text-white min-h-screen'>
			<div className='flex flex-col text-xs'>
				<div className='container mx-auto pt-5 pb-8'>
					<SearchInput />
				</div>
				<div className='flex'>
					<TableListTasks />
					<div
						className='w-4/6 overflow-x-auto h-full overflow-y-hidden'
						ref={dayList}>
						<div className='dates w-full flex'>
							{GantDates.gantTasks.map(mouth => (
								<div>
									<div className='w-full text-center'>{mouth.mouth.name}</div>
									<div
										className={`flex ${Gant.isMouthPadding ? 'mx-5' : null}`}>
										<GantDaysLineList mouth={mouth} />
									</div>
								</div>
							))}
						</div>
						{GantTasks.filteredTasks.map(task => (
							<>
								<div
									key={task.id}
									className={`w-full flex py-2 
									border-black border-l-2 relative`}>
									<div
										className='w-4 absolute top-0 left-0 h-32'
										ref={element}></div>
									{GantDates.gantTasks.map(mouth => (
										<div
											className={`flex relative ${
												Gant.isMouthPadding ? 'mx-5' : null
											}`}>
											{mouth.days.map(day => (
												<CellSort
													task={task}
													day_id={day.id}
													mouth_id={mouth.mouth.id}
												/>
											))}
										</div>
									))}
								</div>
								{task.products.map(product => {
									return (
										<div
											key={task.id}
											className={`w-full flex py-2 
										border-black border-l-2`}>
											{GantDates.gantTasks.map(mouth => (
												<div
													className={`flex relative ${
														Gant.isMouthPadding ? 'mx-5' : null
													}`}>
													{mouth.days.map(day => (
														<CellSort
															task={{
																...task,
																id: product.id,
																isProduct: true,
																shipment: product.shipment_date || '',
																ready: product.production_date || '',
																created: product.created_date || ''
															}}
															day_id={day.id}
															mouth_id={mouth.mouth.id}
														/>
													))}
												</div>
											))}
										</div>
									)
								})}
							</>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default observer(GantPage)
