import { IDistTranslation } from '../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Кафе',
		eng: 'Cafe'
	},
	{
		ru: 'Дело Вкуса',
		eng: 'Delo Vkusa (A Matter of Taste)'
	},
	{
		ru: 'Корзина',
		eng: 'Basket'
	},
	{
		ru: 'Главная',
		eng: 'Main'
	},
	{
		ru: 'Категории',
		eng: 'Categories'
	},
	{
		ru: 'Профиль',
		eng: 'Profile'
	},
	{
		ru: 'Главная',
		eng: 'Main'
	},
	{
		ru: 'Профиль',
		eng: 'Profile'
	},
	{
		ru: 'Уведомления',
		eng: 'Notifications'
	},
	{
		ru: 'Категории',
		eng: 'Categories'
	},
	{
		ru: 'Хлебокомбинат №7',
		eng: 'Bakery No. 7'
	}
]
