import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import LeftNavPanel from '@/components/LeftNavPanel/LeftNavPanel'
import AdminHeader from '@/components/AdminHeader/AdminHeader'
import SectionSkelet from '@/components/SectionSkelet/SectionSkelet'
import AdminPageMain from '@/components/AdminPageMain/AdminPageMain'
import { Route, Routes } from 'react-router-dom'
import CategoriesPage from '../CategoriesPage/CategoriesPage'
import AddProductsPage from '../AddProductsPage/AddProductsPage'
import AboutNewsPage from '../AboutNewsPage/AboutNewsPage'
import WarehousePage from '../WarehousePage/WarehousePage'
import UpdateCategoryPage from '../UpdateCategoryPage/UpdateCategoryPage'
import Bitrix24 from '../Bitrix24/Bitrix24'
import Integration1C from '../Integration1C/Integration1C'
import TestingPage from '../TestingPage/TestingPage'
import UserProfile from '../UserProfile/UserProfile'
import MobileAdminMenu from '@/components/MobileAdminMenu/MobileAdminMenu'
import CreateCategoryPage from '../CreateCategoryPage/CreateCategoryPage'
import UserRouter from '@/components/UserRouter/UserRouter'
import SettingsPage from '../SettingsPage/SettingsPage'
import StoreZonesPage from '../StoreZones/StoreZonesPage'

const AdminPage: FC = () => {
	return (
		<div className='bg-slate-200 dark:bg-gray-800 min-h-screen'>
			<AdminHeader />
			<AdminPageMain>
				<LeftNavPanel />
				<SectionSkelet>
					<Routes>
						<Route path='/' element={<AboutNewsPage />} />
						<Route path='/products' element={<AddProductsPage />} />
						<Route path='/profile' element={<UserProfile />} />
						<Route path='/users/*' element={<UserRouter />} />
						<Route path='/warehouse' element={<WarehousePage />} />
						<Route path='warehouse/zone/:id/*' element={<StoreZonesPage />} />
						<Route path='/category' element={<CategoriesPage />} />
						<Route path='/category/create' element={<CreateCategoryPage />} />
						<Route path='/category/edit/:id' element={<UpdateCategoryPage />} />
						<Route path='/1c-integration' element={<Integration1C />} />
						<Route path='/bitrix24' element={<Bitrix24 />} />
						<Route path='/testing' element={<TestingPage />} />
						<Route path='/settings' element={<SettingsPage />} />
					</Routes>
				</SectionSkelet>
			</AdminPageMain>
			<MobileAdminMenu />
			{/* <ToastUpdate /> */}
		</div>
	)
}

export default observer(AdminPage)
