import st from '../Fastomat.module.scss'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Language } from '@/utils/langSwitch'
import { dict } from './TranslationList'
import LogoImg from '@/assets/header.jpg'

const t = Language.translateWithDictionary(dict)

const Header: FC = () => {
	return (
		<div className={st.header}>
			<div className={st.logo}>
				<img src={LogoImg} alt='headimg' />
			</div>
			<NavLink to={'/'}>
				<div className={st.btnlogout}>
					<p>{t('Выйти')}</p>
				</div>
			</NavLink>
		</div>
	)
}

export default Header
