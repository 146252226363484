import { FC } from 'react'
import FastomatWorker from '../../store/FastomatWorker'
import QRcode from './Subcomponents/OrderModels/QRcode'
import PickWin from './Subcomponents/OrderModels/PickWin'
import ModalAddOrder from './Subcomponents/OrderModels/ModalAddOrder'
import { observer } from 'mobx-react-lite'

const Modals: FC = () => {
	return (
		<>
			{FastomatWorker.addModal && <ModalAddOrder />}
			{FastomatWorker.PickWin && <PickWin />}
			{FastomatWorker.isQrEdit && <QRcode />}
		</>
	)
}

export default observer(Modals)
