import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getWarehouseEmpl } from "../../API/TYPickingAPI";
import { getWarehouse } from "../../API/wareHouseAPI";
import TYPicking from "../../store/TYPicking";
import Warehouse from "../../store/Warehouse";
import st from "./EmployeesPickWarehouse.module.scss";
import WareHouseItem from "./WareHouseItem/WareHouseItem";
import { dict } from "./TranslationList";
import { Language } from "../../utils/langSwitch";

// Переводчик
const t = Language.translateWithDictionary(dict);
const EmployeesPickWarehouse: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {

      if (TYPicking.Warehouse) {
        navigate(`/orders/`);
      }
      else{
        const response = await getWarehouseEmpl()
        console.log(response);
        if(response){
          TYPicking.Warehouse = response
          navigate(`/orders/`);
          return
        }
        if (!Warehouse.store.length) {
          await getWarehouse();
        }
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={st.wrapper}>
      <p className={st.stores}>{t("Начать рабочую смену на складе")}:</p>
      {Warehouse.getTreeProducts().map((e) => {
        return <WareHouseItem e={e} key={e.id} />;
      })}
    </div>
  );
};

export default observer(EmployeesPickWarehouse);
