import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import FastomatWorker from '@/store/FastomatWorker'
import Order from './Order'

const OrderList: FC = () => {
	const { orders } = FastomatWorker
	return (
		<div
			className={`flex items-center container mx-auto 
    justify-center overflow-x-hidden overflow-y-auto 
    flex-col max-w-[980px]`}>
			{orders.map(
				(e, idx) =>
					!FastomatWorker.filted &&
					(e.orderstate === 10 || e.orderstate === 7) && (
						<Order order={e} key={e.id} idx={idx} />
					)
			)}
			{orders.map(
				(e, idx) =>
					FastomatWorker.filted &&
					e.orderstate !== 10 &&
					e.orderstate !== 7 && 
          <Order order={e} key={e.id} idx={idx} />
			)}
		</div>
	)
}

export default observer(OrderList)
