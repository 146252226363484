import { IDistTranslation } from '@/utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Главная',
		eng: 'Dashboard'
	},
	{
		ru: 'Профиль',
		eng: 'Profile'
	},
	{
		ru: 'Продукты',
		eng: 'Products'
	},
	{
		ru: 'Категории',
		eng: 'Categories'
	},
	{
		ru: 'Склад',
		eng: 'Warehouse'
	},
	{
		ru: 'Битрикс 24',
		eng: 'Bitrix24'
	},
	{
		ru: 'Тестирование',
		eng: 'Testing'
	},
	{
		ru: 'Настройки',
		eng: 'Settings'
	},
	{
		ru: 'Пользователи',
		eng: 'Users'
	},
	{
		ru: '1С Интеграция',
		eng: '1С Integration'
	}
]
