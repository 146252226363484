import CellSort from '@/components/GantProductionTable/CellSort'
import GantDaysLineList from '@/components/GantProductionTable/GantDaysLineList'
import GantPagination from '@/components/GantProductionTable/GantPagination'
import StateColorSettings from '@/components/GantProductionTable/StateColorSettings'
import TableListTasks from '@/components/GantProductionTable/TableListTasks'
import SearchInput from '@/components/UI/SearchInput'
import { useMount } from '@/hooks/useMount'
import GantDates from '@/store/GantProduction/GantDates'
import GantTasks from '@/store/GantProduction/GantTasks'
import Gant from '@/store/Settings/Gant/Gant'
import { observer } from 'mobx-react-lite'

const GantProductionPage = () => {
	useMount(() => {
		GantTasks.sortTasks()
		GantTasks.fetchOrders()
		GantDates.getInitData()
	})

	return (
		<div className='dark:bg-slate-800 dark:text-white min-h-screen'>
			<div className='flex flex-col text-xs'>
				<div className='container mx-auto pt-5 pb-8'>
					<SearchInput />
					<GantPagination />
				</div>
				<div className='flex'>
					<TableListTasks />
					<div className='w-4/6 overflow-x-auto overflow-y-hidden'>
						<div className='dates w-full flex'>
							{GantDates.gantTasks.map(mouth => (
								<div>
									<div className='w-full text-center'>{mouth.mouth.name} - {mouth.year}</div>
									<div
										className={`flex ${Gant.isMouthPadding ? 'mx-5' : null}`}>
										<GantDaysLineList mouth={mouth} />
									</div>
								</div>
							))}
						</div>
						{GantTasks.filteredTasks.map(task => (
							<>
								<div
									key={task.id}
									// style={task.color ? { background: task.color, opacity: '0.8' } : {}}
									className={`w-full flex py-2 
									border-black border-l-2 relative`}>
									<div className='w-4 absolute top-0 left-0 h-32'></div>
									{GantDates.gantTasks.map(mouth => (
										<div
											className={`flex relative ${
												Gant.isMouthPadding ? 'mx-5' : null
											}`}>
											{mouth.days.map(day => (
												<CellSort
													task={task}
													day_id={day.id}
													mouth_id={mouth.mouth.id}
												/>
											))}
										</div>
									))}
								</div>
								{task.products.map(product => {
									return (
										<div
											key={task.id}
											className={`w-full flex py-2 
										border-black border-l-2`}>
											{GantDates.gantTasks.map(mouth => (
												<div
													className={`flex relative ${
														Gant.isMouthPadding ? 'mx-5' : null
													}`}>
													{mouth.days.map(day => (
														<CellSort
															task={{
																...task,
																id: product.id,
																isProduct: true,
																shipment: product.shipment_date || '',
																ready: product.production_date || '',
																created: product.created_date || ''
															}}
															day_id={day.id}
															mouth_id={mouth.mouth.id}
														/>
													))}
												</div>
											))}
										</div>
									)
								})}
							</>
						))}
					</div>
				</div>
			</div>
			<div className='flex justify-center'>
				<StateColorSettings preview={true} />
			</div>
		</div>
	)
}

export default observer(GantProductionPage)
