import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import axios from 'axios'

configure({
	enforceActions: 'never'
})


class CirleSending {
	idx = 0
	async send(count: number) {
		this.idx = Math.ceil(count / 10)
		for (let i = 0; i < this.idx; i++) {
			await axios.post(
				`http://localhost:8000/api/flashfastomat`,
				{ count: 10 },
				{
					headers: { 
            Authorization: `Bearer ${localStorage.access_token}` 
          }
				}
			)
		}
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new CirleSending()
