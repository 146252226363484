import { $authHost, $host, errorHandler } from '.'
import AddСategory from '../store/AddСategoryPage'
import Categories from '../store/Categories'
import { FetchStages } from '../types'
import { ICategories } from '../types/categoryChange'
import { reformImage } from '../utils/imageHandler'

export const getCategories = async () => {
	try {
		Categories.isFetching = FetchStages.start
		// $fixme auth
		const { data } = await $authHost.get<ICategories[]>('api/productcategory/')
		data.forEach(category => {
			category.photo = reformImage({
				PhotoBytea: category.photo,
				ImageType: 'image/svg+xml'
			})
		})
		AddСategory.categories = data
		Categories.categoryies = data
		Categories.isFetching = FetchStages.end
		return data
	} catch (err) {
		Categories.isFetching = FetchStages.error
		// getCategories();
	}
}

interface IAddCategory {
	id?: number
	categoryname: string
	categorydescr: string
	parent_id: string | null
	photo: any
}

export const postCategory = async (category: IAddCategory) => {
	try {
		const formData = new FormData()
		formData.append('categoryname', category.categoryname)
		formData.append('categorydescr', category.categorydescr)
		if (category.parent_id) {
			formData.append('parent_id', category.parent_id)
		}
		if (category.photo) {
			formData.append('photo', category.photo)
		}

		await $authHost.post<ICategories>('api/productcategory', formData)
		getCategories()
	} catch (err) {
		errorHandler(err, () => postCategory(category))
	}
}

export const putCategory = async (category: IAddCategory) => {
	try {
		const formData = new FormData()
		formData.append('id', `${category.id}`)
		formData.append('categoryname', category.categoryname)
		formData.append('categorydescr', category.categorydescr)
		if (category.parent_id) {
			formData.append('parent_id', category.parent_id)
		}
		if (category.photo) {
			formData.append('photo', category.photo)
		}

		await $authHost.put<ICategories[]>('api/productcategory/', formData)
	} catch (err) {
		errorHandler(err, () => postCategory(category))
	}
}

export const delCategory = async (id: number) => {
	try {
		await $authHost.delete(`api/productcategory/?id=${id}`)
	} catch (err) {
		errorHandler(err, () => delCategory(id))
	}
}
