import { NavLink } from 'react-router-dom'
import Theme from '@/store/Theme'
import { ReactComponent as BasketImg } from '@/assets/basket.svg'
import { FC } from 'react'
import { BASKET_ROUTE } from '@/utils/consts'
import { observer } from 'mobx-react-lite'
import BasketIndicator from '@/components/BasketIndicator'
import { dict } from '../TranslationList'
import { Language } from '@/utils/langSwitch'

// Переводчик
const t = Language.translateWithDictionary(dict)
const BasketBtnFooter: FC = () => {
	return (
		<>
			<NavLink
				to={BASKET_ROUTE}
				className={`flex lv:hidden flex-col justify-center 
        items-center cursor-pointer relative`}>
				<BasketIndicator />
				<div className='block md:hidden'>
					<BasketImg
						width={'1.75rem'}
						height={'1.75rem'}
						fill={Theme.isDarkTheme ? 'white' : 'black'}
					/>
				</div>
				<div className='hidden md:block'>
					<BasketImg
						width={'4rem'}
						height={'4rem'}
						fill={Theme.isDarkTheme ? 'white' : 'black'}
					/>
				</div>
				<p className='text-xs md:text-xl mt-1 dark:text-white'>
					{t('Корзина')}
				</p>
			</NavLink>
			<div className='px-5 py-2 hidden lv:block '>
				<NavLink
					to='/basket'
					className={`text-baseleading-6 text-gray-500 
          hover:text-gray-900 dark:hover:text-white 
          dark:text-gray-200 dark:hover:underline`}>
					{t('Корзина')}
				</NavLink>
			</div>
		</>
	)
}
export default observer(BasketBtnFooter)
