import FilterImg from '@/assets/filter.svg'
import ExitIcon from '@/assets/exit.svg'
import { observer } from 'mobx-react-lite'
import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react'
import st from './PushProductsPage.module.scss'
import Counter from '@/components/Counter'
import PushProducts from '@/store/PushProducts'
import {
	findProduct,
	getProducts,
	getStoreProducts,
	pushProducts
} from '@/API/PushProducts'
import Product from '@/components/PushProduct/Product/Product'
import { NavLink, useNavigate } from 'react-router-dom'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { ToastContainer } from 'react-toastify'
import { notification } from '@/utils/notification'
import { dict } from './TranslationList'
import { Language } from '@/utils/langSwitch'
import Categories from '@/store/Categories/Categories'

// Переводчик
const t = Language.translateWithDictionary(dict)
const PushProductsPage: FC = () => {
	const [category, setCategory] = useState<number>(135)
	const [filter, setFilter] = useState(false)

	useEffect(() => {
		if (typeof window !== 'undefined') {
			injectStyle()
		}
		getProducts(category)
		getStoreProducts()
		Categories.getCategories()
		document.body.addEventListener('scroll', e => scrollHandler(e))
		return () => {
			document.body.removeEventListener('scroll', e => scrollHandler(e))
		}
	}, [])

	useEffect(() => {
		PushProducts.Products = []
		PushProducts.Page = 2
		PushProducts.PagesStructure = {
			page: 2,
			total: 0,
			totalPages: 0
		}
		const elem = Categories.CategoriesList.find(e => e.id === category)
		if (elem) {
			;(async () => {
				await pushProducts(PushProducts.PagesStructure.page, elem?.id)
			})()
		}
		setFilter(false)
	}, [category])

	useEffect(() => {
		if (PushProducts.isPageFetch) {
			const nextPage = async () => {
				const elem = Categories.CategoriesList.find(e => e.id === category)
				await pushProducts(PushProducts.PagesStructure.page, elem?.id)
				PushProducts.PagesStructure.page = PushProducts.PagesStructure.page + 1
			}
			nextPage()
		}
	}, [PushProducts.isPageFetch])

	useEffect(() => {
		PushProducts.isPageFetch = false
	}, [PushProducts.PagesStructure.page])

	const scrollHandler = (e: Event) => {
		if (
			document.body.scrollHeight -
				(document.body.scrollTop + window.innerHeight) <
			900
		) {
			PushProducts.isPageFetch = true
		}
	}

	return (
		<>
			<header id={st.header}>
				<div>
					<div className={st.headerTag}>
						<p>{t('Продукты')}</p>
					</div>
					<div>
						<div className={st.ProductsInfoBlocks}>
							<div className={st.totalProducts}>
								<p>{t('Всего продуктов')}</p>
								<p className={st.count}>
									{/* 
										Активация счётчика 
										только после ответа сервера 
									*/}
									{!PushProducts.Total ? (
										0
									) : (
										<Counter count={PushProducts.Total} />
									)}
								</p>
							</div>

							<div className={st.totalProducts}>
								<p>{t('Продуктов на складе')}</p>
								<p className={st.count}>
									{/* 
										Активация счётчика 
										только после ответа сервера 
									*/}
									{!PushProducts.StoreProducts.length ? (
										0
									) : (
										<Counter count={PushProducts.StoreProducts.length} />
									)}
								</p>
							</div>
						</div>
					</div>
				</div>
			</header>

			<section id={st.section1}>
				<div className={st.productsList}>
					<div className={st.productListHeader}>
						<p>{t('Список продуктов')}</p>
						<div className={st.filter}>
							<img
								src={FilterImg}
								alt='filter'
								onClick={() => setFilter(prev => !prev)}
							/>
							{filter ? (
								<div className={st.filterModal}>
									<div className={st.categoryies}>
										{Categories.CategoriesList.map(category => (
											<div
												className={st.category}
												onClick={() => setCategory(category.id)}>
												<img
													src={category.photo || ''}
													alt='product category'
												/>
												<div>{category.categoryname}</div>
											</div>
										))}
									</div>
								</div>
							) : null}
						</div>
					</div>
					<div className={st.productsWrapper}>
						{PushProducts.Products.map(product => (
							<Product product={product} key={product.id} />
						))}
					</div>
				</div>
			</section>

			<footer id={st.footer}>
				<div className={st.container}>
					<div className={`${st.wrapper} ${st.navigation}`}>
						<NavLink to={`/`}>
							<img src={ExitIcon} alt='zxc' />
						</NavLink>
						<QRScan />
					</div>
				</div>
			</footer>
			<ToastContainer />
		</>
	)
}

export default observer(PushProductsPage)

const QRScan = () => {
	const [value, setValue] = useState('')
	const navigate = useNavigate()
	const input = useRef<any>()
	const enterHandler = async (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			setValue(input.current.value)
			const product = await findProduct(input.current.value)
			if (product) {
				navigate(`/gant-collect/commerce/push-products/push/${product?.id}`)
			} else {
				notification({ text: 'Продукт не найден', type: 'error' })
			}
			input.current.value = ''
		}
	}
	return (
		<input
			className={`bg-gray-50 border 
	border-gray-300 text-gray-900 text-sm 
		rounded-lg focus:ring-blue-500 
	focus:border-blue-500 block w-full p-2.5 
	dark:bg-gray-700 dark:border-gray-600 
	dark:placeholder-gray-400 dark:text-white 
	dark:focus:ring-blue-500 dark:focus:border-blue-500`}
			onKeyDown={e => enterHandler(e)}
			ref={input}
			placeholder='Место для QR кода'
		/>
	)
}
