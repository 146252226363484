import { observer } from "mobx-react-lite";
import { FC } from "react";
import StageHandler from "../store/StageHandler";
import Stage1 from "./Stage1";
import Stage2 from "./Stage2";
import Stage3 from "./Stage3";
import Stage4 from "./Stage4";
import Stage5 from "./Stage5";

const Body: FC = () => {
  return (
    <div>
      {(() => {
        switch (StageHandler.stage) {
          case 1:
            return <Stage1 />;
          case 2:
            return <Stage2 />;
          case 3:
            return <Stage3 />;
          case 4:
            return <Stage4 />;
          case 5:
            return <Stage5 />;
        }
      })()}
    </div>
  );
};
export default observer(Body);
