import { FC } from "react";
import st from "../EditGuides.module.scss";
import { observer } from "mobx-react-lite";
import CustomSelect from "./CustomSelect";
import StageHandler from "../store/StageHandler";
import GuideHandler from "../store/GuideHandler";

const Stage1: FC = () => {
  const CreateNewGuide = () => {
    GuideHandler.SelectedGuide = {
      id: 0,
      GuideName: "",
      GuideDescr: "",
      TargetProduct_id: null,
      BarCode: "",
      Group_id: null,
      GuideImage: "",
      SortOrder: null,
    };
    GuideHandler.SelectedStage = null
    GuideHandler.Stages = []
    StageHandler.error = null;
    StageHandler.stage = 2;
  };

  return (
    <div className={st.modalBody}>
      <p style={{ margin: "20px 0" }}>Инструкцию вы можете:</p>
      <div className={st.halfs}>
        <div className={st.left}>
          <p>Изменить текущую</p>
          <CustomSelect />
        </div>
        <div className={st.right}>
          <p>Создать новую</p>
          <div className={st.centerAdd} onClick={() => CreateNewGuide()}>
            <div>
              <p style={{ height: "fit-content" }}>+</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default observer(Stage1);
