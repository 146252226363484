import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
const GantPostHeader: FC = () => {
	const location = useLocation()
	return (
		<div className='w-full h-16 bg-white dark:bg-gray-900 border-t-black'>
			<div className='flex items-center h-full w-full justify-center'>
				<div
					className={`gap-11 text-gray-700 
        dark:text-white flex items-center text-center`}>
					{(() => {
						switch (location.pathname) {
							case '/gant-collect/commerce/':
							case '/gant-collect/commerce/order-managment':
							case '/gant-collect/commerce/push-products':
							case '/gant-collect/commerce/collect-order':
							case '/gant-collect/commerce/create-order':
								return <Commerce />
							case '/gant-collect/production/':
							case '/gant-collect/production/instraction':
							case '/gant-collect/production/create-instraction':
							case '/gant-collect/production/gant':
								return <Production />
							case '/gant-collect/supply/':
							case '/gant-collect/supply/gant':
								return <Supply />
							case '/gant-collect/filtration/':
							case '/gant-collect/filtration/gant':
								return <Filtration />
							default:
								return <></>
						}
					})()}
				</div>
			</div>
		</div>
	)
}

export default observer(GantPostHeader)

const Commerce = () => {
	return (
		<>
			<NavLink
				className={({ isActive }) => (isActive ? 'text-red-600 underline' : '')}
				to={'/gant-collect/commerce/collect-order'}>
				<p>Сборка заказов</p>
			</NavLink>
			<NavLink
				className={({ isActive }) => (isActive ? 'text-red-600 underline' : '')}
				to={'/gant-collect/commerce/create-order'}>
				<p>Создание заказа</p>
			</NavLink>
			<NavLink
				className={({ isActive }) => (isActive ? 'text-red-600 underline' : '')}
				to={'/gant-collect/commerce/push-products'}>
				<p>Раскладка товаров на складе</p>
			</NavLink>
			<NavLink
				className={({ isActive }) => (isActive ? 'text-red-600 underline' : '')}
				to={'/gant-collect/commerce/order-managment'}>
				<p>Управление заказами</p>
			</NavLink>
		</>
	)
}

const Production = () => {
	return (
		<>
			<NavLink
				className={({ isActive }) => (isActive ? 'text-red-600 underline' : '')}
				to={'/gant-collect/production/instraction'}>
				<p>Сборка по инструкции</p>
			</NavLink>
			<NavLink
				className={({ isActive }) => (isActive ? 'text-red-600 underline' : '')}
				to={'/gant-collect/production/create-instraction'}>
				<p>Создание инструкции</p>
			</NavLink>
			<NavLink
				className={({ isActive }) => (isActive ? 'text-red-600 underline' : '')}
				to={'/gant-collect/production/gant'}>
				<p>Заказы на производство</p>
			</NavLink>
		</>
	)
}

const Supply = () => {
	return (
		<>
			<NavLink
				className={({ isActive }) => (isActive ? 'text-red-600 underline' : '')}
				to={'/gant-collect/supply/gant'}>
				<p>Заказы на сырье</p>
			</NavLink>
		</>
	)
}
const Filtration = () => {
	return (
		<>
			<NavLink
				className={({ isActive }) => (isActive ? 'text-red-600 underline' : '')}
				to={'/gant-collect/filtration/gant'}>
				<p>График фасовки</p>
			</NavLink>
		</>
	)
}
