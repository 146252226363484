/* eslint-disable array-callback-return */
import { FC, useEffect, useState } from "react";
import st from "../EditGuides.module.scss";
import { observer } from "mobx-react-lite";
import GuideHandler from "../store/GuideHandler";
import { getProduts, getSearch } from "../api/Guides";
import { IProduct } from "../../../types/tycollectguides";

const Stage4: FC = () => {
  const [name, setName] = useState("");

  useEffect(() => {
    (async () => {
      await getProduts();
    })();
  }, []);

  
  useEffect(() => {
    (async () => {
      if(name === "") return;
      await getSearch(name)
    })()
  }, [name]);
  
  const SelectStage = (id: number) => {
    let obj = GuideHandler.Stages.find((stage) => stage.id === id);
    if (!obj) return;
    GuideHandler.SelectedStage = obj;
  };  
  const AddProduct = (product: IProduct) => {
    let id = product.id || 0
    let stage_id = GuideHandler.SelectedStage?.id || 0
    GuideHandler.Products.push({
      id,
      stage_id,
      product_id:product.id,
      quant: 1,
      ao_id: null,
      SortOrder: null,
      comment: null,
      productname: product.ProductName,
      OperationName: null,
      StageName: GuideHandler.SelectedStage?.StageName || '',
      new: true
    });
  };

  return (
    <div className={st.modalBody}>
      <p>Изменение списка компанентов:</p>
      <div className={st.halfs}>
        <div className={st.left}>
          <select
            className={st.customSelect}
            style={{ height: "45px" }}
            onChange={(e) => SelectStage(Number(e.target.value))}
          >
            {GuideHandler.Stages.map((stage) => (
              <option
                value={stage.id}
                className={st.guideitem}
                onClick={() => (GuideHandler.SelectedStage = stage)}
              >
                {stage.StageName}
              </option>
            ))}
          </select>

          <p id={st.stagecomp}>Компаненты стадии</p>

          <div className={st.customSelect} style={{ marginTop: "5px" }}>
            {GuideHandler.Products.map((product) => {
              if (product.stage_id !== GuideHandler.SelectedStage?.id) return;
              return <div>{product.productname}</div>;
            })}
          </div>
        </div>

        <div className={st.right}>
          <div className={st.search}>
            <label>Поиск продукта</label>
            <input 
            placeholder="Введите имя продукта"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
             />
          </div>

          {GuideHandler.Search.map((product) => {
            return (<div className={st.product} onClick={() => AddProduct(product)}>
              <div className={st.ProductName}>
                {product.ProductName}
              </div>
              <div className={st.addProduct}>
                <div>
                  <p>+</p>
                </div>
              </div>
            </div>);
          })}
        </div>
      </div>
    </div>
  );
};
export default observer(Stage4);
