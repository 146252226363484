import { IDistTranslation } from '../../../utils/langSwitch'

export const dict: IDistTranslation[] = [
	{
		ru: 'Выйти',
		eng: 'Quit'
	},
	{
		ru: 'Мир БЕЗ ОЧЕРЕДЕЙ',
		eng: 'A world WITHOUT QUEUES'
	},
	{
		ru: 'Добавить новый заказ',
		eng: 'Add a new order'
	},
	{
		ru: 'Собрать',
		eng: 'Collect'
	}
]
