import { configure, makeAutoObservable } from 'mobx'
import { IWarehouseAspectProps } from './WarehousMap.types'

configure({ enforceActions: 'never' })

class WarehouseMapStore {
	aspectRatio = {
		x: 1,
		y: 1
	}
	mapHeight = 0
	mapWidth = 0

	// высчитывание коэффициента соотношения сторон
	aspestCalc = ({ maxWidth, maxHeight, realHeight, realWidth }: IWarehouseAspectProps) => {
		const zoneHeight = Number(realHeight)
		const zoneWidth = Number(realWidth)
		if (zoneHeight > zoneWidth) {
			const aspect = zoneWidth / zoneHeight
			const height = maxHeight
			const width = maxHeight * aspect
			this.aspectRatio.y = maxHeight / zoneHeight
			this.aspectRatio.x = maxWidth / zoneWidth
			return { width, height }
		} else {
			const aspect = zoneHeight / zoneWidth
			const width = maxWidth
			const height = maxWidth * aspect
			this.aspectRatio.y = maxHeight / zoneHeight
			this.aspectRatio.x = maxWidth / zoneWidth
			return { width, height }
		}
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new WarehouseMapStore()
