export const windowHandler = (windows: number[]) => {
	let wincount = ''
	if (windows.length === 0) return
	if (windows?.length > 1) {
		wincount = 'Окна'
	} else {
		wincount = `Окно ${windows[0]}`
		return wincount
	}
	windows.forEach((e, idx) => {
		wincount += ` ${e}`
		if (!(idx === windows.length - 1)) {
			wincount += ','
		}
	})
	return wincount
}
