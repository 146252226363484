import AdminPanel from '@/store/Settings/AdminPanel/AdminPanel'
import { InitPages } from '@/store/Settings/AdminPanel/InitPages'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

const MobileAdminMenu: FC = () => {
	if (!AdminPanel.isMenu) return <></>
	return (
		<div
			onClick={AdminPanel.toogleMenu}
			className='fixed top-0 left-0 h-screen w-screen bg-black z-50 flex justify-center flex-col items-center gap-10'>
			{InitPages.map(page => {
				return (
					<NavLink className={`text-white`} to={'/admin' + page.url}>
						{page.name}
					</NavLink>
				)
			})}
		</div>
	)
}
export default observer(MobileAdminMenu)
