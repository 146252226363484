import { observer } from 'mobx-react-lite'
import { FC, ReactNode } from 'react'
import { ReactComponent as LoadingImg } from '../../assets/loading.svg'
import Theme from '../../store/Theme'

interface IProps {
	loading: boolean
	className?: string
	children: JSX.Element | JSX.Element[] | ReactNode
}

const Loader: FC<IProps> = ({ loading, children, className }) => {
	if (loading) {
		return (
			<div
				className={`w-full h-full text-center flex 
      justify-center items-center dark:text-white 
      ${className && className}`}>
				<div className='animate-spin h-7 w-7'>
					<LoadingImg 
            stroke={Theme.isDarkTheme ? 'white' : '#535358'} />
				</div>
			</div>
		)
	}
	return <>{children}</>
}
export default observer(Loader)
