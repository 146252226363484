import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import {
	post1Cproducts,
	post1CStore,
	post1COrders
} from '@/API/Integration1CAPI'
import st from './Integration1C.module.scss'
import { dict } from './TranslationList'
import { Language } from '../../utils/langSwitch'

const t = Language.translateWithDictionary(dict)
const Integration1C: FC = () => {
	const [file1, setFile1] = useState<any>(null)
	const [file2, setFile2] = useState<any>(null)
	const [file3, setFile3] = useState<any>(null)
	const [category_id, setCategory_id] = useState<string>('')
	const addFile = (file: React.ChangeEvent<HTMLInputElement>) => {
		// @ts-ignore
		setFile1(file.target.files[0])
	}
	const addFile2 = (file: React.ChangeEvent<HTMLInputElement>) => {
		// @ts-ignore
		setFile2(file.target.files[0])
	}
	const addFile3 = (file: React.ChangeEvent<HTMLInputElement>) => {
		// @ts-ignore
		setFile3(file.target.files[0])
	}

	return (
		<>
			<div className={st.wrapper}>
				<div className={st.form}>
					<div className={st.description}>
						<p>{t('Интеграция 1 - Товары')}</p>
					</div>
					<div className={st.fileinfo}>
						<p>
							{t(
								'Выберите файл xml который вы хотите загрузить в систему TakeYours из 1C'
							)}
						</p>
						<input type='file' onChange={e => addFile(e)} />
					</div>
					<div className={st.fileinfo}>
						<p>{t('Категория')}</p>
						<input
							type='text'
							value={category_id}
							onChange={e => setCategory_id(e.target.value)}
						/>
					</div>
					<div
						className={st.upload}
						onClick={() =>
							post1Cproducts({
								category_id: Number(category_id),
								products: file1
							})
						}>
						<p>{t('Загрузить')}</p>
					</div>
				</div>

				<div className={st.form}>
					<div className={st.description}>
						<p>{t('Интеграция 2 - Складские остатки')}</p>
					</div>
					<div className={st.fileinfo}>
						<p>
							{t(
								'Выберите файл xml который вы хотите загрузить в систему TakeYours из 1C'
							)}
						</p>
						<input type='file' onChange={e => addFile2(e)} />
					</div>
					<div 
            className={st.upload} 
            onClick={() => post1CStore(file2)}>
						<p>{t('Загрузить')}</p>
					</div>
				</div>

				<div className={st.form}>
					<div className={st.description}>
						<p>{t('Интеграция 3 - Заказы')}</p>
					</div>
					<div className={st.fileinfo}>
						<p>
							{t(
								'Выберите файл xml который вы хотите загрузить в систему TakeYours из 1C'
							)}
						</p>
						<input type='file' onChange={e => addFile3(e)} />
					</div>
					<div 
            className={st.upload} 
            onClick={() => post1COrders(file3)}>
						<p>{t('Загрузить')}</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default observer(Integration1C)
