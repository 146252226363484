import { IFastomatAPI } from "./../types/fastomatWorker";
import { $authHost, $host } from "./index";
import { IFreeWindows } from "../types/fastomatWorker";
import FastomatWorker from "../store/FastomatWorker";
import user from "../store/user";
import { toJS } from "mobx";

const errorHandler = async (e: any) => {
  console.error("Error in request");
  if (window.location.pathname === "/login") return;
  if ((e.response.status || 0) === 401) {
    const refresh = localStorage.getItem("refresh_token");
    try {
      let res = await $host.post("api/login/refresh/", { refresh });
      localStorage.setItem("access_token", res.data.access);
    } catch (e) {
      user.Authorization = false;
    }
  }
};

export const getOrders = async () => {
  try {
    let { data } = await $authHost.get<IFastomatAPI[]>("api/fastomatworker/");
    data.sort(function (a, b) {
      return (
        new Date(Date.parse(b.statechangetime)).getTime() -
        new Date(Date.parse(a.statechangetime)).getTime()
      );
    });
    FastomatWorker.orders = data;
  } catch (e) {
    await errorHandler(e);
    getOrders();
  }
};

export const updateOrderAPI = async (order: IFastomatAPI) => {
  try {
    let arrWins = [];
    for (let i = 0; i < order.windows.length; i++) {
      arrWins.push(order.windows[i]);
    }
    
    await $authHost.put(`api/fastomatworker/${order.id}`, {
      id: order.id,
      ordernum: order.ordernum,
      ordername: order.ordername,
      orderqr: order.orderqr,
      ordertext: order.ordertext,
      ordertime: order.ordertime,
      usernamecreated: order.usernamecreated,
      usernamechanged: order.usernamechanged,
      uuid: order.uuid,
      orderstate: order.orderstate,
      client: order.client?.id,
      windows: arrWins,
    });
    
  } catch (e) {
    errorHandler(e);
    updateOrderAPI(order);
  }
};

export let deleteOrderAPI = async (id: number) => {
  try {
    await $authHost.delete(`api/fastomatworker/${id}`);
  } catch (e) {
    errorHandler(e);
    deleteOrderAPI(id);
  }
};

interface IgetFreeWindowsProps {
  addFree(value: IFreeWindows[]): void;
}
export const getFreeWindows = async ({ addFree }: IgetFreeWindowsProps) => {
  try {
    let { data } = await $authHost.get<IFreeWindows[]>("api/getwindows");
    addFree(data);
  } catch (e) {
    errorHandler(e);
    getFreeWindows({ addFree });
  }
};

export let addwindow = async (id: number, window: number) => {
  try {
    await $authHost.post("api/postorderwindow", {
      order: id,
      window: window,
    });
  } catch (e) {
    errorHandler(e);
    addwindow(id, window);
  }
};

type insertProps = {
  ordernum?: number;
  ordername?: string;
  ordertext: string;
  orderstate: number;
  orderqr?: string;
};
export let insertOrderAPI = async ({
  ordernum,
  ordername,
  ordertext,
  orderstate,
  orderqr,
}: insertProps) => {
  try {
    const { data } = await $authHost.post<IFastomatAPI[]>(
      "api/fastomatworker/",
      {
        ordernum,
        ordername,
        ordertext,
        orderstate,
        orderqr,
        windows: [],
      }
    );
    FastomatWorker.orders = [...data, ...FastomatWorker.orders];
  } catch (e) {
    errorHandler(e);
  }
};

export const longpolling = async () => {
  // try {
    /*
    long pooling has 6 ticks of 5 seconds each 
    (in total 30 seconds = the lifetime of the request). If there 
    was no response within 6 ticks = sending a new request, if 
    there was, the ticks are reset and the request is refilled on 
    the trail.tick (after 5 seconds) 
    */

  //   if (FastomatWorker.request !== 6) {
  //     FastomatWorker.request = FastomatWorker.request + 1;
  //   }
  //   if (FastomatWorker.delay === true) {
  //     FastomatWorker.delay = false;
  //     return;
  //   }
  //   if (FastomatWorker.delay === false && FastomatWorker.request === 6) {
  //     // reset the timings
  //     FastomatWorker.request = 0;
  //     await $authHost.get<IFastomatAPI[]>("api/checkstates/");
  //     // update the timings
  //     FastomatWorker.delay = true;
  //     FastomatWorker.request = 5;

  //     // If there was no response -> exit
  //   }
  // } 
  // catch (e) {
  //   // reset request on error
  //   longpolling();
  //   errorHandler(e);
  // }
};

export const longpollingFrontend = async () => {
  let { data } = await $authHost.get<IFastomatAPI[]>("api/fastomatworker/");
  data.sort(function (a, b) {
    return (
      new Date(Date.parse(b.statechangetime)).getTime() -
      new Date(Date.parse(a.statechangetime)).getTime()
    );
  });

  if (JSON.stringify(data) === JSON.stringify(toJS(FastomatWorker.orders))) {
    console.log("data equal");

    return;
  } else {
    let added = [];
    let deleted = [];
    let updated = [];

    if (data.length !== FastomatWorker.orders.length) {
    }

    for (let i = 0; i < data.length; i++) {
      // Берем id текущенго заказа
      let id = data[i].id;
      // e = element
      // ищем такой в ткущем стейте заказ с таким же id
      let order = FastomatWorker.orders.find((e) => e.id === id);
      if (!order) {
        //если такой заказ не найден
        //то он удален
        deleted.push(data[i]);
      } else {
        // проверяем сходяться ли ключи у заказов
        for (const property in data[i]) {
          console.log();
          // @ts-ignore
          if (order[`${property}`] === data[i][property]) {
          } else {
            if (`${property}` === "orderstate") {
              if (!(order.orderstate === data[i].orderstate)) {
                // если стейт разный
                updated.push(data[i]);
                data[i].isCheck = true;
                setTimeout(() => {
                  delete data[i].isCheck;
                }, 1000);
                break;
              } else {
                break;
              }
            }
            if (`${property}` === "windows") {
              if (data[i].windows.length !== order.windows.length) {
                // если кол-во окон не совпадет с сервером
                updated.push(data[i]);
                data[i].isCheck = true;
                setTimeout(() => {
                  delete data[i].isCheck;
                }, 1000);
                break;
              }
              for (let j = 0; j < order.windows.length; j++) {
                if (order.windows[j] === data[i].windows[j]) {
                  // с окнами все ок
                } else {
                  // окна не совпали

                  updated.push(data[i]);
                  data[i].isCheck = true;
                  setTimeout(() => {
                    delete data[i].isCheck;
                  }, 1000);
                  break;
                }
                // проверка всех окон
              }
              break;
            }
            updated.push(data[i]);
            data[i].isCheck = true;
            setTimeout(() => {
              delete data[i].isCheck;
            }, 1000);
            break;
          }
        }
      }
    }
    FastomatWorker.orders = data;
    return updated;
  }
};
